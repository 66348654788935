<template>
        <div
            class="masonry-item"
            v-if="item"
            @click="item.fileFormat === 'video/mov'
                ? null : $emit('previewItem', item.id, 'videos')"
        >
            <img
                loading="lazy"
                style="max-height: 150px;object-fit:scale-down;"
                :src="getOptimizedS3ImageURL(
                    item.poster, { width: 250, height: 250 })"
                @load="loaded=true"
            />
            <div
                class="brand-kit-play w-100 h-100 d-flex align-center justify-center"
            >
                <div v-if="loaded">
                    <v-icon class="play-btn">mdi-play</v-icon>
                </div>
            </div>
            <div class="delete-btn" v-if="$route.name === 'Profile' && loaded">
                <v-btn
                    icon
                    x-small
                    color="black"
                    class="remove-item-icon"
                    v-on:click.stop="$emit('deleteItemConfirm',item.id)"
                >
                <v-icon size="12">mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'portfo-video-item-thumbnail',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>

<style scoped lang="scss">
.masonry-item {
  border-radius: 4px;
  width: 100%;
  background: #F5F5F5;
  position: relative;
  cursor: pointer;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: block;
    position: relative;
  }
  .delete-btn {
    display: none;
    position: absolute;
    top: 2px;
    right: 6px;
    .remove-item-icon {
      width: 16px;
      height: 16px;
      z-index: 2;
      background-color: #dadada;
      box-shadow: 0 1px 2px 0 rgba(125, 125, 125, 0.5);
    }
  }
  &:hover {
    .delete-btn {
      display: block;
    }
  }
}
.brand-kit-play {
    position: absolute;
    top: 0;
    .play-btn {
      background: rgba(0, 0, 0, 0.4);
      color: white;
      cursor: pointer;
    }
  }
</style>
