<template >
  <div class="selected-actions" :class="{'small-screen': this.$vuetify.breakpoint.sm}">
    <v-row>
      <v-col cols=8 sm=5 :class="{'mobile-screen': $vuetify.breakpoint.xsOnly}">
        <v-btn
        icon
        color="primaryGray1"
        @click="$emit('removeSelection')">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        {{count}} Selected
      </v-col>
      <v-col cols=4 sm=7 class="d-flex justify-end" :class="{'py-0': $vuetify.breakpoint.xsOnly}">
        <v-btn
        text
        class="px-2 selected-text"
        color="info"
        @click="$emit('addToMyPortfolio')">
          <v-icon color="info">mdi-plus</v-icon><span class="text">Add to My Portfolio</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .selected-actions {
    background: white;
    font-size: 16px;
    position: fixed;
    top: 0;
    z-index: 20;
    width: calc(100% - 320px);
    padding-top: 5px;
    &.small-screen {
      width: calc(100% - 56px);
      left: 56px;
    }
  }

  @media (max-width: 960px) {
    .selected-actions {
      left: 0;
      padding-right: 10px;
      width: 100%;
      .v-col {
        padding-top: 0px;
        padding-bottom: 0;
      }
      .v-btn {
        padding: 0 !important;
        font-size: 12px;
      }
    }
}

  @media (max-width: 600px) {
    .selected-actions {
      padding: 8px 13px;
      padding-right: 0px !important;
      top:42px !important;
      height: 54px;
      .selected-text {
        .mdi-plus {
          border: 1px solid;
          border-radius: 50%;
        }
        .text {
          display: none;
        }
      }
    }
    .mobile-screen {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 20px;
    }
  }
</style>
