<template>
  <div class="color-picker-container" :class="currentPage && 'brand-color-picker'">
    <div
      v-if="state === 'edit'"
      class="color-picker"
      :class="{'right': alignRight}"
    >
      <v-color-picker
          class="mx-auto"
          :hide-canvas="hideCanvas"
          :hide-inputs="hideInputs"
          :hide-mode-switch="hideModeSwitch"
          :mode.sync="mode"
          :show-swatches="showSwatches"
          v-bind="$attrs"
          v-on="$listeners"
          @update:color="logicalUpdate"
          append-icon="mdi-close"
      >
      </v-color-picker>
      <div class="color-picker-actions d-flex justify-end">
        <v-btn
          v-if="!$attrs.isNew"
          class="ml-4 mr-auto my-auto"
          color="info"
          x-small
          icon
          v-clipboard:copy="color"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <v-icon dark> mdi-content-copy </v-icon>
        </v-btn>
        <v-btn small text color="primaryGray1" @click="closeColor">Cancel</v-btn>
        <v-btn small text color="info" @click="updateColor">
          {{$attrs.isNew ? 'Save': 'Update'}}
        </v-btn>
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ColorPicker',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    currentPage: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: 'normal',
    },
    returnType: {
      type: String,
      default: 'hex',
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      modes: ['rgba', 'hsla', 'hexa'],
      mode: 'hexa',
      hideCanvas: false,
      hideInputs: false,
      hideModeSwitch: true,
      showSwatches: false,
      color: this.$attrs.value,
    });
  },
  methods: {
    ...mapActions(['setNotification']),
    logicalUpdate(colorObj) {
      this.color = colorObj[this.returnType];
      this.$emit('input', colorObj[this.returnType]);
    },
    updateColor() {
      this.$emit('update-color', this.color);
    },
    deleteColor() {
      this.$emit('delete-color');
    },
    closeColor() {
      this.$emit('close-color');
    },
    onCopy() {
      this.setNotification({
        message: 'Color copied to clipboard.',
        type: 'success',
      }, { root: true });
    },
    onError(e) {
      this.setNotification({
        message: e.message,
        type: 'error',
      }, { root: true });
    },
  },
};
</script>

<style lang="scss" scoped>
    .brand-color-picker, .color-picker-container {
      position: relative;
    }
    .brand-color-picker .color-picker {
      left: 50px;
      right: -230px;
      bottom: 0px;
      .v-color-picker {
        border: 1px solid #c0c0c0;
        border-bottom: none;
      }
      .color-picker-actions {
        border: 1px solid #c0c0c0;
        border-top: none;
      }
    }
    .color-picker-primary .color-picker{
      top: -40px;
    }
    .color-picker-secondary .color-picker{
      top: -138px;
    }
    .color-picker {
      background: white;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      position: absolute;
      right: 30px;
      bottom: 30px;
      &.right {
        right: -120px;
        bottom: 70px
      }
      z-index: 10;

      .v-color-picker {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        box-shadow: none;
        ::v-deep {
          .v-color-picker__controls {
            padding-bottom: 4px;
            padding-top: 5px;
          }
          .v-color-picker__edit{
            margin-top: 5px;
          }
        }
      }
    }
    .color-picker-actions {
      background-color: #ffff;
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      padding-bottom: 10px;
    }
    @media (max-width: 600px) {
     ::v-deep {
        .v-color-picker__input input {
         font-size: 16px;
        }
      }
    }
</style>
