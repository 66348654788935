<template>
  <v-container fluid class='content-wrapper'>
    <v-row class="main-content">
        <div class="brand-logo-wrapper">
            <div class="brand-logo-placeholder">
                <v-img
                    width="100%"
                    height="100%"
                    alt="brand logo"
                    src="@/assets/svg/theme/brandquiz/quiz.svg"/>
            </div>
        </div>
        <div class="brand-title">
            <h2>Brand Profile Quiz</h2>
        </div>
        <div class="brand-quiz-info-wrapper">
            <div class="brand-quiz-info">
                <p>This is where it all starts.
                    Your production experience at
                    Blended Sense begins with our help as we guide you through the Brand Style Quiz.
                    The results will populate on your dashboard in the platform
                    for you to access always.</p>
                <p>You will: Upload your Brand Logo,
                    provide your colors and fonts,
                    answer some questions about your brand style,
                listen to and select some music,
                and share any relevant inspirations
                so that we can get to know you intimately.
                </p>
            </div>
            <div class="brand-quiz-info-lines">
                <ul class="pl-0">Let's get started!</ul>
            </div>
        </div>
        <v-btn
            @click="$emit('start')"
            elevation="1"
            class="btn-purple"
        >Begin</v-btn>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable global-require */

export default {
  name: 'brand-quz-call-to-action',
};
</script>

<style scoped lang="scss">
.content-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.main-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.brand-logo-wrapper{
    margin-bottom: 1rem;
}
.brand-logo-placeholder{
    overflow: hidden;
    width: 10rem;
    height: 10rem;
}
.brand-quiz-info-wrapper{
    max-width: 650px;
}
.brand-quiz-info{
    margin: 1rem;
    text-align: center;
}
.brand-quiz-info-lines{
    margin: 1rem;
    display: flex;
    justify-content: center;
}
</style>
