<template>
<!-- eslint-disable max-len -->
    <v-container fluid class="content-wrapper bg-white h-100">
        <Loader v-if="isLoading" style="height:50vh;"/>
        <CallToActionPage
            v-if="!isLoading && this.customer && !enableBrandProfile"
            @start="openModal(1)"
        />
        <v-row v-else-if="!isLoading" class="ma-auto">
            <v-col cols="12" md="4" lg="4" xl="4">
                <div class="brand-profile-legend d-flex align-center">
                    <div class="brand-profile-title-wrapper">
                            <div>
                                <div
                                    style="height: 2rem"
                                    v-if="brandProfiles.length > 1"
                                >
                                    <v-select
                                        class="notranslate"
                                        v-model="currentBrandProfile"
                                        :items="brandProfiles"
                                        item-text="name"
                                        return-object
                                        solo
                                        @change="onSwitchBrandProfile"
                                        flat
                                        style="min-width:150px; height:0px;"
                                        ref="languageSelector"
                                    />
                                </div>
                                <div v-else>
                                    <span class="brand-profile-title font-h2">
                                        {{currentBrandProfile.name}}
                                    </span>
                                </div>
                            </div>
                    </div>
                </div>
                <editable-section-wrapper
                title="CONTACT & SOCIAL INFO"
                @edit="openModal(1)"
                :canEdit="checkEditAccess"
                >
                    <div class="general-info">
                        <div :key="generalInfo.brandProfileName.value" class="d-flex align-center" v-if="!!generalInfo.brandProfileName.value">
                            <div class="info-item-icon mr-2">
                                <v-img
                                    :src="
                                        getOptimizedS3ImageURL(
                                        generalInfo.brandProfileName.icon,
                                        { width: 20, height: 20})"
                                >
                                </v-img>
                            </div>
                            <div class="info-item-label notranslate font-body">{{generalInfo.brandProfileName.value}}</div>
                        </div>
                        <div :key="generalInfo.brandProfileName.value" class="d-flex align-center" v-if="generalInfo.website.value">
                            <div class="info-item-icon mr-2">
                                <v-img :src="generalInfo.website.icon"></v-img>
                            </div>
                            <a
                                class="info-item-label link a-link"
                                target="_blank"
                                rel="noopener"
                                :href="generateUrl(generalInfo.website.value)"
                            >{{generalInfo.website.value}}</a>
                        </div>
                        <!-- <div :key="generalInfo.brandProfileName.value" class="d-flex align-center" v-if="!!generalInfo.contact.value">
                            <div class="info-item-icon mr-2">
                                <v-img :src="generalInfo.contact.icon"></v-img>
                            </div>
                            <div class="info-item-label">{{generalInfo.contact.value}}</div>
                        </div> -->
                        <!-- <div class="d-flex align-center">
                            <div class="info-item-icon mr-2">
                                <img :src="generalInfo.email.icon" alt="email icon"/>
                            </div>
                            <div class="info-item-label">{{generalInfo.email.value}}</div>
                        </div> -->
                    </div>
                    <div class="general-info social-info">
                        <div class="subheader social-links">Social</div>
                        <template v-if="socialInfo.length>0">
                            <div class="d-flex align-center info-item"
                                v-for="(socialAccount) in socialInfo"
                                :key="socialAccount.value"
                            >
                                <div class="info-item-icon mr-2">
                                    <img :src="socialAccount.icon" :alt="socialAccount.type"/>
                                </div>
                                <a
                                    class="info-item-label link a-link"
                                    target="_blank"
                                    :href="generateUrl(socialAccount.value)">{{socialAccount.value}}</a>
                            </div>
                        </template>
                        <div v-else class="social-info-fallback">
                            Information not available
                        </div>
                    </div>
                </editable-section-wrapper>
                <editable-section-wrapper
                    title="COMPANY PURPOSE"
                    @edit="openModal(7)"
                    :hideEditAction="missionPurpose===''"
                    :canEdit="checkEditAccess"
                    :key="missionPurpose"
                >
                    <div class="mission-purpose-content" v-if="missionPurpose!==''">
                        <pre>{{missionPurpose}}</pre>
                    </div>
                    <brand-profile-section-placeholder
                        placeholderText="Enter your company purpose."
                        v-else
                        @start="openModal(7)"
                        :canEdit="checkEditAccess"/>
                </editable-section-wrapper>
                <editable-section-wrapper
                    title="ONBOARDING QUESTIONS"
                    :hideEditAction="false"
                    :canEdit="checkEditAccess"
                    @edit="toggleCustomerEdit(true)"
                >
                    <div class="questions pt-3">
                        <p class="que font-body">{{getQuestion()}}</p>
                        <div
                            class="ans font-body"
                            :key="brandProfileInfo('typeOfService')"
                        >{{brandProfileInfo('typeOfService')}}</div>
                    </div>
                    <div class="questions">
                        <p class="que font-body">Who is your customer?</p>
                        <div
                            class="ans font-body"
                            :key="brandProfileInfo('targetCustomers')"
                        >{{brandProfileInfo('targetCustomers')}}</div>
                    </div>
                    <div class="questions">
                        <p class="que font-body">What age is your customer?</p>
                        <div
                            class="ans ages font-body"
                            :key="brandProfileInfo('customerAges')"
                        >{{brandProfileInfo('customerAges')}}</div>
                    </div>
                    <div
                    class="questions" :key="getLocations.ans.toString()"
                    v-if="verticalType() === 'Professional Services' && getLocations.ans.length > 0">
                        <p class="que font-body">{{getLocations.que}}</p>
                        <ul
                        class="ans ages font-body"
                        v-for="(ans, index) in getLocations.ans"
                        :key="index"><li>{{ans.address}}</li></ul>
                    </div>
                </editable-section-wrapper>
            </v-col>
            <v-col cols="12" md="8" lg="8" xl="8">
                <editable-section-wrapper
                    title="BRAND LOGO FILES"
                    @edit="openModal(2)"
                    :hideEditAction="this.brandProfile.brandKits.length===0"
                    :canEdit="checkEditAccess"
                >
                    <brand-files
                        v-if="this.brandProfile.brandKits.length>0"
                        :downloadLink="downloadLink"
                        :files="brandLogosInfo.uploadedFiles"
                        @onBrandLogoFileClick="e => onBrandLogoFileClick(e, this.brandProfile.brandKits)"
                    />
                    <brand-profile-section-placeholder
                        placeholderText="Upload your brand files. Start here."
                        v-else
                        @start="openModal(2)"
                        :canEdit="checkEditAccess"
                    />
                </editable-section-wrapper>
                <editable-section-wrapper
                    title="BRAND COLORS"
                    @edit="openModal(3)"
                    :hideEditAction="!brandColorsInfo.hasBrandColors"
                    :canEdit="checkEditAccess"
                >
                    <brand-colors
                        v-if="brandColorsInfo.hasBrandColors"
                        :primaryColors="brandColorsInfo.primaryColors"
                        :secondaryColors="brandColorsInfo.secondaryColors"/>
                    <brand-profile-section-placeholder
                        placeholderText="Upload your brand colors. Start here."
                    v-else
                    @start="openModal(3)"
                    :canEdit="checkEditAccess"/>

                </editable-section-wrapper>
                <editable-section-wrapper
                    title="BRAND FONTS"
                    @edit="openModal(4)"
                    :hideEditAction="!brandFontInfo.hasBrandFonts"
                    :canEdit="checkEditAccess"
                >
                    <brand-fonts
                        v-if="brandFontInfo.hasBrandFonts"
                        :primary="brandFontInfo.primary"
                        :secondary="brandFontInfo.secondary"
                    />
                    <brand-profile-section-placeholder
                        placeholderText="Upload your brand fonts. Start here."
                    v-else
                    @start="openModal(4)"
                    :canEdit="checkEditAccess"/>

                </editable-section-wrapper>
                <template v-if="brandStyleInfo.hasBrandStyle && brandMusicInfo.hasBrandMusic">
                    <div class="d-flex flex-wrap">
                        <div class="mr-1 flex-1 d-flex" style="flex:1;">
                            <editable-section-wrapper
                                title="BRAND STYLE"
                                class="flex-1"
                                @edit="openModal(5)"
                                :hideEditAction="!brandStyleInfo.hasBrandStyle"
                                :canEdit="checkEditAccess"
                            >
                                <brand-style
                                    v-if="brandStyleInfo.hasBrandStyle"
                                    :imageUrl="brandStyleInfo.imageUrl"
                                    :name="brandStyleInfo.name"
                                    :key="brandStyleInfo.name"
                                />
                                <brand-profile-section-placeholder
                        placeholderText="Choose your brand style. Start here."
                                    v-else
                                    @start="openModal(5)"
                                    :canEdit="checkEditAccess"
                                />

                            </editable-section-wrapper>
                        </div>
                        <div class="flex-1 d-flex" style="flex:1">
                            <editable-section-wrapper
                                title="MUSIC"
                                class="flex-1"
                                @edit="openModal(6)"
                                :hideEditAction="!brandMusicInfo.hasBrandMusic"
                                :canEdit="checkEditAccess"
                            >
                                <brand-music
                                    v-if="brandMusicInfo.hasBrandMusic"
                                    :audio="brandMusicInfo.audio"
                                    :title="brandMusicInfo.title"
                                    :key="brandMusicInfo.title"
                                />
                                <brand-profile-section-placeholder
                        placeholderText="Choose your music style. Start here."
                                v-else
                                @start="openModal(6)"
                                :canEdit="checkEditAccess"/>
                            </editable-section-wrapper>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <editable-section-wrapper
                            title="BRAND STYLE"
                            @edit="openModal(5)"
                            :hideEditAction="!brandStyleInfo.hasBrandStyle"
                            :canEdit="checkEditAccess"
                        >
                            <brand-style
                                v-if="brandStyleInfo.hasBrandStyle"
                                :imageUrl="brandStyleInfo.imageUrl"
                                :name="brandStyleInfo.name"
                            />
                            <brand-profile-section-placeholder
                        placeholderText="Choose your brand style. Start here."
                                v-else
                                @start="openModal(5)"
                                :canEdit="checkEditAccess"
                            />

                        </editable-section-wrapper>
                        <editable-section-wrapper
                            title="MUSIC"
                            @edit="openModal(6)"
                            :hideEditAction="!brandMusicInfo.hasBrandMusic"
                            :canEdit="checkEditAccess"
                        >
                            <brand-music
                                v-if="brandMusicInfo.hasBrandMusic"
                                :audio="brandMusicInfo.audio"
                                :title="brandMusicInfo.title"/>
                            <brand-profile-section-placeholder
                        placeholderText="Choose your music style. Start here."
                            v-else
                            @start="openModal(6)"
                            :canEdit="checkEditAccess"/>
                        </editable-section-wrapper>
                </template>
                <editable-section-wrapper
                    title="VISUAL INSPIRATION"
                    @edit="openModal(8)"
                    :hideEditAction="!hasBrainstorm"
                    :canEdit="checkEditAccess"
                >
                    <brand-brainstorm
                        v-if="hasBrainstorm"
                        :links="brainStormInfo.brandKitLinks"
                        :uploads="brainStormInfo.uploadedFiles"
                        @onBrandLogoFileClick="e => onBrandLogoFileClick(e,brainStormInfo.uploadedFiles)"
                    />
                    <brand-profile-section-placeholder
                        placeholderText="Upload your visual inspiration. Start here."
                    v-else
                    @start="openModal(8)"
                    :canEdit="checkEditAccess"/>
                </editable-section-wrapper>
            </v-col>
        </v-row>
        <CustomerEditModal
            :brandProfileId="brandProfile.id"
            :reference="this.reference"
            :isModal="customerUpdateModal"
            @close-modal="toggleCustomerEdit(false)"
            :businessType="verticalType()"
            :locationsArr="[...getServiceLocations]"
        />
        <BrandModal
            :modalInitialStep="modalCurrentState"
            :isModal="showBrandQuizModal"
            @closeModal="closeModal"
            :brandProfileId="brandProfile.id"
            :canEdit="checkEditAccess"
        />
        <v-container
        fluid
        v-if="showBrandFilesPreview"
        >
          <Modal persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
            max-width="1280px"
            :modal="showBrandFilesPreview"
            content-class="bq-modal"
            :overlay-opacity="0.9"
          >
            <PortfolioItemPreview
                :prevItemIndex="prevIndex"
                :nextItemIndex="nextIndex"
                :item="currentFile"
                @close-modal="showBrandFilesPreview = false"
                @onNavigate="navigate"
            />
          </Modal>
        </v-container>
    </v-container>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import BusinessCards from '@/components/business/BusinessCards';
import ClientProfileCards from '@/components/profile/client/ProfileCards';
import BrandModal from '@/components/profile/client/brandQuiz/Layout';
import CustomerEditModal from '@/components/BrandProfile/CustomerDetailsModal';
import EditableSectionWrapper from '@/components/common/EditableSectionWrapper';
import BrandProfileSectionPlaceholder from '@/components/BrandProfile/BrandProfileSectionPlaceholder';
import BrandColors from '@/components/BrandProfile/BrandColors';
import BrandFonts from '@/components/BrandProfile/BrandFonts';
import BrandStyle from '@/components/BrandProfile/BrandStyle';
import BrandMusic from '@/components/BrandProfile/BrandMusic';
import BrandBrainstorm from '@/components/BrandProfile/BrandBrainstorm';
import BrandFiles from '@/components/BrandProfile/BrandFiles';
import CallToActionPage from '@/views/BrandProfileCallToAction';
import PortfolioItemPreview from '@/components/profile/creative/PortfolioItemPreview.vue'
import Upload from '@/components/common/Upload';
import { getOptimizedS3ImageURL, generateUrl } from '@/helpers/';
import Modal from '@/components/common/Modal';
import Loader from '@/components/common/Loader';

export default {
  components: {
    BusinessCards,
    ClientProfileCards,
    BrandModal,
    EditableSectionWrapper,
    BrandProfileSectionPlaceholder,
    BrandColors,
    BrandFonts,
    BrandStyle,
    BrandMusic,
    BrandBrainstorm,
    BrandFiles,
    CallToActionPage,
    Upload,
    CustomerEditModal,
    PortfolioItemPreview,
    Modal,
    Loader,
  },
  props: {
    page: {
        type: String,
        default: '',
    },
    reference: {
      type: String,
      default: 'business'
    },
    businessId: {
      type: Number,
    },
    agencyId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters('brandProfile', ['brandProfile']),
    ...mapGetters('user', ['userDetails']),
    enableBrandProfile() {
      const {
        brandColors,
        brandKits,
        brandKitLinks,
        purpose,
        brandMusic,
        brandStyle,
        brandKitInspirations,
        brandFonts,
        phoneNumber,
        website,
        instagram,
        facebook,
        linkedin,
        youtube,
        pinterest,
      } = this.brandProfile;
      const hasBrandFonts = !!(brandFonts.primary && brandFonts.primary.name)
             || !!(brandFonts.secondary && brandFonts.secondary.name);
      const brandMusicData = Object.keys(brandMusic).length > 0;
      const brandStyleData = Object.keys(brandStyle).length > 0;
      if (
        (website || facebook || instagram || linkedin || pinterest || youtube)
      || brandKits.length > 0
      || brandColors.length > 0
      || hasBrandFonts
      || brandStyleData
      || brandMusicData
      || phoneNumber
      || instagram
      || facebook
      || linkedin
      || youtube
      || pinterest
      || purpose
      || (brandKitInspirations.length > 0 || brandKitLinks.length > 0)) {
        return true;
      }
      return false;
    },
    bannerImage() {
      return (filePreview) => {
        const profilePic = _.get(this.brandProfile, 'logo');
        if (filePreview) {
          return filePreview;
        }
        if (profilePic) {
          this.setBorderVisibility();
          return profilePic;
        }
        return require('@/assets/svg/users.svg');
      };
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    checkEditAccess() {
        return this.canEditBrandProfile;
    },
    missionPurpose(){
        return (this.brandProfile.purpose || '').trim();
    },
    brandMusicInfo() {
        const info = {};
        info.hasBrandMusic = Boolean(this.brandProfile.brandMusic && Object.keys(this.brandProfile.brandMusic).length >0);
        info.audio = this.brandProfile.brandMusic.fileUrl;
        info.title = this.brandProfile.brandMusic.name;
        return info;
    },
    brandStyleInfo() {
        const info = {};
        info.hasBrandStyle = Boolean(this.brandProfile.brandStyle.name);
        info.name = this.brandProfile.brandStyle.name;
        info.imageUrl = this.brandProfile.brandStyle.imageUrl;
        return info;
    },
    brandFontInfo() {
        const info = {};
        info.hasBrandFonts = !!(this.brandProfile.brandFonts.primary && this.brandProfile.brandFonts.primary.name)
             || !!(this.brandProfile.brandFonts.secondary && this.brandProfile.brandFonts.secondary.name);
        info.primary = this.brandProfile.brandFonts.primary;
        info.secondary = this.brandProfile.brandFonts.secondary;
        return info;
    },
    hasBrainstorm(){
        return _.get(this.brandProfile, 'brandKitLinks', []).length > 0
        || _.get(this.brandProfile, 'brandKitInspirations', []).length > 0;
    },
    brainStormInfo(){
        const info = {};
        info.hasBrainstorm = this.brandProfile.brandKitLinks.length > 0 || this.brandProfile.brandKitInspirations.length > 0;
        info.brandKitLinks = this.brandProfile.brandKitLinks;
        info.uploadedFiles = this.brandProfile.brandKitInspirations.map(file => ({
            ...file,
            fileName: file.filename,
        }));
        return info;
    },
    brandLogosInfo(){
        const info = {};
        info.uploadedFiles = this.brandProfile.brandKits.map(file => ({
            ...file,
            fileName: file.filename,
            fileUrl: file.kit,
        }));
        return info;
    },
    generalInfo() {
        return ({
            brandProfileName: {
                icon: require('@/assets/svg/theme/user-outline.svg'),
                label: 'Name',
                value: this.brandProfile.name,
            },
            website: {
                icon: require('@/assets/svg/theme/globe-outline.svg'),
                label: 'Website',
                value: this.brandProfile.website,
            },
            contact: {
                icon: require('@/assets/svg/theme/phone-outline.svg'),
                label: 'Contact Number',
                value: this.brandProfile.phoneNumber,
            },
            email: {
                icon: require('@/assets/svg/theme/email-outline.svg'),
                label: 'Email',
                value: this.brandProfile.email,
            },
        });
    },
    socialInfo(){
        return ([
            {
                icon: require('@/assets/svg/instagram.svg'),
                type: 'instagram',
                value: this.brandProfile.instagram,
            },
            {
                icon: require('@/assets/svg/facebook.svg'),
                type: 'facebook',
                value: this.brandProfile.facebook,
            },
            {
                icon: require('@/assets/svg/linkedin.svg'),
                type: 'linkedin',
                value: this.brandProfile.linkedin,
            },
            {
                icon: require('@/assets/svg/theme/youtube-outline.svg'),
                type: 'youtube',
                value: this.brandProfile.youtube,
            },
            {
                icon: require('@/assets/svg/theme/pinterest.svg'),
                type: 'pinterest',
                value: this.brandProfile.pinterest,
            },
        ]).filter(item => !!item.value && item.value !== '0');
    },
    brandColorsInfo(){
        const info = {};
        info.hasBrandColors = this.brandProfile.brandColors && this.brandProfile.brandColors.length>0;
        info.primaryColors = (this.brandProfile.brandColors || [])
            .filter(color => color.colorType === '1')
            .map(color=>color.colorCode);
        info.secondaryColors = (this.brandProfile.brandColors || [])
            .filter(color => color.colorType === '2')
            .map(color=>color.colorCode);
        return info;
    },
    getLocations() {
        const location = {
            que: 'Where do you provide services?',
            ans: _.get(this.brandProfile, 'locations'),
        };
        return location;
    },
    getServiceLocations() {
      if (_.get(this.brandProfile, 'locations')) {
        return _.get(this.brandProfile, 'locations', [])
                .map((x, index) => (
                    { key: `location-${index}`, value: x.address }
                ));
      }
      return [];
    },
  },

  data() {
    return ({
        canEditBrandProfile: false,
        imageLoading: false,
        borderVisiblity: false,
        file: '',
        modalCurrentState: { step: 1 },
        showBrandQuizModal: false,
        isLoading: true,
        user: {},
        customerUpdateModal: false,
        showBrandFilesPreview: false,
        currentFile:  null,
        prevIndex: null,
        nextIndex: null,
        currentPreviewItems: [],
        brandProfiles: [],
        currentBrandProfile: null,
    });
  },
  methods: {
    ...mapActions('brandProfile', [, 'getBrandSources', 'getBrandProfile',
        'downloadLink', 'updateBrandQuizVisit', 'uploadBusinessBanner',
        'checkBrandProfileEditAccess', 'getBrandProfilesListForBusiness',
    ]),
    generateUrl,
    getOptimizedS3ImageURL,
    ...mapActions('profile', ['getProfile']),
    async boot() {
        try {
            this.isLoading = true;
            const { businessId, agencyId, reference } = this;
            let info = {
                brandProfile: null,
            };
            
            let { brandProfile } = await this.getBrandProfile({ 
                businessId: this.currentBrandProfile.agencyId || businessId,
                agencyId: this.currentBrandProfile.agencyId,
                reference: this.currentBrandProfile.type,
            });
            info.brandProfile = brandProfile;
        
            this.canEditBrandProfile = await this.checkBrandProfileEditAccess(
                { brandProfileId: info.brandProfile.id, page: this.page }
            );
            // if (this.reference === 'agency') {
            //     const brandProfileDropDownOption = {
            //         type: 'agency',
            //         agencyId,
            //         name: info.brandProfile.name,
            //     }
            //     this.currentBrandProfile = brandProfileDropDownOption;
            //     this.brandProfiles = [brandProfileDropDownOption]
            // }
            await this.getBrandSources({ businessId, agencyId, reference });
            const user = await this.getProfile();
            this.user = user;
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    },
    onSwitchBrandProfile(brandProfile) {
        this.currentBrandProfile = brandProfile;
        this.boot();
    },
    setBorderVisibility() {
      this.borderVisiblity = true;
    },
    onBrandLogoFileClick(event, files) {
        this.previewItem(event.id, files);
    },
    brandProfileInfo(key) {
        let data = _.get(this.brandProfile, key);
        if (key === 'customerAges') {
            data = data.map((x) => (x.name)).join(', ');
            return data;
        }
        if (data instanceof Object) {
            return data.name;
        } else {
            return data;
        }
    },
    verticalType() {
      const verticalType = _.get(this.brandProfile, 'verticalType.name');
      return verticalType;
    },
    getQuestion() {
        const { name } = (this.brandProfile.verticalType || {});
        if (name === 'Professional Services') {
            return 'What type of services do you offer?';
        }else if (name === 'E-Commerce') {
            return 'What type of physical product do you sell?';
        } else if (name === 'Technology') {
            return 'What type of technology do you sell?';
        }
        return 'What type of product do you sell?';
    },
    async bannerImageUpdate(file) {
        this.imageLoading = true;
        await this.uploadBusinessBanner({
            file,
            brandProfileId: this.brandProfile.id,
            reference: this.reference
        });
      this.imageLoading = false;
    },
      openModal(step){
          this.modalCurrentState = { step };
          this.showBrandQuizModal = true;
      },
      closeModal(){
          this.showBrandQuizModal = false;
          this.modalCurrentState = { step: 1 };
      },

      toggleCustomerEdit(val) {
        this.customerUpdateModal = val; 
      },
    navigate(index) {
      this.currentFile = Object.assign({}, this.currentPreviewItems[index]);
      this.prevIndex = index - 1;
      this.nextIndex = index === this.currentPreviewItems.length - 1 ? -1 : index + 1;
    },
    previewItem(id, files) {
      this.currentPreviewItems = files.map(file => {
        return {
          ...file,
          file: file.kit || file.fileUrl,
          fileType: this.getFileType(file.kit || file.fileUrl),
          fileFormat: this.getFileFormat(file.kit || file.fileUrl),
        }
      });
      this.showBrandFilesPreview = true;
      const itemIndex = _.findIndex(this.currentPreviewItems, ['id', id]);
      this.currentFile = this.currentPreviewItems[itemIndex];
      this.prevIndex = itemIndex - 1;
      this.nextIndex = itemIndex === this.currentPreviewItems.length - 1 ? -1 : itemIndex + 1;
    },
    getFileFormat(fileName) {
        const fileExtension = (fileName.split('.').pop() || '').toLowerCase();
        // order for photo, video, pdf, audio
        if (['jpg','jpeg','png', 'svg'].indexOf(fileExtension) !== -1) {
            return `image/${fileExtension}`;
        }
        if (['mp4','avi','mov','m4v'].indexOf(fileExtension) !== -1) {
            return `video/${fileExtension}`;
        }
        if (['mp3','wav','aac'].indexOf(fileExtension) !== -1) {
            return `audio/${fileExtension}`;
        }
        if (fileExtension === 'pdf') {
            return `application/pdf`;
        }
        return `application/${fileExtension}`;
    },
    getFileType(fileName)  {
        const fileExtension = (fileName.split('.').pop() || '').toLowerCase();
        // order for photo, video, pdf, audio
        if (['jpg','jpeg','png', 'svg'].indexOf(fileExtension) !== -1) {
            return '1';
        }
        if (['mp4','avi','mov','m4v'].indexOf(fileExtension) !== -1) {
            return '2';
        }
        if (['mp3','wav','aac'].indexOf(fileExtension) !== -1) {
            return '4';
        }
        return '3';
    }
  },
  async mounted(){
    if (this.reference === 'business') {
        this.brandProfiles = await this.getBrandProfilesListForBusiness({
            businessId: this.businessId,
            reference: this.reference,
        });
        if (this.brandProfiles.length) {
            // eslint-disable-next-line prefer-destructuring
            this.currentBrandProfile = this.brandProfiles[0];
        }
    }
    this.boot();
  }
};
</script>

<style scoped lang="scss">
.content-wrapper{
    min-height: 50vh;
}
.questions:last-child {
    padding-bottom: 16px;
}
    .questions {
        font-family: $fontFamily1;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        color: $charcoalBlack;
        padding-top: 10px;
        padding-bottom: 10px;
        .que {
            font-weight: bold;
            margin-bottom: 5px;
        }
        .ans.ages {
            line-height: 1.5;
        }
    }
  .profile-image-container {
    border: 2px solid #565682;
    border-radius: 16px;
    height: 100%;
    position: relative;
    &.upload {
      cursor: pointer;
    }
  }
  .profile-image {
    object-fit: contain;
    border-radius: 16px;
  }
  .profile-image-overlay {
    position: absolute;
    height: 42px;
    bottom: 0;
    background: $neutral5;
    display: none;
    width: 100%;
    border-radius: 0 0 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-image-container:hover .profile-image-overlay {
    display: flex;
  }
  .border-none {
       border: none;
       .profile-image-overlay {
         border-radius: 0 0 16px 16px;
       }
    }
.mission-purpose-content {
    overflow: auto;
    max-height: 20rem;
}
.brand-profile-image-wrapper{
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    & > img{
        width: 100%;
        height: 100%;
    }
}
.brand-profile-legend{
    margin-bottom: 2rem;
    .brand-profile-title-wrapper{
        width: 100%;
        overflow-wrap: anywhere;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
}
.mission-purpose-content pre{
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family: inherit;
}
.general-info{
    padding: 0.5rem;
    & > div {
        padding: 0.5rem 0rem;
        & > .info-item-icon{
            width: 1.5rem;
            & > img {
                width: 1.5rem;
            }
        }
        & > .info-item-label{
            overflow: hidden;
            text-overflow: ellipsis;
            &.link:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
.subheader.social-links{
    margin-top: -0.5rem;
    font-weight: 500;
    border-bottom: 2px solid #EAEAEA;
}
.social-info-fallback{
    color: gray;
}
</style>

