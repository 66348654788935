<template>
  <div>
    <div
      class="sub-deliverable"
      >
        <v-row>
            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="9"
              xl="9"
              xxl="9"
              class="primary-col flex-1">
            <v-row class="deliverables-inputs">
              <!-- :cols="columnWidths.type" -->
                <v-col
                xs="12"
                sm="12"
                md="12"
                :lg="columnWidths"
                :xl="columnWidths"
                :xxl="columnWidths"
                  >
                  <v-select
                      :items="deliverableTypes"
                      :label="changeTypeLabel"
                      dense
                      outlined
                      item-text="name"
                      item-value="id"
                      append-icon="keyboard_arrow_down"
                      v-model="subDeliverableData.deliverableId"
                      :error-messages="deliverableTypeError"
                      :hide-details="!Boolean(deliverableTypeError)"
                  />
                </v-col>
                <!-- :cols="columnWidths.quantity" -->
                <v-col
                  lg="2"
                  xl="2"
                  xxl="2"
                   v-if="enabledFieldState.quantity.show">
                  <v-select
                      :items="quantityOptions"
                      :label="changeLabel"
                      dense
                      outlined
                      hide-details
                      append-icon="keyboard_arrow_down"
                      v-model="subDeliverableData.quantity"
                  />
                </v-col>
                <!-- :cols="columnWidths.dimensions" -->
                <v-col
                  v-if="enabledFieldState.dimensions.show">
                  <v-select
                      :items="dimensions"
                      label="Dimensions"
                      dense
                      item-text="name"
                      item-value="id"
                      outlined
                      multiple
                      append-icon="keyboard_arrow_down"
                      small-chips
                      hide-details
                      v-model="subDeliverableData.dimensions"
                  >
                  </v-select>
                </v-col>
                <!-- <v-col :cols="columnWidths.locations" v-if="enabledFieldState.locations.show">
                  <v-select
                    :items="locationOptions"
                    label="Locations"
                    dense
                    outlined
                    hide-details
                    no-data-text="Select a type"
                    append-icon="keyboard_arrow_down"
                    v-model="subDeliverableData.locations"
                  />
                </v-col> -->
            </v-row>
            <div
                v-if="!showDescription"
                class="sub-deliverable-desc-ad-button"
                @click="() => showDescription = true"
            >
                Add Description
            </div>
            <div v-else>
              <textarea
                v-model="subDeliverableData.description"
                class="sub-deliverable-description"
                placeholder="Add Description"
                ></textarea>
            </div>
            </v-col>
            <!-- eslint-disable-next-line -->
            <v-col
            :class="$vuetify.breakpoint.smAndDown
                && deliverableCredits > 0 ? 'ml-5' : 'ml-0'"
              class="d-flex align-center sub-del-cred-section"
            cols="2">
            <!-- eslint-disable-next-line -->
            <div class="sub-deliverable-credits-container">
                <div
                    class="sub-deliverable-credits"
                    :key="
                        subDeliverableData.deliverableId + ':' +subDeliverableData.quantity">
                    <span>{{deliverableCredits > 0 ? deliverableCredits : '#'}}</span>
                </div>
                <div>CREDITS</div>
            </div>
            </v-col>
            <v-col
            class="d-flex justify-center sub-del-action-section"
            cols="1">
            <v-img
              class="sub-deliverable-remove-icon cursor-pointer"
              src="@/assets/svg/theme/trash.svg" contain width="20" height="20"
              @click="handleRemoveSubDeliverable"
            />
            </v-col>
        </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
// import BaseInput from '@/components/common/BaseInput';

export default {
  name: 'OrderSubDeliverable',
  components: {
    // BaseInput,
  },
  props: {
    subDeliverableData: {
      type: Object,
      required: true,
    },
    removeSubDeliverable: {
      type: Function,
      required: true,
    },
    deliverableData: {
      type: Object,
      required: true,
    },
    state: {
      type: String,
    },
    sweep: {
      type: Object,
    },
    index: {
      type: Number,
    },
    // sweepIndex: {
    //   type: Number,
    // },
    // sweepDeliverable: {
    //   type: Object,
    // },
  },
  watch: {
    // eslint-disable-next-line
    'subDeliverableData.deliverableId'(deliverableId) {
      if (deliverableId) {
        // eslint-disable-next-line
        const cost = _.get(_.find(this.deliverableTypes,{ id: deliverableId }), 'creditCost', 0);
        // const deliverableName
        // = _.get(_.find(this.deliverableTypes, { id: deliverableId }), 'name', '');
        if (this.state) {
          // eslint-disable-next-line
          // console.log('fdsafa', this.sweepDeliverable);
          // if (this.subDeliverableData.subId === this.sweepDeliverable.subId) {
          //   this.sweepDeliverable.creatives.push({
          //     name: create.name,
          //   });
          // }
          // console.log('fdsaf', this.subDeliverableData.subId);
          // this.$emit('checkSweepDetails');
          // if (this.subDeliverableData.subId === this.sweep.subId) {
          //   this.subDeliverableData.sweeps = this.sweep;
          // }
          // get digit from deliverableName using regex
          // const digit = parseInt(deliverableName.match(/\b(0|1?\d|20)\b/), 10);
          this.numLocations = 4;
        }
        // eslint-disable-next-line
        this.subDeliverableData.creditCost = cost;
        this.subDeliverableData.id = undefined;
        this.$emit('updateCredits');
      }
    },
    // eslint-disable-next-line
    'subDeliverableData.quantity'() {
      this.$emit('updateCredits');
    },
  },
  methods: {
    handleRemoveSubDeliverable() {
      this.removeSubDeliverable();
      this.$emit('updateCredits');
    },
  },
  computed: {
    ...mapGetters('contentKit', ['dimensions']),
    changeLabel() {
      const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
      if (/Sweep/i.test(typeOfDeliverable)) {
        return 'Hours';
      }
      return 'QTY';
    },
    changeTypeLabel() {
      const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
      if (/Sweep/i.test(typeOfDeliverable)) {
        return 'Creative Type...';
      }
      return 'Select Type...';
    },
    // deliverableCreditsName() {
    //   const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
    //   if (/Sweep/i.test(typeOfDeliverable)) {
    //     return 'credits / hour';
    //   }
    //   return 'credits';
    // },
    deliverableTypes() {
      const options = _.get(this.deliverableData, 'options', []);
      // if (this.state) {
      //   const sweepoptions = this.sweepDeliverables;
      //   return sweepoptions.map((option) => ({
      //     id: option.id,
      //     name: option.category.name,
      //     creditCost: option.creditCost,
      //   }));
      // }
      return options.map((option) => ({
        id: option.id,
        name: `${option.name} (${option.creditCost} credits)`,
        creditCost: option.creditCost,
      }));
    },
    locationOptions() {
      const options = [];
      for (let i = 0; i < this.numLocations; i += 1) {
        options.push(i + 1);
      }
      return options;
    },
    sweepAccess() {
      const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
      let result = false;
      // let result = {
      //   show: /(photo|video|audio|add-ons|add ons)/i.test(typeOfDeliverable),
      // };
      if (/(photo|video|audio|add-ons|add ons)/i.test(typeOfDeliverable)) {
        result = true;
      }
      if (this.state) {
        if (this.sweep.id === this.subDeliverableData.subId) {
          result = true;
        }
      }
      return result;
    },
    enabledFieldState() {
      const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
      const result = {
        dimensions: {
          show: /(photo|video)/i.test(typeOfDeliverable),
        },
        quantity: {
          show: /(photo|video|audio|add-ons|add ons|Sweeps)/i.test(typeOfDeliverable),
        },
        // locations: {
        //   show: !/(photo|video|Sweeps)/i.test(typeOfDeliverable),
        // },
      };
      return result;
    },
    columnWidths() {
      const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
      let result = {};
      if (/sweeps/i.test(typeOfDeliverable)) {
        result = 10;
      }
      if (/(photo|video)/i.test(typeOfDeliverable)) {
        result = 6;
      }
      return result;
    },
    // columnWidths() {
    //   const typeOfDeliverable = _.get(this.deliverableData, 'meta.creativeType.name', '');
    //   const result = {};
    //   // photo, video, => type, dimensions, quantity, (no change of col widths)
    //   // add-ons, audio => type, quantity,
    //   // sweeps => type, locations
    //   if (/(photo|video)/i.test(typeOfDeliverable)) {
    //     result.type = 6;
    //     result.dimensions = 4;
    //     result.quantity = 2;
    //     result.locations = 0;
    //   } else if (/(add-ons|audio|add ons)/i.test(typeOfDeliverable)) {
    //     result.type = 10;
    //     result.dimensions = 0;
    //     result.quantity = 2;
    //     result.locations = 0;
    //   } else if (/sweeps/i.test(typeOfDeliverable)) {
    //     result.type = 10;
    //     result.dimensions = 0;
    //     result.locations = 4;
    //     result.quantity = 2;
    //   }
    //   return result;
    // },
    deliverableTypeError() {
      return this.subDeliverableData.errors.deliverableType;
    },
    quantityOptions() {
      // 1 to 25
      const limit = this.state ? 18 : 25;
      const options = [];
      // eslint-disable-next-line
      for (let i = this.state ? 2 : 1; i <= limit; i++) {
        // options.push(i);
        if (this.state) {
          options.push((i + 2) / 2);
        } else {
          options.push(i);
        }
      }
      return options;
    },
    deliverableCredits() {
      const id = _.get(this.subDeliverableData, 'deliverableId', null);
      if (!id) return 0;
      const cost = _.get(_.find(this.deliverableTypes, { id }), 'creditCost', 0);
      return Math.round(this.subDeliverableData.quantity * cost);
    },
  },
  data() {
    return ({
      // sweepRemove: false,
      showDescription: Boolean(this.subDeliverableData.description),
      numLocations: 0,
    });
  },
  mounted() {
    // if (this.index === 0 && this.state) {
    //   this.sweepRemove = true;
    // }
    const deliverableId = _.get(this.subDeliverableData, 'deliverableId', null);
    if (deliverableId) {
      // const deliverableName
      // = _.get(_.find(this.deliverableTypes, { id: deliverableId }), 'name', '');
      // console.log('fdsfas', this.deliverableTypes);
      // console.log('fdfsa', /Sweep/i.test(deliverableName));
      if (this.state) {
        // get digit from deliverableName using regex
        // const digit = parseInt(deliverableName.match(/\b(0|1?\d|20)\b/), 10);
        this.numLocations = 4;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.primary-col {
  max-width: unset !important;
  flex-basis: 1;
  flex-grow: 1;
}
.sub-deliverable-credits-container {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #828282;
    .sub-deliverable-credits {
      font-size: 30px;
      line-height: 45px;
    }
}
  .sub-deliverable-desc-ad-button {
    color: $secondary1;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  .sub-deliverable {
    border: 1px solid #e0e0e0;
    padding: 0rem 1rem;
    border-radius: 5px;
    background: white;
    margin: 1rem 0rem;
    &:first-child {
      margin-top: 0;
    }
    ::v-deep {
      .v-select .v-select__slot label, .v-select__selections .v-select__selection {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4F4F4F;
      }
    }
  }
  .sub-del-cred-section {
    max-width: 120px;
    display: flex;
    position: relative;
    justify-content: center;
    border-right: 2px solid #e0e0e0;
    // left border with ::before
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 2px;
      height: 80%;
      top: 50%;
      transform: translateY(-50%);
      background: #e0e0e0;
    }
  }
 .sub-deliverable-description{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4F4F4F;
    width: 100%;
    height: 80px;
    border: 1px solid #e5e8ed;
    border-radius: 4px;
    padding: 10px;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  .sub-del-action-section {
    align-items: center;
    max-width: 60px;
  }
  ::v-deep .v-input input::placeholder {
  font-family: $fontFamily1;
  font-size: 15px !important;
  line-height: 18px;
  color: #4F4F4F;
  opacity: 0.9;
}
.location-input {
  display: flex;
  align-items: center;
  .v-input__control {
    max-width: 93%;
  }
  .close {
    padding-left: 5px;
    font-size: 1.4375rem;
  }
}
.location-input.error--text .v-input__slot {
  border: 1px solid #ff0808 !important;
}
.other-input-field {
  margin-top: -15px;
  padding-left: 2.3rem;
  height: 45px !important;
  ::v-deep .input-field {
      width: 100%;
  }
}
.other-input-field.border-red ::v-deep .v-input__slot {
  border: 1px solid red !important;
}
.other-input-field-error.v-text-field__details {
  padding-left: 2.3rem;
}
::v-deep {
  .v-input__slot {
    margin-bottom: 0px;
  }
}
.addLocation {
  cursor: pointer;
  font-family: $fontFamily1;
  font-size: 15px;
  text-decoration-line: underline;
  color: #8066FF;
}
.contact-input {
  // min-width: 250px;
  width: 100%;
  ::v-deep .v-input__slot {
    border: 1px solid $silver !important;
  }
}
.v-text-field__details {
  // min-width: 250px;
  margin: 0.25rem 0rem;
}
@media (max-width: 600px) {
  .deliverables-inputs {
    display: flow !important;
  }
}
</style>
