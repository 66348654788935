<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3732_23951)">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
        :fill="color"
      />
      <path
        d="M12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11.325L7.62901 13.437C7.40357 13.5778 7.24332 13.8024 7.18349 14.0614C7.12367 14.3204 7.16918 14.5926 7.31001 14.818C7.45084 15.0434 7.67545 15.2037 7.93444 15.2635C8.19343 15.3233 8.46557 15.2778 8.69101 15.137L12.531 12.737C12.6761 12.6461 12.7954 12.5195 12.8775 12.3692C12.9596 12.219 13.0018 12.0502 13 11.879V7C13 6.73478 12.8947 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3732_23951">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Clock',
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>
