import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"border":"solid 1px #e6e6e6"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"kit",class:{ 'on-hover': hover},on:{"click":function($event){return _vm.$emit('click', _vm.item)}}},[_c(VCard,{staticClass:"mx-auto kit-inner",class:_vm.from,attrs:{"flat":""}},[(['mp4', 'mov'].includes(_vm.fileExtension))?_c('div',{staticClass:"brand-kit-play w-100 h-100 d-flex align-center justify-center"},[_c('div',[_c(VIcon,{staticClass:"play-btn"},[_vm._v("mdi-play")])],1)]):_vm._e(),_c(VRow,{staticClass:"ma-auto",attrs:{"flex-column":""}},[_c('div',{staticClass:"d-flex justify-end card-body ma-auto"},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","content-class":"uploaded-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"menu-btn",attrs:{"dark":"","icon":"","height":"auto","width":"auto"}},on),[_c(VIcon,{attrs:{"color":"black","dense":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('onDownload')}}},[_c('img',{staticClass:"download-icon",attrs:{"src":require("@/assets/svg/theme/download-small.svg"),"width":"24","height":"14"}}),_vm._v(" Download ")])],1)],1),(_vm.$attrs.delete)?_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100",attrs:{"text":""},on:{"click":_vm.$attrs.delete}},[_c('img',{staticClass:"download-icon",staticStyle:{"filter":"brightness(0%)"},attrs:{"src":require("@/assets/svg/theme/trash.svg"),"width":"24","height":"14"}}),_vm._v(" Delete ")])],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"card-image d-flex justify-center align-center ma-auto",class:{ 'no-image': !_vm.validImage }},[(!_vm.validImage)?_c('div',{staticClass:"long-copy d-flex justify-center align-center w-100 h-100"},[_c('img',{class:{ 'default-image': !_vm.validImage },attrs:{"src":_vm.getOptimizedS3ImageURL(_vm.imageSrc, { width: 250, height: 250 }),"alt":"no-image"}})]):_c('img',{class:{ 'default-image': !_vm.validImage },attrs:{"src":_vm.getOptimizedS3ImageURL(_vm.imageSrc, { width: 250, height: 250 }),"alt":"no-image"}})])],1)])],1)],1)]}}])}),(_vm.parentComponent === 'brandFiles')?_c('div',{staticClass:"filename-text py-1 d-flex align-center font-body-small"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"notranslate"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fileName)+" ")])]}}],null,false,681065688)},[_c('span',{staticClass:"notranslate"},[_vm._v(" "+_vm._s(_vm.fileName)+" ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }