<!-- eslint-disable -->
<template>
   <v-container class="sweepclose-container">
      <div>
         <div class="label">Calendar</div>
         <div class="mt-5">
            <v-row class="table-headers pl-5">
               <v-col class="d-flex">
                  <div class="day-title" style="min-width: 80px;">
                     {{ showMonth }}
                  </div>
                  <div class="action-item">
                     <v-icon color="#262626" @click="monthDec">mdi-chevron-left</v-icon>
                     <v-icon color="#262626" @click="monthInc">mdi-chevron-right</v-icon>
                  </div>
               </v-col>
               <div>
                  <v-row>
                     <v-col >
                        <v-menu offset-y left content-class="admin-menu">
                           <template v-slot:activator="{ on, attrs }">
                              <div
                                 color="info"
                                 dark
                                 v-bind="attrs"
                                 v-on="on"
                                 style="color:#8066FF"
                                 >
                                 <span>
                                 {{dayFilter}}
                                 </span>
                                 <v-icon color="#8066FF" style="padding-left: 2px;" size="20">
                                    mdi-menu-down
                                 </v-icon>
                              </div>
                           </template>
                           <v-list>
                              <v-list-item
                                 :key="'Month'"
                                 @click="dayFilter='Month'"
                                 >
                                 <v-list-item-title>
                                    Month
                                 </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                 :key="'Week'"
                                 @click="dayFilter='Week'"
                                 >
                                 <v-list-item-title>Week</v-list-item-title>
                              </v-list-item>
                           </v-list>
                        </v-menu>
                     </v-col>
                     <v-col style="width: 180px;margin-right: 20px;">
                        <v-menu offset-y left content-class="admin-menu">
                           <template v-slot:activator="{ on, attrs }">
                              <div
                                 dark
                                 v-bind="attrs"
                                 v-on="on"
                                 style="color:#8066FF"
                                 >
                                 {{sweepFilter}}
                                 <v-icon style="padding-left: 2px;" color="#8066FF" size="20">
                                    mdi-menu-down
                                 </v-icon>
                              </div>
                           </template>
                           <v-list>
                              <v-list-item
                                 :key="'All Sweeps'"
                                 @click="sweepFilter='All Sweeps'"
                                 >
                                 <v-list-item-title>
                                    All Sweeps
                                 </v-list-item-title>
                              </v-list-item>
                              <v-list-item 
                                 v-if="role !== 'admin'"
                                 :key="'My Sweeps'"
                                 @click="sweepFilter='My Sweeps'"
                                 >
                                 <v-list-item-title>My Sweeps</v-list-item-title>
                              </v-list-item>
                           </v-list>
                        </v-menu>
                     </v-col>
                  </v-row>
               </div>
            </v-row>
            <v-row class="days ml-2">
               <v-col style="margin-left:0px;width: 150px;" v-for="date in dates" :key="date">
                  {{getDate(date)}}
               </v-col>
            </v-row>
            <div class="d-flex" v-if="dayFilter == 'Week'">
               <v-col class="" v-for="date in dates" :key="date">
                  <div class="">
                     <v-col class="no-sweep" v-if="!events(date).length">
                        No Sweeps.
                     </v-col>
                     <!-- eslint-disable-next-line -->
                     <v-col v-bind:class = "sweep.cancelledAt?'sweep-box cancel-background':'sweep-box'"  v-for="sweep in events(date)"  :key="sweep.id">
                        <div style="margin: -4px;">
                           <!-- eslint-disable-next-line -->
                           {{ `${moment(`${moment().format('YYYY-MM-DD')} ${sweep.fromTime}`).format('h:mma').replace(':00', "")}-${moment(`${moment().format('YYYY-MM-DD')} ${sweep.toTime}`).format('h:mma').replace(':00', "")}`}}
                        </div>
                        <v-row class="week-data">
                           <div class="col-data" :key="sweep.id">
                              <v-menu >
                                 <template v-slot:activator="{ on, attrs }">
                                    <img
                                       v-bind="attrs"
                                       v-on="on"
                                       :src="sweep.business.bannerImage || bannerImage" class="event"/>
                                 </template>
                                 <v-list class="menu-item">
                                    <v-list-item>
                                       <div class="d-flex">
                                          <div class="sweepDetails">
                                             <div class="d-flex">
                                                <div class="sweep_title cursor-pointer" @click="gotoSweep(sweep)">
                                                   {{sweep.title}}
                                                </div>
                                             </div>
                                             <div class="mb-3 d-flex align-center">
                                                <div class="mr-4" style="width: 18px; height: 18px;">
                                                   <Clock width="18" height="18" />
                                                </div>
                                                <!-- During sweep -->
                                                <span class="font-body">
                                                {{
                                                `${moment(sweep.fromTime, ['HH:mm']).format('h:mma')} -
                                                ${moment(sweep.toTime, ['HH:mm']).format('h:mma')}`
                                                }}</span
                                                   >
                                                <span class="font-label duration pl-2">
                                                {{duration(sweep)}}
                                                </span>
                                             </div>
                                             <div class="mb-1 d-flex">
                                                <div class="mr-4" style="width: 18px; height: 18px; padding-top: 3px;">
                                                   <MapMarker width="18" height="18" />
                                                </div>
                                                <div class="font-body" v-if="sweep.sweepLocations.length === 0">
                                                   -
                                                </div>
                                                <div v-else-if="sweep.sweepLocations">
                                                   <div v-for="(location, i) in sweep.sweepLocations" :key="i" class="pb-1">
                                                      <div class="locationCount" v-if="sweep.sweepLocations.length > 1">
                                                         Location #{{ i + 1 }}
                                                      </div>
                                                      <div class="font-body">
                                                         {{
                                                         `${!location.location ? '-' : location.location.split(',')[0] || ''}`
                                                         }}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="mb-3 d-flex align-center cursor-pointer" @click="gotoBusiness(sweep)">
                                                <div class="mr-4" style="width: 18px; height: 18px;">
                                                   <Brief width="18" height="18" />
                                                </div>
                                                <!-- During sweep -->
                                                <span class="font-body">{{ sweep.business.name }}</span
                                                   >
                                             </div>
                                          </div>
                                          <div class="justify-end align-center mb-4">
                                             <v-icon
                                                class="close cursor-pointer">mdi-close</v-icon>
                                          </div>
                                       </div>
                                    </v-list-item>
                                 </v-list>
                              </v-menu>
                           </div>
                        </v-row>
                        <div class="cancel-text" v-if="sweep.cancelledAt">
                           CANCELED
                        </div>
                     </v-col>
                  </div>
               </v-col>
            </div>
            <div v-if="dayFilter == 'Month'">
               <v-row v-for="week in monthDates" :key="week" class="ml-2">
                  <v-col class="sweep-box" v-for="date in week" :key="date">
                     <div class="title">
                        {{ showDate(date) }}
                     </div>
                     <div>
                        <template>
                           <v-row class="events-data">
                              <div class="col-data" v-for="(sweep, i) in events(date, 1)" :key="sweep.id">
                                 <v-menu v-if="i < 2">
                                    <template v-slot:activator="{ on, attrs }">
                                       <img
                                          v-bind="attrs"
                                          v-on="on"
                                          :src="sweep.business.bannerImage || bannerImage" class="event"/>
                                    </template>
                                    <v-list class="menu-item">
                                       <v-list-item>
                                          <div class="d-flex">
                                             <div class="sweepDetails">
                                                <div class="d-flex">
                                                   <div class="sweep_title cursor-pointer" @click="gotoSweep(sweep)">
                                                      {{sweep.title}}
                                                   </div>
                                                </div>
                                                <div class="mb-3 d-flex align-center">
                                                   <div class="mr-4" style="width: 18px; height: 18px;">
                                                      <Clock width="18" height="18" />
                                                   </div>
                                                   <!-- During sweep -->
                                                   <span class="font-body">
                                                   {{
                                                   `${moment(sweep.fromTime, ['HH:mm']).format('h:mma')} -
                                                   ${moment(sweep.toTime, ['HH:mm']).format('h:mma')}`
                                                   }}</span
                                                      >
                                                   <span class="font-label duration pl-2">
                                                   {{duration(sweep)}}
                                                   </span>
                                                </div>
                                                <div class="mb-1 d-flex">
                                                   <div class="mr-4" style="width: 18px; height: 18px; padding-top: 3px;">
                                                      <MapMarker width="18" height="18" />
                                                   </div>
                                                   <div class="font-body" v-if="sweep.sweepLocations.length === 0">
                                                      -
                                                   </div>
                                                   <div v-else-if="sweep.sweepLocations">
                                                      <div v-for="(location, i) in sweep.sweepLocations" :key="i" class="pb-1">
                                                         <div class="locationCount" v-if="sweep.sweepLocations.length > 1">
                                                            Location #{{ i + 1 }}
                                                         </div>
                                                         <div class="font-body">
                                                            {{
                                                            `${!location.location ? '-' : location.location.split(',')[0] || ''}`
                                                            }}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="mb-3 d-flex align-center cursor-pointer" @click="gotoBusiness(sweep)">
                                                   <div class="mr-4" style="width: 18px; height: 18px;">
                                                      <Brief width="18" height="18" />
                                                   </div>
                                                   <!-- During sweep -->
                                                   <span class="font-body">{{ sweep.business.name }}</span
                                                      >
                                                </div>
                                             </div>
                                             <div class="justify-end align-center mb-4">
                                                <v-icon
                                                   class="close cursor-pointer">mdi-close</v-icon>
                                             </div>
                                          </div>
                                       </v-list-item>
                                    </v-list>
                                 </v-menu>
                              </div>
                              <div class="col-data" v-if="events(date).length">
                                 <v-menu>
                                    <template v-slot:activator="{ on, attrs }">
                                       <img v-if="events(date).length == 1"
                                          v-bind="attrs"
                                          v-on="on"
                                          :src="events(date)[0]['business']['bannerImage'] || bannerImage" class="event"/>
                                       <span class="plus-icon" v-if="events(date).length > 1">
                                          <v-avatar
                                             v-bind="attrs"
                                             v-on="on"
                                             size="33"
                                             style="background-color: #C0C0C0;border: 2px solid #FFFFFF;border-radius: 10px;"
                                             class="ma-0 d-flex avatar cursor-pointer"
                                             @click="$emit('openPopup')"
                                             >
                                             <div class="name-initial1">
                                                {{ `+${events(date).length}`}}
                                             </div>
                                          </v-avatar>
                                       </span>
                                    </template>
                                    <v-list class="menu-item">
                                       <v-list-item >
                                          <div class="d-flex">
                                             <div>
                                                <div class="sweepDetails" v-for="sweep  in events(date)" :key="sweep.id">
                                                   <div>
                                                      <div class="sweep_title cursor-pointer" @click="gotoSweep(sweep)">
                                                         {{sweep.title}}
                                                      </div>
                                                   </div>
                                                   <div class="mb-3 d-flex align-center">
                                                      <div class="mr-4" style="width: 18px; height: 18px;">
                                                         <Clock width="18" height="18" />
                                                      </div>
                                                      <!-- During sweep -->
                                                      <span class="font-body">
                                                      {{
                                                      `${moment(sweep.fromTime, ['HH:mm']).format('h:mma')} -
                                                      ${moment(sweep.toTime, ['HH:mm']).format('h:mma')}`
                                                      }}</span
                                                         >
                                                      <span class="font-label duration pl-2">
                                                      {{duration(sweep)}}
                                                      </span>
                                                   </div>
                                                   <div class="mb-1 d-flex">
                                                      <div class="mr-4" style="width: 18px; height: 18px; padding-top: 3px;">
                                                         <MapMarker width="18" height="18" />
                                                      </div>
                                                      <div class="font-body" v-if="sweep.sweepLocations.length === 0">
                                                         -
                                                      </div>
                                                      <div v-else-if="sweep.sweepLocations">
                                                         <div v-for="(location, i) in sweep.sweepLocations" :key="i" class="pb-1">
                                                            <div class="locationCount" v-if="sweep.sweepLocations.length > 1">
                                                               Location #{{ i + 1 }}
                                                            </div>
                                                            <div class="font-body">
                                                            <!-- eslint-disable-next-line -->
                                                               {{`${!location.location ? '-' : location.location.split(',')[0] || ''}`}}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="mb-3 d-flex align-center cursor-pointer" @click="gotoBusiness(sweep)">
                                                   <!-- eslint-disable-next-line -->
                                                      <div class="mr-4" style="width: 18px; height: 18px;">
                                                         <Brief width="18" height="18" />
                                                      </div>
                                                     <!-- eslint-disable-next-line -->
                                                      <span class="font-body">{{ sweep.business.name }}</span
                                                         >
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="justify-end align-center mb-4">
                                                <v-icon
                                                   class="close cursor-pointer">mdi-close</v-icon>
                                             </div>
                                          </div>
                                       </v-list-item>
                                    </v-list>
                                 </v-menu>
                              </div>
                           </v-row>
                        </template>
                     </div>
                  </v-col>
               </v-row>
            </div>
         </div>
      </div>
   </v-container>
</template>
<!-- eslint-enable -->

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Clock from '@/components/common/svg/Clock';
import MapMarker from '@/components/common/svg/MapMarker';
import Brief from '@/components/common/svg/Brief';
import { getWeeks } from '../../../helpers/orders';

export default {
  name: 'SweepCalendar',
  props: {
    item: {
      type: Object,
      required: true,
    },
    lastUpdated: {
      default: '',
    },
  },
  components: {
    Clock,
    MapMarker,
    Brief,
  },
  data() {
    return {
      closeOutModal: false,
      dateEvents: [],
      dayFilter: 'Month',
      sweepFilter: 'All Sweeps',
      monthName: moment(),
      weekNumber: moment().week() - 1,
      monthDates: this.getWeekDays(moment().year(), moment().month()),
    };
  },
  mounted() {
    this.getSweeps();
  },
  methods: {
    ...mapActions('sweep', ['getSweepsByDate']),
    getWeeks,
    moment,
    gotoBusiness(val) {
      this.$router.push(`/project_overview/${val.projectId}?businessId=${val.businessId}`);
    },
    duration(sweep) {
      const startTime = moment(sweep.fromTime, 'hh:mm a');
      const endTime = moment(sweep.toTime, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      const num = diff.asMinutes();
      const hours = num / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      const checkForMin = `${rminutes > 0 ? `(${rminutes} minutes)` : ''}`;
      const checkForHours = `${rhours > 0 ? `(${rhours} hours)` : checkForMin}`;
      const text = rhours > 0 && rminutes > 0 ? `(${rhours} hours and ${rminutes} minutes)` : checkForHours;
      return text;
    },
    showDate(date) {
      if (typeof date !== 'object') {
        return date;
      }
      return moment(date).format('D');
    },
    monthInc() {
      if (this.dayFilter === 'Month') {
        this.monthName = moment(this.monthName).add(1, 'M');
        this.monthDates = this.getWeekDays(this.monthName.year(), this.monthName.month());
      } else {
        this.weekNumber = this.weekNumber + 1;
      }
      this.getSweeps();
    },
    monthDec() {
      if (this.dayFilter === 'Month') {
        this.monthName = moment(this.monthName).subtract(1, 'M');
        this.monthDates = this.getWeekDays(this.monthName.year(), this.monthName.month());
      } else {
        this.weekNumber = this.weekNumber - 1;
      }
      this.getSweeps();
    },
    weekDates() {
      const startDate = moment(moment().year().toString()).add(this.weekNumber, 'weeks').startOf('week');
      const dates = [1, 2, 3, 4, 5, 6, 7].map((day) => moment(startDate).add(day, 'days'));
      return dates;
    },
    getWeekDays(year, month) {
      // eslint-disable-next-line
      let dates = getWeeks(year, month);
      // eslint-disable-next-line
      if (Array.isArray(dates[0])) {
        const empty = new Array(7 - dates[0].length).fill(null);
        // eslint-disable-next-line
        const previousMonthDates = empty.map((x, i) => moment(this.monthName).date(-i));
        dates[0] = [...previousMonthDates.reverse(), ...dates[0]];
      }
      if (Array.isArray(dates[dates.length - 1])) {
        const lastDay = moment(this.monthName).endOf('month');
        // eslint-disable-next-line
        let lastWeek = dates[dates.length - 1];
        while (lastWeek.length < 7) {
          if (!lastWeek.includes(Number(lastDay.format('D')))) {
            lastWeek.push(lastDay.format('D'));
          } else {
            lastWeek.push(null);
          }
        }
        // eslint-disable-next-line
        const nextMonthDates = lastWeek.filter((item) => item === null).map((x, i) => moment(lastDay).add(i+1, 'd'));
        dates[dates.length - 1] = [...lastWeek.filter((item) => item !== null), ...nextMonthDates];
      }
      return dates;
    },
    async getSweeps() {
      let from = moment();
      let to = moment();
      if (this.dayFilter === 'Month') {
        // eslint-disable-next-line
        from = this.monthDates[0][0];
        // eslint-disable-next-line
        to = this.monthDates[4][6];
        if (typeof from !== 'object') {
          from = moment([moment(this.monthName).year(), moment(this.monthName).month(), from]);
        }
        if (typeof to !== 'object') {
          to = moment([moment(this.monthName).year(), moment(this.monthName).month(), to]);
        }
      } else {
        const weekday = moment(moment().year().toString()).add(this.weekNumber, 'weeks').startOf('week');
        from = moment(weekday).add(1, 'days');
        to = moment(weekday).add(7, 'days');
      }
      const data = await this.getSweepsByDate({ from: moment(from).format('YYYY/MM/DD'), to: moment(to).format('YYYY/MM/DD'), type: this.sweepFilter });
      if (data.success) {
        this.dateEvents = data.sweeps;
      }
    },
    events(date, isFirst) {
      let selectedDate = date;
      let data = [];
      if (typeof from !== 'object') {
        // eslint-disable-next-line
        selectedDate = moment([moment(this.monthName).year(), moment(this.monthName).month(), selectedDate]);
      }
      if (this.dayFilter === 'Month') {
        data = this.dateEvents[moment(selectedDate).format('YYYY-MM-DD')] || this.dateEvents[moment(date).format('YYYY-MM-DD')] || [];
        if (this.sweepFilter === 'My Sweeps') {
          data = data.filter((x) => (x.isMySweep === true));
        }
        if (isFirst) {
          return data.slice(0, 2);
        }
        return data.slice(2, data.length);
      }
      data = this.dateEvents[date.format('YYYY-MM-DD')] || [];
      if (this.sweepFilter === 'My Sweeps') {
        data = data.filter((x) => (x.isMySweep === true));
      }
      return data;
    },
    getDate(day) {
      if (this.dayFilter === 'Week') {
        return moment(day).format('ddd, MMM DD');
      }
      return day;
    },
    gotoSweep(sweep) {
      this.$router.push(`/sweep_details/${sweep.businessId}/${sweep.id}`);
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    memberInitials() {
      return (member) => {
        const firstName = _.get(member || member, 'firstName', '').trim();
        const lastName = _.get(member || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    showMonth() {
      if (this.dayFilter === 'Month') {
        return moment(this.monthName).format('MMMM');
      }
      const startDate = moment(moment().year().toString()).add(this.weekNumber, 'weeks').startOf('week');
      return `${moment(startDate).add(1, 'days').format('MMM DD')} - ${moment(startDate).add(7, 'days').format('MMM DD YYYY')}`;
    },
    dates() {
      if (this.dayFilter === 'Month') {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      }
      return this.weekDates();
    },
    bannerImage() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/businesses.svg');
    },
  },
  watch: {
    dayFilter(newVal, oldValue) {
      if (newVal && newVal !== oldValue) {
        this.monthName = moment();
        this.weekNumber = moment().week() - 1;
        this.monthDates = this.getWeekDays(this.monthName.year(), this.monthName.month());
        this.getSweeps();
      }
    },
    monthDates() {
      this.getSweeps();
    },
    lastUpdated() {
      this.getSweeps();
    },
  },
};
</script>

<style lang="scss">
.sweepclose-container {
   .sweepDetails {
    cursor: pointer;
  }
   .cancel-background {
     background: #FFF7F7 !important;
     border: 1px solid #FF7474 !important;
     border-radius: 4px !important;
     height: 100px !important;
   }
   .cancel-text {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 500;
     font-size: 13px;
     line-height: 20px;
     color: #FF371B;
   }
   .no-sweep {
   font-family: 'Poppins';
font-style: italic;
font-weight: 400;
font-size: 14px;
line-height: 21px;

color: #262626;
  }
  .action-item {
    margin-top: 2px;
    margin-left: 10px;
  }
  .title {
    font-family: 'Poppins';
font-style: normal;
font-weight: 450;
font-size: 13px;
line-height: 20px;
  }
  .sweepDetails {
    padding-bottom: 15px;
    cursor: pointer;
  }
  .name-initial1 {
    font-size: 18px !important;
    color: #ffffff;
  }
  .event {
    width: 29px;
    height: 29px;
    border-radius: 10px;
  }
  .events-data {
    display: flex;
    padding: 10px;
  }
  .week-data {
    display: flex;
    padding: 6px 8px;
  }
  .col-data {
    padding-right: 0px;
    padding-left: 5px;
  }
  .menu-item {
  position: absolute;
width: 227px;
height: 149px;
background: #FFFFFF;
border: 1px solid #D8D8D8;
box-shadow: 0px 0px 10px
  }
  @media (min-width: 1550px) {
    .profile-col {
      min-width: 335px;
    }
  }
  .label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #929292;
    border-bottom: 1px solid #d8d8d8;
  }
  .days {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #929292;
   border-bottom: 1px solid #d8d8d8;
  }
  .sweep-box {
    width: 100%;
    height: 87px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin: 5px;
    margin-left: 10px;
  }
}
  .sweepclose-table {
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    .title-style {
      width: fit-content;
      overflow-x: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
    .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        .business-name {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #929292;
        }
    }
    .creative-icon {
        margin-left: -15px;
    }
    .plus-icon {
      margin-left: -15px;
    }
    .table-headers {
        z-index: 2;
        background: white;
        top: 0px;
        position: -webkit-sticky !important;
        position: sticky !important;
      .table-header {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #b4b4b4;
      }
    }
    .divider {
      border-left: 1px solid #DADADA;
    }
    .button {
        height: 35px;
        width: 130px;
        font-family: 'Poppins';
        background: #ffffff;
        border: 2px solid #41E0BA;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #262626;
    }
    .table-data {
        margin-bottom: 5px;
        margin-left: 20px;
        margin-right: 20px;
        border-top: 1px solid #D8D8D8;
    }
  }
  .sweep_title {
    font-weight: 600;
font-size: 18px;
line-height: 18px;
color: #000000;
font-family: 'Poppins';
width: 90%;
margin-top: 10px;
margin-bottom: 10px;
  }
  .day-title {
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
min-width: 80%;
  }
</style>
