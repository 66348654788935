import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"my-0"},[_c(VCol,{staticClass:"d-flex py-0",attrs:{"cols":"8","md":"10"}},[_c(VCardTitle,{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.title))])],1),(_vm.canEdit)?_c(VCol,{staticClass:"d-flex justify-end py-0",attrs:{"cols":"4","md":"2"}},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":_vm.toggleMode}},[_c('img',{attrs:{"src":require("@/assets/svg/theme/edit.svg")}})])],1):_vm._e()],1),_c(VCardText,{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"text--primary pa-0 mt-1 html-content",style:({height: _vm.contentHeight}),domProps:{"innerHTML":_vm._s(("<pre>" + (_vm.value ? _vm.value : '') + "</pre>"))}}),(_vm.mode === 'edit')?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{key:_vm.title,attrs:{"persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"content-class":"html-editor","modal":_vm.mode === 'edit'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title))]),_c(VDivider,{staticClass:"my-2"}),_c('BaseHtmlEditor',{staticClass:"py-2 px-5",attrs:{"content":_vm.value},on:{"save":_vm.handleSave,"cancel":_vm.toggleMode}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }