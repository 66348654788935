<template>
  <div class="orders-history pa-0">
      <v-col class="pa-0">
          <div class="table-data scrollable">
            <v-row
            v-if="!creative"
            class="ma-auto cursor-pointer"
            :key="item.id">
              <v-col
              cols="2"
              class="data"
              >{{ date(item.date) }}</v-col>
              <v-col class="data text-left title">{{ item.title }}</v-col>
            </v-row>
            <v-row
            v-if="creative"
            class="ma-auto cursor-pointer"
            :key="item.sweep.id">
              <v-col
              cols="2"
              class="data"
              >{{ date(item.sweep.date) }}</v-col>
              <v-col class="data text-left title">{{ item.sweep.title }}</v-col>
              <v-col cols="3" v-if="creative" class="data text-left pl-4">
                {{ duration }}
              </v-col>
              <v-col cols="2" v-if="creative" class="data text-left pl-3">
                <span v-for="creative in item.sweep.sweepCreativePayout" :key="creative">
                  <span v-if="creative.userId === userId">
                  ${{creative.payout}}.00
                  </span>
                </span>
                <!-- {{ item }} -->
              </v-col>
            </v-row>
          </div>
      </v-col>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'SweepHistory',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    userId() {
      const val = _.get(this.userDetails, 'id');
      return val;
    },
    creative() {
      return this.role === 'creative';
    },
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `${rminutes} minutes` : ''}`;
        const checkForHours = `${rhours > 0 ? `${rhours} hours` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `${rhours} hours and ${rminutes} minutes` : checkForHours;
        return text;
      };
    },
    duration() {
      const startTime = moment(this.item.sweep.fromTime, 'hh:mm a');
      const endTime = moment(this.item.sweep.toTime, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
  },
  methods: {
    date(createdDate) {
      return moment(createdDate).format('M/DD/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.orders-history {
  .scrollable {
    // overflow: auto;
    // max-height: 600px;
  }
  .title {
    word-break: break-all;
  }
  .table-header {
    border-bottom: 3px solid #D8D8D8;
    padding-top: 18px;
    padding-bottom: 7px;
    div {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
      padding: 0px;
      margin-right: 5px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .table-data {
    .data {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      padding: 0px;
      margin-right: 5px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>
