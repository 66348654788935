<template>
  <div class="brand-fonts-container">
    <div class="brf-item d-flex py-2" :key="primary.name">
      <div class="brf-type mr-2">Primary</div>
      <div class="brf-name">
        {{ primary.name }}
        <span v-if="primary.custom">(uploaded)</span>
      </div>
      <div class="download" v-if="primary.name" @click="downloadFiles(primary.fontId)">
        <v-img src="@/assets/svg/download.svg" />
      </div>
    </div>
    <div class="brf-item d-flex py-2" :key="secondary.name">
      <div class="brf-type mr-2">Secondary</div>
      <div class="brf-name">
        {{ secondary.name }}
        <span v-if="secondary.custom">(uploaded)</span>
      </div>
      <div class="download" v-if="secondary.name" @click="downloadFiles(secondary.fontId)">
        <v-img src="@/assets/svg/download.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'brand-fonts',
  props: {
    primary: Object,
    secondary: Object,
  },
  methods: {
    ...mapActions('business', ['downloadFont']),
    async downloadFiles(id) {
      const result = await this.downloadFont({ id });
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-fonts-container {
  .brf-type {
    color: #757575;
  }
  .download {
    height: 20px;
    width: 20px;
    margin-left: 20px;
    cursor: pointer;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
