<template>
  <div>
    <div class="d-flex align-center pb-4">
      <Upload
        v-model="file"
        mode="justImage"
        @change="bannerImageUpdate"
        :acceptedFiles="'.jpg,.jpeg,.png'"
        :disabled="!canEdit"
      >
        <template v-slot:default="{filePreview}">
          <div class="d-flex profile-image-container"
            :class="{'border-none' : borderVisiblity, 'upload': canEdit}"
          >
              <v-img
                class="profile-image"
                :src="bannerImage(filePreview)"
                alt="Profile"
              />
              <div class="profile-image-loading" v-if="imageLoading">
                <v-progress-circular indeterminate color="grey"/>
              </div>
              <div class="profile-image-overlay" v-if="canEdit">
                <img src="@/assets/svg/camera_event.svg" />
                <span>Update</span>
              </div>
          </div>
        </template>
      </Upload>
      <div class="business-name pl-4 d-flex justify-space-between w-100">
        <BaseInput
          outlined
          :mode="mode.name"
          :placeholder="'Business Name'"
          :name="'businessName'"
          v-model.trim="business.name"
          :status="errors('name').length ? 'error': 'normal'"
          :error-messages="errors('name')"
          @input="$v.business.name.$touch()"
          @blur="$v.business.name.$touch()"
        >
          <div class="name">{{ business.name }}</div>
        </BaseInput>
        <v-btn text icon color="info" @click="toggleMode('name')" class="ml-2"
          v-if="canEdit"
        >
          <img v-if="mode.name ==='normal'" src="@/assets/svg/theme/edit.svg" />
          <span v-else>Save</span>
        </v-btn>
      </div>
    </div>
    <v-card class="mt-3 py-4 px-6">
      <v-container pa-0 class="h-100">
        <v-row>
          <v-col class="d-flex align-center py-0">
              <div class="text-uppercase card-heading">
                About
              </div>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
              <v-btn text icon @click="toggleMode('about')"
                v-if="canEdit"
              >
                <img v-if="mode.about ==='normal'" src="@/assets/svg/theme/edit.svg" />
                <span v-else class="text-purple">Save</span>
              </v-btn>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-list
                subheader
            >
                <v-subheader>Contact</v-subheader>
                <v-divider class="mb-4"></v-divider>
                <v-list-item
                    v-for="(detail, i) in basicDetails"
                    :key="`basic${i}`"
                    :class="{'hidden-screen-only': (!detail.value && mode.about === 'normal')}"
                    class="icon-position d-flex align-middle"
                  >
                  <v-list-item-avatar
                  :class="mode.about === 'edit' ? 'mb-3': 'mt-1'">
                  <v-img :src="detail.avatar" contain></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="social-item">
                  <BaseInput
                    v-if="detail.name === 'contactNumber' && mode.about === 'edit'"
                    :key="`input${i}`"
                    v-mask="'###-###-####'"
                    type="tel"
                    outlined
                    dense
                    :mode="mode.about"
                    :label="detail.label"
                    :name="detail.name"
                    v-model.trim="business[detail.name]"
                    :status="errors(detail.name).length ? 'error': 'normal'"
                    :error-messages="errors(detail.name)"
                    @input="$v.business[detail.name].$touch()"
                    @blur="$v.business[detail.name].$touch()"
                  >
                    <div class='my-1'> {{ detail.value }} </div>
                  </BaseInput>
                  <div v-else-if="detail.name === 'contactOwnerEmail'"
                  :class="mode.about === 'edit' ? 'mb-6': 'mt-1'"
                  class='my-1'> {{ detail.value }} </div>
                  <BaseInput
                    v-else
                    outlined
                    dense
                    :mode="mode.about"
                    :label="detail.label"
                    :name="detail.name"
                    v-model.trim="business[detail.name]"
                    :status="errors(detail.name).length ? 'error': 'normal'"
                    :error-messages="errors(detail.name)"
                    @input="$v.business[detail.name].$touch()"
                    @blur="$v.business[detail.name].$touch()"
                  >
                      <div class='about-contact my-1'> {{ detail.value }} </div>
                  </BaseInput>
                </v-list-item-content>
                </v-list-item>
                <v-subheader>
                    Social
                </v-subheader>
                <v-divider class="mb-4"></v-divider>
                <v-list-item
                    v-for="(link, j) in socialLinks" :key="`link${j}`"
                    class="icon-position d-flex align-middle"
                >
                  <v-list-item-avatar :class="{ 'mb-3': mode.about === 'edit' }">
                    <v-img :src="link.avatar" contain></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="social-item">
                    <BaseInput
                      outlined
                      dense
                      :mode="mode.about"
                      :label="link.placeholder"
                      :name="link.name"
                      v-model.trim="business[link.name]"
                      :status="errors(link.name).length ? 'error': 'normal'"
                      :error-messages="errors(link.name)"
                      @input="$v.business[link.name].$touch()"
                      @blur="$v.business[link.name].$touch()"
                    >
                    <a class="anchorlink a-link mb-2" :href="url(link.value)" target="_blank">
                      {{link.value}}
                    </a>
                  </BaseInput>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="profile-card py-4 px-6">
      <v-container pa-0 class="h-100">
        <v-row>
          <v-col class="d-flex align-center py-0">
              <div class="text-uppercase card-heading">
                Mission /
              </div>
          </v-col>
          <v-col class="d-flex justify-end pt-0 pr-0">
            <v-btn text icon color="info" @click="toggleMode('description')"
              v-if="canEdit"
            >
              <img v-if="mode.description ==='normal'" src="@/assets/svg/theme/edit.svg" />
              <span v-else>Save</span>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-0 card-content">
            <base-text-area-field
              outlined
              :rows="3"
              placeholder="Description"
              :mode="mode.description"
              name="brandProfile"
              v-model.trim="business.description"
              hide-details
            >
              <span class="d-block">
                <LongText
                  :text="business.description"
                  :maxLength=220
                  :popupTitle="'BUSINESS DESCRIPTION'"
                  >
                </LongText>
              </span>
            </base-text-area-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <template v-if="showConfirm">
      <v-dialog
        v-model="showConfirm"
        max-width="600"
      >
        <v-card class="modal-card py-4" flat>
          <v-card-title class="black--text">
           Please save current changes before editing another section.
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text color="info"
            @click="showConfirm = false"
            class="py-0"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions } from 'vuex';
import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import _ from 'lodash';

import { url, generateUrl } from '@/helpers';
import Upload from '@/components/common/Upload';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import LongText from '@/components/common/LongText';

export default {
  components: {
    BaseInput,
    BaseTextAreaField,
    LongText,
    Upload,
  },
  directives: {
    mask,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    business: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      contactOwnerName: {
        maxLength: maxLength(50),
      },
      contactNumber: {
        minLength: minLength(12),
      },
      website: {
        url,
      },
      facebook: {
        url,
      },
      instagram: {
        url,
      },
      youtube: {
        url,
      },
      linkedin: {
        url,
      },
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.business[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.business.name.required) { errors.push('Please provide Name'); }
            if (!this.$v.business.name.maxLength) { errors.push('Business name must be less than 50 characters length'); }
            break;
          case 'contactOwnerName':
            if (!this.$v.business.contactOwnerName.maxLength) { errors.push('Contact owner name must be less than 50 characters length'); }
            break;
          case 'contactNumber':
            if (!this.$v.business.contactNumber.minLength) { errors.push('Please provide valid Number'); }
            break;
          case 'website':
          case 'instagram':
          case 'facebook':
          case 'linkedin':
          case 'youtube':
            if (!this.$v.business[field].url) { errors.push('Please provide valid url'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    bannerImage() {
      return (filePreview) => {
        const profilePic = _.get(this.business, 'smallImage');
        if (filePreview) {
          return filePreview;
        }
        if (profilePic) {
          this.setBorderVisibility();
          return profilePic;
        }
        return require('@/assets/svg/users.svg');
      };
    },

    bannerThumbImage() {
      return _.get(this.business, 'smallImage');
    },
    basicDetails() {
      return ([
        {
          name: 'contactOwnerName',
          value: this.business.contactOwnerName,
          label: 'Contact Owner Name',
          avatar: require('@/assets/svg/theme/user-outline.svg'),
        }, {
          name: 'website',
          value: this.business.website,
          label: 'Business Website',
          avatar: require('@/assets/svg/theme/globe-outline.svg'),
        }, {
          name: 'contactNumber',
          value: this.business.contactNumber,
          label: 'Contact Number',
          avatar: require('@/assets/svg/theme/phone-outline.svg'),
        }, {
          name: 'contactOwnerEmail',
          value: this.userEmail(),
          label: 'Contact Owner Email',
          avatar: require('@/assets/svg/theme/email-outline.svg'),
        },
      ]);
    },
    socialLinks() {
      return (
        [{
          name: 'instagram',
          placeholder: 'Instagram',
          value: this.business.instagram,
          avatar: require('@/assets/svg/instagram.svg'),

        }, {
          name: 'facebook',
          placeholder: 'Facebook',
          value: this.business.facebook,
          avatar: require('@/assets/svg/facebook.svg'),

        }, {
          name: 'linkedin',
          placeholder: 'Linkedin',
          value: this.business.linkedin,
          avatar: require('@/assets/svg/linkedin.svg'),
        }, {
          name: 'youtube',
          placeholder: 'Youtube',
          value: this.business.youtube,
          avatar: require('@/assets/svg/theme/youtube-outline.svg'),
        }]);
    },
    multipleEdits() {
      return this.mode.name === 'edit' || this.mode.description === 'edit' || this.mode.about === 'edit';
    },
  },

  data() {
    return {
      file: '',
      imageLoading: false,
      borderVisiblity: false,
      mode: {
        name: 'normal',
        description: 'normal',
        about: 'normal',
      },
      showConfirm: false,
    };
  },

  methods: {
    ...mapActions('business', ['uploadBusinessBanner', 'updateBusienss']),
    setBorderVisibility() {
      this.borderVisiblity = true;
    },
    userEmail() {
      let ownerEmail = '';
      _.map(this.business.userBusiness, (id) => {
        ownerEmail = id.user.email;
      });
      return ownerEmail;
    },
    async toggleMode(type) {
      if (this.mode[type] === 'normal') {
        this.showConfirm = this.multipleEdits;
        if (this.mode.name === 'normal' && this.mode.description === 'normal' && this.mode.about === 'normal') {
          this.mode[type] = 'edit';
          this.showConfirm = false;
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const response = await this.updateBusienss(this.business);
          if (response.success) {
            this.mode[type] = 'normal';
          }
        }
      }
    },
    async bannerImageUpdate(file) {
      this.imageLoading = true;
      await this.uploadBusinessBanner({ file, businessId: this.business.id });
      // if (response.success) {
      //   this.imageLoading = false;
      // }
      this.imageLoading = false;
    },

    url(link) {
      return generateUrl(link);
    },

    isLink(name) {
      return _.includes(['website', 'facebook', 'instagram', 'linkedin', 'youtube'], name);
    },
  },
};
</script>

<style scoped lang="scss">
  .business-name {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoalBlack;
    padding-right: 10px;
    color: $neutral1;
    text-overflow: ellipsis;
    word-break: break-all;
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.3em;
    }
  }
  .profile-image-container {
    width: 96px;
    height: 96px;
    border: 2px solid #565682;
    border-radius: 24px;
    position: relative;
    &.upload {
      cursor: pointer;
    }
  }
  .profile-image {
    // width: 100%;
    object-fit: contain;
    border-radius: 20px;
  }
  .profile-image-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .profile-image-overlay {
    position: absolute;
    height: 55px;
    bottom: 0;
    background: $neutral5;
    display: none;
    width: 100%;
    border-radius: 0 0 22px 22px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-image-container:hover .profile-image-overlay {
    display: flex;
  }
  .border-none {
       border: none;
       .profile-image-overlay {
         border-radius: 0 0 20px 20px;
       }
    }
  .label {
    color: black;
    font-size: 12px;
  }
  .icon-position {
      display: -webkit-box;
      -webkit-box-align: start;
    }
  .v-list-item {
    font-family: $fontFamily1;
    letter-spacing: -0.02px;
    color: $neutral1;
    min-height: 20px;
    margin: 0;
  }
  .v-list-item__avatar {
    width: 20px !important;
    height: 20px !important;
    margin: 0;
    min-width: 0 !important;
    border-radius: 0;
    margin-right: 15px !important;
  }
  .v-list-item__content {
    padding: 5px 0 0;
  }
  ::v-deep .v-input {
    font-size: 14px;
    input, textarea {
      color: #292929;
    }
  }
  ::v-deep .input-field.v-textarea {
    height: auto;
  }
  ::v-deep .input-field {
    height:70px !important;
  }
  .card-content {
    color: black !important;
  }
  .base-input {
      width: 100%;
    }
  .social-item {
      > div {
        max-width: 100%;
      }
      .anchorlink {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
      }
      .about-contact {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.3em;
      }
    }
  .v-subheader {
    padding: 0;
    height: 22px;
    margin: 15px 0 5px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.32px;
    color: $neutral1;
  }
  .v-list-item {
    font-family: $fontFamily1;
    letter-spacing: -0.02px;
    color: $neutral1;
    min-height: auto;
    margin: 6px 0;
    padding: 0 10px;
    div {
      color: $neutral1;
    }
  }

</style>
