<template>
  <div>
    <v-row class="ma-0 mb-1">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              @click="gotoDetails(sweep.id)"
              v-bind="attrs"
              v-on="on"
              class="cursor-pointer text-ellipsis notranslate"
            >
              <b>{{ sweep.title }}</b>
            </div>
          </template>
          <span class="notranslate">{{ sweep.title }}</span>
        </v-tooltip>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0"> Date: {{ sweepDate }}</v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0"> Time: {{sweepTime(sweep.fromTime)}} - {{sweepTime(sweep.toTime)}}</v-col>
    </v-row>
    <v-row class="ma-0 mt-1">
        <span style="word-break: break-all;width: fit-content;"
            v-for="(location) in sweep.sweepLocations.slice(0, 1)"
            :key="location.id">
            <div style="width: 80%;min-width: fit-content; ">
            Location:
            <a class="mb-0 a-link" style="color: black;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0 pt-0"
              v-if="sweep.sweepLocations.length !== 1">
              +{{sweep.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
    </v-row>
    <div class="mt-3"> Team: </div>
    <div class="sweep-team" v-if="sweepTeam.length">
      <CreativeTeam
        :creativeTeam="sweepMembers"
        :openPopupLeft="false"
        class="pt-2 w-100"
        :vertical="false"
        :openPopupBottom="true"
        :openDetailsPopup="true"
      />
    </div>
    <div class="my-3" v-else>
      - No creatives matched yet
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import CreativeTeam from '@/components/dashboard/screens/common/CreativeTeam';

export default {
  components: {
    CreativeTeam,
  },
  props: {
    sweep: {
      type: Object,
      required: true,
    },
    sweepTeam: {
      type: Array,
      default: () => [],
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sweepDate() {
      return moment(this.sweep.date).format('MMMM D, YYYY');
    },
    sweepTime() {
      return (time) => moment((time), ['HH:mm']).format('h:mm A');
    },
    sweepSkill() {
      return (skill) => {
        switch (skill) {
          case '1':
            return 'Photographer';
          case '2':
            return 'Videographer';
          default:
            return 'Talent';
        }
      };
    },
    sweepMembers() {
      return _.map(this.sweepTeam, (team) => ({
        profilePic: team.creatives.profilePic,
        smallImage: team.creatives.smallImage,
        thumbnail: team.creatives.thumbnail,
        firstName: team.creatives.firstName,
        lastName: team.creatives.lastName,
        skill: this.sweepSkill(team.skill),
        number: team.creatives.number,
        email: team.creatives.email,
        bio: _.get(team.creatives, 'creativeProfile.bio', ''),
        userPronouns: team.creatives.userPronouns,
        availability: team.creatives.availability,
        role: 'creative',
        userId: team.creatives.id,
        deletedAt: team.creatives.deletedAt,
      }));
    },
  },
  methods: {
    gotoDetails(val) {
      const sweepId = val;
      const { businessId } = this.sweep;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          businessId,
          sweepId,
        },
      });
    },
  },
  data() {
    return {

    };
  },

};
</script>

<style scoped>
  .sweep-team {
    height: 55px;
    position: relative;
  }
</style>
