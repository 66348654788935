<template>
  <div class="sweeplist-container"
  :style="[clients ? {'margin-left': '30px'} : {'margin-left': '10px'}]"
  >
    <Loader v-if="loading" style="height:70vh;" />
    <div
    :style="[clientstyle ? {'width': '70%'} : {'width': '100%'}]"
    v-if="(!(this.$route.path === '/sweeps') || creative || clients) && loading === false">
      <v-col class="pb-0 pl-0 pr-0 active-col">
          <div class="sweep-title">
            <span class="text active-heading">Active Sweeps</span>
          </div>
          <span v-if="active.length !== 0" :key="active.length">
            <div v-for="item in active" :key="item.id">
              <ActiveSweeps
                :item="item"
            />
            </div>
          </span>
          <v-row class="ma-0 no-history" v-else>
            <v-col class="d-flex flex-column justify-center align-center pt-12">
              <v-img
                width="2rem"
                src="@/assets/svg/no_active_orders.svg"
                aspect-ratio="1"
              ></v-img>
              <div style="height: 100px;">No Active Sweep</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pb-0 pl-0 pr-0">
            <div class="sweep-title">
              <span class="text upcoming-heading">Upcoming Sweeps</span>
            </div>
            <span v-if="upcoming.length !== 0" :key="upcoming.length">
              <div
                v-for="item in upcoming" :key="item.id">
                <UpcomingSweeps
                  @list-update="listupdate"
                  :item="item"
                />
              </div>
            </span>
            <v-row class="ma-0 no-history" v-else>
            <v-col class="d-flex flex-column justify-center align-center pt-12">
                <v-img
                  width="2rem"
                  src="@/assets/svg/no_active_orders.svg"
                  aspect-ratio="1"
                ></v-img>
                <div style="height: 100px;">No Upcoming Sweep</div>
              </v-col>
            </v-row>
        </v-col>
            <div class="sweep-title">
              <span class="text upcoming-heading">Sweeps History</span>
            </div>
              <v-row class="ma-0 table-header pt-0 mb-3">
            <v-col cols="2" class="py-0 order-date pl-0"
            :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">Date</v-col>
            <v-col cols="5" class="order-date pt-0 pb-0 pl-1 deliverables"
            :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">Sweep</v-col>
            <v-col cols="3" v-if="creative" class="order-date pb-0 pt-0"
            :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">Duration</v-col>
            <v-col cols="2" v-if="creative" class="order-date pt-0 pb-0"
            :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">Payout</v-col>
          </v-row>
        <span v-if="history.length !== 0" :key="history.length">
          <div v-for="item in historySweeps" :key="item.id"
            @click="gotoSweep(item)" class="sweephistory">
            <History
              :item="item"
          />
          </div>
        </span>
            <v-row class="ma-0 no-history" v-else>
            <v-col class="d-flex flex-column justify-center align-center pt-12">
                <v-img
                  width="2rem"
                  src="@/assets/svg/no_active_orders.svg"
                  aspect-ratio="1"
                ></v-img>
                <div style="height: 100px;">No Sweeps</div>
              </v-col>
            </v-row>
            <div v-if="history.length > 5"
              class="addLocation"
              @click="showMore = !showMore">
              <span v-if="!showMore">
                Show More <v-icon class="locationIcon" dense>mdi-menu-down</v-icon>
              </span>
              <span v-if="showMore">
                Show Less <v-icon class="locationIcon" dense>mdi-menu-up</v-icon>
              </span>
            </div>
          </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ActiveSweeps from '@/components/sweeps/ActiveSweeps';
import UpcomingSweeps from '@/components/sweeps/UpcomingSweeps';
import History from '@/components/sweeps/History';
import Loader from '@/components/common/Loader';

export default {
  props: {
    lastUpdated: {
      default: false,
    },
  },
  components: {
    ActiveSweeps,
    UpcomingSweeps,
    History,
    Loader,
  },
  data() {
    return {
      active: [],
      upcoming: [],
      history: [],
      showMore: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    creative() {
      return this.role === 'creative';
    },
    clientstyle() {
      return ['editor'].includes(this.role);
    },
    clients() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    businessId() {
      let { businessId } = this.$route.query;
      if (this.clients) {
        businessId = _.get(this.userDetails, 'currentBusinessId');
      }
      if (this.$route.name === 'Organization') {
        businessId = this.$route.params.organizationId;
      }
      return businessId;
    },
    historySweeps() {
      if (this.showMore) {
        return this.history;
      }
      return this.history.slice(0, 5);
    },
  },
  methods: {
    ...mapActions('sweep', ['getBusinessSweeps', 'CreativeSweepsList']),
    async listupdate() {
      await this.getList();
    },
    gotoSweep(sweep) {
      this.$router.push(`/sweep_details/${sweep.businessId}/${sweep.id}`);
    },
    async getList() {
      if (this.$route.path === `/project_overview/${this.$route.params.projectId}` || this.clients) {
        const data = await this.getBusinessSweeps({ businessId: this.businessId || _.get(this.userDetails, 'currentBusinessId') });
        this.active = _.get(data.sweeps, 'active', []);
        this.upcoming = _.get(data.sweeps, 'upcoming', []);
        this.history = _.get(data.sweeps, 'history', []);
      }
      if (this.creative) {
        const data = await this.CreativeSweepsList();
        this.active = _.get(data.sweeps, 'active', []);
        this.upcoming = _.get(data.sweeps, 'upcoming', []);
        this.history = _.get(data.sweeps, 'history', []);
      }
    },
  },
  watch: {
    lastUpdated() {
      this.getList();
    },
    $route() {
      if (this.$route.query.activeTab === 'sweeps') {
        this.getList();
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.getList();
    if (this.$route.query.activeTab === 'sweeps') {
      this.getList();
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 0px) and (max-width: 600px) {
  .sweeplist-container {
    width: 100% !important;
  }
}
.sweeplist-container {
.sweep-title {
  padding-left: 0px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 15px;
}
.no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2rem;
    color: $charcoalBlack;
    height: 100%;
    display: flex;
    align-items: center;
  }
.table-header {
    border-bottom: 3px solid #D8D8D8;
    padding-top: 18px;
    padding-bottom: 7px;
  .order-date {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
  }
}
.visibility-hidden {
  visibility: hidden !important;
}
.deliverables {
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
    }
.addLocation {
    cursor: pointer;
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 14px;
    color: $secondary1;
  }
  .locationIcon {
    font-weight: bold;
    font-size: 18px;
    color: $secondary1;
  }
 .sweep-card {
    padding: 20px 24px 12px !important;
    .v-card__text {
      color: black !important;
    }
    .font-family-2 {
    font-family: $fontFamily1;
    }
    .no-sweep{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  .sub-heading {
    font-size: 16px;
    font-weight: bold;
  }
    .sweep-expirty-time {
      border: 1px solid #ff0808;
      border-radius: 6px;
      font-family: $fontFamily1;
      font-size: 12px;
      letter-spacing: normal;
    }
}
  @media (max-width:960px) {
    .mobile-padding {
      margin: 0 8px;
    }
    .v-slide-group__wrapper {
      display: inline;
    }
  }
</style>
