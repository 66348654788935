<template>
    <div class="brand-bs-container">
        <div class="brand-bs-section">
            <div class="brand-bs-section-title">
                Links
            </div>
            <div class="brand-bs-links">
                <div class="brand-bs-link"
                    v-for="link in links"
                    :key="link.id"
                >
                    {{link.link}}
                </div>
            </div>
        </div>
        <div class="brand-bs-section">
            <div class="brand-bs-section-title">
                Uploaded Inspiration
            </div>
            <div class="brand-bs-uploads d-flex flex-wrap">
                <div
                    class="brand-bs-upload"
                    v-for="file in uploads"
                    :key="file.thumbnail"
                >
                    <BrandKit
                        :item="file"
                        :file="file.fileUrl"
                        @onDownload="downloadFiles(file.id)"
                        @click="$emit('onBrandLogoFileClick', { id: file.id })"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import BrandKit from '@/components/profile/client/brandQuiz/wizard/common/BrandKit';

export default {
  name: 'brand-brain-storm',
  components: {
    BrandKit,
  },
  props: {
    links: Array,
    uploads: Array,
  },
  methods: {
    ...mapActions('brandProfile', ['downloadInspirationLink']),
    async downloadFiles(id) {
      const result = await this.downloadInspirationLink({ id });
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-bs-container{
    max-height: 350px;
    overflow-y: auto;
    padding: 0.5rem;
}
.brand-bs-section-title{
    font-weight: 500;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid #D8D8D8;
    max-width: 500px;
}
.brand-bs-link{
    padding: 0.5rem 0rem;
}
.brand-bs-upload{
    margin: 0.5rem;
    & > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
