import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"reqModal pa-0"},[_c(VRow,{staticClass:"ma-auto title-header"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"text modal-header-title"},[_vm._v(" Request Changes ")])]),_c(VCol,{staticClass:"d-flex justify-end py-0 pr-0"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v("mdi-close")])],1)],1),_c(VRow,{staticClass:"ma-auto"},[_c(VCol,{staticClass:"py-5"},[_c(VForm,{on:{"submit":_vm.submitForm}},[_c('div',{staticClass:"scollable-div"},[_c(VRow,{staticClass:"ma-auto"},[_c(VCol,{staticClass:"pt-0 pb-2",attrs:{"cols":"12"}},[_c('label',{staticClass:"text-left font-label"},[_vm._v(" Notes* ")]),_c('BaseTextAreaField',{staticClass:"bio-text mb-n5",attrs:{"filled":"","placeholder":"Describe the changes you want to make to your order?","rows":"4","solo":"","dense":"","flat":"","status":_vm.errors('notes').length ? 'error' : 'normal',"error-messages":_vm.errors('notes')},on:{"input":function($event){return _vm.$v.notes.$touch()},"blur":function($event){return _vm.$v.notes.$touch()}},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"notes"}}),_c('i',{staticClass:"notes-desc"},[_vm._v(" Your dedicated Content Producer will receive a notification with your request. We will update your order accordingly.")])],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"py-0 cancel",attrs:{"text":"","color":"primaryGray1"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"py-0 submit btn-purple",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.$v.$dirty && _vm.$v.$invalid}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }