import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uploaded-asset-container",attrs:{"title":_vm.file.name}},[(_vm.canRemove)?_c('div',{staticClass:"remove-icon"},[_c(VIcon,{staticClass:"close cursor-pointer",attrs:{"size":"16"},on:{"click":function($event){return _vm.$emit('remove', {
                fileId: _vm.file.id,
                orderDeliverableId: _vm.file.orderDeliverableId,
            })}}},[_vm._v(" mdi-close ")])],1):_vm._e(),_c('div',{staticClass:"usc-inner",on:{"click":function($event){return _vm.$emit('showPreview')}}},[_c('div',{staticClass:"usc"},[(_vm.isPhoto)?_c('div',{staticClass:"ucs-main"},[_c('img',{attrs:{"src":_vm.getOptimizedS3ImageURL(_vm.file.file, {
                        width: 200,
                        height: 200
                    }),"alt":"asset"}})]):_vm._e(),(_vm.isVideo)?_c('div',{staticClass:"ucs-main"},[_c('img',{attrs:{"src":_vm.getOptimizedS3ImageURL(_vm.poster, {
                        width: 200,
                        height: 200
                    }),"alt":"asset"}}),_c(VIcon,{staticClass:"play-btn",attrs:{"size":"24"}},[_vm._v("mdi-play")])],1):_vm._e(),(_vm.isOther)?_c('div',{staticClass:"ucs-main"},[_c(VIcon,{attrs:{"size":"54","color":"#f2f2f2"}},[_vm._v(" mdi-file-outline ")])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }