import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"sweepclose-container"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v("Sweeps to Close")]),_c('div',{staticClass:"sweepclose-table mt-5"},[_c(VRow,{staticClass:"table-headers pl-5"},[_c(VCol,{staticClass:"table-header"},[_vm._v("Sweep / Customer")]),_c(VCol,{staticClass:"table-header sorting"},[_vm._v("Time Since Sweep "),_c(VIcon,{staticClass:"ml-2",staticStyle:{"height":"0px","width":"0px","color":"#B4B4B4"},on:{"click":_vm.sortLowest}},[_vm._v(" mdi-menu-down ")])],1),_c(VCol,{staticClass:"table-header"},[_vm._v("Team")]),_c(VCol)],1),_c('div',{staticStyle:{"overflow-y":"scroll","height":"80%"}},_vm._l((_vm.item),function(item){return _c(VRow,{key:item.id,staticClass:"table-data pt-4"},[_c(VCol,{staticClass:"pt-0 ml-0 pl-0 text-purple title cursor-pointer title-style",on:{"click":function($event){return _vm.goToSweepDetails(item.id, item.businessId)}}},[_vm._v(_vm._s(item.title)+" "),_c(VRow,{staticClass:"text-black pl-3 business-name"},[_vm._v(" "+_vm._s(item.business.name)+" ")])],1),_c(VCol,{staticClass:"pa-5 pl-2 pt-0 mt-3 title time-col"},[_vm._v(" "+_vm._s(_vm.getMinutes(item.date, item.fromTime)))]),_c('span',{staticClass:"profile-pic"},[_c(VCol,{staticClass:"pl-0 pt-0 pb-0 mr-5 d-flex no-wrap profile-col",staticStyle:{"width":"230px"}},[(item.pointOfContact.profilePic
    || item.pointOfContact.firstName)?_c('div',{staticClass:"pointofcontact"},[_c('AvatarRound',{attrs:{"size":52,"avatar":item.pointOfContact.profilePic,"name":_vm.memberInitials(item.pointOfContact),"textStyle":"font-size: 16px;"}})],1):_c('div',{staticClass:"ml-3"}),(_vm.creativeSweepTeam(item.sweepTeam).length > 0
    && item.pointOfContact.firstName)?_c('div',{staticClass:"divider ml-5 mr-8 mb-2 mt-2"}):_vm._e(),_vm._l((_vm.creativeSweepTeam(item.sweepTeam).slice(0, 4)),function(items){return _c('span',{key:items.id},[_c('AvatarRound',{staticClass:"creative-icon",attrs:{"size":52,"avatar":items.creatives.profilePic,"name":_vm.memberInitials(items.creatives),"textStyle":"font-size: 25px;"}})],1)}),(_vm.creativeSweepTeam(item.sweepTeam).length > 4)?_c('span',{staticClass:"plus-icon"},[_c('AvatarRound',{attrs:{"size":52,"name":("+" + (_vm.creativeSweepTeam(item.sweepTeam).length - 4)),"textStyle":"font-size: 25px;"}})],1):_vm._e()],2)],1),_c(VCol,{staticClass:"ma-0 pt-0 d-flex justify-end pr-0"},[_c('button',{staticClass:"button cursor-pointer mt-2",on:{"click":function($event){return _vm.goToSweepClose(item)}}},[_vm._v("Close Out")])])],1)}),1)],1)]),(_vm.closeOutModal)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{staticClass:"sweep-container",attrs:{"persistent":"","content-class":"new-user-form","modal":_vm.closeOutModal,"width":"480px","height":"899px"}},[_c('CloseOutForm',{attrs:{"id":"sweepModal","sweep":this.selectedSweep},on:{"closemodal":_vm.removeCloseOut}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }