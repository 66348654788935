<template>
    <div class="brand-files-container d-flex">
        <div class="brand-file-wrapper ma-1"
            v-for="file in files"
            :key="file.kit"
        >
            <BrandKit
                :item="file"
                :file="file.fileUrl"
                from="profileView"
                @onDownload="downloadFiles(file.id)"
                @click="$emit('onBrandLogoFileClick', { id: file.id })"
                parentComponent="brandFiles"
            />
        </div>
    </div>
</template>
<script>
import BrandKit from '@/components/profile/client/brandQuiz/wizard/common/BrandKit';

export default {
  name: 'brand-files',
  components: {
    BrandKit,
  },
  props: {
    files: Array,
    downloadLink: Function,
  },
  methods: {
    async downloadFiles(id) {
      const result = await this.downloadLink({ id, projectId: this.projectId });
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-files-container{
  overflow-x: auto;
}
.brand-file-wrapper{
    width: min-content;
    & > .kit {
        height: 100% !important;
    }
    & > .kit-inner{
        height: 100% !important;
    }
}
</style>
