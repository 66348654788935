<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card style="border-radius: unset !important" class="card-container">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="modal-header-title"> Cancel Order </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon class="cursor-pointer" color="darken-1" @click.native="cancel">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-card-text class="pa-4">
        <!-- eslint-disable-next-line -->
        <div class="body-text">Are you sure you want to cancel this order? this action cannot be undone.</div>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          text
          depressed
          color="primaryGray1"
          @click.native="cancel">Cancel</v-btn>
        <v-btn
          depressed
          class="complete-order-button btn-purple"
          @click.native="agree">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CompleteOrderConfirmationModal',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: '#fff',
      width: 510,
      zIndex: 200,
    },
  }),
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve({
        confirm: true,
      });
      this.dialog = false;
    },
    cancel() {
      this.resolve({
        confirm: false,
      });
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  .title-header {
    border-bottom: 1px solid #D1D1D1;
    padding: 16px 21px 13px 21px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
  }
}
.body-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.complete-order-button {
    padding: 0.5rem 1.5rem !important;
}
</style>
