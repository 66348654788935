import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"blendedsense"},[(_vm.isModal)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"persistent":"","fullscreen":"","modal":_vm.isModal,"content-class":"main-modal preview-modal full-screen","width":"80%","scrollable":""}},[_c(VCard,{staticClass:"pa-5 confirm-popup",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex titleCard"},[_c(VRow,{staticClass:"my-0 mr-0 ml-0 title"},[_c(VCol,{staticClass:"d-flex align-center modal-header-title py-0 pl-0",attrs:{"cols":"10"}},[_vm._v(" Browse from Blended Sense ")]),_c(VCol,{staticClass:"d-flex justify-end pa-0",attrs:{"cols":"2"}},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.$emit('closeModal', false)}}},[_vm._v("mdi-close")])],1)],1)],1),_c(VRow,[_c(VContainer,{staticClass:"content-wrapper",attrs:{"fluid":""}},[_c('ContentKitFilters',{attrs:{"showSearch":_vm.canShowSearch}}),_c('ContentKitOverview',{attrs:{"isLoading":_vm.loading},on:{"filters-change":_vm.filtersValue,"modal":_vm.setBlendedSenseModal}})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }