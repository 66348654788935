<template>
  <div class="creative-form">
    <v-card flat>
        <div class="px-7">
            <v-row class="my-0">
            <v-col class="d-flex align-center pb-2" cols=10>
                <span class="text-capitalize mr-1 card-heading"> Edit Profile</span>
            </v-col>
            <v-col class="d-flex justify-end pb-2 pr-1">
                <v-icon color="darken-1" class="close-icon" @click="closeForm">
                  mdi-close
              </v-icon>
            </v-col>
            </v-row>
        </div>
        <v-divider></v-divider>
        <div class="form-body">
            <v-card-text class="pa-0 pt-2">
                <v-row class="d-flex align-center ma-0 px-7">
                    <v-col cols="12" class="pa-0">
                      <v-row>
                        <v-col cols="4" class="pt-3 d-flex align-center"
                         v-if="$vuetify.breakpoint.smAndUp">
                            <label class="text-left font-label">
                              First Name
                            </label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <BaseInput
                                :solo="$vuetify.breakpoint.smAndUp"
                                :dense="$vuetify.breakpoint.xsOnly"
                                :label="$vuetify.breakpoint.xsOnly ? 'First Name' : ''"
                                :outlined="$vuetify.breakpoint.xsOnly"
                                class="text-input"
                                name="firstName"
                                :placeholder="$vuetify.breakpoint.smAndUp ? 'First Name' : ''"
                                v-model.trim="profile.firstName"
                                :status="errors('firstName').length ? 'error': 'normal'"
                                :error-messages="errors('firstName')"
                                @input="$v.profile.firstName.$touch()"
                                @blur="$v.profile.firstName.$touch()"
                            />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="pt-3 d-flex align-center"
                        v-if="$vuetify.breakpoint.smAndUp">
                            <label class="text-left font-label">
                               Last Name
                            </label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <BaseInput
                                :solo="$vuetify.breakpoint.smAndUp"
                                :dense="$vuetify.breakpoint.xsOnly"
                                :label="$vuetify.breakpoint.xsOnly ? 'Last Name' : ''"
                                :outlined="$vuetify.breakpoint.xsOnly"
                                class="text-input"
                                name="lastName"
                                :placeholder="$vuetify.breakpoint.smAndUp ? 'Last Name' : ''"
                                v-model.trim="profile.lastName"
                                :status="errors('lastName').length ? 'error': 'normal'"
                                :error-messages="errors('lastName')"
                                @input="$v.profile.lastName.$touch()"
                                @blur="$v.profile.lastName.$touch()"
                            />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="py-0 d-flex align-center"
                        v-if="$vuetify.breakpoint.smAndUp">
                           <label class="text-left font-label">
                               Pronouns
                            </label>
                        </v-col>
                        <v-col cols="12" sm="8" class="pb-0">
                            <v-select
                                :solo="$vuetify.breakpoint.smAndUp"
                                :dense="$vuetify.breakpoint.xsOnly"
                                :label="$vuetify.breakpoint.xsOnly ? 'Pronouns' : ''"
                                :placeholder="$vuetify.breakpoint.smAndUp ? 'Select Pronouns' : ''"
                                :outlined="$vuetify.breakpoint.xsOnly"
                                hint="You can select maximum two pronouns"
                                persistent-hint
                                class="text-input mb-n2"
                                :items="pronouns"
                                item-text="name"
                                item-value="id"
                                flat
                                multiple
                                return-object
                                v-on:input="limiter"
                                v-model="profile.userPronouns"
                            ></v-select>
                        </v-col>
                      </v-row>
                      <div v-for="(link, j) in socialLinks" :key="`link${j}`">
                        <v-row>
                            <v-col cols="4" class="pt-3 d-flex align-center"
                            v-if="$vuetify.breakpoint.smAndUp">
                            <label class="text-left font-label">
                                {{ link.placeholder }}
                            </label>
                            </v-col>
                            <v-col cols="12" sm="8">
                            <BaseInput
                                :solo="$vuetify.breakpoint.smAndUp"
                                :dense="$vuetify.breakpoint.xsOnly"
                                :label="$vuetify.breakpoint.xsOnly ? link.placeholder : ''"
                                :outlined="$vuetify.breakpoint.xsOnly"
                                class="text-input"
                                :name="link.name"
                                :placeholder="$vuetify.breakpoint.smAndUp ? link.placeholder : ''"
                                v-model.trim="profile[link.name]"
                                :status="errors(link.name).length ? 'error': 'normal'"
                                :error-messages="errors(link.name)"
                                @input="$v.profile[link.name].$touch()"
                                @blur="$v.profile[link.name].$touch()"
                            >
                            </BaseInput>
                            </v-col>
                        </v-row>
                       </div>
                    <span class="personal-info">Personal Information</span>
                    <div v-for="(detail, i) in basicDetails" :key="`basic${i}`">
                        <v-row>
                            <v-col cols="4" class="pt-3 d-flex align-center"
                            >
                              <label
                                v-if="detail.name === 'radius'"
                                class="text-left font-label"
                              >
                                {{detail.placeholder}} <span class="grey-text">(Miles)</span>
                              </label>
                              <label v-else class="text-left font-label">
                                {{detail.placeholder}}
                              </label>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <BaseInput
                                    v-if="detail.name === 'number'"
                                    :solo="$vuetify.breakpoint.smAndUp"
                                    :dense="$vuetify.breakpoint.xsOnly"
                                    :label="$vuetify.breakpoint.xsOnly ? detail.placeholder : ''"
                                    :outlined="$vuetify.breakpoint.xsOnly"
                                    :key="`input${i}`"
                                    v-mask="'###-###-####'"
                                    type="tel"
                                    class="text-input"
                                    :name="detail.name"
                                    v-model.trim="profile[detail.name]"
                                    :status="errors(detail.name).length ? 'error': 'normal'"
                                    :error-messages="errors(detail.name)"
                                    @input="$v.profile[detail.name].$touch()"
                                    @blur="$v.profile[detail.name].$touch()"
                                />
                                <div class="mt-5" v-else-if="detail.name === 'radius'">
                                  <v-slider
                                    :key="detail.value"
                                    :value="detail.value"
                                    @change="(radius) => setRadius(radius)"
                                    :thumb-size="24"
                                    max="80"
                                    min="5"
                                    thumb-label="always"
                                  ></v-slider>
                                </div>
                                <BaseInput
                                    v-else
                                    :solo="$vuetify.breakpoint.smAndUp"
                                    :dense="$vuetify.breakpoint.xsOnly"
                                    :label="$vuetify.breakpoint.xsOnly ? detail.placeholder : ''"
                                    :outlined="$vuetify.breakpoint.xsOnly"
                                    class="text-input"
                                    :name="detail.name"
                                :placeholder="$vuetify.breakpoint.smAndUp ? detail.placeholder : ''"
                                    v-model.trim="profile[detail.name]"
                                    :status="errors(detail.name).length ? 'error': 'normal'"
                                    :error-messages="errors(detail.name)"
                                    @input="$v.profile[detail.name].$touch()"
                                    @blur="$v.profile[detail.name].$touch()"
                                />
                            </v-col>
                        </v-row>
                       </div>
                       <v-row>
                         <v-col class="d-flex pl-1">
                            <v-checkbox
                                class='m-0 pa-0 pb-2 remember-check-box'
                                off-icon="mdi-checkbox"
                                on-icon= 'mdi-check'
                                color="info"
                                v-model="profile.isPrivateAccount"
                            />
                            <div class="align pl-2">
                              <span>Make personal information private.</span>
                              <span>This won’t be visible on your public profile.</span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end px-7 pt-2 pb-6 mobile-button-padding">
                <v-btn
                  class="pa-2 cancel-btn"
                  color="primaryGray1"
                  @click="closeForm"
                  text
                  >
                    <span class="cancel">Cancel</span>
                </v-btn>
                <v-btn class="ml-3 submit-btn"
                  :loading="loading"
                  color="info"
                  @click="submit">
                    <span class="submit">Save</span>
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
    <v-container
      fluid
      v-if="emailEdit"
    >
      <Modal
        :modal="emailEdit"
        width="650"
        persistent
      >
          <v-card class="pa-5 confirm-popup" flat>
            <v-row>
              <v-col class="d-flex align-center pb-0" cols="11">
                <div class="modal-header-title"> Confirm email update </div>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="font-family-2 note">
                Please note that you'll be logged out now.
                We’ll send you a confirmation email with a link to confirm your new email address.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 font-family-2" xs="12" sm="8">
                <BaseInput
                  outlined
                  dense
                  label="New Email*"
                  name="email"
                  class="email-input"
                  v-model.trim="profile.email"
                  disabled
                >
                </BaseInput>
              </v-col>
              <v-col cols="12" class="d-flex justify-end pa-0">
                  <v-btn text
                    @click="cancelEmailUpdate"
                    class="py-0 info--text"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-2 info--text"
                    @click="sendConfirmationEmail"
                  >
                    Confirm
                  </v-btn>
              </v-col>
            </v-row>
          </v-card>
      </Modal>
     </v-container>
   </div>
</template>

<script>
/* eslint-disable global-require */
import {
  required, maxLength, minLength, numeric, email,
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { url } from '@/helpers';
import Modal from '@/components/common/Modal';
import BaseInput from '@/components/common/BaseInput';

export default {
  name: 'CreativeProfileForm',
  components: {
    BaseInput,
    Modal,
  },
  directives: {
    mask,
  },
  props: {
    profileDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      email: '',
      emailEdit: false,
      profile: {
        firstName: '',
        lastName: '',
        number: null,
        email: '',
        portfolio: null,
        instagramUrl: '',
        facebookUrl: '',
        zipcode: null,
        radius: 5,
        isPrivateAccount: false,
        userPronouns: [],
      },
      pronouns: [],
    };
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.profile.firstName.required) { errors.push('Please provide your first name'); }
            if (!this.$v.profile.firstName.maxLength) { errors.push('First name must be less than 50 characters length'); }
            break;
          case 'lastName':
            if (!this.$v.profile.lastName.required) { errors.push('Please provide your last name'); }
            if (!this.$v.profile.lastName.maxLength) { errors.push('Last name must be less than 50 characters length'); }
            break;
          case 'number':
            if (!this.$v.profile.number.required) { errors.push('Please provide your Phone Number'); }
            if (!this.$v.profile.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'email':
            if (!this.$v.profile.email.required) { errors.push('Please provide your Email'); }
            if (!this.$v.profile.email.email) { errors.push('Please provide valid Email'); }
            break;
          case 'portfolio':
            if (!this.$v.profile.portfolio.required) { errors.push('Please provide your website'); }
            if (!this.$v.profile.portfolio.url) { errors.push('Please provide valid url'); }
            break;
          case 'instagramUrl':
          case 'facebookUrl':
            if (!this.$v.profile[field].url) { errors.push('Please provide valid url'); }
            break;
          case 'zipcode':
            if (!this.$v.profile.zipcode.minLength) {
              errors.push('Zipcode is required');
            }
            if (!this.$v.profile.zipcode.validZipcode) {
              errors.push('Invalid Zipcode');
            }
            // if (!this.$v.profile.zipcode.required)
            //  { errors.push('Please provide your Zip Code'); }
            // if (!this.$v.profile.zipcode.numeric)
            //  { errors.push('Zip Code must be numeric'); }
            // if (!this.$v.profile.zipcode.minLength
            //  || !this.$v.profile.zipcode.maxLength)
            //   { errors.push('Zip Code length should be 5 digits'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    basicDetails() {
      return ([{
        name: 'number',
        placeholder: 'Phone Number',
        value: this.profile.number,
      }, {
        name: 'email',
        placeholder: 'Email',
        value: this.profile.email,
      }, {
        name: 'zipcode',
        placeholder: 'Zip Code',
        value: this.profile.zipcode,
      },
      {
        name: 'radius',
        placeholder: 'Radius',
        value: this.profile.radius,
      },
      ]);
    },
    socialLinks() {
      return ([{
        name: 'instagramUrl',
        placeholder: 'Instagram',
        value: this.profile.instagramUrl,
      }, {
        name: 'facebookUrl',
        placeholder: 'Facebook',
        value: this.profile.facebookUrl,
      }, {
        name: 'portfolio',
        placeholder: 'Website',
        value: this.profile.portfolio,
      }]);
    },
  },
  methods: {
    ...mapActions('profile', ['creativeProfileUpdate', 'getPronouns']),
    ...mapActions('user', ['logoutUser']),
    setRadius(radius) {
      this.profile.radius = radius;
    },
    closeForm(reload) {
      this.$emit('close-modal', reload);
    },
    limiter(e) {
      if (e.length > 2) {
        e.pop();
      }
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.profileDetails.email !== this.profile.email) {
          this.emailEdit = true;
        } else {
          this.loading = true;
          const payload = { ...this.profile };
          const response = await this.creativeProfileUpdate(payload);
          if (response.success) {
            this.loading = false;
            this.$emit('close-modal', true);
          }
        }
      }
    },
    cancelEmailUpdate() {
      this.$v.$reset();
      this.emailEdit = false;
    },
    async sendConfirmationEmail() {
      const payload = Object.assign(this.profile, { emailUpdated: true });
      const response = await this.creativeProfileUpdate(payload);
      if (response.success) {
        this.emailEdit = false;
        this.$emit('close-modal', true);
        await this.logoutUser();
        this.$router.push('/');
      }
    },
  },
  validations() {
    return {
      profile: {
        firstName: {
          required,
          maxLength: maxLength(50),
        },
        lastName: {
          required,
          maxLength: maxLength(50),
        },
        number: {
          required,
          minLength: minLength(12),
        },
        email: {
          required,
          email,
        },
        portfolio: {
          required,
          url,
        },
        instagramUrl: {
          url,
        },
        facebookUrl: {
          url,
        },
        zipcode: {
          minLength: minLength(5),
          // maxLength: maxLength(10),
          // eslint-disable-next-line func-names
          required,
          validZipcode: (value) => /^[0-9A-Z]+$/.test(value),
          numeric,
          maxLength: maxLength(5),
        },
        radius: {
          numeric,
        },
      },
    };
  },
  async mounted() {
    this.profile = Object.assign(this.profile, this.profileDetails);
    const result = await this.getPronouns();
    this.pronouns = result;
  },
};
</script>


<style scoped lang="scss">
.grey-text{
  padding-left: 5px;
  color: #727272 !important;
}
    .v-card__text {
        font-size: 18px;
        letter-spacing: -0.36px;
        color: $neutral1;
        overflow: hidden;
        .base-input {
            width: 100%;
        }
    }
    .card-heading {
        color: $card-title;
        font-family: $fontFamily1;
        font-size: 14px;
        font-weight: bold;
    }
    .form-body {
        max-height: 570px;
        overflow: auto;
    }
    .personal-info{
        font-family: $fontFamily1;
        font-size: 14px;
        font-weight: 500;
        color: #727272;
    }
    ::v-deep .v-text-field.v-text-field--solo .v-input__control {
        min-height: 40px !important;
        height: auto !important;
    }
    ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
        font-family: $fontFamily1 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #292929 !important;
    }
    ::v-deep .v-text-field.v-text-field--solo .v-label {
        font-size: 14px;
        padding-left: 4px;
        padding-right: 4px;
    }
    ::v-deep .v-input__slot {
        margin-bottom: 4px;
    }
    ::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
        box-shadow: none;
        border-radius: 2px;
        background-color: #ffffff;
        font-family: $fontFamily1;
        font-size: 14px;
        font-weight: 500;
        border: solid 1px #e0e0e0;
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    ::v-deep .input-field {
        height: 40px;
        &.v-textarea {
        height: auto;
        }
    }
    .cancel {
        font-family: $fontFamily1;
        font-size: 16px;
        font-weight: 500;
        color: #828282;
    }
    ::v-deep .v-select__selections {
        padding-left: 6px;
        font-size: 14px;
        font-family: $fontFamily1;
        text-transform: lowercase;
    }
    ::v-deep .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
      text-transform: lowercase;
    }
    ::v-deep .v-select__slot {
        min-width: 20px;
        border-radius: 2px;
        padding-left: 0px;
        min-height: 36px;
        border: solid 1px #e0e0e0 !important;
    }
    ::v-deep.email-input > .input-field > .v-input__control > .v-input__slot {
      padding-left: 10px !important;
    }
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) >
    .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed
    .v-text-field__details {
        padding: 0px;
    }
    .submit {
        font-family: $fontFamily1;
        font-size: 16px;
        font-weight: 500;
    }
    .submit-btn {
        border-radius: 6px;
        width: 120px;
    }
    .cancel-btn {
        width: 120px;
    }
    .remember-check-box {
        padding-top: 12px !important;
        padding-left: 8px !important;
        ::v-deep {
          .v-input--selection-controls__ripple {
            height: 28px;
            width: 28px;
          }
        }
    }
    ::v-deep .v-input--selection-controls .v-input__slot > .v-label,
    .v-input--selection-controls .v-radio > .v-label{
        line-height: 14px;
        font-family: $fontFamily1;
        font-size: 14px;
        font-weight: normal;
        color: $charcoalBlack;
    }
    ::v-deep .v-input--selection-controls__input {
        margin-right: 6px;
    }
    ::v-deep .v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
      margin-right: 12px !important;
    }
    .align {
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      font-family: $fontFamily1;
      font-size: 14px;
      font-weight: normal;
      color: $charcoalBlack;
      line-height: 1.3;
    }
  @media (max-width: 600px) {
    .remember-check-box {
      padding-left: 0px !important;
    }
    ::v-deep {
      .v-select__slot {
        border-radius: 4px;
        border: none !important;
      }
    }
    ::v-deep {
      .text-input > .input-field > .v-input__control > .v-input__slot {
        padding-left: 0px !important;
        padding-right: 0px !important;
        border: none;
      }
    }
    ::v-deep {
      .v-text-field--outlined.v-input--dense .v-label {
        padding-left: 16px;
      }
    }
    ::v-deep.input-field > .v-input__control > .v-input__slot {
      padding-left: 10px;
    }
    ::v-deep .v-select__selections {
      padding: 4px !important;
    }
    ::v-deep .v-text-field input{
      padding: 4px 12px;
    }
    ::v-deep .v-select__slot {
        padding-left: 9px;
        padding-right: 9px;
    }
    .form-body {
      max-height: 520px;
    }
  }
  @media screen and (max-width: 700px) and (orientation: landscape) {
    .form-body {
      max-height: 800px;
    }
}
</style>
