<template>
    <div class="order-container">
        <div class="order-main-section" ref="orderMainSection">
            <div class="order-header">
                <v-row class="align-center order-header-top">
                    <div class="order-number">
                        <span>Order #{{order.id}}</span>
                    </div>
                    <div
                        class="order-status-container"
                        :style="
                        `border: 2px solid ${getStatusColor(order.status)};
                        border-left: 0.5rem solid ${getStatusColor(order.status)};
                        background: ${getStatusBackground(order.status)}`"
                    >
                        <span class="order-status">{{getStatusDisplayText(order.status)}}</span>
                    </div>
                    <v-spacer></v-spacer>
                    <!-- add a menu -->
                    <div class="order-menu-container" v-if="showMenu">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                size="2em"
                                v-on="on"
                                color="#262626">mdi-dots-horizontal </v-icon>
                            </template>
                            <v-list width="200px">
                                <v-list-item
                                    @click="$emit('editOrder')"
                                    class="order-menu-item">
                                    <v-list-item-title >Edit Order</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item
                                    @click="$emit('deleteOrder')"
                                    class="order-menu-item delete"
                                >
                                    <v-list-item-title
                                        style="color: #ff371a">
                                            Cancel Order
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <div class="order-date-container">
                            <div class="order-main-label">
                                ORDER DATE
                            </div>
                            <span class="order-main-text">{{orderDate}}</span>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="order-credits-container" :key="order.key">
                            <div class="order-main-label">COMPLETED CREDITS</div>
                            <span class="order-main-text">
                                {{creditsCompleted}}/{{order.credits}}</span>
                        </div>
                    </v-col>
                </v-row>
                <v-row style="padding: 0 1rem;">
                    <div class="order-description-container">
                        <div class="order-main-label">
                            ORDER DESCRIPTION
                        </div>
                        <div class="order-main-text">{{order.description || '-'}}</div>
                    </div>
                </v-row>
                <v-row
                    class="order-actions-container"
                    v-if="canCompleteOrder">
                    <!-- complete order button -->
                    <v-col cols="6" class="order-button-container">
                        <v-btn
                            class="btn-purple"
                            depressed
                            @click="$emit('completeOrder')"
                        >
                            Complete Order
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div
                class="order-body"
                :class="{showTopBorder: showDeliverables}"
                :style="`height: ${showDeliverables ? '100%' : '0px'}`"
                ref="orderBody"
            >
            <!-- change v-simple-table to grid system -->
            <div class="deliverables-table" ref="deliverablesTable"
                v-if="nonSweepDeliverables.length > 0"
            >
                <v-row class="dt-head">
                    <v-col
                        class="dt-col-deliverable"
                    >
                        Deliverable
                    </v-col>
                    <v-col
                        v-if="order.status === 'ACTIVE'"
                        class="text-center mr-2 dt-col-upload2"
                        style="min-width: 120px"
                    >
                        Status
                    </v-col>
                    <v-col
                        class="text-center dt-col-dimension"
                    >
                        Dimensions
                    </v-col>
                    <v-col
                        class="text-center col-min-width dt-col-qty2">
                        Qty
                    </v-col>
                    <v-col
                        class="text-center col-min-width dt-col-total2"
                    >
                        Total
                    </v-col>
                </v-row>
                <div class="dt-body">
                    <div
                    v-for="deliverable in nonSweepDeliverables"
                    :key="deliverable.id"
                    class="dt-row flex-column"
                    >
                        <v-row>
                            <v-col
                                class="dt-col-deliverable"
                            >
                                <div style="overflow-wrap: anywhere" class="deliverable-item">
                                    <!-- eslint-disable-next-line -->
                                    <div class="order-del-category">{{deliverable.deliverable.category.name}}</div>
                                    <!-- eslint-disable-next-line -->
                                    <div class="order-del-name">{{deliverable.deliverable.name}}&nbsp;{{`(${deliverable.deliverable.creditCost} credits)`}}</div>
                                    <!-- eslint-disable-next-line -->
                                    <div class="order-del-description">{{deliverable.description || '-'}}</div>
                                </div>
                            </v-col>
                            <v-col
                                v-if="order.status === 'ACTIVE'"
                                class="text-center mr-2 dt-col-upload"
                            >
                                <div class="py-2">
                                    <div class="order-del-upload-btn">
                                        <v-btn
                                            v-if="orderPermissions.uploadAssets"
                                            depressed
                                            class="del-upload-btn"
                                            @click="() => openUploadAssetsForm(deliverable)"
                                        >
                                            Upload
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="text-center dt-col-dimension">
                                <div class="dimensions-labels">
                                    <span
                                        class="dimensions-label"
                                        v-for="(dim, index) in deliverable.dimensions"
                                        :key="index"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        {{`${dim.dimension.name}${index !== deliverable.dimensions.length - 1 ? ',' : '' }`}}
                                    </span>
                                </div>
                            </v-col>
                            <v-col class="text-center col-min-width dt-col-qty1">
                                {{ deliverable.quantity }}
                            </v-col>
                            <v-col class="text-center col-min-width dt-col-total1">
                                {{ deliverable.quantity * deliverable.deliverable.creditCost }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center dt-col-dimension2">
                                <div class="dimensions-labels">
                                    <span
                                        class="dimensions-label"
                                        v-for="(dim, index) in deliverable.dimensions"
                                        :key="index"
                                    >
                                    <!-- eslint-disable-next-line -->
                                    {{`${dim.dimension.name}${index !== deliverable.dimensions.length - 1 ? ',' : '' }`}}
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                v-if="order.status === 'ACTIVE'"
                                class="text-center mr-2 dt-col-upload3"
                            >
                                <div class="py-2">
                                    <div class="order-del-upload-btn">
                                        <v-btn
                                            v-if="orderPermissions.uploadAssets"
                                            depressed
                                            class="del-upload-btn"
                                            @click="() => openUploadAssetsForm(deliverable)"
                                        >
                                            Upload
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="(deliverable.files || []).length">
                            <div class="asset-grid d-flex flex-wrap">
                                <UploadedFileGridItem
                                    v-for="(file, index) in deliverable.files || []"
                                    :key="file.id"
                                    :file="file"
                                    @remove="removeFile"
                                    :canRemove="orderPermissions.removeAssets"
                                    @showPreview="$emit('showPreview', index, deliverable.files)"
                                />
                            </div>
                        </v-row>
                    </div>
                    <v-row v-if="sweepDeliverables.length === 0">
                        <v-spacer class="spacer"></v-spacer>
                        <v-col class="text-center dt-col-qty3">Total</v-col>
                        <v-col class="text-center dt-col-total3">{{order.credits}}</v-col>
                    </v-row>
                </div>
            </div>
            <div class="deliverables-table" ref="deliverablesTable"
                :class="{ 'mt-2': nonSweepDeliverables.length > 0 }"
                v-if="sweepDeliverables.length > 0"
            >
                <v-row class="dt-head"
                    :class="{ 'border-top': nonSweepDeliverables.length > 0 }"
                >
                    <v-col
                        class="dt-col-deliverable"
                    >
                        Sweeps
                    </v-col>
                    <v-col
                        v-if="order.status === 'ACTIVE'"
                        class="text-center mr-2 dt-col-upload2"
                        style="min-width: 120px"
                    >
                        Status
                    </v-col>
                    <v-col
                        class="text-center dt-col-dimension"
                    >
                    </v-col>
                    <v-col
                        class="text-center col-min-width dt-col-locations">
                        Locations
                    </v-col>
                    <v-col
                        class="text-center col-min-width dt-col-total2"
                    >
                        Total
                    </v-col>
                </v-row>
                <div class="dt-body">
                    <div
                    v-for="deliverable in sweepDeliverables"
                    :key="deliverable.id"
                    class="dt-row flex-column"
                    >
                        <v-row v-if="getSweeps(deliverable)">
                            <v-col
                                class="dt-col-deliverable"
                            >
                                <div style="overflow-wrap: anywhere">
                                    <!-- eslint-disable-next-line -->
                                    <div class="order-del-category">{{deliverable.deliverable.category.name}}</div>
                                    <!-- eslint-disable-next-line -->
                                    <div class="order-del-name">{{deliverable.deliverable.name}}&nbsp;{{`(${deliverable.deliverable.creditCost} credits)`}}</div>
                                    <!-- eslint-disable-next-line -->
                                    <div class="order-del-description">{{deliverable.description || '-'}}</div>
                                </div>
                            </v-col>
                            <v-col
                                style="width: 300px;"
                                v-if="order.status === 'ACTIVE'"
                                class="text-center mr-5 dt-col-upload"
                            >
                                <div class="py-2" v-if="!deliverable.sweeps">
                                    <div class="order-del-upload-btn">
                                        <v-btn
                                            v-if="orderPermissions.scheduleSweep"
                                            depressed
                                            class="del-upload-btn"
                                            @click="() => scheduleTheSweep(deliverable)"
                                        >
                                            Schedule
                                        </v-btn>
                                    </div>
                                </div>
                                <!-- eslint-disable-next-line -->
                                <div style="width: 135px;" v-if="deliverable.sweeps !== null">
                                <span
                                  class="order-del-category">sweep name
                                </span>
                                    <div
                                      @click="goToSweepDetails(deliverable.sweeps)"
                                      class="sweep-title">
                                        {{ deliverable.sweeps.title }}
                                    </div>
                                <span class="order-del-category">date & time</span>
                                <div v-if="deliverable.sweeps !== null">
                                    {{ sweepDate(deliverable.sweeps.date) }}
                {{ time(deliverable.sweeps.fromTime) }}-{{ time(deliverable.sweeps.toTime) }}
                                </div>
                                </div>
                            </v-col>
                            <v-col class="text-center dt-col-dimension">
                                <div class="dimensions-labels">
                                    <span
                                        class="dimensions-label"
                                        v-for="(dim, index) in deliverable.dimensions"
                                        :key="index"
                                    >
                                    </span>
                                </div>
                            </v-col>
                            <v-col class="text-center col-min-width dt-col-locations">
                                {{ deliverable.locations }}
                            </v-col>
                            <v-col class="text-center col-min-width dt-col-total">
                                {{ deliverable.quantity * deliverable.deliverable.creditCost }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center dt-col-dimension2">
                                <div class="dimensions-labels">
                                    <span
                                        class="dimensions-label"
                                        v-for="(dim, index) in deliverable.dimensions"
                                        :key="index"
                                    >
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                v-if="order.status === 'ACTIVE'"
                                class="text-center mr-2 dt-col-upload3"
                            >
                                <div class="py-2" v-if="!deliverable.sweeps">
                                    <div class="order-del-upload-btn">
                                        <v-btn
                                            v-if="orderPermissions.scheduleSweep"
                                            depressed
                                            class="del-upload-btn"
                                            @click="() => scheduleTheSweep(deliverable)"
                                        >
                                            Schedule
                                        </v-btn>
                                    </div>
                                </div>
                                <!-- eslint-disable-next-line -->
                                <div style="width: 140px;" v-if="deliverable.sweeps !== null">
                                <span class="order-del-category">sweep name
                                </span>
                                    <div
                                      @click="goToSweepDetails(deliverable.sweeps)"
                                      class="sweep-title">
                                        {{ deliverable.sweeps.title }}
                                    </div>
                                <span class="order-del-category">date & time</span>
                                <div v-if="deliverable.sweeps !== null">
                                    {{ sweepDate(deliverable.sweeps.date) }}
                {{ time(deliverable.sweeps.fromTime) }}-{{ time(deliverable.sweeps.toTime) }}
                                </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="(deliverable.files || []).length">
                            <div class="asset-grid d-flex flex-wrap">
                                <UploadedFileGridItem
                                    v-for="(file, index) in deliverable.files || []"
                                    :key="file.id"
                                    :file="file"
                                    @remove="removeFile"
                                    :canRemove="orderPermissions.removeAssets"
                                    @showPreview="$emit('showPreview', index, deliverable.files)"
                                />
                            </div>
                        </v-row>
                    </div>
                    <v-row>
                        <v-spacer class="spacer"></v-spacer>
                        <v-col class="text-center dt-col-qty3">Total</v-col>
                        <v-col class="text-center dt-col-total3">{{order.credits}}</v-col>
                    </v-row>
                </div>
            </div>
            </div>
        </div>
        <div class="order-toggler d-flex justify-end">
            <div class="order-toggle-btn d-flex text-purple" @click="toggleDeliverables">
                <div class="order-toggle-text" :key="showDeliverables">
                    <span>{{ showDeliverables ? 'Hide' : 'Show' }} Deliverables</span>
                </div>
                <div class="order-toggle-icon">
                    <v-icon color="#8066FF">
                        {{ showDeliverables ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';
import { getStatusDisplayText, getStatusColor, getStatusBackground } from '@/helpers/orders';
import UploadedFileGridItem from './UploadedFileGridItem';

export default {
  name: 'Order',
  props: {
    order: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    orderPermissions: {
      type: Object,
      required: false,
      default: () => ({
        create: false,
        modify: false,
        uploadAssets: false,
        removeAssets: false,
        scheduleSweep: false,
      }),
    },
  },
  components: {
    UploadedFileGridItem,
  },
  methods: {
    ...mapActions('orders', ['getOrderFiles']),
    getStatusDisplayText,
    getStatusColor,
    getStatusBackground,
    isSweepLive(val) {
      // eslint-disable-next-line
      const date = moment().format('YYYY-MM-DD');
      const currentDate = new Date();
      const endDate = new Date(currentDate.getTime());
      endDate.setHours(val.toTime.split(':')[0]);
      endDate.setMinutes(val.toTime.split(':')[1]);
      const valid = endDate < currentDate;
      if (date === val.date && valid) {
        return true;
      }
      return false;
    },
    enableCompleteOrder(val) {
      const sweepDate = new Date(val.date);
      const currentDate = new Date();
      const endDate = new Date(currentDate.getTime());
      endDate.setHours(val.toTime.split(':')[0]);
      endDate.setMinutes(val.toTime.split(':')[1]);
      const valid = currentDate > endDate;
      if ((currentDate.getTime() >= sweepDate.getTime() && valid)
      && !val.closedAt && !val.cancelledAt) {
        return true;
      }
      return this.isSweepLive(val);
    },
    getSweeps(val) {
      if (val.sweeps === null) {
        this.sweepAlive = false;
      }
      return true;
    },
    goToSweepDetails(val) {
      const sweepId = val.id;
      const { businessId } = val;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          sweepId,
          businessId,
        },
      });
    },
    openUploadAssetsForm(deliverable) {
      this.$emit('openUploadAssetsForm', {
        orderId: this.order.id,
        deliverable,
      });
    },
    scheduleTheSweep(val) {
      const projects = _.get(this.business, 'projects', []);
      const privateProject = _.find(projects, { sharedWithBusinessId: null });
      if (!privateProject) {
        this.$toast.error('You must create a private project before scheduling a sweep.');
        return;
      }
      this.$router.replace({
        query: {
          ...this.$route.query,
          new: true,
          referrer: 'orders',
          orderId: this.order.id,
          deliverableId: val.id,
        },
      });
      this.$emit('showSweeps', 1);
    },
    removeFile({ fileId, orderDeliverableId }) {
      this.$emit('removeFile', {
        orderId: this.order.id,
        fileId,
        orderDeliverableId,
        callback: () => {
          this.$forceUpdate();
        },
      });
    },
    async toggleDeliverables() {
      if (this.deliverablesLoading) return;
      const showDeliverables = !this.showDeliverables;
      if (!showDeliverables) {
        this.showDeliverables = false;
        this.order.showDeliverables = false;
        return;
      }
      this.showDeliverables = true;
      this.order.showDeliverables = true;
    },
  },
  computed: {
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepDate() {
      return (val) => moment((val)).format('M-DD-YYYY');
    },
    orderDate() {
      return moment(this.order.createdAt).format('M/DD/YYYY - hh:mm A');
    },
    nonSweepDeliverables() {
      return this.order.orderDeliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return !/^Sweeps$/i.test(category);
      });
    },
    sweepDeliverables() {
      return this.order.orderDeliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return /^Sweeps$/i.test(category);
      });
    },
    showMenu() {
      const allowedStatuses = ['DRAFT', 'PENDING_APPROVAL', 'REQUESTED_CHANGES', 'ACTIVE'];
      return this.orderPermissions.modify && allowedStatuses.includes(this.order.status);
    },
    deliverableColumnsSpecs() {
      const specs = {
        deliverable: 4,
        dimensions: 4,
        status: null,
        quantity: 1,
        credits: 1,
      };
      if (this.order.status === 'ACTIVE') {
        specs.dimensions = 4;
        specs.status = 2;
      }
      return specs;
    },
    creditsCompleted() {
      let completedCredits = 0;
      this.order.orderDeliverables.forEach((orderDeliverable) => {
        const uploadedFileCount = (orderDeliverable.files || []).length;
        const { deliverable, quantity } = orderDeliverable;
        const { units, creditCost } = deliverable;
        const unitsCompleted = Math.floor(uploadedFileCount / units);
        const creditsCompleted = Math.min(unitsCompleted, quantity) * creditCost;
        completedCredits += creditsCompleted;
      });
      return completedCredits;
    },
    canCompleteOrder() {
      if (!this.orderPermissions.modify) return false;
      const deliverableDeliverables = this.order.orderDeliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return !/(Sweeps|add-ons|add ons|Sweep Add-ons)/i.test(category);
      });
      const uploadedFileToEveryDeliverable = deliverableDeliverables.every((deliverable) => {
        const { files } = deliverable;
        return files.length > 0;
      });
      const sweepDeliverable = this.order.orderDeliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'sweeps');
        return category;
      });
      const sweep = sweepDeliverable.every((deliverable) => {
        const { sweeps } = deliverable;
        if (sweeps !== null) {
          return this.enableCompleteOrder(sweeps);
        }
        return sweeps;
      });
      return this.order.status === 'ACTIVE' && uploadedFileToEveryDeliverable && sweep && this.sweepAlive;
    },
  },
  data() {
    return {
      sweepAlive: true,
      showDeliverables: false,
      bodyHeight: 0,
      timer: null,
      loadedDeliverables: false,
      deliverablesLoading: false,
    };
  },
  mounted() {
    this.showDeliverables = this.order.showDeliverables;
    this.timer = setTimeout(() => {
      // eslint-disable-next-line
      const height = this.$refs.orderBody.scrollHeight;
      this.bodyHeight = height;
    }, 100);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.order-container {
    margin-top: 1.5rem;
    border: 1px solid #D8D8D8;
    padding: 1rem 0rem 0rem 0rem;
    border-radius: 1rem;
    overflow: hidden;
}
.order-main-section {
    padding: 0rem 1rem;
}
.order-header{
    padding-bottom: 1em;
    .order-number {
        margin-right: 1rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #9E9E9E;
    }
    .order-header-top{
        border-bottom: 1px solid #ccc;
        padding: 0em 0em 1em 0em;
    }
    .order-status-container{
        border-radius: 0.5rem;
        padding: 0.2rem 0.5rem;
        text-transform: uppercase;
        .order-status {
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            color: #262626;
        }
    }
}
.row {
    margin: 0px !important;
}
.dt-head {
  flex-wrap: nowrap !important;
  border-bottom: thin solid #ccc;
  font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #262626;
 &.border-top {
    border-top: thin solid #ccc;
 }
}
.order-body {
    // transition: height 0.5s ease-in-out;
    will-change: height;
    overflow: hidden;
    &.showTopBorder{
        border-top: 1px solid #ccc;
    }
}
.order-toggler{
    background: #f9f9f9;
    padding: 1rem;
    border-top: 1px solid #ccc;;
}
.order-toggle-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}
.order-main-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #999999;
    .order-main-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
    }
}
.order-del-category {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #999999;
}
.sweep-title {
    cursor: pointer;
    word-break: break-all;
    width: 100% !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: #8066ff;
    }
  }
.order-del-name{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.order-del-description {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #999999;
}

.order-menu-item {
    cursor: pointer;
    .v-list-item__title {
        color: #262626 !important;
    }
    &.delete .v-list-item__title {
        color: #FF371B !important;
    }
}
.order-actions-container {
    background: #F2F2F2;
    margin-top: 0.5rem !important;
    border-radius: 0.3rem;
}
.del-upload-btn {
    background: #fff !important;
    border: 2px solid $secondary2;
    border-radius: 1.5rem;
    & > .v-btn__content {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.dt-row .text-center {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #262626;
}
// add border for dt-row except last row
.dt-row:not(:last-child) {
    border-bottom: thin solid #ccc;
}
.col-min-width {
    min-width: 70px;
}
.dimensions-labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.dimensions-label {
    margin-right: 0.3rem;
    margin-bottom: 0.1rem;
}
.dt-col-deliverable{
    flex: 1 !important;
    // max-width: 330px !important;
}
.dt-col-upload,
.dt-col-upload2,
.dt-col-dimension{
    flex: 1;
    min-width: 120px;
    flex-basis: 120px;
    max-width: 120px;
}
.dt-col-qty,
.dt-col-qty1,
.dt-col-qty2,
.dt-col-qty3,
.dt-col-locations,
.dt-col-total3,
.dt-col-total2,
.dt-col-total1,
.dt-col-total{
    flex: 1;
    min-width: 85px;
    flex-basis: 85px;
    max-width: 85px;
}
.deliverable-item{
    flex-wrap: nowrap !important;
}
.dt-col-dimension2 .dimensions-label  {
    display: none;
}
.dt-col-upload3{
    display: none;
}
@media screen and (max-width: 600px) {
    .row {
        flex-wrap:wrap !important;
    }
    .col{
        padding:0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .dt-col-dimension{
        display: none !important;
    }
    .dt-col-dimension2{
        margin-left:0px !important;
        margin-right: 230px;
    }
    .dt-col-dimension2 .dimensions-label{
        display: block;
    }
    .dimensions-labels{
        -webkit-box-pack: start;
        display: -webkit-box !important;
        flex-wrap: nowrap;
    }
    .dt-col-upload3 {
        display: block;
        margin-left: 0px !important;
        margin-right: 250px !important;
    }
    .deliverable-item{
        width:200px;
    }
    .dt-col-upload{
        display: none;
    }
    .dt-col-upload2{
        display: none;
    }
    .dt-col-qty2{
        min-width: 80px;
        max-width:80px;
    }
    .dt-col-qty1{
        margin-left:30px;
    }
    .dt-col-total2,
    .dt-col-total1,
    .dt-col-total3{
        min-width: 50px;
        max-width:40px;
    }
    // .order-main-label{
    //     display: none;
    // }
    .order-credits-container{
        margin-left: 10px;
    }
    .order-description-container{
        display: none;
    }
    .order-del-category{
        width:150px;
    }
    .order-del-name{
        width:200px;
    }
    .dt-col-deliverable{
        width:50px;
    }
}
</style>
