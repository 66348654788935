import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"ma-0 mb-1"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"cursor-pointer text-ellipsis notranslate",on:{"click":function($event){return _vm.gotoDetails(_vm.sweep.id)}}},'div',attrs,false),on),[_c('b',[_vm._v(_vm._s(_vm.sweep.title))])])]}}])},[_c('span',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm.sweep.title))])])],1),_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0"},[_vm._v(" Date: "+_vm._s(_vm.sweepDate))])],1),_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0"},[_vm._v(" Time: "+_vm._s(_vm.sweepTime(_vm.sweep.fromTime))+" - "+_vm._s(_vm.sweepTime(_vm.sweep.toTime)))])],1),_c(VRow,{staticClass:"ma-0 mt-1"},_vm._l((_vm.sweep.sweepLocations.slice(0, 1)),function(location){return _c('span',{key:location.id,staticStyle:{"word-break":"break-all","width":"fit-content"}},[_c('div',{staticStyle:{"width":"80%","min-width":"fit-content"}},[_vm._v(" Location: "),_c('a',{staticClass:"mb-0 a-link",staticStyle:{"color":"black"},attrs:{"href":("https://www.google.com/maps/place/" + (location.location)),"target":"_blank"}},[_vm._v(" "+_vm._s(("" + (!location.location && !location.zipcode ? '-' : location.location || '') + (location.zipcode ? (", " + (location.zipcode)) : '')))+" ")])]),(_vm.sweep.sweepLocations.length !== 1)?_c('div',{staticClass:"more-location mt-0 pt-0"},[_vm._v(" +"+_vm._s(_vm.sweep.sweepLocations.length - 1)+" other location(s) ")]):_vm._e()])}),0),_c('div',{staticClass:"mt-3"},[_vm._v(" Team: ")]),(_vm.sweepTeam.length)?_c('div',{staticClass:"sweep-team"},[_c('CreativeTeam',{staticClass:"pt-2 w-100",attrs:{"creativeTeam":_vm.sweepMembers,"openPopupLeft":false,"vertical":false,"openPopupBottom":true,"openDetailsPopup":true}})],1):_c('div',{staticClass:"my-3"},[_vm._v(" - No creatives matched yet ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }