<template>
    <v-container class="brand-logo-files">
      <v-row v-if="brandKitLogosData.length === 0">
        <v-col cols="12" class="dropzone-state">
          <v-overlay :value="loading" z-index=8 >
            <div class="loading-template"> Adding file. Please wait... </div>
          </v-overlay>
          <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          :include-styling="false"
          :useCustomSlot="true"
          @vdropzone-file-added="fileAdding"
          v-on:vdropzone-sending="onDropZoneFileSending"
          >
            <div class="upload-state">
              <div>
                <img src="@/assets/svg/theme/file-upload.svg" />
                <span class="ml-1 add-text h-100">
                  Drop logo files here or <span class="text-purple">browse</span></span>
              </div>
            </div>
          </vue-dropzone>
        </v-col>
      </v-row>
      <div class="brandLogos-dropzone" v-else>
        <v-row class="direction-column">
          <v-col cols="12" class="dropzone-state-small pa-0">
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-row class="brand-kit-files">
                    <div
                      class="uploaded-document"
                      v-for="(document, index) in brandKitLogosData"
                      :key="index"
                    >
                      <BrandKit
                        :brandQuiz="true"
                        :item="document"
                        :file="document.kit"
                        :delete="canUpload ? () => showConfirmModal(document) : null"
                        @onDownload="downloadFiles(document.id)"
                        parentComponent="brandFiles"
                        :addHeight="true"
                      />
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-overlay :value="loading" z-index=8 >
                    <div class="loading-template"> Adding file. Please wait... </div>
                  </v-overlay>
                  <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :include-styling="false"
                  :useCustomSlot="true"
                  @vdropzone-file-added="fileAdding"
                  >
                    <div class="upload-state">
                      <div>
                        <img src="@/assets/svg/theme/file-upload.svg" />
                        <span class="ml-1 add-text h-100">
                          Drop logo files here or <span class="text-purple">browse</span></span>
                      </div>
                    </div>
                  </vue-dropzone>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </div>
      <ConfirmModal
      :showConfirm="showConfirm"
      @onDelete="confirmDelete"
      @cancel="cancelModal"
      />
    </v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapActions } from 'vuex';
import ConfirmModal from '@/components/profile/client/brandQuiz/wizard/common/Modal';
import BrandKit from '@/components/profile/client/brandQuiz/wizard/common/BrandKit';

export default {
  name: 'BrandKitFiles',
  components: {
    vueDropzone: vue2Dropzone,
    BrandKit,
    ConfirmModal,
  },
  props: {
    reference: {
      type: String,
      default: 'business',
    },
    brandProfileId: {
      type: Number,
      default: null,
    },
    brandKitLogosData: {
      type: Array,
      default: () => [],
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const baseURL = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000';
    return {
      dropzoneOptions: {
        url: `${baseURL}/brandings/brand_kit`,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('__bs_valid')).token}`,
          Accept: 'application/json',
        },
        previewTemplate: this.template(),
        maxFiles: 1,
      },
      file: [],
      loading: false,
      showConfirm: false,
      selectedDocument: {},
    };
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions('brandProfile', ['downloadLink', 'brandKitUpload', 'brandKitDelete']),
    template() {
      return `<div class="dz-preview dz-file-preview" style="display: none;">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    getExtension(filename) {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    },
    fileAdding(file) {
      const extensions = ['jpg', 'jpeg', 'png', 'svg', 'mp4', 'mov'];
      const ext = this.getExtension(file.name).toLowerCase();
      if (extensions.includes(ext)) {
        this.uploadDocuments([file]);
      } else {
        this.setNotification({
          message: 'File format not supported.',
          type: 'error',
        }, { root: true });
      }
    },
    onDropZoneFileSending(file, xhr, formData) {
      formData.append('brandProfileId', this.brandProfileId);
      formData.append('reference', this.reference);
    },
    async uploadDocuments(files) {
      if (this.brandProfileId) {
        if (files && files.length) {
          this.loading = true;
          await this.brandKitUpload({
            files,
            brandProfileId: this.brandProfileId,
            reference: this.reference,
          });
          this.loading = false;
        }
      } else {
        this.setNotification({
          message: 'Something went wrong, please contact your administrator.',
          type: 'error',
        }, { root: true });
      }
    },
    showConfirmModal(document) {
      this.selectedDocument = document;
      this.showConfirm = true;
    },
    cancelModal() {
      this.showConfirm = false;
    },
    confirmDelete() {
      this.showConfirm = false;
      if (this.brandProfileId) {
        this.brandKitDelete({ id: this.selectedDocument.id, brandProfileId: this.brandProfileId });
      }
    },
    async downloadFiles(id) {
      const result = await this.downloadLink({ id, projectId: this.projectId });
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 960px) {
  .brandLogos-dropzone {
    padding: 5px 22px;
    overflow-y: auto;
    height: 100%;
  }
}
.dropzone.dz-started .dz-message {
  display: block !important;
}
.dropzone .dz-preview {
  display: none !important;
}
.brand-logo-files {
  color: $neutral1 !important;
  .vue-dropzone {
    background: #F8F8F8;
    border: 3px dashed #DBDBDB;
    border-radius: 10px;
  }
  .dropzone-state-small {
    background: #F8F8F8;
    border: 3px dashed #DBDBDB;
    box-sizing: border-box;
    border-radius: 10px;
    .brand-kit-files {
      border-bottom: 1px solid #D7D7D7;;
      margin: 0px 35px;
      padding-top: 25px;
    }
    .upload-button {
      width: 100%;
      height: 102px;
    }
  }
  .dropzone-state {
    box-shadow: none !important;
    width: 100%;
    height: 148px;
    background: #F8F8F8;
    border: 3px dashed #DBDBDB;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .upload-state {
    padding: 20px 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-text {
      padding-top: 13px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.add-text {
    font-size: 14px;
    letter-spacing: -0.28px;
    color: $charcoalBlack;
    .text-purple {
      text-decoration: underline;
    }
}
.brand-kit {
  overflow-x: auto;
}
.uploaded-document {
  width: min-content;
  margin-right: 16px;
  padding-bottom: 25px;
}
</style>
