<template>
    <v-container
      fluid
      v-if="viewProfileModal"
    >
      <Modal
        :modal="viewProfileModal"
        width="300"
        persistent
      >
        <div class="details-block">
          <v-card
          class="detail-card"
          flat
        >
        <v-img
            class="white--text align-end profile-pic"
            :src="getOptimizedS3ImageURL(profilePic, { width: 500, height: 500 } )"
            :aspect-ratio="1.2"
          />
          <v-btn
            icon
            width="30"
            height="30"
            color="#343838"
            class="close-icon"
            @click="$emit('setViewProfileModal', false)"
          >
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
          <v-card-text class="text--primary pb-2">
            <div class="name text-capitalize pb-1 notranslate font-h3">
              {{ name }}
              <span class="pl-2 pronouns">
                {{ pronoun(selectedMember.userPronouns) }}
              </span>
            </div>
            <div class="text skill">{{ skillset }}</div>
          </v-card-text>
          <v-card-subtitle class="px-4 py-0 number">{{selectedMember.number}}</v-card-subtitle>
          <v-card-subtitle class="px-4 py-0 pb-2 email notranslate">
            {{selectedMember.email}}</v-card-subtitle>
          <div class="px-4 pb-2 profilebtn"
          v-if="parent === 'creativeTeam'
          &&
          (selectedMember.role === 'creative' || selectedMember.role === 'client')
          && isCreativeDeleted">
            <v-btn text @click="$emit('modalOpen',true)" class="pa-0">
              <span class="text text-purple view-profile pa-0">View Portfolio</span>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <v-card-text class="py-2 px-4">
            <div class="text bio-text" v-if="selectedMember.creativeProfile">
              Bio: {{selectedMember.creativeProfile.bio}}</div>
            <div class="text bio-text" v-else>Bio: {{selectedMember.bio}}</div>
          </v-card-text>
        </v-card>
        </div>
      </Modal>
    </v-container>
</template>

<script>
/* eslint-disable global-require */
import _ from 'lodash';
import Modal from '@/components/common/Modal';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  components: {
    Modal,
  },
  props: {
    profilePic: {
      type: String,
      default: '',
    },
    imageBackground: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    skillset: {
      type: String,
      default: '',
    },
    parent: {
      type: String,
      default: '',
    },
    viewProfileModal: {
      type: Boolean,
      default: false,
    },
    selectedMember: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  computed: {
    isProfileExists() {
      if (this.profilePic.indexOf('.svg') >= 0) {
        return false;
      }
      return true;
    },
    pronoun() {
      return (items) => {
        const val = _.map(items, 'name').join('/ ');
        return val;
      };
    },
    isCreativeDeleted() {
      return _.isNil(this.selectedMember.deletedAt);
    },
  },
};
</script>
<style lang="scss" scoped>
  .details-block {
    .detail-card .v-card__subtitle {
      font-size: 14px;
      letter-spacing: -0.11px;
      line-height: normal;
    }
    .name {
      letter-spacing: -0.12px;
      line-height: normal;
    }
    .close-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 23px !important;
      width: 23px !important;
    }
    .pronouns {
      font-family: $fontFamily1;
      font-size: 13px;
      font-weight: normal;
      font-style: italic;
      color: #666;
      padding-top: 3px;
      text-transform: lowercase;
    }
    .text {
      font-size: 14px;
      letter-spacing: -0.11px;
      color: $charcoalBlack;
    }
    .view-profile {
      flex-grow: 0;
      font-family: $fontFamily1;
      font-size: 14px;
      font-weight: normal;
    }
    .bio-text {
      white-space: pre-wrap;
      line-height: normal;
      max-height: 106px;
      overflow: auto;
    }
    .v-btn:not(.v-btn--round).v-size--default {
      height: 24px;
    }
  }
</style>
