<template>
  <div class="creative-team-container">
    <div
      class="creative-team-wrapper"
      :ref="'creative-team'"
      :style="maxHeight" v-if="creativeTeam.length"
      :class="{'d-flex': !vertical}"
    >
      <!-- <v-row class='ma-0'> -->
        <div
          v-for="(member, index) in getSortedCreativeTeam(creativeTeam)"
          class="pa-0 me-2"
          :key="index">
          <div class="creative-member d-flex pa-lg-0 flex-row mr-1"
            :class="{'mb-4': vertical}"
            >
            <v-tooltip
              left
              bottom
              :disabled="!$vuetify.breakpoint.mdAndUp"
            >
              <template v-slot:activator="{ on, attrs }" class="ma-0">
                <div
                v-bind="attrs"
                v-on="on">
                <AvatarRound
                  :size="50"
                  :avatar="profilePic(member, 'profilePic')"
                  :name="memberInitials(member)"
                  @openPopup="showPopup(index)"
                  textStyle="font-size: 16px;"
                />
                <v-badge bordered dot bottom v-if ="member.role === 'creative'"
                :color="availabilityCheck(member)">
                </v-badge>
                </div>
              </template>
              <span class="notranslate"> {{ member.firstName }} {{ member.lastName }}</span>
            </v-tooltip>
            <div
            class="d-flex ml-2 mt-2 pt-0 flex-column card-width" v-if="vertical">
              <div class="text text-ellipsis text-capitalize notranslate">
                {{ member.firstName }} {{ member.lastName }}</div>
              <div class="text skillsets">
                {{member.skill}}
              </div>
            </div>
          </div>
        </div>
      <!-- </v-row> -->
      <view-profile-modal
      :profilePic="profileAvatar(selectedMember)"
      :imageBackground="checkProfile(selectedMember.profilePic)"
      :name="`${selectedMember.firstName || '' } ${ selectedMember.lastName || '' }`"
      :skillset="selectedMember.skill"
      :viewProfileModal="showDetails"
      :selectedMember="selectedMember"
      @setViewProfileModal="showDetails=false"
      @modalOpen="modalOpen=true"
      parent="creativeTeam"
      />
      <v-container
      fluid
      v-if="modalOpen"
      class="modal"
      >
        <Modal
          :modal="modalOpen"
          persistent
          fullscreen
          content-class="preview-modal full-screen"
        >
          <v-card elevation="0">
            <v-card-text class="d-flex justify-end">
              <v-btn
                icon
                width="23"
                height="23"
                color="black"
                class="close-icon"
                @click="modalOpen=false"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text>
              <v-container fluid>
                <Profile :profileId="selectedMember.userId" />
              </v-container>
            </v-card-text>
        </v-card>
        </Modal>
      </v-container>
    </div>
    <div class="no-team" v-else>
      <v-row>
        <v-col cols="12" class="pa-0 px-4">
        <div class="static-text">
          Part of the magic is finding the right creative professionals for your super group.
          Once they’re ready, you’ll see them here.
        </div>
      </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 pr-4 pt-6 d-flex justify-end" cols="12">
          <img src="@/assets/svg/theme/no-creative-team.svg" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import _ from 'lodash';
import vClickOutside from 'v-click-outside';
import Modal from '@/components/common/Modal';
import ViewProfileModal from '@/components/common/ViewProfileModal';
import { getOptimizedS3ImageURL } from '@/helpers/';
import AvatarRound from '@/components/common/Avatar';
import Profile from '../../../profile/creative/ProfileView';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Modal,
    Profile,
    ViewProfileModal,
    AvatarRound,
  },
  props: {
    creativeTeam: {
      type: Array,
      default() {
        return [];
      },
    },
    openPopupLeft: {
      type: Boolean,
      default: true,
    },
    openPopupBottom: {
      type: Boolean,
      default: false,
    },
    wrapperMaxHeight: {
      type: String,
      default: '300px',
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    openDetailsPopup: {
      type: Boolean,
      default: true,
    },
    avatarSize: {
      type: [String, Number],
      default: 50,
    },
  },

  computed: {
    memberInitials() {
      return (member) => {
        const firstName = _.get(member.creatives || member, 'firstName', '').trim();
        const lastName = _.get(member.creatives || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    selectedName() {
      return this.selectedMember.firstName + (this.selectedMember.lastName ? ` ${this.selectedMember.lastName}` : '');
    },
    maxHeight() {
      return `max-height: ${this.wrapperMaxHeight}`;
    },
  },
  data() {
    return {
      tooltip: '',
      showDetails: false,
      selectedMember: {},
      modalOpen: false,
    };
  },
  methods: {
    getOptimizedS3ImageURL,
    getSortedCreativeTeam(team) {
      const ordering = {
        'Production Manager': 1,
        'Content Producer': 2,
        Editor: 3,
      };
      const sortedTeam = team.map((user) => {
        const userWithOrder = { ...user };
        userWithOrder.order = ordering[user.role] || 100;
        return userWithOrder;
      }).sort((a, b) => a.order - b.order);
      return sortedTeam;
    },
    checkProfile(pic) {
      if (pic) {
        return false;
      }
      return true;
    },
    profilePic(member, version) {
      const profilePic = _.get(member, version, null);
      if (profilePic) {
        return profilePic;
      }
      return null;
    },
    profileAvatar(member) {
      return this.profilePic(member, 'profilePic') || require('@/assets/svg/users.svg');
    },
    availabilityCheck(member) {
      const indication = member.availability;
      if (indication === '1') {
        return 'green !important';
      }
      return 'red !important';
    },
    toggleTooltip(member) {
      this.tooltip = this.tooltip.id === member.id ? '' : member;
    },

    detailCardClass(isMobile) {
      const classProps = { };
      if (isMobile) {
        classProps['shift-top'] = true;
        return classProps;
      }
      if (this.openPopupLeft) {
        classProps['shift-left'] = true;
      } else {
        classProps['shift-right'] = true;
      }
      return classProps;
    },
    showBio(member) {
      if (member.producerProfile) {
        return member.producerProfile.bio;
      }
      return _.get(member.creativeProfile, 'bio', '');
    },
    showSkillsets(member) {
      const skillsets = _.get(member, 'creativeProfile.skillsets');
      if (skillsets) {
        return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
      }
      const role = _.get(member.role, 'name');
      return role === 'producer' ? 'Content Producer' : 'Production Manager';
    },
    showPopup(index) {
      this.selectedMember = this.getSortedCreativeTeam(this.creativeTeam)[index];
      if (this.openDetailsPopup) {
        this.showDetails = true;
      }
    },
    // TODO: Might be useful in future
    showPopups(e, index) {
      if (this.openDetailsPopup) {
        this.selectedMember = this.creativeTeam[index];
        const obj = e.target;
        const parentPos = this.$refs['creative-team'].getBoundingClientRect();
        const objSet = obj.getBoundingClientRect();
        if (this.openPopupBottom) {
          this.$refs['details-popup'].style.top = '30px';// `${parentPos.height - Math.abs(parentPos.top - objSet.top) - 28}px`;
        } else {
          this.$refs['details-popup'].style.bottom = `${parentPos.height - Math.abs(parentPos.top - objSet.top - 18)}px`;
        }
        if (this.$vuetify.breakpoint.smAndDown || !this.openPopupLeft) {
          this.$refs['details-popup'].style.left = this.openPopupBottom ? `${objSet.left - parentPos.left + 60}px` : '60px';
        } else {
          this.$refs['details-popup'].style.right = `${parentPos.width + 10}px`;
        }
        this.showDetails = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-width {
    width:100%;
    overflow-x: hidden;
  }
::v-deep .v-badge--dot .v-badge__badge::after {
    border-width: 3px;
}
::v-deep .v-badge--dot .v-badge__badge {
    height: 13px;
    width: 13px;
    inset: calc(100% - 27px) auto auto calc(100% - -40px) !important;
    border-radius: 11px;
}
.static-text{
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.18px;
   line-height: 1.66;
  color: #727272;
}
.creative-team-container {
  font-family: $fontFamily1;
  // position: absolute;
}
  .v-card {
    border-radius: 3px !important;
  }
  .creative-team-wrapper {
    overflow-y: auto;
  }
  .creative-member {
    // position: static;
    .text {
      letter-spacing: -0.16px;
      &.skillsets {
        line-height: normal;
        padding-top: 2px;
      }
    }
    .v-image {
      border-radius: 18px;
      cursor: pointer;
      &.no-image {
        border: 2px solid $card-title;
      }
    }
  }
  .text {
    font-size: 14px;
    letter-spacing: -0.11px;
    color: $neutral1 !important;
  }
  // .shift-top {
  //   position: absolute;
  //   bottom: 100%;
  //   left: 0;
  // }
// @media (min-width: 1264px) {
//   .shift-left {
//     left: - calc(100% - 40px);
//   }
// }
  .creative-team-wrapper {
    padding-bottom: 0px;
  }
  .pronouns {
    font-family: $fontFamily1;
    font-size: 13px;
    font-weight: normal;
    font-style: italic;
    color: #666;
    padding-top: 3px;
    text-transform: lowercase;
  }
  .view-profile {
    flex-grow: 0;
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: normal;
    color: #00b2d5 !important;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 24px;
    width: 80px;
  }
  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: #9d9d9d;
  }
  @media (min-width:960px) and (max-width: 1260px) {
    .padding-creative{
      padding-left: 32px !important;
      padding-right: 10px !important;
    }
  }
</style>
