<template>
<div>
  <v-row
  class="ma-0"
  :class="$route.name === 'Public Profile' ? 'creative-public-profile' :
        $route.name === 'Profile' ? 'creative-profile' : 'creative-team'">
    <PageTitle name="Portfolio" />
  </v-row>
  <v-row class="ma-0" v-if="profileCompleted">
    <v-container fluid
      :class="$route.name === 'Public Profile' ? 'creative-public-profile' :
        $route.name === 'Profile' ? 'creative-profile' : 'creative-team'"
    >
      <v-row
        align="center"
        justify="space-between"
        class="profile-wrapper d-flex align-stretch pa-0 ma-0"
      >
        <v-col
          cols="12"
          sm="8"
          md="9"
          lg="10"
          xl="10">
          <v-row class="ma-0 card-content profile-details-card left-details">
            <div cols="12" class="pa-0 d-flex">
              <div>
                  <ProfilePic
                  ref="profilePic"
                  mode="normal"
                  role="creative"
                  :profilePic="getOptimizedS3ImageURL(
                    profileFieldValue('profilePic'), { width: 200, height: 200 })"
                  :isCreativeProfile="isCreative"
                  :publicRoute="isPublicRoute"
                  />
              </div>
              <span class="userDiv">
                <div class="userName">
                  <div class="d-flex flex-wrap">
                    <span class="name notranslate">
                      {{ profileFieldValue('firstName') }} {{ profileFieldValue('lastName') }}
                    </span>
                    <span class="pronounce d-flex">
                      {{ userPronouns() }}
                    </span>
                  </div>
                  <v-btn
                  text
                  icon
                  color="info"
                  class="ml-5 edit-profile"
                  @click="openForm"
                  v-if="!isPublicRoute">
                    <img src="@/assets/svg/theme/edit.svg" />
                  </v-btn>
                </div>
                <div class="location">
                  <div>{{ skills() }}</div>
                  <div v-if="profile.address !== null">
                    {{ address('city') }}, {{ address('state') }} - {{ address('zip')}}
                  </div>
                </div>
                <div :key="distanceAvailabilityText">
                  <div v-if="distanceAvailabilityText" class="radius location">
                    {{ distanceAvailabilityText }}
                  </div>
                </div>
              </span>
            </div>
          </v-row>
              </v-col>
        <v-col
        cols="12"
          sm="4"
          md="3"
          lg="2"
          xl="2" class="d-flex align-center">
          <v-row d-flex class="right-details">
            <v-btn
              v-if="!isPublicRoute"
              text
              class="share-btn-div"
              color="info"
              v-clipboard:copy="publicLink"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <span class="share-btn">Share page</span>
            </v-btn>
            <v-menu
            v-if="!isPrivateAccount"
            :close-on-content-click="false"
            v-model="show"
            offset-y
            content-class="contact-container">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="contact"
                v-bind="attrs"
                v-on="on">Contact</div>
            </template>
            <v-list>
              <v-list-item>
                <div class="contact-info">
                  {{ profileFieldValue('firstName') }} {{ profileFieldValue('lastName') }}
                </div>
              </v-list-item>
              <v-list-item>
                <div class="contact-info">
                  <a :href="`tel:${profileFieldValue('number')}`">
                    {{ profileFieldValue('number') }}</a>
                </div>
              </v-list-item>
              <v-list-item>
                <div class="contact-info">
                  <a :href="`mailto:${profileFieldValue('email')}`">
                    {{ profileFieldValue('email') }}</a>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
            <v-row d-flex class="social-icons">
              <a
                class="anchorlink"
                v-for="(link, j) in socialLinks"
                :key="`link${j}`"
                :href="url(link.value)"
                target="_blank"
                >
                  <v-list-item-avatar
                  v-if="link.value"
                  :class="mode"
                  :width="link.width"
                  :height="link.height"
                  min-width="20px"
                  >
                  <v-icon color="black lighten-1">
                  {{link.icon}}
                  </v-icon>
                  </v-list-item-avatar>
              </a>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols='12' class="d-flex align-center about-div pt-2">
          <v-row align="center">
              <div class="about">About Me</div>
              <div class="edit-btns" v-if="!isPublicRoute && mode === 'normal'" @click="toggleMode">
                  <v-btn text icon color="info" @click="toggleMode"
                              :disabled="!$v.profile.bio.maxLength">
                      <img src="@/assets/svg/theme/edit.svg" />
                  </v-btn>
              </div>
              <div v-else-if="!isPublicRoute && mode === 'edit'" class="edit-btns">
                  <v-btn
                  text
                  color="primaryGray1"
                  class="px-1"
                  @click="toggleCancel"
                  >
                      <span class="pr-2">Cancel</span>
                  </v-btn>
                  <v-btn
                      text
                      icon
                      color="info"
                      @click="toggleMode"
                      :disabled="!$v.profile.bio.maxLength"
                  >
                      <span class="save-btn">Save</span>
                  </v-btn>
              </div>
          </v-row>
        </v-col>
        <v-col cols='12' class="pa-0">
            <div
            class="bio-placeholder"
            v-if="!isPublicRoute"
            >Write a little bit about yourself here for others to see.</div>
            <BaseTextAreaField
              filled
              rows="3"
              :mode="mode"
              placeholder="About Me"
              name="bio"
              v-model="profile.bio"
              :status="errors('bio').length ? 'error' : 'normal'"
              :error-messages="errors('bio')"
              @input="$v.profile.bio.$touch()"
              @blur="$v.profile.bio.$touch()"
          >
              <div class="d-block bio">{{ profileFieldValue('bio') }}</div>
          </BaseTextAreaField>
        </v-col>
      </v-row>
      <v-row class="mt-5 ma-0">
        <v-col class="px-0">
          <Portfolio :profileId="this.profileId"/>
        </v-col>
      </v-row>
      <v-container
          fluid
          v-if="ShowForm"
        >
          <Modal
            persistent
            content-class="new-user-form"
            :modal="ShowForm"
            width="600"
            scrollable
          >
          <CreativeProfileForm
              @close-modal="closeForm"
              :profileDetails="profile"
          />
          </Modal>
      </v-container>
    </v-container>
  </v-row>
  <v-row class="ma-0" v-else>
    <v-overlay :value="isLoading || loader" v-if="isLoading || loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-col cols="10" offset="1" offset-sm="3" sm="6" v-else>
      <v-img
        class="empty-profile-image"
        width="100%"
        src="@/assets/svg/theme/empty-profile.svg"
        alt="empty profile"
      />
      <div class="text text-center context-text mt-4">Nothing to see here!</div>
      <v-btn class="d-flex complete-profile-button btn-purple" to="/dashboard">
        <span v-if="isPublicRoute"> Go to dashboard</span>
        <span v-else> Complete Creative Profile </span>
      </v-btn>
    </v-col>
  </v-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import { generateUrl, getOptimizedS3ImageURL } from '@/helpers';
import ProfilePic from '@/components/profile/ProfilePic';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import Modal from '@/components/common/Modal';
import CreativeProfileForm from '@/components/profile/creative/CreativeProfileForm';
import Portfolio from '@/components/profile/creative/Portfolio';
import PageTitle from '@/components/common/PageTitle';

const namespace = 'profile';
export default {
  components: {
    ProfilePic,
    BaseTextAreaField,
    Modal,
    CreativeProfileForm,
    Portfolio,
    PageTitle,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    profileId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      show: false,
      mode: 'normal',
      isCreative: true,
      ShowForm: false,
      loader: false,
    };
  },
  computed: {
    ...mapGetters(namespace, { profile: 'creativeProfile' }),
    ...mapGetters('user', ['userDetails']),
    isPublicRoute() {
      if (this.$route.name !== 'Profile') {
        return true;
      }
      return false;
    },
    distanceAvailabilityText() {
      const travelMilesType = _.get(this.profile, 'radius', null);
      if (!travelMilesType) return null;
      return `Available within ${travelMilesType} miles.`;
    },
    publicLink() {
      const userId = _.get(this.profile, 'userId');
      const baseUrl = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000';
      const removeAfter = baseUrl.indexOf('/api');
      const url = baseUrl.substring(0, removeAfter);
      return `${url}/creative/public/${userId}`;
    },
    profileCompleted() {
      return _.get(this.profile, 'onboardingStatus.profile');
    },
    isPrivateAccount() {
      return _.get(this.profile, 'isPrivateAccount');
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        switch (field) {
          case 'bio':
            if (!this.$v.profile.bio.maxLength) { errors.push('About Me length must be less than 340 characters'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    socialLinks() {
      return [
        {
          name: 'website',
          value: _.get(this.profile, 'portfolio'),
          placeholder: 'Website',
          icon: 'mdi-web',
          width: '27px',
          height: '27px',
        },
        {
          name: 'instagramUrl',
          placeholder: 'Instagram',
          icon: 'mdi-instagram',
          value: _.get(this.profile, 'instagramUrl'),
          width: '25.2px',
          height: '25.2px',
        },
        {
          name: 'facebookUrl',
          placeholder: 'Facebook',
          icon: 'mdi-facebook',
          value: _.get(this.profile, 'facebookUrl'),
          width: '11.2px',
          height: '20.8px',
        },
      ];
    },
  },
  async mounted() {
    if (this.isPublicRoute) {
      this.loader = true;
      await this.publicProfile(this.profileId || this.$route.params.id);
      this.loader = false;
    }
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions(namespace, ['creativeProfileUpdate', 'publicProfile', 'getProfile']),
    getOptimizedS3ImageURL,
    skills() {
      const skillset = _.get(this.profile, 'skillsets', []);
      return skillset.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    userPronouns() {
      const pronouns = _.get(this.profile, 'userPronouns', []);
      return pronouns.map((x) => (x.name)).join('/');
    },
    profileFieldValue(type) {
      return _.get(this.profile, type);
    },
    address(type) {
      return _.get(this.profile.address, type);
    },
    url(link) {
      return generateUrl(link);
    },
    async toggleCancel() {
      this.mode = 'normal';
      await this.getProfile();
    },
    async toggleMode() {
      if (this.mode === 'normal') {
        this.mode = 'edit';
      } else {
        const response = await this.creativeProfileUpdate(this.profile);
        if (response.success) {
          this.mode = 'normal';
        }
      }
    },
    openForm() {
      this.ShowForm = true;
    },
    closeForm() {
      this.ShowForm = false;
    },
    onCopy() {
      this.setNotification(
        {
          message: 'Your Profile link has been copied to clipboard.',
          type: 'success',
        },
        { root: true },
      );
    },
    onError(e) {
      this.setNotification(
        {
          message: e.message,
          type: 'error',
        },
        { root: true },
      );
    },
  },
  validations: {
    profile: {
      bio: {
        maxLength: maxLength(340),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.creative-public-profile {
  padding: 40px 180px;
  font-family: $fontFamily1;
}
.creative-team {
  padding: 20px 80px;
  font-family: $fontFamily1;
}
.creative-profile{
  padding: 15px 50px;
  font-family: $fontFamily1;
}
.contact-container {
  border: solid 1px #e0e0e0;
  box-shadow: none;
  width: 257px;
  font-family: $fontFamily1;
  .v-list {
    padding: 10px 20px;
    .v-list-item {
      padding: 5px 0px 5px 0px;
      min-height: 1px;
    }
  }
}
  .contact-info {
    font-size: 14px;
    a {
      color: $charcoalBlack;
    }
  }
  .contact {
    font-size: 16px;
    color: $charcoalBlack;
    cursor: pointer;
    padding-top: 10px;
  }
.context-text {
  font-size: 24px;
  letter-spacing: -0.48px;
}
.empty-profile-image {
  margin-top: 75px;
}
.complete-profile-button {
  margin: auto;
  height: 50px !important;
  width: 300px;
  margin-top: 20px;
  ::v-deep .v-btn__content {
    font-size: 16px;
  }
}
.profile-wrapper,
.about-div {
  font-family: $fontFamily1;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  .bio-placeholder {
    padding-bottom: 5px;
  }
}
.about-div {
  padding-bottom: 10px !important;
}
.userDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  .userName {
    display: flex;
    align-items: center;
    font-family: $fontFamily1;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    .name {
      padding-right: 8px;
      font-size: 24px;
      font-weight: 600;
    }
    .pronounce {
      font-size: 16px;
      font-style: italic;
      color: #727272;
      align-items: flex-end;
      text-transform: lowercase;
    }
  }
  .location {
    padding-top: 12px;
    flex-grow: 0;
    font-size: 16px;
  }
}
.share-btn-div {
  padding: 0px !important;
  width: auto;
  margin-bottom: 10px;
  .share-btn {
    color: $secondary1;
    padding: 0px;
    font-size: 16px;
    font-family: $fontFamily1;
  }
}
.share-btn-div.info--text {
  color: transparent !important;
}
.right-details {
  flex-direction: column;
  align-items: flex-end;
}

.about {
  font-size: 16px;
  font-weight: 600;
}
.bio {
  font-size: 16px;
  white-space: pre-wrap;
  height: 100px;
  overflow: auto;
}
.social-icons {
  margin-top: 20px;
  .v-avatar.v-list-item__avatar {
    margin: 0 7.3px 0 0;
    object-fit: contain;
    background-color: #fff;
    cursor: pointer;
  }
}

.edit-btns {
    margin-left: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .userName {
      font-size: 20px !important;
    }
    .share-btn {
        margin-bottom: 15px;
    }
    .creative-profile {
      padding: 44px 20px 20px!important;
    }
    .creative-public-profile {
      padding: 20px 30px;
    }
    .creative-team {
      padding: 20px 30px;
    }
}
@media only screen and (max-width: 375px) {
    .userName {
      font-size: 16px !important;
    }
    .location {
        padding-top: 6px !important;
        font-size: 14px !important;
    }
    .share-btn, .contact{
        font-size: 14px !important;
    }
    .social-icons {
        margin-top: 12px !important;
    }
    .about-div {
        padding-bottom: 5px !important;
    }
}
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
  .creative-profile {
    padding-top: 40px !important;
  }
}
@media only screen and (max-width: 960px) and (orientation: landscape) {
    .creative-public-profile {
      padding: 10px 30px;
    }
    .creative-team {
      padding: 10px 30px;
    }
}
</style>
