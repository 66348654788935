<template>
  <v-container class="brand-fonts">
    <v-row class="flex-column align-center">
      <v-col class="d-flex align-center pb-0 pt-4" cols="8" offset="2">
        <div class="d-flex justify-space-between align-center">
          <div>
            <label class="text-left font-label">Primary Font (Title)</label>
            <v-select
              @click:clear="removeUserSelectedFont('primary')"
              :clearable="true"
              class="type-selection mb-n3 notranslate"
              :items="fontFiles"
              v-model="brandFont.primary"
              label="Select..."
              item-text="name"
              item-value="id"
              chips
              solo
              flat
              outlined
              dense
              style="width:400px"
              @change="updateFont(null, 1, brandFont.primary)"
              :menu-props="{'content-class' : 'notranslate'}"
            >
            <template slot="selection" slot-scope="data">
                {{ data.item.name }}
            </template>
            <template slot="item" slot-scope="data">
              <template>
                <v-list style="width: 100%;">
                  <v-list-item class="font-title" style="width: 100%;">
                    <div>{{ data.item.name }}</div>
                    <v-icon
                      v-if="data.item.isPrivate === true"
                      class="close-icon"
                      small
                      @click.stop="deleteFont(data.item.id)"
                    >close</v-icon>
                  </v-list-item>
                </v-list>
              </template>
            </template>
          </v-select>
          </div>
          <div class="middle-text">
            <span>OR</span>
          </div>
          <div>
            <Upload
              id="upload-documents"
              class="upload-button text-center"
              v-model="primaryFontFile"
              :multiple="false"
              mode="justImage"
              :acceptedFiles="'.otf, .ttf, .fnt, .ttc,'"
              @change="files => updateFont(files, 1)"
            >
              <template>
                <img src="@/assets/svg/theme/file-upload.svg" width="15"/>
                <span class="ml-1 add-text h-100">Upload a font</span>
              </template>
            </Upload>
          </div>
        </div>
        <div class="ml-3">
          <v-progress-circular
              v-if="fontuploading.primary"
              indeterminate
              color="primary"
            ></v-progress-circular>
        </div>
      </v-col>
      <v-col class="d-flex align-center pb-0 pt-4" cols="8" offset="2">
          <div class="d-flex justify-space-between align-center">
          <div>
            <label class="text-left font-label">Secondary Font (Body)</label>
            <v-select
              @click:clear="removeUserSelectedFont('secondary')"
              :clearable="true"
              class="type-selection mb-n3 notranslate"
              :items="fontFiles"
              label="Select..."
              item-text="name"
              item-value="id"
              chips
              solo
              flat
              outlined
              dense
              style="width:400px"
              v-model="brandFont.secondary"
              @change="updateFont(null, 2, brandFont.secondary)"
              :menu-props="{'content-class' : 'notranslate'}"
            >
            <template slot="selection" slot-scope="data">
                {{ data.item.name }}
            </template>
            <template slot="item" slot-scope="data">
              <template>
                <v-list style="width: 100%;">
                  <v-list-item class="font-title" style="width: 100%;">
                    <div>{{ data.item.name }}</div>
                    <v-icon
                      v-if="data.item.isPrivate === true"
                      class="close-icon"
                      small
                      @click.stop="deleteFont(data.item.id)"
                    >close</v-icon>
                  </v-list-item>
                </v-list>
              </template>
            </template>
          </v-select>
          </div>
          <div class="middle-text">
            <span>OR</span>
          </div>
          <div>
            <Upload
              id="upload-documents-secondary"
              class="upload-button text-center"
              v-model="secondaryFontFile"
              :multiple="false"
              mode="justImage"
              :acceptedFiles="'.otf, .ttf, .fnt, .ttc,'"
              @change="files => updateFont(files, 2)"
            >
              <template>
                <img src="@/assets/svg/theme/file-upload.svg" width="15"/>
                <span class="ml-1 add-text h-100">Upload a font</span>
              </template>
            </Upload>
          </div>
        </div>
        <div class="ml-3">
          <v-progress-circular
              v-if="fontuploading.secondary"
              indeterminate
              color="primary"
            ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <div class="note">.OTF, .TTF, .TTC, and .FNT. are acceptable for upload.</div>
    <ConfirmModal
    :showConfirm="showConfirm"
    @onDelete="confirmDelete"
    @cancel="cancelModal"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ConfirmModal from '@/components/profile/client/brandQuiz/wizard/common/Modal';
import Upload from '@/components/common/Upload';

export default {
  name: 'BrandFonts',
  components: {
    Upload,
    ConfirmModal,
  },
  props: {
    fonts: {
      type: Array,
    },
    brandProfileId: {
      type: Number,
      default: null,
    },
    reference: {
      type: String,
      default: 'business',
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fonts(data) {
      const fontFiles = [
        { header: 'Popular Fonts' },
      ];
      this.fontFiles = fontFiles;
      const fontDataArr = _.groupBy(data, 'isPrivate');
      if (fontDataArr.true) {
        this.fontFiles.unshift({ header: 'Uploaded Fonts' });
        this.fontFiles.splice(1, 0, { divider: true });
        this.fontFiles.splice(1, 0, ...fontDataArr.true);
      }
      if (fontDataArr.false) {
        const { length } = this.fontFiles;
        this.fontFiles.splice(length, 1, ...fontDataArr.false);
      }
      this.brandFont.primary = _.toNumber(this.brandFonts.primary.brandFontId);
      this.brandFont.secondary = _.toNumber(this.brandFonts.secondary.brandFontId);
    },
  },
  data() {
    return {
      selectedFontId: '',
      showConfirm: false,
      fontFiles: [
        { header: 'Popular Fonts' },
      ],
      file: [],
      brandFont: {
        primary: null,
        secondary: null,
      },
      primaryFontFile: null,
      secondaryFontFile: null,
      fontuploading: {
        primary: false,
        secondary: false,
      },
    };
  },
  computed: {
    ...mapGetters('brandProfile', ['brandProfile']),
    brandFonts() {
      return this.brandProfile.brandFonts;
    },
  },
  methods: {
    ...mapActions('brandProfile', ['updateBrandFont', 'deleteBrandFont', 'brandKitUserFontDelete']),
    async removeUserSelectedFont(type) {
      await this.brandKitUserFontDelete({
        fontId: this.brandFonts[type].fontId,
        brandProfileId: this.brandProfileId,
        reference: this.reference,
      });
    },
    deleteFont(id) {
      this.selectedFontId = id;
      this.showConfirm = true;
    },
    cancelModal() {
      this.showConfirm = false;
    },
    async confirmDelete() {
      this.showConfirm = false;
      await this.deleteBrandFont(
        {
          fontId: this.selectedFontId,
          brandProfileId: this.brandProfileId,
          reference: this.reference,
        },
      );
    },
    async updateFont(files, fontType, fontId) {
      const file = Array.isArray(files) && files.length > 0 && files[0];
      const payload = new FormData();
      payload.append('brandProfileId', this.brandProfileId);
      payload.append('reference', this.reference);
      payload.append('fontType', fontType);
      if (file) {
        payload.append('brandKitFonts', file);
      } else if (fontId) {
        payload.append('brandFontId', fontId);
      } else {
        return;
      }
      if (fontType === 1) {
        this.fontuploading.primary = true;
      } else {
        this.fontuploading.secondary = true;
      }
      const response = await this.updateBrandFont(payload);
      if (response) {
        if (fontType === 1) {
          this.brandFont.primary = response.id;
          this.fontuploading.primary = false;
        } else {
          this.brandFont.secondary = response.id;
          this.fontuploading.secondary = false;
        }
      } else {
        this.fontuploading.primary = false;
        this.fontuploading.secondary = false;
      }
    },
  },
  mounted() {
    const fontDataArr = _.groupBy(this.fonts, 'isPrivate');
    if (fontDataArr.true) {
      this.fontFiles.unshift({ header: 'Uploaded Fonts' });
      this.fontFiles.splice(1, 0, { divider: true });
      this.fontFiles.splice(1, 0, ...fontDataArr.true);
    }
    if (fontDataArr.false) {
      const { length } = this.fontFiles;
      this.fontFiles.splice(length, 1, ...fontDataArr.false);
    }
    this.brandFont.primary = _.toNumber(this.brandFonts.primary.brandFontId);
    this.brandFont.secondary = _.toNumber(this.brandFonts.secondary.brandFontId);
  },
};
</script>
<style lang="scss" scoped>
.font-selector-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .font-selector-wrapper{
    flex-direction: column;
  }
}

::v-deep .v-subheader {
  height: 14px;
  // font-family: $fontFamily1;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  padding: 0 16px;
}
::v-deep .v-divider {
  border: dashed;
  margin: 5px 0px 10px;
  border: 1px dashed #C4C4C4 !important;
}
.font-title {
  padding: 0px !important;
  min-height: 29px;
  font-size: 18px;
  line-height: 27px;
  color: $charcoalBlack;
  display: flex;
  justify-content: space-between;
}
.brand-fonts{
  color: $neutral1 !important;
  .note {
    text-align: center;
    padding-top: 20px;
    font-family: $fontFamily1;
    font-style: italic;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #787878;
  }
  .middle-text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
::v-deep .upload-button {
  width: 136px;
  height: 28px !important;
  border-radius: 30px;
  box-shadow: none;
  border: solid 2px $secondary2;
  label {
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-radius: 19.5px;
  }
}
.add-text {
    font-size: 14px;
    letter-spacing: -0.28px;
    color: $charcoalBlack;
}
</style>
