<template>
  <v-container class="reqModal pa-0">
    <v-row class="ma-auto title-header">
      <v-col class="d-flex align-center py-0 pl-0" cols="10">
        <span class="text modal-header-title"> Request Changes </span>
      </v-col>
      <v-col class="d-flex justify-end py-0 pr-0">
        <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-row class="ma-auto">
      <v-col class="py-5">
        <v-form @submit="submitForm">
          <div class="scollable-div">
            <v-row class="ma-auto">
              <v-col cols="12" class="pt-0 pb-2">
                <label class="text-left font-label">
                  Notes*
                </label>
                <BaseTextAreaField
                  filled
                  placeholder="Describe the changes you want to make to your order?"
                  rows="4"
                  class="bio-text mb-n5"
                  solo
                  dense
                  flat
                  v-model.trim="notes"
                  :status="errors('notes').length ? 'error' : 'normal'"
                  :error-messages="errors('notes')"
                  @input="$v.notes.$touch()"
                  @blur="$v.notes.$touch()"
                />
                <i class="notes-desc">
                    Your dedicated Content Producer will receive a notification with your request.
                    We will update your order accordingly.</i>
              </v-col>
            </v-row>
          </div>
          <v-card-actions>
            <v-btn text class="py-0 cancel" color="primaryGray1" @click="$emit('close-modal')">
              Cancel
            </v-btn>
            <v-btn
              class="py-0 submit btn-purple"
              type="submit"
              :loading="loading"
              :disabled="$v.$dirty && $v.$invalid"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required,
} from 'vuelidate/lib/validators';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  name: 'UpdateCredits',
  components: {
    BaseTextAreaField,
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      notes: '',
      loading: false,
    };
  },
  methods: {
    ...mapActions('orders', ['requestChanges']),
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const { notes } = this;
        const request = {
          orderId: this.orderId,
          notes,
        };
        const result = await this.requestChanges(request);
        if (result.success) {
          this.$emit('close-modal');
        }
        this.loading = false;
      }
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'notes':
            if (!this.$v.notes.required) {
              errors.push('Please provide notes');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  validations: {
    notes: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
.reqModal {
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 21px 13px 21px;
    color: #929292;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .scollable-div {
    .base-input {
      margin-top: 6px;
      margin-bottom: 0px !important;
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
    }
    .notes-desc {
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #8D8D8D;
    }
  }
  .v-form {
    .v-card__actions {
      padding: 0;
      justify-content: flex-end;
      padding: 26px 27px 18px;
      .v-btn {
        border-radius: 30px;
        color: $secondary3;
        box-shadow: none;
        height: 42px;
        ::v-deep .v-btn__content {
          font-weight: 400;
          line-height: 24px;
          font-size: 16px;
        }
      }
      .submit {
        ::v-deep .v-btn__content {
          font-weight: 600;
          line-height: 28px;
          font-size: 16px;
        }
        width: 124px;
        margin-left: 12px !important;
      }
      .cancel {
        width: 57px;
      }
    }
    ::v-deep .input-field {
      font-family: $fontFamily1;
      height: inherit;
    }
    ::v-deep .v-select__slot {
      font-family: $fontFamily1;
    }
    ::v-deep .normalState .v-input__slot {
      border: 1px solid #e0e0e0 !important;
    }
    ::v-deep .nullState .v-input__slot {
      border: 1px solid #e0e0e0 !important;
    }
    ::v-deep .v-input {
      .v-input__control {
        .v-select__selection,
        input {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #4f4f4f !important;
        }
      }
    }
    ::v-deep .v-input__slot {
      min-height: 40px;
    }
    ::v-deep .type-selection {
      margin-top: 6px;
      .v-input__slot {
        border: solid 1px #e6e8ed;
      }
    }
    ::v-deep .type-selection.error--text .v-input__slot {
      border: solid 1px #ff0808;
    }
  }
}
</style>
