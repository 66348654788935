<template>
    <v-container class="brand-style">
      <v-row
      class="ma-0 d-flex align-stretch flex-wrap justify-center content-kit-files-grid"
      >
        <div class="pt-0 pb-1 ma-2 brand-style-item-wrapper"
          v-for="(style, i) in styles" :key="i"
        >
        <div class="content-kit-file-container"
        :class="selected.id === style.id && 'active'"
        @click="setSelected(style)"
        >
          <img
          class='multi-check'
          v-if="selected.id === style.id"
          src="@/assets/svg/theme/check-white.svg" />
          <div class="card">
            <div class="card-body">
                <v-hover v-slot:default="{ hover }">
                  <div class="card-file d-flex justify-center align-center"
                    :class="{ 'on-hover': hover}"
                  >
                    <div
                      class="card-image d-flex justify-center align-center"
                    >
                      <img
                        :src="style.url" alt="no-image"
                        style="object-fit:cover"
                      />
                    </div>
                  </div>
                </v-hover>
              </div>
            </div>
            <div
            class="card-name row ma-0">
              <div class="title mb-1"> {{style.name}} </div>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BrandStyle',
  props: {
    styles: Array,
    brandProfileId: Number,
    reference: {
      type: String,
      default: 'business',
    },
  },
  computed: {
    ...mapGetters('brandProfile', ['brandProfile']),
    brandStyle() {
      return this.brandProfile.brandStyle;
    },
  },
  methods: {
    ...mapActions('brandProfile', ['updateBrandStyle']),
    async setSelected(val) {
      if (this.brandStyle.id !== val.id) {
        await this.updateBrandStyle({
          brandStyleId: val.id,
          brandProfileId: this.brandProfileId,
          reference: this.reference,
        });
      }
      if (this.selected.id === val.id) {
        // disabling unselect for now.
        // this.selected = {};
      } else {
        this.selected = val;
        this.$emit('selectstyle', val.id);
      }
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  mounted() {
    this.setSelected(this.brandStyle);
  },
};
</script>
<style lang="scss" scoped>
  .content-kit-file-container {
    padding: 10px;
    background: #F6F6F6;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    position: relative;
    box-sizing: content-box;
    border: 3px solid transparent;
    &.active {
      background: #F7F7F7;
      border: 3px solid #0FB23B;
      border-radius: 5px;
    }
  }
.brand-style {
  flex: 1;
  color: $neutral1 !important;
}
.brand-style-item-wrapper{
  width: 250px;
}

.multi-check {
  border: 1px solid #0FB23B;
  border-radius: 50%;
  background-color: #0FB23B;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 2;
  ::v-deep {
    .v-icon{
      background-color: rgba(0, 0, 0, 0.2);
      width: 14px;
      height: 14px;
      border-radius: 50%;
      font-size: 18px;
      &.mdi-checkbox-blank-circle-outline{
      color: #fff !important;
      }
      &.mdi-checkbox-marked-circle{
        color: $lightGreen !important;
      }
    }
    .v-input--selection-controls__ripple {
      width: 18px;
      height: 18px;
    }
  }
  &.v-input--is-label-active {
    ::v-deep {
      .v-icon{
        background-color: white;
      }
    }
  }
}

  .card {
    border: none !important;
    .card-body {
      background: $neutral5;
      height: 179px;
      position: relative;
      .card-info {
        box-shadow: 0 2px 2px 0 $silver;
        width: 90%;
        overflow-y: auto;
        position: absolute;
        right: 5%;
        top: 20%;
        .v-card__title, .v-card__text {
          font-size: 12px !important;
        }
        .v-card__title {
          line-height: normal;
        }
        .name {
          font-weight: normal;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &.hover {
        opacity: 0.5;
      }
      .card-image {
        height: 179px;
        width: 100%;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          object-fit: fill;
          &.default-image {
            width: 80px !important;
            height: 80px !important;
          }
        }
      }
    }
  }
      .card-name {
      font-size: 16px;
      font-weight: bold;
      padding: 7px 8px 4px 8px;
      .title {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: $charcoalBlack;
        word-break: break-word;
      }
    }
  .card-info {
    overflow: hidden;
    white-space: nowrap;
    .col-6 {
      padding: 0;
    }
  }
  .v-card {
    border-radius: 0 !important;
  }

  ::v-deep .v-input {
    height: 28px;
    margin: 0 !important;
    padding: 0 !important;
    input {
      font-weight: 400;
      padding-bottom: 4px;
      padding-top: 0;
    }
  }
  .card-file {
    opacity: 0.7;
  }
  .card-file:not(.on-hover) {
    opacity: 1;
  }

  @media screen and (min-width: 1340px) {
    .content-kit-files-grid{
      display: grid !important;
      grid-template-columns: repeat(3,270px);
      grid-auto-rows: auto;
      justify-items: center;
      align-items: center;
    }
  }
</style>
