<template>
  <v-card flat>
    <v-card-title class="d-flex modal-close">
        <v-row class="title-header">
      <v-col class="d-flex align-center py-0 pl-0" cols="10">
        <span class="text modal-header-title" v-if="this.invitationStatus == '1'">
          Accept Sweep Invite
        </span>
        <span class="text modal-header-title" v-else>
          Decline Sweep Invite
        </span>
      </v-col>
      <v-col class="d-flex justify-end py-0 pr-0">
        <v-icon color="darken-1" @click="$emit('close-invitation-dialog')">mdi-close</v-icon>
      </v-col>
    </v-row>
    </v-card-title>
    <!-- <v-form class="font-family-2" @submit="$emit('submit-invitation', selectedReasonId)"> -->
      <v-card-text class="pb-1 pt-1 black--text card-text">
        {{inviteStatusTitle}} <b>{{name}}.</b>
      </v-card-text>
      <v-card-text class="pt-3 reason-text text--primary" v-if="askForReason">
        <v-row class="ma-0">
          <v-col class="align-center pa-0" sm=8>
          <div class="decline-text"> Reason for Decline</div>
          <v-select
            :items="sweepInvitationReasons"
            item-text="name"
            item-value="id"
            label="Please select reason"
            outlined
            dense
            width="200px"
            v-model="selectedReasonId"
            :menu-props="{'content-class' : 'select-dropdown'}"
          ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pr-5">
        <v-spacer></v-spacer>
        <v-btn text color="primaryGray1"
          @click="$emit('close-invitation-dialog')"
          class="py-0 pr-7"
        >
          Cancel
        </v-btn>
        <v-btn
         v-if="this.invitationStatus == '1'"
          class="py-3 submit btn-purple"
          type="submit"
          :loading="isLoading"
          @click="$emit('submit-invitation', selectedReasonId)"
        >
          Accept
        </v-btn>
        <v-btn
         v-if="this.invitationStatus != '1'"
          class="py-3 submit btn-red"
          type="submit"
          :loading="isLoading"
          @click="$emit('submit-invitation', selectedReasonId)"
        >
          Decline
        </v-btn>
      </v-card-actions>
    <!-- </v-form> -->
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    invitationStatus: {
      type: String,
      required: true,
    },
    askForReason: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    creative: {
      default: {},
    },
  },
  computed: {
    ...mapGetters(['sweepInvitationReasons']),
    isLoading() {
      return this.loading;
    },
    name() {
      return `${this.creative.firstName} ${this.creative.lastName}`;
    },
    inviteStatusTitle() {
      switch (this.invitationStatus) {
        case '0':
          return 'Maybe next time! Let us know why you can’t make it.';
        case '1':
          return 'Mark sweep as accepted for';
        default:
          return this.askForReason ? 'Mark sweep as declined for'
            : 'Remove creative from declined? The creative will be available to match again if removed.';
      }
    },
  },
  watch: {
    loading(val) {
      return val;
    },
  },
  data() {
    return {
      selectedReasonId: '',
    };
  },
};
</script>

<style scoped lang="scss">
.decline-text {
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #262626;
padding: 6px 0px;
}
.btn-red {
  background: #FF371B;
}
.card-text {
  font-size: 16px;
  padding: 15px !important;
  padding-top: 25px !important;
}
.reason-text {
  padding-left: 15px !important;
}
.title-header {
    border-bottom: 1px solid #D1D1D1;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
    padding: 15px;
    margin: 0px;
  }
.modal-close {
    padding: 0 !important;
    margin: 0 !important;
}
@media (max-width: 960px) {
  .modal-close {
    padding: 5px 10px;
  }
  .card-text {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
