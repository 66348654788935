<template>
  <div class="content-kit-overview-container">
    <v-overlay :value="isLoading" z-index=8 >
      <div class="loading-template"> Please wait while we load the data... </div>
    </v-overlay>

    <template v-if="selectedLength > 0">
      <content-kit-file-actions
        :count="selectedLength"
        @removeSelection="removeSelected()"
        @addToMyPortfolio="showPopup('add to my portfolio')"
      > </content-kit-file-actions>
    </template>

    <div style="position: relative">
      <template v-if="!isLoading">
        <div class="content-kit-files" :class="{'small': $vuetify.breakpoint.smAndBelow}">
          <template v-if="separateByKit">
            <div v-if="Object.keys(contentKitFilesByKit).length">
              <div
                v-for="name in Object.keys(contentKitFilesByKit)"
                :key="`kit-${name}`"
              >
                <v-row class="ma-0 font-family-2">
                  <v-col class="pt-0 text-capitalize">{{name}}<v-divider/> </v-col>
                </v-row>
                <content-kit-files-list
                  :items="contentKitFilesByKit[name]"
                  :previewItems="previewItems"
                  :selectedItems="[...selected.photo, ...selected.video, ...selected.copy]"
                  @selected="checkSelected"
                  :fileType="'photo'"
                  :section="'photo'"
                  :search="search"
                  :dimensions="dimensionIds"
                  :socialTags="socialTagIds"
                />
              </div>
            </div>
            <div v-else>
              <v-row class="ma-0 font-family-2">
                <v-col class="pt-0">
                  Sorry, no results found!
                  Please try searching for something else or applying a different filter.
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-else>
            <v-col cols="12" class="pa-0" v-if="filterType('1')">
              <v-row class="ma-0 font-family-2">
                <v-col cols=12 class="pt-0 kit-padding"> Photo <v-divider /> </v-col>
              </v-row>
              <content-kit-files-list
                :items="contentKitItems('1', false)"
                :previewItems="previewItems"
                :selectedItems="[...selected.photo]"
                @selected="checkSelected"
                :fileType="'photo'"
                :section="'photo'"
                :search="search"
                :dimensions="dimensionIds"
                :socialTags="socialTagIds"
              />
            </v-col>
            <v-col cols="12" class="pa-0" v-if="filterType('2')">
              <v-row class="ma-0 font-family-2">
                <v-col cols=12 class="pt-0 kit-padding"> Video <v-divider /> </v-col>
              </v-row>
              <content-kit-files-list
                :items="contentKitItems('2', false)"
                :previewItems="previewItems"
                :selectedItems="[...selected.video]"
                @selected="checkSelected"
                :fileType="'video'"
                :section="'video'"
                :search="search"
                :dimensions="dimensionIds"
                :socialTags="socialTagIds"
              />
            </v-col>
            <v-col cols="12" class="pa-0" v-if="filterType('3')">
              <v-row class="ma-0 font-family-2">
                <v-col cols=12 class="pt-0 kit-padding"> Copy <v-divider /> </v-col>
              </v-row>
              <content-kit-files-list
                :items="contentKitItems('3', false)"
                :previewItems="previewItems"
                :selectedItems="[...selected.copy]"
                @selected="checkSelected"
                :fileType="'copy'"
                :section="'copy'"
                :search="search"
                :dimensions="dimensionIds"
                :socialTags="socialTagIds"
              />
            </v-col>
          </template>
        </div>
      </template>
    </div>
    <v-container
      fluid
      v-if="modal"
    >
    <Modal
      persistent
      content-class="main-modal"
      :modal="modal"
      width="400"
      >
        <BulkActionModal
          :count="selectedLength"
          :action="action"
          @close-modal="setModal(false)"
          @confirm="(id) => addToPortfolio()"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import EventBus from '@/helpers/event-bus';
import BulkActionModal from '@/components/contentKits/BulkActionModal';
import ContentKitFileActions from '@/components/profile/creative/blendedSense/AssetFileActions';
import ContentKitFilesList from '@/components/profile/creative/blendedSense/AssetsFilesList';
import Modal from '@/components/common/Modal';

export default {
  name: 'ContentKitOverview',
  components: {
    BulkActionModal,
    ContentKitFileActions,
    ContentKitFilesList,
    Modal,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    separateByKit: {
      type: Boolean,
      dafault: false,
    },
  },
  computed: {
    ...mapGetters(['modal', 'contentKitFilters']),
    ...mapGetters('contentKit', ['contentKitFiles']),
    ...mapGetters('contentKit', ['dimensions', 'socialTags']),
    ...mapGetters('user', ['userDetails']),
    selectedLength() {
      return _.uniq([...this.selected.photo, ...this.selected.caption,
        ...this.selected.video, ...this.selected.copy]).length;
    },
    selectedIds() {
      return _.uniq(
        _.concat(this.selected.photo, this.selected.video,
          this.selected.caption, this.selected.copy),
      );
    },
    filterType() {
      return (type = '1') => {
        if (this.noTypeSelected) return true;
        return _.includes(_.get(this.contentKitFilters, 'selectedTypes'), type);
      };
    },
    noTypeSelected() {
      return _.isEmpty(_.get(this.contentKitFilters, 'selectedTypes', []));
    },
    dimensionIds() {
      return _.get(this.contentKitFilters, 'selectedDimensions', []);
    },
    socialTagIds() {
      return _.get(this.contentKitFilters, 'selectedPlatforms', []);
    },
    projectIds() {
      return _.get(this.contentKitFilters, 'selectedProjects', []);
    },

    filteredContentKits() {
      let { contentKitFiles } = this;
      this.$emit('filters-change', contentKitFiles.length);
      const search = _.get(this.contentKitFilters, 'search', '').toLowerCase();
      if (search.length > 2) {
        contentKitFiles = _.filter(contentKitFiles, (c) => {
          const name = c.name.toLowerCase();
          const caption = c.caption || '';
          return _.includes(name, search) || _.includes(caption.toLowerCase(), search);
        });
      }
      if (this.dimensionIds.length > 0) {
        contentKitFiles = _.filter(contentKitFiles, (item) => _.includes(this.dimensionIds,
          item.dimensionId));
      }
      if (this.socialTagIds.length > 0) {
        contentKitFiles = _.filter(contentKitFiles, (item) => _.intersection(this.socialTagIds,
          _.map(item.socialTags, (x) => x.id)).length > 0);
      }
      if (this.projectIds.length > 0) {
        contentKitFiles = _.filter(contentKitFiles, (item) => _.includes(this.projectIds,
          item.contentKit.project.id));
      }
      if (this.contentKitFilters.sortBy === 'recent') {
        contentKitFiles = _.orderBy(contentKitFiles, (file) => [moment(file.releasedDate)], ['desc']);
      }
      return contentKitFiles;
    },
    contentKitFilesByKit() {
      const contentKitFiles = this.previewItems;
      return _.groupBy(contentKitFiles, (file) => file.contentKit.name);
    },
    previewItems() {
      if (this.noTypeSelected) {
        return this.allItems;
      }
      const items = _.map(_.sortBy(_.get(this.contentKitFilters, 'selectedTypes', [])), (type) => [...this.contentKitItems(type, false)]);
      return _.flatten(items);
    },
    allItems() {
      return [...this.contentKitItems('1', false),
        ...this.contentKitItems('2', false),
        ...this.contentKitItems('3', false),
      ];
    },
    filter() {
      return [
        this.search,
        this.dimensionIds,
        this.socialTagIds,
      ];
    },
  },
  data() {
    return {
      selected: {
        photo: [],
        video: [],
        caption: [],
        copy: [],
      },
      search: '',
      action: '',
    };
  },
  methods: {
    ...mapActions('contentKit', ['getAllCreativeAssets', 'addMyPortfolio']),
    ...mapActions(['setModal']),
    contentKitItems(fileType = '1', filterCaptions = true) {
      if (filterCaptions) {
        return _.filter(this.filteredContentKits, { fileType, captionNeeded: false });
      }
      return _.filter(this.filteredContentKits, { fileType });
    },
    removeSelected() {
      this.selected = {
        photo: [],
        video: [],
        caption: [],
        copy: [],
      };
    },
    checkSelected(selected, fileType) {
      this.selected[fileType] = selected;
    },
    showPopup(action) {
      this.action = action;
      this.setModal(true);
    },
    async addToPortfolio() {
      const payload = {
        blendedsense: true, contentKitFileIds: this.selectedIds, userId: this.userDetails.id,
      };
      const res = await this.addMyPortfolio(payload);
      if (res.success) {
        this.removeSelected();
        this.setModal(false);
        this.$emit('modal', false);
      }
    },
  },

  async mounted() {
    EventBus.$on('filters-updated', () => {
      this.removeSelected();
    });
    this.getAllCreativeAssets();
  },

  beforeDestroy() {
    EventBus.$off('filters-updated');
  },
};
</script>

<style lang="scss" scoped>
  .content-kit-overview-container {
    max-height: calc(100vh - 200px);
  }
  .content-kit-files {
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 150px);
  }


  ::v-deep {
    .v-slide-group__prev.v-slide-group__prev--disabled {
      display: none !important;
    }
    .v-text-field input {
      padding: 3px 0;
    }
    .v-input__control {
      height: 32px;
    }
    .v-select__selection--comma {
      font-family: $fontFamily1;
      font-size: 14px !important;
      letter-spacing: -0.28px;
      margin: 3px 4px 3px 0;
    }
    .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
      min-height: 32px;
    }
    .v-select__selections input {
      font-family: $fontFamily1;

      width: 0;
    }
  }

  .search-input {
    width: 200px;
    ::v-deep .v-input {
      height: 32px;
    }
    ::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) >
      .v-input__control > .v-input__slot {
      min-height: 32px !important;
      border-radius: 6px !important;
      box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
    }
  }
  .loading-template {
    border: solid 1px;
    border-color: #babfc7;
    background: #ececec;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    color: black;
    padding: 6px;
  }
  @media (min-width: 601px) and (max-width: 1264px) {
    .content-kit-files {
      max-height: calc(100vh - 190px)
    }
  }
  @media (max-width: 600px) {
    .content-kit-files {
      max-height: calc(100vh - 220px);
    }
    .kit-padding {
      padding-left: 4px;
    }
  }
</style>
