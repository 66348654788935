<template>
  <div>
    <div
      class="music-item-container"
      :style="`${selected && 'border: 3px solid #0FB23B'};`"
      @click="$emit('select',id)">
      <div class="music-item">
        <div class="music-cover">
          <div
            v-if="selected"
            class="selected-check-wrapper">
            <svg
              width="100%" height="100%"
              viewBox="0 0 30 30" fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="15" fill="#0FB23B"/>
              <!-- eslint-disable-next-line max-len -->
              <path d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM12.5 21.25L6.25 15L8.0125 13.2375L12.5 17.7125L21.9875 8.225L23.75 10L12.5 21.25Z" fill="white"/>
            </svg>
          </div>
          <div class="music-cover-fill" :style="`background:${coverColor}`"></div>
          <div class="music-control" @click.stop="toggleAudio">
            <div
            class="music-control-btn-wrapper"
            :class="{'pause' : isPlaying}"
            >
              <img
                class="music-control-btn play"
                src="@/assets/svg/theme/brandquiz/media-play.svg"
                alt="play button"
                v-if="!isPlaying"
              >
              <img
                class="music-control-btn pause"
                src="@/assets/svg/theme/brandquiz/pause_button.svg"
                alt="pause button"
                v-if="isPlaying"
              >
            </div>
          </div>
        </div>
        <div class="music-info">
          <div class="genre">{{title}}</div>
          <div class="duration" :key="timeElapsed">
            <span
              v-if="timeElapsed !=='0:00'"
            >{{this.timeElapsed}}&nbsp;/&nbsp;</span>
            <span :key="duration">{{duration}}</span>
          </div>
        </div>
      </div>
    </div>
    <audio :src="audio" hidden ref="audio" @timeupdate="onTimeUpdate"></audio>
  </div>
</template>

<script>

export default {
  name: 'music-action-item',
  props: {
    id: {
      type: Number,
    },
    coverColor: {
      type: String,
    },
    audio: {
      type: String,
    },
    title: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
    activeMusicId: {
      type: Number,
    },
  },
  data() {
    return ({
      currentTime: '0:00',
      isPlaying: false,
      duration: '0:00',
      timeElapsed: '0:00',
    });
  },
  watch: {
    activeMusicId(newId) {
      if (newId !== this.id) {
        this.pauseAudio();
      }
    },
  },
  methods: {
    secondsToHms(seconds) {
      if (seconds === '') return '';
      if (Number.isNaN(seconds)) return '';
      const d = Number(seconds);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const formattedDuration = `${h > 0 ? h : ''}${h > 0 ? ':' : ''}`
        + `${m}:`
        // eslint-disable-next-line prefer-template
        + `${s > 9 ? s : '0' + s}`;
      return formattedDuration;
    },
    playAudio() {
      this.isPlaying = true;
      this.audioElement.play();
      this.$emit('play', this.id);
    },
    pauseAudio() {
      this.isPlaying = false;
      this.audioElement.pause();
    },
    toggleAudio() {
      if (!this.isPlaying) return this.playAudio();
      return this.pauseAudio();
    },
    onTimeUpdate() {
      this.currentTime = this.secondsToHms(this.audioElement.currentTime);
    },
  },
  mounted() {
    this.audioElement = this.$refs.audio;
    this.audioElement.addEventListener('loadedmetadata', () => {
      this.duration = this.secondsToHms(this.audioElement.duration);
    });
    this.audioElement.addEventListener('ended', () => {
      this.pauseAudio();
      this.timeElapsed = '0:00';
    });
    this.audioElement.addEventListener('timeupdate', () => {
      this.timeElapsed = this.secondsToHms(this.audioElement.currentTime);
    });
  },
};
</script>
<style lang="scss">
  .music-item-container{
    background: #F6F6F6;
    min-width: 250px;
    max-width: 300px;
    display: flex;
    padding: 1rem;
    cursor: pointer;
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 3px solid #E5E5E5;
  }
  .music-item-container.selected{
    border: 3px solid black;
  }
  .music-item{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .music-cover{
    display: flex;
    flex: 1;
    position: relative;
    min-height: 50px;
    .music-cover-fill{
      background: tomato;
      flex: 1;
      border-radius: 0.5rem;
    }
    .selected-check-wrapper{
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: -0.4rem;
      left: -0.4rem;
    }
    .music-control{
      position: absolute;
      top: 50%;
      right: 10%;
      background: white;
      width: 3rem;
      height: 3rem;
      border: 1px solid #C9C9C9;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .music-control-btn-wrapper{
        width: 16px;
        height: 16px;
        .music-control-btn{
          width: 100%;
          height: 100%;
        }
      }
      .music-control-btn-wrapper.pause{
        width: 23px;
        height: 23px;
      }
    }
  }
  .music-info{
    flex: 1;
    margin-top: 1rem;
    .genre{
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
    }
  }
</style>
