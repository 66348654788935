import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"long-text"},[_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}],domProps:{"innerHTML":_vm._s(_vm.truncatedText)}}),(_vm.isTextExceededLength())?_c('span',{staticClass:"more-text ml-1",on:{"click":function($event){_vm.showPopup=true}}},[_vm._v(" "+_vm._s(_vm.moreText)+" ")]):_vm._e(),(_vm.showPopup)?_c(VContainer,{staticClass:"pa-0"},[_c('Modal',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"modal":_vm.showPopup,"width":"600","persistent":""}},[_c(VCard,{staticClass:"pa-1"},[_c(VCardTitle,{staticClass:"d-flex"},[_c(VRow,{staticClass:"my-0"},[_c(VCol,{staticClass:"d-flex align-center py-0"},[_c(VCardTitle,{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.popupTitle)+" ")])],1),_c(VCol,{staticClass:"d-flex justify-end pa-0"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){_vm.showPopup = false}}},[_vm._v("mdi-close")])],1)],1)],1),_c(VDivider),_c(VCardText,{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"pt-3 text--primary"},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }