<template>
    <div class="brand-style-wrapper d-flex">
        <div class="brand-style">
            <div class="bs-image-cover">
                <img
                  :src="imageUrl"
                  alt="brand style" style="object-fit: cover;">
            </div>
            <div class="bs-image-label">
                {{name}}
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'brand-style',
  props: {
    imageUrl: String,
    name: String,
  },
};

</script>
<style lang="scss" scoped>
.brand-style{
    flex: 1;
    background: #F6F6F6;
    padding: 1rem 1rem 0rem 1rem;
    border-radius: 0.2rem;
    max-width: 350px;
    .bs-image-cover{
        width: 100%;
        display: flex;
        img{
            flex: 1;
            max-height: 200px;
        }
    }
}
.bs-image-label{
    font-weight: bold;
    font-size: 1.1rem;
    padding: 1rem 0rem;
}
</style>
