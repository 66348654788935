<template>
    <div class="placeholder-container">
        <!-- eslint-disable-next-line max-len -->
        <div class="placeholder-container-inner d-flex align-center justify-space-between flex-wrap">
            <div class="placeholder-info-section">
                <!-- eslint-disable-next-line max-len -->
                <div class="placeholder-title font-body" v-if="canEdit">{{placeholderText}}</div>
            </div>
            <div class="placeholder-action-section" v-if="canEdit">
                <v-btn
                    class="btn-purple px-10"
                    @click="$emit('start')"
                >START</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'brand-profile-section-placeholder',
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-container-inner{
    background: #F5F5F5;
    padding: 1rem;
}
</style>
