<template>
    <div class="bg-white h-100"
      :style="{
        'padding-left': customer ? '60px' : '30px',
      }"
      :class="customer ? 'main-container orders-list' : 'main-container pl-0 pr-0 pt-10'">
      <div class="h-100">
         <Loader v-if="loading" style="height:60vh;" />
        <v-row class="orders ma-0"
        :class="{'flex-column' : $vuetify.breakpoint.mdAndDown}"
        v-else>
          <v-col cols="12" class="orders-heading" v-if="customer">
            <v-container class="pb-0 px-0"> <PageTitle /> </v-container>
          </v-col>
          <v-col
          :class="$vuetify.breakpoint.xsOnly && 'pa-0'"
          xs="12" sm="12" md="12" lg="8"
          :style="`padding-right: ${$vuetify.breakpoint.mdAndUp && '60px'}`">
              <v-container class="pa-0 pb-10">
                  <v-col class="active-orders pa-0">
                      <v-row class="active-orders-header ma-0">
                          <div class="heading1">Active Orders</div>
                          <div
                          v-if="orderPermissions.create"
                          class="heading2 text-purple"
                          @click="openCreateOrderModal">+ New Order</div>
                      </v-row>
                      <v-row class="active-orders-body ma-0 flex-column">
                        <div v-if="orders.length" style="width: 100%">
                          <template v-if="customer">
                            <ClientOrder
                              v-for="order in orders"
                              :key="order.key"
                              :order="order"
                              @editOrder="() => openEditModel(order.id)"
                              @showPreview="onShowPreview"
                              />
                          </template>
                          <template v-else>
                            <Order
                              v-for="order in orders"
                              :key="order.key"
                              :order="order"
                              @editOrder="() => openEditModel(order.id)"
                              @deleteOrder="() => onDeleteOrder(order.id)"
                              @completeOrder="() => onComplete(order.id)"
                              @openUploadAssetsForm="onOpenUploadAssetsForm"
                              @removeFile="onRemoveFile"
                              :orderPermissions="orderPermissions"
                              @showPreview="onShowPreview"
                              :business="business"
                              @showSweeps="$emit('showSweeps')"
                            />
                          </template>
                        </div>
                        <div v-else>
                          <div class="empty-text my-5">
                            <div class="icon">
                              <v-img
                                style="width: 50px; height: 47px;"
                                src="@/assets/svg/no_active_orders.svg"
                                aspect-ratio="1"
                              ></v-img>
                            </div>
                            <div class="text">No active orders at the moment.</div>
                          </div>
                        </div>
                      </v-row>
                  </v-col>
              </v-container>
              <v-container
              v-if="$vuetify.breakpoint.lgAndUp && completedOrders && completedOrders.length"
              class="pa-0">
                <v-col class="order-history pa-0">
                  <OrderHistory
                  @goToOrder="(orderId) => $emit('goToOrder', orderId)" />
                </v-col>
              </v-container>
              <div v-if="showOrderModal">
                <OrderForm
                  :open="showOrderModal"
                  @close="closeCreateOrderModal"
                  :orderId="orderToEdit.id"
                />
              </div>
              <CompleteOrderConfirmationModal ref="completeOrderConfirmationModal"/>
              <RemoveAssetConfirmationModel ref="removeAssetConfirmationModel"/>
              <CancelOrderConfirmationModel ref="cancelOrderConfirmationModel"/>
              <UploadAssetModal
                v-if="showUploadAssetModal"
                :open="showUploadAssetModal"
                :deliverable="currentUploadAssetsInfo.deliverable"
                :orderId="currentUploadAssetsInfo.orderId"
                @close="onCloseUploadAssetsForm"
                :enableSharingWithBusinesses="enableAssetSharing"
              />
              <UploadedItemPreviewWrapper
                @close="showPreview = false"
                :open="showPreview"
                v-if="showPreview"
                :items="previewItems"
                :previewIndex="previewIndex"
              />
          </v-col>
           <v-col
           :class="$vuetify.breakpoint.xsOnly && 'pa-0'"
           xs="12" sm="12" md="12" lg="4">
                <v-container class="credits-container pa-0 pb-2">
                <CreditBank
                  v-if="customer"
                  :cardDetails="cardDetails"
                  @reload-cards="reloadCardsList"
                ></CreditBank>
                <Credits v-else/>
                </v-container>
                 <!-- <v-container class="content-container pa-0">
                <ContentPlanManagement />
                </v-container> -->
                <v-container
                  v-if="$vuetify.breakpoint.mdAndDown && completedOrders && completedOrders.length"
                  class="history-container pa-0 pt-10">
                  <v-col class="order-history pa-0">
                    <OrderHistory
                    @goToOrder="(orderId) => $emit('goToOrder', orderId)" />
                  </v-col>
                </v-container>
            </v-col>
        </v-row>
      </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Credits from '@/views/CreditsManagement';
import OrderForm from '@/components/orders/OrderForm';
import CompleteOrderConfirmationModal from '@/components/orders/CompleteOrderConfirmationModal';
import Order from '@/components/orders/Order';
import ClientOrder from '@/components/orders/ClientOrder';
import OrderHistory from '@/components/orders/OrderHistory';
// import ContentPlanManagement from '@/views/ContentPlanManagement';
import CreditBank from '@/components/credits/CreditBank';
import UploadAssetModal from '@/components/orders/UploadAssetModal';
import RemoveAssetConfirmationModel from '@/components/orders/RemoveAssetConfirmationModel';
import CancelOrderConfirmationModel from '@/components/orders/CancelOrderConfirmationModel';
import UploadedItemPreviewWrapper from '@/components/orders/UploadedItemPreviewWrapper';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';

export default {
  name: 'Orders',
  components: {
    Credits,
    OrderForm,
    Order,
    OrderHistory,
    CreditBank,
    // ContentPlanManagement,
    CompleteOrderConfirmationModal,
    ClientOrder,
    UploadAssetModal,
    RemoveAssetConfirmationModel,
    CancelOrderConfirmationModel,
    UploadedItemPreviewWrapper,
    Loader,
    PageTitle,
  },
  data() {
    return {
      cardDetails: [],
      showNotification: false,
      snackbar: true,
      timeout: 6000,
      showOrderModal: false,
      orderToEdit: {},
      loading: true,
      showCompleteOrderModal: true,
      showUploadAssetModal: false,
      currentUploadAssetsInfo: {},
      showPreview: false,
      previewItems: [],
      previewIndex: null,
    };
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('orders', ['orders', 'completedOrders']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    // amIAssignedToProject() {
    //   const team = _.get(this.currentProject, 'business.businessTeam', []);
    //   const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
    //   return assigned;
    // },
    canAccess() {
      return ['admin', 'executive', 'producer'].includes(this.role);
    },
    customer() {
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(this.role);
    },
    businessId() {
      let { businessId } = this.$route.query;
      if (this.customer) {
        businessId = _.get(this.userDetails, 'currentBusinessId');
      }
      if (this.$route.name === 'Organization') {
        businessId = this.$route.params.organizationId;
      }
      return businessId;
    },
    orderPermissions() {
      const amIAssigned = this.business.assigned;
      const permissions = {
        create: amIAssigned && ['producer', 'executive'].includes(this.role),
        modify: amIAssigned && ['producer', 'executive'].includes(this.role),
        uploadAssets: amIAssigned && ['producer', 'executive', 'editor'].includes(this.role),
        removeAssets: amIAssigned && ['producer', 'executive', 'editor'].includes(this.role),
        scheduleSweep: amIAssigned && ['producer', 'executive'].includes(this.role),
      };
      // admin and pm can do anything.
      if (['admin', 'executive'].includes(this.role)) {
        permissions.create = true;
        permissions.modify = true;
        permissions.uploadAssets = true;
        permissions.removeAssets = true;
        permissions.scheduleSweep = true;
      }
      if (['client', 'agency_owner', 'agency_member', 'member'].includes(this.role)) {
        permissions.create = true;
      }
      return permissions;
    },
    enableAssetSharing() {
      // only orgs can have this feature
      return this.business.type === '2';
    },
  },
  methods: {
    ...mapActions('orders', [
      'createOrder', 'getOrders', 'deleteOrder',
      'completeOrder', 'removeAsset', 'getCompletedOrders',
    ]),
    ...mapActions('user', ['paymentMethods']),
    ...mapActions('business', ['getBusiness', 'getCreditsHistory']),
    async openCreateOrderModal() {
      this.orderToEdit = await this.createOrder({ businessId: this.business.id });
      // have to set the order id
      if (this.canAccess) {
        this.showOrderModal = true;
      }
      if (this.customer) {
        this.$router.push(`/order_create/${this.orderToEdit.businessId}/${this.orderToEdit.id}`);
        // this.$router.replace({
        //   query: {
        //     orderId: this.orderToEdit.id,
        //     businessId: this.business.id,
        //   },
        // });
      }
    },
    openEditModel(orderId) {
      // find order from orders
      this.orderToEdit = this.orders.find((order) => order.id === orderId);
      if (this.customer) {
        this.$router.push(`/order_create/${this.orderToEdit.businessId}/${this.orderToEdit.id}`);
        // this.$router.push({
        //   name: 'Order Details',
        //   params: {
        //     businessId: this.orderToEdit.businessId,
        //     orderId: this.orderToEdit.id,
        //   },
        // });
      }
      if (this.canAccess) {
        this.showOrderModal = true;
      }
    },
    closeCreateOrderModal() {
      this.orderToEdit = {};
      this.showOrderModal = false;
    },
    async onComplete(orderId) {
      const confirmModal = this.$refs.completeOrderConfirmationModal;
      const { confirm, sendNotification } = await confirmModal.open();
      if (!confirm) return;
      this.completeOrder({ orderId, sendNotification });
    },
    async onRemoveFile(payload) {
      const confirmModal = this.$refs.removeAssetConfirmationModel;
      const { confirm } = await confirmModal.open();
      if (!confirm) return;
      const response = await this.removeAsset(payload);
      if (!response) return;
      if (payload.callback) {
        payload.callback();
      }
    },
    async onDeleteOrder(orderId) {
      const confirmModal = this.$refs.cancelOrderConfirmationModel;
      const { confirm } = await confirmModal.open();
      if (!confirm) return;
      await this.deleteOrder({ orderId });
      await this.getBusiness(this.businessId);
      await this.getCreditsHistory({ businessId: this.businessId });
    },
    onOpenUploadAssetsForm({ orderId, deliverable }) {
      this.currentUploadAssetsInfo = { orderId, deliverable };
      this.showUploadAssetModal = true;
    },
    onCloseUploadAssetsForm() {
      this.currentUploadAssetsInfo = {};
      this.showUploadAssetModal = false;
    },
    onShowPreview(index, items) {
      this.previewItems = items;
      this.previewIndex = index;
      this.showPreview = true;
    },
  },
  async mounted() {
    this.loading = true;
    // dispatch SET_ORDERS action to clear the orders in the store
    await this.getBusiness(this.businessId);
    this.$store.commit('orders/SET_ORDERS', []);
    this.$store.commit('orders/SET_COMPLETED_ORDERS', []);
    await this.getOrders({ businessId: this.businessId });
    await this.getCompletedOrders(this.businessId);
    const result = await this.paymentMethods(this.businessId);
    this.cardDetails = result.paymentMethods;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .orders-list {
    padding-left: 40px !important;
    .orders-heading {
      padding-left: 0px !important;
    }
  }
}
.orders {
  .active-orders-header {
    border-bottom: 1px solid #D8D8D8;
    align-items: center;
    justify-content: space-between;
    .heading1 {
       font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #929292;
    }
    .heading2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .empty-text {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #A1A1A1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
      margin-top: 17px;
    }
  }
}
</style>
