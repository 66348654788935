<template>
  <v-container fluid class="pa-0">
    <v-row class="basic-details">
        <v-col cols="12" class="pa-0 d-flex justify-center">
          <div :class="isVisibility">
            <div
            v-if="publicRoute"
            :class="(!profilePic && 'avatar-container ')+isCreative">
              <v-avatar tile
                class="ma-0 d-flex"
                :class="isCreative"
              >
                <v-img
                  :class="isCreative"
                  :src="profilePic ?
                    getOptimizedS3ImageURL(profilePic, { width: 200, height: 200 })
                    : defaultImage"
                ></v-img>
              </v-avatar>
            </div>
            <Upload
            v-else
            acceptedFiles="image/x-png,image/jpeg"
            v-model="file"
            mode="justImage"
            @change="loadImage">
                <template v-slot:default="{filePreview}">
                  <template v-if="role === 'creative'">
                  <v-badge
                    bordered dot overlap bottom
                    :color="availabilityCheck"
                  >
                    <div class="d-flex ma-auto profile-image-container"
                      :class="isVisibility"
                    >
                        <v-img
                          :class="isCreative"
                          lazy-src="@/assets/svg/theme/profile.svg"
                          :src="
                            getOptimizedS3ImageURL(image(filePreview), {
                              width: 200,
                              height: 200
                            })
                          "
                          alt="Profile"
                        />
                          <div class="profile-image-loading" v-if="imageLoading">
                              <v-progress-circular indeterminate color="grey"/>
                          </div>
                          <div class="profile-image-overlay">
                              <img src="@/assets/svg/camera_event.svg" />
                              <span>Update</span>
                          </div>
                      </div>
                    </v-badge>
                  </template>
                  <div v-else class="d-flex ma-auto profile-image-container"
                     :class="borderVisiblity ? 'border-none' : ''"
                     ><v-img
                        class="profile-image"
                        lazy-src="@/assets/svg/theme/profile.svg"
                        :src="
                          getOptimizedS3ImageURL(image(filePreview), {
                            width: 200,
                            height: 200
                          })
                        "
                        alt="Profile"
                      />
                        <div class="profile-image-loading" v-if="imageLoading">
                            <v-progress-circular indeterminate color="grey"/>
                        </div>
                        <div class="profile-image-overlay">
                            <img src="@/assets/svg/camera_event.svg" />
                            <span>Update</span>
                        </div>
                    </div>
                </template>
            </Upload>
          </div>
        </v-col>
        <v-col cols="12" class="pa-0 px-2" v-if="role !== 'creative'">
            <v-card-title class="justify-center pa-0" :class="{'pt-2': mode === 'edit'}">
              <BaseInput
                outlined
                dense
                class="ml-1 mr-1 profile-name text-ellipsis"
                :mode="mode"
                label="First Name"
                v-model.trim="profile.firstName"
                :status="errors('firstName').length ? 'error': 'normal'"
                :error-messages="errors('firstName')"
                @input="$v.profile.firstName.$touch()"
                @blur="$v.profile.firstName.$touch()"
              >
                <span class="profile-name notranslate">
                  {{ profile.firstName }}
                </span>
              </BaseInput>
              <BaseInput
                outlined
                dense
                class="ml-1 mr-1 profile-name text-ellipsis"
                :mode="mode"
                label="Last Name"
                v-model.trim="profile.lastName"
                :status="errors('lastName').length ? 'error': 'normal'"
                :error-messages="errors('lastName')"
                @input="$v.profile.lastName.$touch()"
                @blur="$v.profile.lastName.$touch()"
              >
                <span class="profile-name notranslate">
                  {{ profile.lastName }}
                </span>
              </BaseInput>
            </v-card-title>
            <!-- <v-card-title
              v-if="role === 'client'"
              class="justify-center pa-0"
            >
              <BaseInput
                filled
                class="ml-1 mr-1 profile-name"
                :mode="mode"
                placeholder="Business Name"
                v-model.trim="profile.businessName"
                :status="errors('businessName').length ? 'error': 'normal'"
                :error-messages="errors('businessName')"
                @input="$v.profile.businessName.$touch()"
                @blur="$v.profile.businessName.$touch()"
              >
                {{ profile.businessName }}
              </BaseInput>
            </v-card-title> -->
        </v-col>
    </v-row>
    <ProfileCropper
      :filename="filename"
      :img="img"
      :isModal="isModal"
      @onImageLoading="imgLoader"
      @modalCropper="isCropModal"
    />
    <template>
      <v-snackbar
        v-model="profileSize"
        color='#ff0808'
        multi-line
        top
        right
        :timeout="timeout"
      >
      {{message}}
        <v-icon
          dark
          right
          @click="profileSize = false"
        >
          mdi-close-circle
        </v-icon>
      </v-snackbar>
    </template>
  </v-container>
</template>
<script>
/* eslint-disable global-require */
import _ from 'lodash';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import Upload from '@/components/common/Upload';
import BaseInput from '@/components/common/BaseInput';
import ProfileCropper from '@/components/profile/common/ProfileCropper';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'ProfilePic',
  components: {
    Upload,
    BaseInput,
    ProfileCropper,
  },
  props: {
    profilePic: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'normal',
    },
    role: {
      type: String,
      default: 'client',
    },
    isCreativeProfile: {
      type: Boolean,
      default: false,
    },
    publicRoute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['userProfile']),
    isCreative() {
      if (this.isCreativeProfile) {
        return 'borderRadius profile-image';
      }
      return 'profile-image';
    },
    isVisibility() {
      if (this.borderVisiblity && this.isCreativeProfile) {
        return 'border-none profile-div';
      }
      if (this.isCreativeProfile) {
        return 'profile-div';
      }
      if (this.borderVisiblity) {
        return 'border-none';
      }
      return '';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.profile.firstName.required) { errors.push('Please provide your first name'); }
            if (!this.$v.profile.firstName.maxLength) { errors.push('First name must be less than 50 characters length'); }
            break;
          case 'lastName':
            if (!this.$v.profile.lastName.required) { errors.push('Please provide your last name'); }
            if (!this.$v.profile.lastName.maxLength) { errors.push('Last name must be less than 50 characters length'); }
            break;
          // case 'businessName':
          //   if (!this.$v.profile.businessName.required) {
            // errors.push('Please provide your business name');
            // }
          //   break;
          default:
            break;
        }
        return errors;
      };
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    profile() {
      return _.get(this.userProfile, this.customer ? 'client' : this.role);
    },
    availabilityCheck() {
      const indication = this.userDetails.availability;
      if (indication === '1') {
        return 'green !important';
      }
      return 'red !important';
    },
    defaultImage() {
      return require('@/assets/svg/users.svg');
    },
    image() {
      return (filePreview) => {
        const profilePic = _.get(this.userDetails, 'profilePic');
        if (filePreview) {
          return filePreview;
        }
        if (profilePic && profilePic !== 'null') {
          this.setBorderVisibility();
          return profilePic;
        }
        return this.defaultImage;
      };
    },
  },
  data() {
    return ({
      file: '',
      imageLoading: false,
      borderVisiblity: false,
      filename: '',
      isModal: false,
      img: '',
      timeout: 6000,
      message: '',
      profileSize: false,
    });
  },
  methods: {
    getOptimizedS3ImageURL,
    loadImage(file) {
      this.file = file;
      const { name } = file;
      const parts = name.split('.');
      const ext = parts[parts.length - 1].toLowerCase();
      if (ext === 'png' || ext === 'jpeg' || ext === 'jpg') {
        if (file.size > process.env.VUE_APP_PROFILE_SIZE) {
          this.profileSize = true;
          this.message = `That’s a very nice image, but it’s a bit too big.
      Try images less than 10mb file size.`;
        } else {
          this.message = '';
          this.profileSize = false;
          this.filename = name;
          this.isModal = true;
          if (file) {
            if (this.img) {
              URL.revokeObjectURL(this.img);
            }
            const blob = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.onload = () => {
              this.img = blob;
            };
            reader.readAsArrayBuffer(file);
          }
        }
      } else {
        this.profileSize = true;
        this.message = 'File format not supported. Please upload image/png, image/jpeg';
      }
    },
    imgLoader(bool) {
      this.imageLoading = bool;
    },
    isCropModal(val) {
      this.isModal = val;
    },
    setBorderVisibility() {
      this.borderVisiblity = true;
    },
  },
  validations() {
    // const { role } = this;
    return ({
      profile: {
        firstName: {
          required,
          maxLength: maxLength(50),
        },
        lastName: {
          required,
          maxLength: maxLength(50),
        },
        // businessName: {
        //   required: role === 'client' ? required : false,
        // },
      },
    });
  },
};
</script>
<style lang="scss" scoped>
    ::v-deep .v-badge--dot .v-badge__badge {
     height: 14px;
     width: 14px;
     inset: calc(100% - 11px) auto auto calc(100% - 10px) !important;
     border-radius: 11px;
     }
     ::v-deep .v-badge--dot .v-badge__badge::after {
     border-width: 3px;
     }
     .v-card__title {
      font-size: 18px;
      letter-spacing: -0.36px;
      color: $neutral1;
      .base-input {
        width: 100%;
      }
    }
    ::v-deep .v-responsive__sizer {
      padding-bottom: 0px !important;
    }
    // ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
    //   padding: 0 12px !important;
    // }
    .profile-image-container {
        width: 96px;
        height: 96px;
        border: 2px solid #565682;
        border-radius: 24px;
        cursor: pointer;
        position: relative;
    }
    .profile-image {
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
    .profile-image-loading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .profile-image-overlay {
        position: absolute;
        height: 55px;
        bottom: 0;
        background: $neutral5;
        display: none;
        width: 100%;
        border-radius: 0 0 22px 22px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .profile-image-container:hover .profile-image-overlay {
        display: flex;
    }
    .profile-name {
      font-weight: normal;
      padding-top: 6px !important;
    }
    .border-none {
       border: none;
       .profile-image-overlay {
         border-radius: 0 0 20px 20px;
       }
    }
    .borderRadius {
      border-radius: 50%;
      width: 120px !important;
      height: 120px !important;
    }
    .border-none {
      .avatar-container {
        border: none !important;
      }
    }
    .avatar-container {
        border: 2px solid #565682;
    }
    .profile-div {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      .profile-image-overlay {
        border-radius: 0 0 56px 56px;
      }
    }
  ::v-deep .input-field {
    height:70px !important;
  }
  @media only screen and (max-width: 600px) {
    .borderRadius {
      width: 60px !important;
      height: 60px !important;
    }
    .profile-div {
      width: 60px;
      height: 60px;
    }
    .profile-div {
      .profile-image-overlay {
        border-radius: 56px;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .borderRadius {
      width: 50px !important;
      height: 50px !important;
    }
    .profile-div {
      width: 50px;
      height: 50px;
    }
  }
</style>
