import { render, staticRenderFns } from "./GenaralInfo.vue?vue&type=template&id=0478d496&scoped=true"
import script from "./GenaralInfo.vue?vue&type=script&lang=js"
export * from "./GenaralInfo.vue?vue&type=script&lang=js"
import style0 from "./GenaralInfo.vue?vue&type=style&index=0&id=0478d496&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0478d496",
  null
  
)

export default component.exports