<template>
  <v-container fluid class="content-wrapper pa-0 sweeps-list bg-white h-100">
    <Loader v-if="fetching && !clientstyle" style="height:70vh;" />
    <template v-else>
      <template class="add-sweep-wrapper">
        <v-col
          :style="{
            'padding-top': clients ? '50px' : '30px',
          }"
          :class="clients ? 'main-container mobile-sweep pl-2' : 'pl-0'"
          v-if="alignment === 'justify-left' || clients"
        >
        <v-row class="ma-0 pl-7" v-if="clients">
          <v-col class="pl-7"> <PageTitle /> </v-col>
        </v-row>
            <!-- && !enableCloseOut -->
        <!-- || enableCloseOut -->
          <!-- <div class="sweep-title">Sweeps</div> -->
        <v-row
          :class="
            clients
              ? 'ma-0 pl-7 justify-space-between sweeps-lists'
                : 'ma-0 justify-space-between sweeps-lists'"
        >
            <SweepList
              :sweepId="sweepId"
              :lastUpdated="lastUpdated"
              :style="[clientstyle ? {'width': '90%'} : {'width': '60%'}]"
            >
            </SweepList>
          <v-btn
            class="add-sweep-btn btn-purple mt-5"
            v-if="isEditableSweep"
            @click="addSweepModal = true"
          >
            + Add Sweep
          </v-btn>
          <v-col class="pl-12" v-if="clients">
            <PlaceOrder>
              </PlaceOrder>
            </v-col>
          <space v-if="enableCloseOut"></space></v-row>
        </v-col>
        <v-row class="ma-0" :class="alignment">
          <!-- <v-col cols="2" xs="0" sm="1" md="2" v-if="enableBack"> </v-col> -->
          <!-- <v-col cols="12" class="px-3 mobile-sweep" xs="12" sm="10" md="8"> -->
          <v-col class="px-3 mobile-sweep main-container">
            <v-row
              class="pl-10 pr-10 justify-space-between"
              v-if="alignment === 'justify-center' && !clients"
            >
            <!-- && !enableCloseOut -->
              <div class="pb-10"> <PageTitle /> </div>
            <v-btn
              v-if="!enableBack"
              class="add-sweep-btn2 mr-7 mt-4 btn-purple"
              @click="addSweepModal = true"
            >
              + Add Sweep
            </v-btn>
            </v-row>
            <template v-if="creative">
              <v-row class="pl-5 creatives-div">
                <SweepList
                  :sweepId="sweepId"
                  :lastUpdated="lastUpdated"
                  class="ml-5 pr-12 card-padding"
                >
                </SweepList>
                <v-col>
            <div class="invite-title">
            <span class="upcoming-heading">Invitations</span>
            </div>
            <span v-if="invitationPending.length">
              <span v-for="item in invitationPending" :key="item">
              <CreativeInvitations
                :item="item"
                @list-update="listupdate"
              />
            </span>
            </span>
              <v-row class="ma-0 no-history" v-else>
            <v-col class="d-flex flex-column justify-center align-center pt-12">
              <v-img
                width="2rem"
                src="@/assets/svg/no_active_orders.svg"
                aspect-ratio="1"
              ></v-img>
              <div style="height: 100px;">No Invitation Sweeps</div>
            </v-col>
          </v-row>
            </v-col>
          </v-row>
            </template>
            <template class="sweepclose-container"
            v-if="(this.$route.path === '/sweeps')">
                <SweepCloseOut
                  v-if="!enableBack && this.closeout.length > 0"
                  @reload="fetchSweep"
                  :item="this.closeout"
                />
                 <SweepCalendar
                  v-if="!enableBack"
                  @reload="fetchSweep"
                  :item="this.closeout"
                  :lastUpdated="lastUpdated"
                />
            </template>
            <!-- <v-btn v-if="enableBack" text :to="backRoute" class="sweeps-back-btn pa-0">
              <v-icon>mdi-chevron-left </v-icon>
              Back to {{ projectId ? 'Creative Brief' : 'Dashboard' }}
            </v-btn> -->
          </v-col>
        </v-row>
      </template>
    </template>
        <v-container
          fluid
          v-if="addSweepModal"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="addSweepModal"
            width="726px"
            height="1148px"
          >
          <SweepForm
            id="sweepModal"
            :orderId="orderId"
            :deliverableId="deliverableId"
            @close-modal="closeOutModal"
            :showBusiness="!projectId"
            @reload-sweeps="fetchSweep"
          />
          <!-- @cancel="cancelEdit" -->
            <!-- @reload-sweeps="fetchSweeps" -->
            <!-- :sweepObj="currentSweep"
            :fallBackProjectId="currentProject.id" -->
          </Modal>
        </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import SweepForm from '@/components/projects/sweep/SweepForm';
import SweepList from '@/components/projects/sweep/SweepList';
import SweepCloseOut from '@/components/projects/sweep/SweepCloseOut';
import SweepCalendar from '@/components/projects/sweep/SweepCalendar';
import CreativeInvitations from '@/components/sweeps/CreativeInvitations';
import PlaceOrder from '@/components/orders/PlaceOrder';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';
import Modal from '@/components/common/Modal';

export default {
  components: {
    SweepForm,
    SweepList,
    SweepCloseOut,
    SweepCalendar,
    PlaceOrder,
    Loader,
    PageTitle,
    Modal,
    CreativeInvitations,
  },
  props: {
    selectedBusinessId: {
      type: String,
      default: '',
    },
    alignment: {
      type: String,
      default: 'justify-center',
    },
    // sweepsType: {
    //   default: 0,
    // },
    sweepId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('project', ['currentProject']),
    ...mapGetters('sweep', ['sweeps']),
    ...mapGetters('user', ['userDetails']),
    // ...mapGetters('dashboard', ['project']),
    ...mapGetters('business', ['business']),
    // canNotUpdate() {
    //   // eslint-disable-next-line
    //   return _.includes(
    //     ['admin', 'editor', 'client', 'member', 'agency_owner', 'agency_member'],
    //     this.role,
    //   );
    // },
    // isEditable() {
    //   const team = _.get(this.currentProject, 'business.businessTeam', []);
    //   const assigned = !!team.find((x) =>
    //  `${x.userId}` === `${this.userDetails.id}`);
    //   return (!this.enableBack) || (!this.canNotUpdate &&
    //  assigned && this.currentProject.status !== '2');
    // },
    isEditableSweep() {
      if (['editor'].includes(this.role)) {
        return false;
      }
      if (['admin', 'executive'].includes(this.role)) {
        return true;
      }
      return this.business.assigned;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    clients() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    clientstyle() {
      return ['editor'].includes(this.role);
    },
    editor() {
      return ['editor'].includes(this.role);
    },
    enableCloseOut() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    enableBack() {
      return !['admin', 'executive', 'producer'].includes(this.role);
    },
    creative() {
      return this.role === 'creative';
    },
    currentSweepTeam() {
      return _.get(this.currentSweep, 'sweepTeam', []);
    },

    projectId() {
      return this.$route.params.projectId;
    },
    // backRoute() {
    //   return this.projectId
    //     ? { name: 'Project', params: { projectId: this.projectId } }
    //     : '/dashboard';
    // },
  },

  data() {
    return {
      fetching: false,
      showOrderModal: false,
      orderToEdit: {},
      // workspace: {},
      lastUpdated: Date.now(),
      // showForm: false,
      orderId: '',
      deliverableId: '',
      addSweepModal: false,
      currentSweep: {},
      closeout: {},
      invitationPending: [],
    };
  },
  watch: {
    $route() {
      if (this.$route.query.new) {
        this.addSweepModal = true;
        this.orderId = this.$route.query.orderId;
        this.deliverableId = this.$route.query.deliverableId;
      }
    },
    // sweepsType(type) {
    //   this.showForm = type === 1 && true;
    // },
    async sweepId(id) {
      this.currentSweep = {};
      if (id) {
        this.currentSweep = await this.getSweep(id);
      }
    },
  },
  methods: {
    // ...mapActions('project', ['removeSweep', 'getProject']),
    ...mapActions('orders', ['createOrder']),
    ...mapActions('sweep', ['getSweep', 'resetState', 'sweepCloseOut', 'CreativeSweepsList']),
    ...mapActions('profile', ['getCreativeOptions']),
    async openCreateOrderModal() {
      this.orderToEdit = await this.createOrder({ businessId: this.userDetails.currentBusinessId });
      // // have to set the order id
      // this.showOrderModal = true;
      this.$router.push({
        name: 'Order Details',
        params: {
          businessId: this.orderToEdit.businessId,
          orderId: this.orderToEdit.id,
        },
      });
    },
    // ...mapActions('business', ['getBusiness', 'loadBusinessWorkspaces']),
    // async editSweep(id) {
    //   this.currentSweep = await this.getSweep(id);
    //   this.addSweepModal = true;
    // },
    // cancelEdit() {
    //   if (this.showForm) {
    //     this.showForm = false;
    //     this.currentSweep = {};
    //   } else {
    //     this.$router.push({
    // name: 'Project', params: { projectId: this.$route.params.projectId } });
    //   }
    //   // this.fetchSweeps();
    // },
    // async fetchSweeps() {
    //   if (this.sweepId) {
    //     this.currentSweep = await this.getSweep(this.sweepId);
    //   }
    //   const projectId = this.workspace ? this.workspace.id : '';
    //   if (this.creative) {
    //     this.getSweeps({ projectId: this.$route.params.projectId, status: 'pending' });
    //   }
    //   _.map(['upcoming', 'elapsed'], (status) => {
    //     if (this.role === 'client' || this.role === 'member') {
    //       this.getSweeps({ projectId: this.project.id || projectId, status });
    //     } else {
    //       this.getSweeps({
    //         projectId: this.$route.params.projectId || projectId,
    //         status,
    //       });
    //     }
    //   });
    // },
    closeOutModal() {
      this.orderId = '';
      this.deliverableId = '';
      this.addSweepModal = false;
      this.lastUpdated = Date.now();
    },
    async listupdate() {
      const data = await this.CreativeSweepsList();
      this.invitationPending = _.get(data.sweeps, 'invitationPending', []);
      this.lastUpdated = Date.now();
    },
    async fetchSweep() {
      if (!this.clients) {
        const { sweeps } = await this.sweepCloseOut();
        this.closeout = sweeps;
      }
      this.loading = false;
    },
  },
  async mounted() {
    // this.showForm = this.sweepsType === 1 && true;
    if (this.sweepId) {
      this.currentSweep = await this.getSweep(this.sweepId);
    }
    this.fetching = true;
    const data = await this.CreativeSweepsList();
    this.invitationPending = _.get(data.sweeps, 'invitationPending', []);
    if (['admin', 'executive', 'producer'].includes(this.role)) {
      const { sweeps } = await this.sweepCloseOut();
      this.closeout = sweeps;
    }
    // if (this.selectedBusinessId && ['admin', 'executive', 'producer'].includes(this.role)) {
    //   // load all workspaces for client
    //   const { projects } = await this.loadBusinessWorkspaces({
    //     businessId: this.selectedBusinessId,
    //   });
    //   const workspaceData = await _.filter(projects, (project) => !project.sharedWithBusinessId);
    //   this.workspace = workspaceData.length > 0 ? workspaceData[0] : {};
    //   if (this.workspace && this.workspace.id) {
    //     await this.getProject(this.workspace.id);
    //   }
    // }
    if (this.$route.query.new) {
      this.addSweepModal = true;
      this.orderId = this.$route.query.orderId;
      this.deliverableId = this.$route.query.deliverableId;
    }
    //  else {
    //   await this.fetchSweeps();
    // }
    if (!this.creative) {
      await this.getCreativeOptions();
    }
    this.fetching = false;
  },

  destroyed() {
    this.resetState();
  },
};
</script>

<style lang="scss">
.sweepclose-container {
  margin-left: 20px;
  .label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #929292;
    border-bottom: 1px solid #d8d8d8;
  }
}
.creatives-div {
  .invite-title {
    border-bottom: 1px solid #D8D8D8;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    color: $charcoalBlack;
  }
  .no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2rem;
    color: $charcoalBlack;
    display: flex;
    align-items: center;
  }
}
.sweep-container {
  ::v-deep.v-dialog {
    overflow-x: hidden !important;
  }
}
.card-padding {
  width: 60% !important;
}
.add-sweep-btn {
  width: 316px;
  .v-btn__content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
}
.add-sweep-btn2 {
  width: 155px;
  .v-btn__content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
}
.sweeps-back-btn {
  color: $neutral7 !important;
  font-weight: bold;
  &::before {
    opacity: 0 !important;
  }
  .v-btn__content {
    font-size: 14px;
  }
}
.v-list-item__title {
  font-size: 14px !important;
}
@media (max-width: 600px) {
  .mobile-sweep {
    padding-left: 0px;
    padding-top: 30px;
  }
  .card-padding {
    padding-right: 8px !important;
    margin-left: 14px !important;
    padding-top: 10px !important;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .sweeps-lists {
    width: 100% !important;
    // flex-wrap: nowrap !important;
    // justify-content: center !important;
  }
  // .add-sweep-btn {
  //   width: 40% !important;
  // }
  .card-padding {
    width: 110% !important;
  }
}
</style>
