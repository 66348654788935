<template>
    <v-container class="brain-storm">
      <div class="brainstorm-dropzone">
        <v-row class="direction-column">
          <v-col cols="12" class="dropzone-state" v-if="brandInspirations.length === 0">
          <v-overlay :value="loading" z-index=8 >
            <div class="loading-template"> Adding file. Please wait... </div>
          </v-overlay>
            <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :include-styling="false"
            :useCustomSlot="true"
            @vdropzone-file-added="fileAdding"
            v-on:vdropzone-sending="onDropZoneFileSending"
            >
              <div class="upload-state">
                <div>
                  <img src="@/assets/svg/theme/file-upload.svg" />
                  <span class="ml-1 add-text h-100">
                    Drop logo files here or <span class="text-purple">browse</span></span>
                </div>
              </div>
            </vue-dropzone>
          </v-col>
          <v-col cols="12" class="dropzone-state-small pa-0" v-else>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-row class="brand-kit-files">
                  <div
                    class="uploaded-document"
                    v-for="(document, index) in brandInspirations"
                    :key="index"
                  >
                    <BrandKit
                      :brandQuiz="true"
                      :item="document"
                      :file="document.fileUrl"
                      :delete="canUpload ? () => showConfirmModal(document) : null"
                      @onDownload="downloadFiles(document.id)"
                    />
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-overlay :value="loading" z-index=8 >
                  <div class="loading-template"> Adding file. Please wait... </div>
                </v-overlay>
                <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :include-styling="false"
                :useCustomSlot="true"
                @vdropzone-file-added="fileAdding"
                v-on:vdropzone-sending="onDropZoneFileSending"
                >
                  <div class="upload-state">
                    <div>
                      <img src="@/assets/svg/theme/file-upload.svg" />
                      <span class="ml-1 add-text h-100">
                        Drop logo files here or <span class="text-purple">browse</span></span>
                    </div>
                  </div>
                </vue-dropzone>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="flex-column pt-10">
          <label class="link-title font-h5">Add links</label>
          <v-row class="flex-column link-text">
            <v-col
            v-for="(link, index) in brandLinks"
            :key="index"
            class="link px-0"
            >
            <span>{{link.link}}</span>
            <v-icon color="darken-1" class="close-icon" @click="deleteLink(link.id)"
            >mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="justify-between">
          <v-col cols="11" class="pa-0 pr-8">
            <BaseInput
            type="url"
            class="text-field-link"
              outlined
              :rows="1"
              placeholder=
              "Competitive brands that you admire..."
              name="link"
              v-model.trim="link"
              :status="errors('link').length ? 'error': 'normal'"
              :error-messages="errors('link')"
              @input="$v.link.$touch()"
              @blur="$v.link.$touch()"

            >
            </BaseInput>
          </v-col>
          <v-col cols="1" class="pa-1 add-button">
            <v-btn class="add-btn btn-purple" @click="addLink">Add</v-btn>
          </v-col>
        </v-row>
      </div>
      <ConfirmModal
      :showConfirm="showConfirm"
      @onDelete="confirmDelete"
      @cancel="cancelModal"
      />
    </v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapActions } from 'vuex';
import ConfirmModal from '@/components/profile/client/brandQuiz/wizard/common/Modal';
import BrandKit from '@/components/profile/client/brandQuiz/wizard/common/BrandKit';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';

export default {
  name: 'BrainStorm',
  validations: {
    link: {
      url,
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.link.$dirty) return errors;
        switch (field) {
          case 'link':
            if (!this.$v.link.url) { errors.push('Please provide valid url'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    BrandKit,
    ConfirmModal,
    BaseInput,
  },
  props: {
    brandLinks: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: Number,
      default: null,
    },
    businessId: {
      type: Number,
      default: null,
    },
    brandInspirations: {
      type: Array,
      default: () => [],
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
    brandProfileId: {
      type: Number,
      default: null,
    },
    reference: {
      type: String,
      default: 'business',
    },
  },
  data() {
    const baseURL = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000';
    return {
      dropzoneOptions: {
        url: `${baseURL}/brandings/brand_inspiration`,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('__bs_valid')).token}`,
          Accept: 'application/json',
        },
        previewTemplate: this.template(),
        maxFiles: 1,
      },
      link: '',
      file: [],
      loading: false,
      showConfirm: false,
      selectedDocument: {},
    };
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions('brandProfile', ['brandInspirationUpload', 'brandInspirationDelete', 'downloadInspirationLink', 'brainStormAddLink', 'brainStormLinkDelete']),
    template() {
      return `<div class="dz-preview dz-file-preview" style="display: none;">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    getExtension(filename) {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    },
    fileAdding(file) {
      const extensions = ['jpg', 'jpeg', 'png', 'svg', 'mov', 'mp4'];
      const ext = this.getExtension(file.name).toLowerCase();
      if (extensions.includes(ext)) {
        this.uploadDocuments([file]);
      } else {
        this.setNotification({
          message: 'File format not supported.',
          type: 'error',
        }, { root: true });
      }
    },
    async addLink() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.link) {
          const link = {
            link: this.link,
            projectId: this.projectId,
            businessId: this.businessId,
            brandProfileId: this.brandProfileId,
            reference: this.reference,
          };
          await this.brainStormAddLink(link);
          this.link = '';
        }
      }
    },
    async deleteLink(id) {
      if (this.brandProfileId) {
        this.brainStormLinkDelete(
          { id, brandProfileId: this.brandProfileId },
        );
      }
    },
    onDropZoneFileSending(file, xhr, formData) {
      formData.append('projectId', this.projectId);
      formData.append('businessId', this.businessId);
    },
    async uploadDocuments(files) {
      if (this.brandProfileId) {
        if (files && files.length) {
          this.loading = true;
          await this.brandInspirationUpload(
            { files, brandProfileId: this.brandProfileId, reference: this.reference },
          );
          this.loading = false;
        }
      } else {
        this.setNotification({
          message: 'Something went wrong, please contact your administrator.',
          type: 'error',
        }, { root: true });
      }
    },
    showConfirmModal(document) {
      this.selectedDocument = document;
      this.showConfirm = true;
    },
    cancelModal() {
      this.showConfirm = false;
    },
    confirmDelete() {
      this.showConfirm = false;
      if (this.brandProfileId) {
        this.brandInspirationDelete(
          { id: this.selectedDocument.id, brandProfileId: this.brandProfileId },
        );
      }
    },
    async downloadFiles(id) {
      const result = await this.downloadInspirationLink({ id, projectId: this.projectId });
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: auto;
}
.link-text {
  width: 100%;
}
@media (min-width: 960px) {
  .brainstorm-dropzone {
    padding: 5px 10px;
    overflow-y: auto;
    height: 100%;
  }
}
.dropzone.dz-started .dz-message {
  display: block !important;
}
.dropzone .dz-preview {
  display: none !important;
}
.brain-storm {
  color: $neutral1 !important;
  .vue-dropzone {
    background: #F8F8F8;
    border: 3px dashed #DBDBDB;
    border-radius: 10px;
  }

  .link-title {
    padding-bottom: 6px;
    margin-bottom: 14px;
    border-bottom: 1px solid #D7D7D7;
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0px;
    padding-bottom: 16px;
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #727272;
    .close-icon {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
  .dropzone-state-small {
    background: #F8F8F8;
    border: 3px dashed #DBDBDB;
    box-sizing: border-box;
    border-radius: 10px;
    .brand-kit-files {
      border-bottom: 1px solid #D7D7D7;;
      margin: 0px 35px;
      padding-top: 25px;
    }
    .upload-button {
      width: 100%;
      height: 102px;
    }
  }
  .dropzone-state {
    box-shadow: none !important;
    width: 100%;
    height: 148px;
    background: #F8F8F8;
    border: 3px dashed #DBDBDB;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .upload-state {
    padding: 20px 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-text {
      padding-top: 13px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .add-btn {
    padding: 7px 12px !important;
    font-family: $fontFamily1;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
  .add-button{
    margin-left: -4rem;
  }
}
.add-text {
    font-size: 14px;
    letter-spacing: -0.28px;
    color: $charcoalBlack;
    .text-purple {
      text-decoration: underline;
    }
}
.brand-kit {
  overflow-x: auto;
}
.uploaded-document {
  margin-right: 16px;
  padding-bottom: 25px;
}
.text-field-link {
  ::v-deep .v-text-field .v-input__control .v-input__slot {
    min-height: 41px !important;
    width: 90% !important;
  }
  ::v-deep .v-text-field .v-input__append-inner {
    margin-top: 10px !important;
  }
}

</style>
