<template>
    <v-container class="sweepclose-container">
    <div>
    <div class="label">Sweeps to Close</div>
    <div class="sweepclose-table mt-5">
      <v-row class="table-headers pl-5">
        <v-col class="table-header">Sweep / Customer</v-col>
        <v-col class="table-header sorting">Time Since Sweep
          <!-- <v-icon @click="sortHighest" style="height: 20px;"> mdi-menu-up </v-icon> -->
          <v-icon @click="sortLowest"
          class="ml-2"
          style="height: 0px;width: 0px;color: #B4B4B4;"> mdi-menu-down </v-icon>
        </v-col>
        <v-col class="table-header">Team</v-col>
        <v-col></v-col>
      </v-row>
      <div style="overflow-y: scroll;height: 80%;">
      <v-row v-for="item in item" :key="item.id" class="table-data pt-4">
        <v-col class="pt-0 ml-0 pl-0 text-purple title cursor-pointer title-style"
        @click="goToSweepDetails(item.id, item.businessId)"
        >{{item.title}}
            <v-row class="text-black pl-3 business-name">
            {{item.business.name}}
            </v-row>
        </v-col>
        <v-col class="pa-5 pl-2 pt-0 mt-3 title time-col">
          {{getMinutes(item.date, item.fromTime)}}</v-col>
        <!-- <span v-if="item.sweepTeam.length > 0"> -->
        <span class="profile-pic">
        <v-col class="pl-0 pt-0 pb-0 mr-5 d-flex no-wrap profile-col" style="width: 230px;">
        <div v-if="item.pointOfContact.profilePic
        || item.pointOfContact.firstName" class="pointofcontact">
            <AvatarRound
                :size="52"
                :avatar="item.pointOfContact.profilePic"
                :name="memberInitials(item.pointOfContact)"
                textStyle="font-size: 16px;"
            />
        </div>
        <div v-else class="ml-3"></div>
        <div v-if="creativeSweepTeam(item.sweepTeam).length > 0
        && item.pointOfContact.firstName" class="divider ml-5 mr-8 mb-2 mt-2"></div>
        <span v-for="(items) in creativeSweepTeam(item.sweepTeam).slice(0, 4)" :key="items.id">
            <AvatarRound
                class="creative-icon"
                :size="52"
                :avatar="items.creatives.profilePic"
                :name="memberInitials(items.creatives)"
                textStyle="font-size: 25px;"
            />
        </span>
        <span v-if="creativeSweepTeam(item.sweepTeam).length > 4" class="plus-icon">
              <AvatarRound
                :size="52"
                :name="`+${creativeSweepTeam(item.sweepTeam).length - 4}`"
                textStyle="font-size: 25px;"
              />
            </span>
        </v-col>
        </span>
        <v-col class="ma-0 pt-0 d-flex justify-end pr-0">
          <button class="button cursor-pointer mt-2"
          @click="goToSweepClose(item)">Close Out</button>
        </v-col>
      </v-row>
      </div>
    </div>
    </div>
    <v-container
          fluid
          v-if="closeOutModal"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="closeOutModal"
            width="480px"
            height="899px"
          >
          <CloseOutForm
            id="sweepModal"
            @closemodal="removeCloseOut"
            :sweep="this.selectedSweep"
          />
          </Modal>
        </v-container>
    </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Modal from '@/components/common/Modal';
import CloseOutForm from '@/components/sweeps/CloseOutForm';
import AvatarRound from '@/components/common/Avatar';

export default {
  name: 'SweepCloseOut',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AvatarRound,
    Modal,
    CloseOutForm,
  },
  data() {
    return {
      closeOutModal: false,
      selectedSweep: [],
    };
  },
  computed: {
    memberInitials() {
      return (member) => {
        const firstName = _.get(member || member, 'firstName', '').trim();
        const lastName = _.get(member || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
  },
  methods: {
    creativeSweepTeam(val) {
      const data = val.filter((item) => item.accepted);
      return data;
    },
    sortLowest() {
      this.item.sort((a, b) => (new Date(a.toTime) > new Date(b.toTime) ? 1 : -1));
    },
    removeCloseOut() {
      this.closeOutModal = false;
      this.$emit('reload');
    },
    goToSweepDetails(val, id) {
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          businessId: id,
          sweepId: val,
        },
      });
    },
    goToSweepClose(val) {
      this.selectedSweep = _.cloneDeep(_.find(this.item, ['id', val.id]));
      this.closeOutModal = true;
    },
    getMinutes(date, time) {
      const settime = moment(time, 'h:mm:ss A').format('HH:mm:ss');
      const dateTime = `${date} ${settime}`;
      const getDate = new Date(dateTime);
      const newDate = getDate / 1000;
      const exartTime = new Date() / 1000;
      const diff = exartTime - newDate;
      let day = Math.floor(diff / 86400);
      let hours = Math.floor(diff / 3600) % 24;
      let minutes = Math.floor(diff / 60) % 60;
      // eslint-disable-next-line
      [day, hours, minutes] = [day, hours, minutes];
      day = day > 0 ? day : '';
      hours = hours > 0 ? hours : '';
      minutes = minutes > 0 ? minutes : '';
      let days = day === 1 ? `${day} day ` : day;
      days = days > 1 ? `${days} days ` : days;
      let hour = hours === 1 ? `${hours} hour ` : hours;
      hour = hour > 1 ? `${hour} hours ` : hour;
      let minute = minutes === 1 ? `${minutes} min` : minutes;
      minute = minute > 1 ? `${minute} mins` : minute;
      // eslint-disable-next-line
      let value = days + '' + hour + '' + minute + '';
      return value;
    },
  },
};
</script>

<style lang="scss">
.sweepclose-container {
  @media (min-width: 1550px) {
    .profile-col {
      min-width: 335px;
    }
  }
  .label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #929292;
    border-bottom: 1px solid #d8d8d8;
  }
}
  .sweepclose-table {
    height: 350px;
    overflow-y: hidden !important;
    overflow-x: hidden;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    .title-style {
      width: fit-content;
      overflow-x: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
    .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        .business-name {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #929292;
        }
    }
    .creative-icon {
        margin-left: -15px;
    }
    .plus-icon {
      margin-left: -15px;
    }
    .table-headers {
        z-index: 2;
        background: white;
        top: 0px;
        position: -webkit-sticky !important;
        position: sticky !important;
      .table-header {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #b4b4b4;
      }
    }
    .divider {
      border-left: 1px solid #DADADA;
    }
    .button {
        height: 35px;
        width: 130px;
        font-family: 'Poppins';
        background: #ffffff;
        border: 2px solid #41E0BA;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #262626;
    }
    .table-data {
        margin-bottom: 5px;
        margin-left: 20px;
        margin-right: 20px;
        border-top: 1px solid #D8D8D8;
    }
  }
</style>
