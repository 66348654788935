<template>
  <div class="main-container">
    <v-row class="ma-0 justify-end">
    <v-col
      cols="12" xxl="3" xl="3" lg="4" md="5" xs="12" sm="12"
      class="d-flex align-center justify-end pa-0"
      :style="`order: ${$vuetify.breakpoint.smAndDown ? 1 : 0}`">
      <BaseInput
        solo
        hide-details
        v-model="search"
        placeholder="Search ..."
        prepend-inner-icon="search"
        class="search-box mb-5"
        clearable
        @click:clear="search === ''"
      />
    </v-col>
    <div>
      <v-menu>
        <template v-slot:activator="{ on, attrs }" v-if="isAddable">
          <v-btn
            color="#8066FF"
            class="add-button align-center mb-5"
            v-bind="attrs"
            v-on="on"
          >Actions
          <span class="ml-1">
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0L5 6L0 0H10Z" fill="white"/>
            </svg>
          </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="openForm">
            <v-list-item-title>Add Business</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="['admin', 'executive', 'producer'].includes(role)"
            @click="openImportModal"
          >
              <v-list-item-title>Bulk Import</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-row>
  <div class="listing-block">
      <BusinessList
      :businesses="agencyBusinesses"
      :fetching="loading"
      @onModalOpen="openEditModal"
      :search="search"
      @rowClicked="(row) => navigate(row)"
      :refreshKey="refreshKey"
      />
  </div>
    <v-container
      fluid
      v-if="showBusinessForm"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showBusinessForm"
        scrollable
      >
        <BusinessForm
          @close-modal="closeForm"
          :selectedAgency="agency"
          :businessObj="selectedBusiness"
          :canArchive="$can('delete', 'project management')"
        />
      </Modal>
    </v-container>
    <v-container v-if="showConfirm">
      <RemoveAffiliationPopup
      :showConfirm="showConfirm"
      @close="close"
      @remove="unAffiliate(businessId)"
      :agencyName="agency.name"
      :selectedBusinessName="selectedBusiness.name" />
    </v-container>
        <v-dialog
      v-model="showImportModal"
      persistent
      width="750px"
      max-width="1280px"
    >
      <v-card class="pa-2 pb-0" flat>
          <div>
          <v-row class="ma-auto">
            <v-col class="d-flex align-center pa-0" cols="10">
              <span class="pa-2 title" style="color:#565682;">Import Businesses</span>
            </v-col>
            <!-- <v-col class="d-flex justify-end pa-0">
              <v-icon color="darken-1" @click="showImportModal = false">mdi-close</v-icon>
            </v-col> -->
          </v-row>
          </div>
          <v-divider></v-divider>
          <v-card-text class="upload-modal-content">
          <div class="mt-2">
            <v-file-input
              :key="selectedFile"
              accept="text/csv"
              v-model="selectedFile"
              label="Choose a CSV File"
              @change="file => { resetImportState(); selectedFile = file }"
              @reset="resetImportState"
            ></v-file-input>
          </div>
          <div v-if="csvErrors.length > 0" class="ml-2">
            <div>Please fix below issues in CSV file</div>
            <div class="csv-error-wrapper ml-2" v-for="error in csvErrors" :key="error.lineNumber">
              <div class="red--text">
              <span>At line {{error.lineNumber}}:</span>&nbsp;
              <span>{{error.errors.join(',')}}</span></div>
            </div>
          </div>
          <div v-if="serverErrors.length > 0" class="ml-2">
            <div>Below businesses were failed to import</div>
            <div class="csv-error-wrapper ml-2"
              v-for="error in serverErrors" :key="error.businessName">
              <div class="red--text">
              <span>{{error.businessName}}:</span>&nbsp;
              <span>{{error.reason}}</span></div>
            </div>
          </div>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="card-actions px-5 py-4 justify-end">
            <v-btn text class="mr-2 cancel-btn" @click="showImportModal = false">CANCEL</v-btn>
            <v-btn
            class="save-btn ml-2 info" type="submit"
            @click="uploadBusinesses"
            :disabled="!selectedFile"
            :loading="uploadingCSVFile">
              UPLOAD
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import BusinessList from '@/components/organizations/businesses/BusinessList';
import organizationsIcon from '@/assets/svg/organisations.svg';
import { getOptimizedS3ImageURL } from '@/helpers/';
import BaseInput from '@/components/common/BaseInput';
import RemoveAffiliationPopup from '@/components/organizations/RemoveAffiliationPopup';
import Modal from '@/components/common/Modal';
import BusinessForm from '@/components/organizations/businesses/BusinessForm';

export default {
  name: 'AffiliatedBusinesses',
  props: {
    businessId: {
      type: Number,
      default: null,
    },
    refreshKey: {
      type: String,
      default: null,
    },
  },
  components: {
    BusinessList,
    BaseInput,
    RemoveAffiliationPopup,
    Modal,
    BusinessForm,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('organizations', ['agency', 'agencyBusinesses']),
    ...mapGetters('project', ['currentProject']),
    canAccess() {
      return ['admin', 'executive', 'producer'].includes(this.role);
    },
    bannerImage() {
      if (this.agency.bannerImage) {
        return this.agency.bannerImage;
      }
      return organizationsIcon;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    agencyId() {
      return _.get(this.agency, 'id');
    },
    isAddable() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return ['agency_owner', 'admin', 'executive'].includes(this.role) || (['producer'].includes(this.role) && assigned);
    },
  },
  data() {
    return {
      step: 1,
      breadCrumbs: [
        {
          text: 'All Organizations',
          disabled: false,
          to: '/organizations',
        },
      ],
      csvErrors: [],
      serverErrors: [],
      showImportModal: false,
      selectedFile: null,
      uploadingCSVFile: false,
      parentTab: null,
      showBusinessForm: false,
      search: '',
      status: '',
      loading: false,
      showLoader: false,
      selectedBusiness: {},
      showConfirm: false,
      selectedLibraryProjectId: '',
      selectedOrderId: '',
    };
  },
  methods: {
    getOptimizedS3ImageURL,
    ...mapActions(['setNotification']),
    ...mapActions('organizations',
      ['removeAffiliation', 'getAgencyBusinesses', 'getSingleAgency', 'importBusinesses']),
    ...mapActions('profile', ['getClientOptions']),
    ...mapActions('business', ['getBusiness', 'getContentPlan']),
    close() {
      this.showConfirm = false;
    },
    openImportModal() {
      this.resetImportState();
      this.showImportModal = true;
    },
    closeImportModal() {
      this.resetImportState();
      this.showImportModal = false;
    },
    resetImportState() {
      this.selectedFile = null;
      this.csvErrors = [];
      this.serverErrors = [];
    },
    async uploadBusinesses() {
      this.uploadingCSVFile = true;
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('agencyId', this.agencyId);
      this.csvErrors = [];
      this.serverErrors = [];
      const response = await this.importBusinesses(formData);
      this.uploadingCSVFile = false;
      if (response.success) {
        // can fully imported or partial
        const { unImportedBusinesses, importState } = response;
        const serverErrors = [];
        unImportedBusinesses.forEach((business) => {
          serverErrors.push({
            businessName: business.businessName,
            reason: business.failureReason,
          });
        });
        this.serverErrors = serverErrors;
        if (importState === 'all') {
          this.setNotification({
            message: 'All businesses were imported successfully',
            type: 'success',
          }, { root: true });
          await this.getAgencyBusinesses({ businessId: this.agencyId, status: 1 });
          this.closeImportModal();
        } else if (importState === 'partial') {
          this.setNotification({
            message: 'Some businesses were failed to import',
            type: 'error',
          }, { root: true });
          await this.getAgencyBusinesses({ businessId: this.agencyId, status: 1 });
        } else {
          this.setNotification({
            message: 'No businesses were imported',
            type: 'error',
          }, { root: true });
        }
      } else {
        // eslint-disable-next-line
        if (response.data.errors && response.data.errors.length > 0) {
          this.csvErrors = response.data.errors;
        } else {
          this.csvErrors = [];
        }
      }
    },
    navigate(row) {
      const { id, name, sharedProject } = row.data.business;
      const projectId = sharedProject ? sharedProject.id : null;
      let url = `/organizations/${this.businessId}/business/${id}`;
      url += `?agencyName=${this.agency.name}&businessName=${name}&agencyProjectId=${projectId}`;
      this.$router.push(url);
    },
    async unAffiliate(id) {
      const result = await this.removeAffiliation({
        businessId: JSON.parse(this.agencyId),
        affiliatedBusinessId: id,
        reference: 'organization',
      });
      if (result.success) {
        this.showConfirm = false;
      }
    },
    async closeForm() {
      this.showBusinessForm = false;
      this.selectedBusiness = {};
    },
    openForm() {
      this.selectedBusiness = {};
      this.showBusinessForm = true;
    },
    openEditModal(id, type) {
      this.selectedBusiness = _.cloneDeep(_.find(this.agencyBusinesses, ['id', id]));
      const users = _.get(this.selectedBusiness, 'userBusiness');
      let primaryOwner = {};
      _.map(users, (user) => {
        if (user.user.isPrimaryOwner) {
          primaryOwner = user.user;
        }
      });
      if (type === 1) {
        this.showLoader = true;
        this.selectedBusiness.businessTypeId = _.get(this.selectedBusiness, 'businessTypeId', null);
        this.selectedBusiness.name = _.get(this.selectedBusiness, 'name');
        this.selectedBusiness.bannerImage = _.get(this.selectedBusiness, 'bannerImage', null);
        this.selectedBusiness.status = _.get(this.selectedBusiness, 'status');
        this.selectedBusiness.subscriptionId = _.get(this.selectedBusiness, 'subscriptionBlends[0].id');
        this.selectedBusiness.ownerFirstName = primaryOwner.firstName;
        this.selectedBusiness.ownerLastName = primaryOwner.lastName;
        this.selectedBusiness.ownerEmail = primaryOwner.email;
        this.showBusinessForm = true;
        this.showLoader = false;
      } else if (type === 2) {
        this.openDeleteModal(id);
      }
    },
    openDeleteModal(id) {
      this.showConfirm = true;
      this.selectedBusiness = _.cloneDeep(_.find(this.agencyBusinesses, ['id', id]));
      this.businessId = this.selectedBusiness.id;
    },
    async getAgencyBusinessByStatus(status) {
      this.loading = true;
      if (status === 'Archived') {
        await this.getAgencyBusinesses({ businessId: this.agencyId, status: 2 });
      } else {
        await this.getAgencyBusinesses({ businessId: this.agencyId, status: 1 });
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;
    const agencyId = this.businessId;
    await this.getSingleAgency(agencyId);
    await this.getAgencyBusinesses({ businessId: agencyId, status: 1 });
    await this.getClientOptions();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  ::v-deep.page-loader-div {
    height: 500px !important;
    margin-top: 0px !important;
  }
}
.tab-box-items {
  // margin-top: 45px;
  margin-top: 2rem;
}
  .affiliation {
    .v-card__actions {
      width: 100%;
      justify-content: flex-end;
      border-top: 1px solid #E0E0E0;
      padding: 14px 15px 6px;
      .v-btn {
        border-radius: 6px;
        font-weight: 500;
        line-height: 19px;
        font-size: 16px;
        color: $secondary3;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        ::v-deep .v-btn__content {
          text-transform: uppercase;
        }
      }
      .submit {
        ::v-deep .v-btn__content {
          color: #fff !important;
        }
        background: #D50000 !important;
        width: 112px;
        height: 40px;
        margin-left: 12px !important;
      }
      .cancel {
        background: #F5F5F5 !important;
        .v-btn__content {
          color: #828282 !important;
        }
        width: 99px;
        height: 40px
      }
    }
  .info-text {
    padding: 28px 21px 34px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .title-header {
    border-bottom: 1px solid #E0E0E0;
    padding: 4px 21px 21px;
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }
  .modal-heading {
    font-family: $fontFamily1;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    color: #1b1c1e !important;
  }
  .cancel-btn {
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;
  }
}
.upload-modal-content {
  overflow: auto;
  max-height: 500px;
}
.organization-filter {
  border: 1px solid #b0b0b0 !important;
  max-width: 150px;
}
::v-deep .v-tabs .v-tabs-bar, .v-tabs-items {
    background-color: transparent !important;
}
::v-deep .v-tabs .v-tabs-bar {
  border-bottom: 1px solid #babfc7;
  .v-tabs-bar__content {
    color: #3A8290 !important;
  }
  .tab-title {
    font-size: 14px;
    text-transform: capitalize;
  }
}
.text-purple {
  cursor: pointer;
  width: fit-content;
}
.padding-right{
  padding-right:60px !important;
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box {
 margin-right: 10px;
 ::v-deep {
  .v-text-field {
    height: 40px;
    font-size: 16px;
    .v-input__control > .v-input__slot {
      min-height: 40px !important;
      border: 1px solid  #b0b0b0 !important;
      box-shadow: none !important;
      border-radius: 4px !important;
    }
  }
}
}
.add-button, .export-button {
  box-shadow: none !important;
  border-radius: 56px;
  ::v-deep .v-btn__content {
    color: #fff;
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
.modal-heading {
  font-family: $fontFamily1;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: #1b1c1e !important;
}
.cancel-btn {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.modal-card {
  position: absolute;
  right: 0px;
  width:500px;
  font-family: $fontFamily1;
  font-size: 14px;
  top: 50px;
  z-index: 10;
  .card-list {
    background-color: #f8f8f8;
  }
}

.listing-block {
  min-height: 1400px !important;
  height: calc(100vh - 150px);
  overflow: auto;
}
@media (min-width: 600px) {
  .border-right {
    border-right: 1px solid #dddddd;
  }
}
@media (max-width: 600px) {
  .search-box {
     width: 500px !important;
     margin-left: 0px !important;
     margin-right: 0px !important;
  }
  .listing-block {
    height: calc(100vh - 165px);
  }
}
</style>
