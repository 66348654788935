<template>
  <v-form class="add-organization-form" @submit="submitForm">
    <v-container class="pa-0">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols=10>
          <span class="text modal-header-title"> {{title}} </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="scollable-div">
        <v-row class="organization-info">
          <v-col cols="12" class="pa-0">
            <div class="organization-fields" style="width: 100%;">
              <label class="text-left font-label"> Business Logo </label>
              <div class="d-flex justify-center">
                <div class="banner-img">
                  <v-img
                    v-if="business.bannerImage"
                    class="profile-image"
                    :src="getOptimizedS3ImageURL(profilePicture,
                      { width: 250, height: 250 })"
                    alt="Profile" />
                  <div v-else class="profile-image border-dashed"/>
                </div>
              </div>
              <div class="text-center pt-2">
                <Upload
                  id="upload-documents"
                  class="upload-button"
                  mode="justImage"
                  v-model="business.bannerImage"
                  :acceptedFiles="'.jpg,.jpeg,.png'"
                >
                  <span class="update-pic">Update</span>
                </Upload>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="px-0 pb-0">
            <label class="text-left font-label"> Business Name *</label>
            <BaseInput
              name="businessName"
              solo
              dense
              flat
              v-model.trim="business.name"
              :status="errors('name').length ? 'error': 'normal'"
              :error-messages="errors('name')"
              @input="$v.business.name.$touch()"
              @blur="$v.business.name.$touch()"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <label class="text-left font-label"> Business Vertical *</label>
            <v-select
            solo
            flat
            class="type-selection mb-n3 notranslate"
            name="businessTypeId"
            :items="businessTypes"
            item-text="name"
            item-value="id"
            v-model="business.businessTypeId"
            @change="verticalUpdated = true"
            :menu-props="{'content-class' : 'notranslate'}"
            :status="errors('businessTypeId').length ? 'error': 'normal'"
            :error-messages="errors('businessTypeId')"
            @input="$v.business.name.$touch()"
            @blur="$v.business.name.$touch()"
          ></v-select>
          </v-col>
        </v-row>
        <v-row class="flex-column primary-info">
          <div class="text1">Primary Contact</div>
          <div class="text2">This will be the primary owner of the business account.</div>
          <v-row>
            <v-col cols="6" class="pb-0 pr-2">
              <label class="text-left font-label">First Name *</label>
              <BaseInput
                solo
                dense
                flat
                name="ownerFirstName"
                :disabled="business.id ? true: false"
                v-model.trim="business.ownerFirstName"
                :status="errors('ownerFirstName').length ? 'error': 'normal'"
                :error-messages="errors('ownerFirstName')"
                @input="$v.business.ownerFirstName.$touch()"
                @blur="$v.business.ownerFirstName.$touch()"
              />
            </v-col>
            <v-col cols="6" class="pb-0 pl-2">
              <label class="text-left font-label">Last Name *</label>
              <BaseInput
                solo
                dense
                flat
                name="ownerLastName"
                :disabled="business.id ? true: false"
                v-model.trim="business.ownerLastName"
                :status="errors('ownerLastName').length ? 'error': 'normal'"
                :error-messages="errors('ownerLastName')"
                @input="$v.business.ownerLastName.$touch()"
                @blur="$v.business.ownerLastName.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0 pb-0">
              <label class="text-left font-label">Email Address *</label>
              <BaseInput
                solo
                dense
                flat
                name="ownerEmail"
                :disabled="business.id ? true: false"
                v-model.trim="business.ownerEmail"
                :status="errors('ownerEmail').length ? 'error': 'normal'"
                :error-messages="errors('ownerEmail')"
                @input="$v.business.ownerEmail.$touch()"
                @blur="$v.business.ownerEmail.$touch()"
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row class="flex-column primary-info" v-if="enableOperationsTeam">
          <div class="text1 mb-4">Operations Team</div>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="projectDependencies.producersList"
              multiple
              label="Content Producer"
              name="producerIds"
              :value="business.producerIds"
              @change="setProducers"
              class="notranslate"
              item-text="label"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="projectDependencies.executivesList"
              multiple
              label="Production Manager"
              name="executiveIds"
              :value="business.executiveIds"
              @change="setExecutives"
              class="notranslate"
              item-text="label"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="projectDependencies.editorsList"
              multiple
              label="Editor"
              name="editorIds"
              v-model="business.editorIds"
              class="notranslate"
              item-text="label"
              item-value="value"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <AutoCompleteField
              :items="contactsList"
              label="Point of Contact"
              name="pointOfContactId"
              :value="JSON.parse(business.pointOfContactId)"
              @change="setContact"
              class="notranslate"
              item-text="label"
              item-value="value"
              notClearable
            />
          </v-col>
        </v-row>
      </div>
      <v-card-actions>
        <v-btn text class="py-0" color="primaryGray1"  @click="$emit('close-modal')">
          Cancel
        </v-btn>
        <v-btn
          class="py-0 submit btn-purple"
          type="submit"
          :loading="loading"
          :disabled="$v.$dirty && $v.$invalid"
        >
          {{ action }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import { getOptimizedS3ImageURL, url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';
import Upload from '@/components/common/Upload';
import AutoCompleteField from '@/components/common/AutoCompleteField';

export default {
  components: {
    BaseInput,
    Upload,
    AutoCompleteField,
  },
  props: {
    selectedAgency: {
      type: Object,
      default: () => {},
    },
    businessObj: {
      type: Object,
      default: () => {},
    },
    canArchive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['businessTypes']),
    ...mapGetters('user', ['userDetails']),
    enableOperationsTeam() {
      return ['admin', 'executive'].includes(this.role);
    },
    contactsList() {
      const { producersList, executivesList } = this.projectDependencies;
      return _.concat(producersList, executivesList);
    },
    profilePicture() {
      if (this.business.bannerImage instanceof File) {
        return URL.createObjectURL(this.business.bannerImage);
      }
      return this.business.bannerImage;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    agency_customer() {
      return ['agency_owner', 'agency_member'].includes(this.role);
    },
    filteredSubscriptionBlends() {
      if (this.business.businessTypeId) {
        const filtered = _.filter(this.projectDependencies.subscriptionBlends,
          { verticalId: this.business.businessTypeId });
        if (this.verticalUpdated) return filtered;
        const legacy = !_.map(filtered, 'id').includes(this.business.subscriptionId);
        if (this.business.id && legacy) {
          return this.projectDependencies.subscriptionBlends;
        }
        return filtered;
      }
      return this.projectDependencies.subscriptionBlends;
    },
    title() {
      if (this.businessObj.id) {
        return 'Edit Business';
      }
      return 'Add Business';
    },
    action() {
      if (this.businessObj.id) {
        return 'Update';
      }
      return 'Save';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.business[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.business.name.required) { errors.push('Please provide business name'); }
            if (!this.$v.business.name.maxLength) { errors.push('Business name must be less than 160 characters length'); }
            break;
          case 'businessTypeId':
            if (!this.$v.business.businessTypeId.required) { errors.push('Please select business vertical'); }
            break;
          case 'ownerFirstName':
            if (!this.$v.business.ownerFirstName.required) { errors.push('Please provide first name'); }
            if (!this.$v.business.ownerFirstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'ownerLastName':
            if (!this.$v.business.ownerLastName.required) { errors.push('Please provide last name'); }
            if (!this.$v.business.ownerLastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'ownerEmail':
            if (!this.$v.business.ownerEmail.required) { errors.push('Please provide email address.'); }
            if (!this.$v.business.ownerEmail.email) { errors.push('Please provide a valid email address.'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
      projectDependencies: {
        subscriptionBlends: [],
        executivesList: [],
        producersList: [],
        editorsList: [],
      },
      business: {
        subscriptionId: '',
        agencyId: '',
        name: '',
        businessTypeId: '',
        bannerImage: '',
        ownerFirstName: '',
        ownerLastName: '',
        ownerEmail: '',
        producerIds: [],
        executiveIds: [],
        editorIds: [],
        pointOfContactId: null,
      },
      loading: false,
      verticalUpdated: false,
    });
  },
  methods: {
    ...mapActions('organizations', ['addBusiness', 'updateBusiness']),
    ...mapActions('project', ['getSubscriptionBlends']),
    ...mapActions('user', ['fetchUsers']),
    ...mapActions(['setNotification']),
    getOptimizedS3ImageURL,
    async setProducers(newval) {
      const contactId = _.get(this.business, 'pointOfContactId', null);
      const pointOfContactId = contactId ? JSON.parse(contactId) : null;
      this.business.producerIds = newval;
      if (this.pointOfContactRole === 'producer' && pointOfContactId
          && ((newval.length === 0) || (newval.length > 0
          && !newval.includes(pointOfContactId)))) {
        this.business.producerIds.push(pointOfContactId);
        this.setNotification({
          message: 'Cannot update point of contact!',
          type: 'error',
        }, { root: true });
      }
    },
    async setExecutives(newval) {
      const contactId = _.get(this.business, 'pointOfContactId', null);
      const pointOfContactId = contactId ? JSON.parse(contactId) : null;
      this.business.executiveIds = newval;
      this.selectedExectiveIds = await _.filter(newval,
        (val) => val !== pointOfContactId);
      if (this.pointOfContactRole === 'executive' && pointOfContactId
          && ((newval.length === 0) || (newval.length > 0
          && !newval.includes(pointOfContactId)))) {
        this.business.executiveIds.push(pointOfContactId);
        this.setNotification({
          message: 'Cannot update point of contact!',
          type: 'error',
        }, { root: true });
      }
    },
    async setContact(data) {
      if (data) {
        this.business.pointOfContactId = data;
        const producer = _.filter(this.business.producerIds, (val) => val === data);
        const executive = _.filter(this.business.executiveIds, (val) => val === data);
        const { producersList, executivesList } = this.projectDependencies;
        const isExective = _.includes(_.map(executivesList, (item) => item.value),
          data)
          ? 'executive' : '';
        const isProducer = _.includes(_.map(producersList, (item) => item.value),
          data)
          ? 'producer' : '';
        this.pointOfContactRole = isProducer || isExective;
        if (isProducer && producer.length === 0) {
          this.business.producerIds.push(data);
        }
        if (isExective && executive.length === 0) {
          this.business.executiveIds.push(data);
        }
      } else {
        this.business.pointOfContactId = null;
      }
    },
    async fetchProjectDependencies() {
      const subscriptionBlends = await this.getSubscriptionBlends();
      this.projectDependencies.subscriptionBlends = subscriptionBlends.subscriptionBlends;
      const [
        executivesList,
        producersList,
        editorsList,
      ] = await Promise.all([
        this.fetchUsers('executive'),
        this.fetchUsers('producer'),
        this.fetchUsers('editor'),
      ]);
      const executives = _.map(executivesList.users, (executive) => ({
        label: `${executive.firstName} ${executive.lastName ? executive.lastName : ''}`,
        value: executive.id,
      }));
      this.projectDependencies.executivesList = _.sortBy(executives, [(item) => item.label.toLowerCase()], ['asc']);
      const allProducers = _.filter(producersList.users, (user) => (!user.isTrainee
        || (this.business.producerIds && this.business.producerIds.includes(user.id))));
      const producers = _.map(allProducers, (producer) => ({
        label: `${producer.firstName} ${producer.lastName ? producer.lastName : ''}`,
        value: producer.id,
      }));
      this.projectDependencies.producersList = _.sortBy(producers, [(item) => item.label.toLowerCase()], ['asc']);
      const editors = _.map(editorsList.users, (editor) => ({
        label: `${editor.firstName} ${editor.lastName ? editor.lastName : ''}`,
        value: editor.id,
      }));
      this.projectDependencies.editorsList = _.sortBy(editors, [(item) => item.label.toLowerCase()], ['asc']);

      const isExective = _.includes(_.map(this.projectDependencies.executivesList,
        (item) => item.value),
      this.business.pointOfContactId
        && JSON.parse(this.business.pointOfContactId))
        ? 'executive' : '';
      const isProducer = _.includes(_.map(this.projectDependencies.producersList,
        (item) => item.value),
      this.business.pointOfContactId
        && JSON.parse(this.business.pointOfContactId))
        ? 'producer' : '';
      this.pointOfContactRole = isProducer || isExective;
    },
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const formData = new FormData();
        const business = {
          ...this.business,
          verticalId: this.business.businessTypeId,
        };
        await _.map(business, (val, key) => {
          if (key === 'bannerImage' && !url(val)) {
            formData.append('bannerImage', val);
          } else if (val !== null) {
            formData.append(key, _.isObject(val) ? JSON.stringify(val) : val);
          }
        });
        let result;
        if (this.business.id) {
          result = await this.updateBusiness({
            agencyId: this.selectedAgency.id,
            business: formData,
            businessId: this.business.id,
          });
        } else {
          result = await this.addBusiness({
            business: formData,
            agencyId: this.selectedAgency.id,
          });
        }
        if (result.success) {
          setTimeout(() => {
            this.$emit('close-modal');
          }, 1000);
        }
      }
      this.loading = false;
    },
    deleteBannerImage() {
      this.business.bannerImage = '';
    },
  },
  validations: {
    business: {
      name: { required, maxLength: maxLength(160) },
      businessTypeId: { required },
      ownerFirstName: {
        required,
        maxLength: maxLength(50),
      },
      ownerLastName: {
        required,
        maxLength: maxLength(50),
      },
      ownerEmail: { required, email },
    },
  },
  mounted() {
    if (this.enableOperationsTeam) {
      this.fetchProjectDependencies();
    }
    if (this.businessObj.id) {
      this.business = { ...this.businessObj };
    } else {
      if (_.get(this.userDetails, 'role.name', '') !== 'admin') {
        if (_.get(this.userDetails, 'role.name', '') === 'executive') {
          this.business.executiveIds = [this.userDetails.id];
        } else {
          this.business.producerIds = [this.userDetails.id];
        }
      }
      this.business.businessTypeId = this.selectedAgency.businessTypeId;
    }
    this.business.agencyId = this.selectedAgency.id;
  },
};
</script>

<style lang="scss" scoped>
.add-organization-form {
  .scollable-div {
    overflow: auto;
    max-height: 450px;
    .base-input {
      margin-top: 6px;
    }
  }
  .title-header {
    border-bottom: 1px solid #E0E0E0;
    padding: 16px 21px 21px;
  }
  .organization-info {
    margin: 22px 25px;
  }
  .primary-info {
    border-top: 1px solid #F1F1F1;
    margin: 22px 25px 28px 25px !important;
    .text1 {
      padding-top: 22px;
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      color: $charcoalBlack;
    }
    .text2 {
      padding-top: 11px;
      padding-bottom: 21px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #808080;
    }
  }
}
.v-form {
  .v-card__actions {
      padding: 0;
      justify-content: flex-end;
      border-top: 1px solid #E0E0E0;
      padding: 14px 27px 18px;
      .v-btn {
        border-radius: 6px;
        font-weight: 400;
        color: $secondary3;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        line-height: 24px;
        ::v-deep .v-btn__content {
          text-transform: uppercase;
          font-weight: 500;
          line-height: 19px;
          font-size: 16px;
        }
      }
      .submit {
        width: 112px;
        margin-left: 12px !important;
      }
    }
  .banner-img {
    width: 88px;
    height: 88px;
    .profile-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .border-dashed {
      object-fit: cover;
      background: #DEDEDE;
      border: 1px dashed $charcoalBlack;
    }
  }
  .organization-fields {
    .update-pic {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $secondary1;
      cursor: pointer;
    }
  }
}
  .auto-complete {
    margin-bottom: 0;
  }
  .heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $charcoalBlack;
  }
  ::v-deep .v-picker.v-card {
      border-radius: 0 !important;
  }
  ::v-deep .input-field {
    font-family: $fontFamily1;
    height: inherit;
  }
  ::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
  ::v-deep .v-select__slot {
    font-family: $fontFamily1;
  }
  ::v-deep .v-input {
    input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px white inset;
    }
  }
  .upload-button {
    font-family: $fontFamily1;
    font-size: 12px;
    letter-spacing: -0.01px;
    color: $secondary1;
    span {
      cursor: pointer !important;
    }
  }
  ::v-deep .normalState .v-input__slot {
    border: 1px solid #E0E0E0 !important;
  }
  ::v-deep .nullState .v-input__slot {
    border: 1px solid #E0E0E0 !important;
  }
  ::v-deep .v-input {
  .v-input__control {
    .v-select__selection, input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4F4F4F !important;
    }
  }
}
  ::v-deep .v-input__slot {
    min-height: 40px !important;
  }
  ::v-deep .type-selection {
    margin-top: 6px;
    .v-input__slot {
      border: solid 1px #e6e8ed;
      min-height: 40px !important;
    }
  }
  ::v-deep .type-selection.v-text-field .v-input__control > .v-input__slot {
    min-height: 40px !important;
    box-shadow: none;
  }
  ::v-deep .type-selection.error--text .v-input__slot {
    border: solid 1px #ff0808;
  }
</style>
