<template>
  <Modal
    persistent
    :modal="open"
    content-class="asset-preview-modal full-screen"
    :max-width="$vuetify.breakpoint.smAndUp ? '1100' : '100%'"
    scrollable
  >
        <content-kit-file-preview
        :prevItemIndex="prevIndex"
        :nextItemIndex="nextIndex"
        :item="selectedItem"
        @close-modal="$emit('close')"
        @download="downloadFiles(selectedItem.id)"
        @onNavigate="navigate"
        @favourite="updateFavourite"
        :userRole="role"
        :src="src"
        :numPages="numPages"
        :pdfLoading="pdfLoading"
        :workSpaceId="workspaceId"
        @onSaveAssets="reloadOrderInfo"
        />
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import pdf from 'vue-pdf';
import _ from 'lodash';
import ContentKitFilePreview from '@/components/AssetLibrary/ContentKitFilePreview';
import Modal from '@/components/common/Modal';

export default {
  name: 'UploadedItemPreviewWrapper',
  components: {
    ContentKitFilePreview,
    Modal,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    previewIndex: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return ({
      prevIndex: null,
      nextIndex: null,
      selectedItem: null,
      workspaceId: null,
      src: '',
    });
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
  },
  methods: {
    ...mapActions('contentKit', ['downloadContentKitFiles']),
    ...mapActions('orders', ['getOrderFiles']),
    reloadOrderInfo() {
      this.getOrderFiles({
        orderId: this.selectedItem.orderId,
      });
    },
    async downloadFiles(fileId) {
      const file = _.find(this.items, ['id', fileId]);
      const result = await this.downloadContentKitFiles({
        contentKitId: file.contentKitId,
        fileIds: [fileId],
      });
      if (result.success) {
        _.map(result.data, (data) => {
          window.open(data.url, '_blank');
        });
      }
    },
    navigate(index) {
      this.selectedItem = Object.assign({}, this.items[index]);
      this.workspaceId = _.get(this.selectedItem, 'workSpaceFile[0].workspaceId');
      this.prevIndex = index - 1;
      this.nextIndex = index === this.items.length - 1 ? -1 : index + 1;
      this.getPdfFullFileView(this.selectedItem);
    },
    updateFavourite(item) {
      this.selectedItem = { ...item };
    },
    getPdfFullFileView(selectedItem) {
      const ext = selectedItem.fileFormat;
      if (ext === 'application/pdf') {
        this.pdfLoading = true;
        const loadingTask = pdf.createLoadingTask(selectedItem.file);
        this.src = loadingTask;
        this.src.promise.then((pdfFile) => {
          this.numPages = pdfFile.numPages;
          this.pdfLoading = false;
        });
      }
    },
  },
  mounted() {
    this.selectedItem = this.items[this.previewIndex];
    this.workspaceId = _.get(this.selectedItem, 'workSpaceFile[0].workspaceId');
    const index = this.previewIndex;
    this.prevIndex = index - 1;
    this.nextIndex = index === this.items.length - 1 ? -1 : index + 1;
    this.getPdfFullFileView(this.selectedItem);
  },
};
</script>
<style lang="scss" scoped>

</style>
