<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" lg="6" class="d-flex filters pt-2 search-padding">
        <div v-if="showSearch" class="search-input">
          <base-input
            class="search-input font-family-2"
            type="text"
            solo
            flat
            dense
            sm
            hide-details
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="contentKitFilters.search"
            @change="filtersUpdated"
          >
          </base-input>
        </div>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <div v-if="showSearch" class="type-filter">
            <v-select
              :items="types"
              label="Type"
              solo
              flat
              dense
              item-text="name"
              item-value="id"
              :hide-details="true"
              v-model="contentKitFilters.selectedTypes"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="type-filter"
              background-color="transparent"
              @change="filtersUpdated"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Type </span>
              </template>
            </v-select>
          </div>
          <div v-if="showSearch" class="dimension-filter">
            <v-select
              :items="dimensions"
              label="Dimension"
              solo
              flat
              dense
              item-value="id"
              item-text="name"
              :hide-details="true"
              v-model="contentKitFilters.selectedDimensions"
              @change="filtersUpdated"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="dimension-filter"
              background-color="transparent"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Dimension </span>
              </template>
            </v-select>
          </div>
          <div v-if="showSearch" class="platform-filter">
            <v-select
              :items="socialTags"
              label="Platform"
              solo
              flat
              dense
              item-value="id"
              item-text="name"
              :hide-details="true"
              v-model="contentKitFilters.selectedPlatforms"
              @change="filtersUpdated"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="platform-filter"
              background-color="transparent"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Platform </span>
              </template>
            </v-select>
          </div>
          <div v-if="showSearch" class="platform-filter">
            <v-select
              :items="userProjects"
              label="Projects"
              solo
              flat
              dense
              item-value="id"
              item-text="name"
              :hide-details="true"
              v-model="contentKitFilters.selectedProjects"
              @change="filtersUpdated"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="platform-filter"
              background-color="transparent"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Projects </span>
              </template>
            </v-select>
          </div>
          <div class="clear-filter mt-1 ml-2" v-if="anyFilter">
            <v-btn small text class="pa-0"
              @click="clearFilters" color="info">
              Clear All
            </v-btn>
          </div>
        </template>
        <template v-else>
          <template class="filter-icon" v-if="showSearch">
          <v-btn icon v-if="anyFilter" @click="mobileFilters = !mobileFilters" class="ml-auto">
            <v-img src="@/assets/svg/theme/filter-icon-selected.svg"
              contain width="20" height="20"/>
          </v-btn>
          <v-btn v-else icon @click="mobileFilters = !mobileFilters" class="ml-auto">
            <v-img src="@/assets/svg/theme/filter-icon.svg" contain width="20" height="20"/>
          </v-btn>
          </template>
        </template>
      </v-col>
    </v-row>
    <v-container
      fluid
      v-if="mobileFilters"
      background-color="white"
    >
    <Modal
      persistent
      fullscreen
      content-class="asset-filters-modal"
      :modal="mobileFilters"
      width="400"
      light
      background-color="white"
      >
        <div class="heading">
        <v-row class="ma-0 px-4 pb-2">
          <v-col class="d-flex align-center pb-0" cols="11">
            <div class="text-capitalize modal-header-title"> Filters</div>
          </v-col>
          <v-col class="d-flex justify-end pb-0 px-0" cols="1">
            <v-icon color="darken-1" @click="closeFilters">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="pa-0"> <v-divider> </v-divider> </v-col>
        </v-row>
        </div>
        <div class="filters-list mt-2">
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Type"
                :options="types"
                name="contentTypeIds"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedTypes"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Dimension"
                :options="dimensions"
                name="selectedDimensions"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedDimensions"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Platform"
                :options="socialTags"
                name="selectedPlatforms"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedPlatforms"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Projects"
                :options="userProjects"
                name="selectedProjects"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedProjects"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
          </v-row>
          <div class="actions">
            <v-divider> </v-divider>
            <v-row class="pr-4 pb-0 ma-0">
              <v-col cols="6" class="d-flex align-items-center">
                <v-btn small @click="resetMobileFilters" text> Clear All </v-btn>
              </v-col>
              <v-col class="d-flex justify-end px-0" cols="6">
                <v-btn small class="primary" @click="applyFilters">Apply</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';
import Modal from '@/components/common/Modal';

export default {
  components: {
    BaseInput,
    CustomCheckboxGroup,
    Modal,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('contentKit', ['dimensions', 'socialTags']),
    ...mapGetters('project', ['userProjects']),
    ...mapGetters(['contentKitFilters']),
    anyFilter() {
      return _.get(this.contentKitFilters, 'search', '').length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0
        || _.get(this.contentKitFilters, 'selectedProjects', []).length > 0;
    },
  },
  data() {
    return {
      types: [{ name: 'Photo', id: '1' }, { name: 'Video', id: '2' }, { name: 'Document', id: '3' }],
      sortBy: 'File Name',
      mobileFilters: false,
      mobileSelectedFilters: {
        sortBy: 'name',
        selectedTypes: [],
        selectedDimensions: [],
        selectedPlatforms: [],
        selectedProjects: [],
      },
    };
  },
  methods: {
    ...mapActions('contentKit', ['getDimensions', 'getSocialTags']),
    ...mapActions(['setFilters', 'resetFilters']),
    ...mapActions('project', ['getUserProjects']),
    filtersUpdated() {
      EventBus.$emit('filters-updated');
      this.setFilters(this.contentKitFilters);
    },
    applyFilters() {
      this.setFilters(Object.assign(this.mobileSelectedFilters, this.contentKitFilters));
      this.mobileFilters = false;
    },
    closeFilters() {
      this.mobileSelectedFilters = { ...this.contentKitFilters };
      this.mobileFilters = false;
    },
    resetMobileFilters() {
      this.mobileSelectedFilters = {
        sortBy: 'name',
        selectedTypes: [],
        selectedDimensions: [],
        selectedPlatforms: [],
        selectedProjects: [],
      };
    },
    clearFilters() {
      this.resetFilters({ favoriteItems: this.contentKitFilters.favoriteItems });
    },
  },
  beforeMount() {
    this.getDimensions();
    this.getSocialTags();
  },
  mounted() {
    this.getUserProjects(1);
  },
};
</script>

<style scoped lang="scss">
  .filters, ::v-deep .v-input, ::v-deep .v-label {
    color: black;
    font-family: $fontFamily1;
    font-size: 13px;
    font-weight: 500;
  }
  ::v-deep .theme--light.v-select .v-select__selection--comma {
    color: black;
  }
  .selected {
    color: $secondary1;
  }
  .search-input {
    width: 205px;
  }
  ::v-deep .input-field {
    height: 40px;
  }
  ::v-deep .v-input__slot {
    padding-right: 0 !important;
    padding-left: 8px !important;
  }
  ::v-deep .search-input .v-input__control .v-input__slot {
    border: 1px solid $border2;
    padding-right: 0;
  }
  .type-filter {
    width: 64px;
  }
  .dimension-filter {
    width: 98px;
  }
  .platform-filter {
    width: 87px;
  }
  .clear-filter {
    ::v-deep .v-btn__content {
      font-size: 13px;
    }
  }

</style>
<style lang="scss">
  .asset-filters-modal {
    background-color: white;
    .heading {
      background: white;
      position: fixed;
      z-index: 5;
      font-size: 16px;
      color: black;
      font-weight: 600;
    }
    .filters-list {
      overflow: hidden;
      position: absolute;
      padding: 50px 0;
      .v-input__slot {
        padding-left: 0 !important;
      }
      .input-field-label {
        margin-bottom: 10px;
      }
    }
    .actions {
      bottom: 0;
      position: fixed;
      background: white;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
   .search-input {
     .v-text-field input {
       font-size: 16px;
      }
    }
    .search-padding {
      padding-left: 4px;
    }
    .search-input {
    width: 170px !important;
    }
  }
</style>
