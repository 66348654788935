<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 2H8.95C8.83395 1.43571 8.52691 0.928673 8.08063 0.564359C7.63434 0.200045 7.0761 0.000727262 6.5 0L5.5 0C4.9239 0.000727262 4.36566 0.200045 3.91937 0.564359C3.47309 0.928673 3.16605 1.43571 3.05 2H2.5C1.8372 2.00079 1.20178 2.26444 0.73311 2.73311C0.264441 3.20178 0.000793929 3.8372 0 4.5L0 9.5C0.000793929 10.1628 0.264441 10.7982 0.73311 11.2669C1.20178 11.7356 1.8372 11.9992 2.5 12H9.5C10.1628 11.9992 10.7982 11.7356 11.2669 11.2669C11.7356 10.7982 11.9992 10.1628 12 9.5V4.5C11.9992 3.8372 11.7356 3.20178 11.2669 2.73311C10.7982 2.26444 10.1628 2.00079 9.5 2ZM5.5 1H6.5C6.80913 1.00128 7.11031 1.09804 7.36235 1.27704C7.61438 1.45605 7.80496 1.70855 7.908 2H4.092C4.19504 1.70855 4.38562 1.45605 4.63765 1.27704C4.88969 1.09804 5.19087 1.00128 5.5 1ZM2.5 3H9.5C9.89782 3 10.2794 3.15804 10.5607 3.43934C10.842 3.72064 11 4.10218 11 4.5V6H1V4.5C1 4.10218 1.15804 3.72064 1.43934 3.43934C1.72064 3.15804 2.10218 3 2.5 3ZM9.5 11H2.5C2.10218 11 1.72064 10.842 1.43934 10.5607C1.15804 10.2794 1 9.89782 1 9.5V7H5.5V7.5C5.5 7.63261 5.55268 7.75979 5.64645 7.85355C5.74021 7.94732 5.86739 8 6 8C6.13261 8 6.25979 7.94732 6.35355 7.85355C6.44732 7.75979 6.5 7.63261 6.5 7.5V7H11V9.5C11 9.89782 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89782 11 9.5 11Z" :fill="color"/>
</svg>
</template>

<script>
export default {
  name: 'Brief',
  props: {
    color: {
      type: String,
      default: '#262626',
    },
  },
};
</script>
