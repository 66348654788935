<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card style="border-radius: unset !important" class="card-container">
      <div class="pa-4">
        <div class="modal-header-title">Complete the Order</div>
      </div>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <!-- eslint-disable-next-line -->
        <div class="body-text">Marking this as complete will close out the order. All uploaded assets are available from your asset library.</div>
        <div>
          <!-- checkbox with text -->
          <v-checkbox
            v-model="sendNotification"
            label="Send order completion notification to customer"
            color="#8066FF"
            class="mt-3"
            style="width: fit-content !important"
          ></v-checkbox>
        </div>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          text
          depressed
          color="primaryGray1"
          @click.native="cancel">Cancel</v-btn>
        <v-btn
          depressed
          class="complete-order-button btn-purple"
          @click.native="agree">Complete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CompleteOrderConfirmationModal',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    sendNotification: false,
    options: {
      color: '#fff',
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve({
        sendNotification: this.sendNotification,
        confirm: true,
      });
      this.dialog = false;
    },
    cancel() {
      this.resolve({
        sendNotification: this.sendNotification,
        confirm: false,
      });
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
}
.body-text {
  font-weight: 400;
  color: $charcoalBlack !important;
}
.complete-order-button {
    padding: 0.5rem 1.5rem !important;
}
</style>
