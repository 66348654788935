<template>
  <div>
    <v-container class="content-wrapper pb-0">
      <v-row class="pb-10 ma-auto">
        <v-col cols="12" md="4" lg="4" xl="4" class="pb-0"
        v-if="$route.name === 'Customer Brand Kit' || $route.name === 'Brand Kit'">
          <PageTitle />
        </v-col>
      </v-row>
    </v-container>
    <BrandProfile reference="business" :businessId="businessId" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BrandProfile from '@/views/BrandProfile';
import PageTitle from '@/components/common/PageTitle';

export default {
  name: 'BusinessBrandProfile',
  components: {
    BrandProfile,
    PageTitle,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    businessId() {
      const businessId = this.$route.params.businessId || this.$route.query.businessId;
      if (businessId) {
        return businessId;
      }
      return this.userDetails.currentBusinessId;
    },
  },
};
</script>

<style></style>
