<template>
  <div>
    <tiptap-vuetify
      v-model="contentText"
      :extensions="extensions"
      :autoFocus="true"
      class="notranslate"
    />
    <v-card-actions class="d-flex justify-end pa-3">
      <v-btn @click="$emit('cancel')" text color="primaryGray1">Cancel</v-btn>
      <v-btn class="btn-purple" @click="handleSave">Save</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  BulletList,
  ListItem,
  Italic,
  Underline,
  Paragraph,
  Link,
  Strike,
  OrderedList,
} from 'tiptap-vuetify';

export default {
  components: {
    TiptapVuetify,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mode: 'normal',
      extensions: [
        Bold,
        BulletList,
        ListItem,
        Italic,
        Underline,
        Paragraph,
        Link,
        Strike,
        OrderedList,
      ],
      contentText: '',
    };
  },
  mounted() {
    this.contentText = this.content;
  },
  watch: {
    content() {
      this.contentText = this.content;
    },
  },
  methods: {
    handleSave() {
      this.$emit('save', this.contentText);
    },
  },
};
</script>

<style scoped lang="scss">
.tiptap-vuetify-editor {
    ::v-deep .v-card {
      border-radius: 4px !important;
    }
  }
</style>
