<template>
  <div class="portfolio-container">
    <div class="upload-block-small mb-5"
    v-if="$route.name === 'Profile' && $vuetify.breakpoint.xsOnly">
      <Upload
        v-if="parentTab === 'my_portfolio' && myPortfolioItems.length !== 0"
        id="add-documents"
        class="upload-button text-center ml-2"
        multiple
        mode="justImage"
        @change="uploadPortfolioItems"
        :acceptedFiles="'.jpg,.jpeg,.png,.pdf,.gif,.mp4,.mov,.doc,.docx'"
      >
        <template>
          <img src="@/assets/svg/theme/file-upload.svg" width="20" height="20" />
        </template>
      </Upload>
      <template v-else>
        <div v-if="blendedsenseItems.length !== 0" class="upload-container" @click="isModal = true">
          <div
            id="add-documents"
            class="upload-blendedsense text-center border-none"
            @click="isModal = true"
          >
            <v-row class="d-flex justify-center text-info">
              <v-icon text small color="info">mdi-plus</v-icon>
            </v-row>
          </div>
        </div>
      </template>
    </div>
    <v-select
      v-if="$vuetify.breakpoint.xsOnly"
      solo
      flat
      class="type-selection notranslate tabs-dropdown"
      placeholder="Select..."
      :items="tabsList"
      item-text="name"
      item-value="value"
      v-model="parentTab"
      hide-details
      :menu-props="{ 'content-class': 'notranslate' }"
      append-icon="mdi-chevron-down"
    ></v-select>
    <v-tabs v-else v-model="parentTab" class="portfolioTabs">
      <v-tab class="tab-title" href="#my_portfolio">My Portfolio</v-tab>
      <v-tab class="tab-title" href="#blended_sense">Blended Sense</v-tab>
    </v-tabs>
    <v-tabs-items v-model="parentTab">
      <v-tab-item
        value="my_portfolio"
        key="my_portfolio"
        :transition="false"
        :reverse-transition="false"
      >
        <template v-if="myPortfolioItems.length === 0">
          <div class="blendedsense" v-if="$route.name === 'Profile'">
            <div class="blendedsense-text">
              Uh oh. You don't have any files uploaded to your portfolio.
              <div class="upload-container pt-3">
                <div id="upload-documents" class="upload-blendedsense text-center">
                  <v-row class="d-flex justify-center">
                    <Upload
                      id="empty-upload-documents"
                      class="text-center"
                      multiple
                      mode="justImage"
                      @change="uploadPortfolioItems"
                      :acceptedFiles="'.jpg,.jpeg,.png,.pdf,.gif,.mp4,.mov,.doc,.docx'"
                    >
                      <template>
                        <img src="@/assets/svg/theme/file-upload.svg" width="20" height="20" />
                        <span class="ml-1 add-text h-100">Upload</span>
                      </template>
                    </Upload>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
          <div class="blendedsense" v-else>
            <div class="blendedsense-text">
              No files to preview.
            </div>
          </div>
        </template>
        <template v-else>
          <v-card flat v-if="parentTab === 'my_portfolio'">
            <v-card-text class="px-0">
              <v-select
                v-if="$vuetify.breakpoint.xsOnly"
                solo
                flat
                class="type-selection notranslate tabs-dropdown"
                placeholder="Select..."
                :items="portfolioList"
                item-text="name"
                item-value="value"
                v-model="portfolioChildTab"
                hide-details
                :menu-props="{ 'content-class': 'notranslate' }"
                append-icon="mdi-chevron-down"
              ></v-select>
              <v-tabs v-model="portfolioChildTab" v-else class="portfolio-child-tabs">
                <v-tab v-show="photos.length" class="tab-title subtab" href="#portfolioPhotography">
                  Photography
                </v-tab>
                <v-tab v-show="videos.length" class="tab-title subtab" href="#portfolioVideography">
                  Videography
                </v-tab>
                <v-tab v-show="copy.length" class="tab-title subtab" href="#portfolioCopy">
                  Copywriting
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="portfolioChildTab">
                <v-tab-item
                  key="portfolioPhotography"
                  value="portfolioPhotography"
                  :transition="false"
                  :reverse-transition="false"
                >
                <v-row class="ma-0 pt-2">
                    <v-col cols="6" md="4" lg="3"
                     class="pl-1 pr-1 pt-1 pb-1" v-for="item in photos" :key="item">
                      <div class="masonry-item" v-if="item"
                       :key="item.id" @click="previewItem(item.id)">
                          <img
                            loading="lazy"
                            style="max-height: 150px;object-fit:scale-down;"
                            :src="getOptimizedS3ImageURL(item.file)"
                          />
                          <div class="delete-btn" v-if="$route.name === 'Profile'">
                            <v-btn
                              icon
                              x-small
                              color="black"
                              class="remove-item-icon"
                              v-on:click.stop="deleteItemConfirm(item.id)"
                            >
                              <v-icon size="12">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </div>
                    </v-col>
                  </v-row>
                  <!-- <div class="py-5">
                    <vue-masonry-wall :items="photos" :options="{ width: 250, padding: 12 }">
                      <template v-slot:default="{ item }">
                        <div class="masonry-item" v-if="item" @click="previewItem(item.id)">
                          <img
                            loading="lazy"
                            :src="getOptimizedS3ImageURL(item.file, { width: 250, height: 250 })"
                          />
                          <div class="delete-btn" v-if="$route.name === 'Profile'">
                            <v-btn
                              icon
                              x-small
                              color="black"
                              class="remove-item-icon"
                              v-on:click.stop="deleteItemConfirm(item.id)"
                            >
                              <v-icon size="12">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </template>
                    </vue-masonry-wall>
                  </div> -->
                  <!-- <div
                      @click="previewItem(item.id)"
                      class="portfolio-item"
                    >
                      <PortfolioItem
                        :item="item"
                        :file="item.file"
                        @delete-item="deleteItemConfirm(item.id)"
                      />
                    </div> -->
                  <!-- </v-col> -->
                  <!-- </v-row> -->
                </v-tab-item>
                <v-tab-item
                  value="portfolioVideography"
                  key="portfolioVideography"
                  :transition="false"
                  :reverse-transition="false"
                >
                <v-row class="ma-0 py-2">
                    <v-col cols="6" md="4" lg="3" v-for="item in videos" :key="item">
                        <PortfolioVideoItemThumbnail
                          :item="item"
                          @deleteItemConfirm="deleteItemConfirm(item.id)"
                          @previewItem="(id, cat) => previewItem(id, cat)"
                        />
                    </v-col>
                  </v-row>
                  <!-- <div class="py-5">
                    <vue-masonry-wall :items="videos" :options="{ width: 250, padding: 12 }">
                      <template v-slot:default="{ item }">
                        <PortfolioVideoItemThumbnail
                          :item="item"
                          @deleteItemConfirm="deleteItemConfirm(item.id)"
                          @previewItem="(id, cat) => previewItem(id, cat)"
                        />
                      </template>
                    </vue-masonry-wall>
                  </div> -->
                </v-tab-item>
                <v-tab-item
                  value="portfolioCopy"
                  key="portfolioCopy"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-row class="ma-0 py-2">
                    <v-col cols="6" md="4" lg="3" v-for="item in copy" :key="item.id">
                      <div @click="previewItem(item.id, 'copy')" class="portfolio-item">
                        <PortfolioItem
                          :item="item"
                          :file="item.file"
                          @delete-item="deleteItemConfirm(item.id)"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </template>
      </v-tab-item>
      <v-tab-item
        value="blended_sense"
        key="blended_sense"
        :transition="false"
        :reverse-transition="false"
      >
        <template v-if="blendedsenseItems.length === 0">
          <div class="blendedsense" v-if="$route.name === 'Profile'">
            <div class="blendedsense-text">
              Add to your portfolio from your <br />
              Blended Sense projects
            </div>
            <div class="upload-container">
              <div
                id="upload-documents"
                class="upload-blendedsense text-center"
                @click="isModal = true"
              >
                <v-row class="d-flex justify-center">
                  <v-icon small>mdi-plus</v-icon>
                  <span class="ml-1 add-text h-100">Add</span>
                </v-row>
              </div>
            </div>
          </div>
          <div class="blendedsense" v-else>
            <div class="blendedsense-text">
              No files to preview.
            </div>
          </div>
        </template>
        <template v-else>
          <v-card flat v-if="parentTab === 'blended_sense'">
            <v-card-text class="px-0">
              <v-select
                v-if="$vuetify.breakpoint.xsOnly"
                solo
                flat
                class="type-selection notranslate tabs-dropdown"
                placeholder="Select..."
                :items="blendedSenseList"
                item-text="name"
                item-value="value"
                v-model="blendedsenseChildTab"
                hide-details
                :menu-props="{ 'content-class': 'notranslate' }"
                append-icon="mdi-chevron-down"
              ></v-select>
              <v-tabs v-model="blendedsenseChildTab" v-else class="blendedSense-child-tabs">
                <v-tab
                  v-show="photos.length"
                  class="tab-title subtab"
                  href="#blendedsensePhotography"
                >
                  Photography
                </v-tab>
                <v-tab
                  v-show="videos.length"
                  class="tab-title subtab"
                  href="#blendedsenseVideography"
                >
                  Videography
                </v-tab>
                <v-tab v-show="copy.length" class="tab-title subtab" href="#blendedsenseCopy">
                  Copywriting
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="blendedsenseChildTab">
                <v-tab-item
                  value="blendedsensePhotography"
                  key="blendedsensePhotography"
                  :transition="false"
                  :reverse-transition="false"
                >
                    <v-row class="ma-0 pt-2">
                    <v-col cols="6" md="4" lg="3"
                     class="pl-1 pr-1 pt-1 pb-1" v-for="item in photos" :key="item">
                      <div class="masonry-item" v-if="item"
                      :key="item.id" @click="previewItem(item.id)">
                          <img
                            loading="lazy"
                            style="max-height: 150px;object-fit:scale-down;"
                            :src="getOptimizedS3ImageURL(item.file)"
                          />
                          <div class="delete-btn" v-if="$route.name === 'Profile'">
                            <v-btn
                              icon
                              x-small
                              color="black"
                              class="remove-item-icon"
                              v-on:click.stop="deleteItemConfirm(item.id)"
                            >
                              <v-icon size="12">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </div>
                    </v-col>
                  </v-row>
                    <!-- <vue-masonry-wall :items="photos" :options="{ width: 250, padding: 12 }">
                      <template v-slot:default="{ item }">
                        <v-col
                        class="masonry-item" v-if="item" @click="previewItem(item.id)">
                          <img
                            :src="getOptimizedS3ImageURL(item.file, { width: 250, height: 250 })"
                          />
                          <div class="delete-btn" v-if="$route.name === 'Profile'">
                            <v-btn
                              icon
                              x-small
                              color="black"
                              class="remove-item-icon"
                              v-on:click.stop="deleteItemConfirm(item.id)"
                            >
                              <v-icon size="12">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </template>
                    </vue-masonry-wall> -->
                </v-tab-item>
                <v-tab-item
                  value="blendedsenseVideography"
                  key="blendedsenseVideography"
                  :transition="false"
                  :reverse-transition="false"
                >
                <v-row class="ma-0 py-2">
                    <v-col cols="6" md="4" lg="4" v-for="item in videos" :key="item">
                        <PortfolioVideoItemThumbnail
                          :item="item"
                          @deleteItemConfirm="deleteItemConfirm(item.id)"
                          @previewItem="(id, cat) => previewItem(id, cat)"
                        />
                    </v-col>
                  </v-row>
                  <!-- <div class="py-5">
                    <vue-masonry-wall :items="videos" :options="{ width: 250, padding: 12 }">
                      <template v-slot:default="{ item }">
                        <PortfolioVideoItemThumbnail
                          :item="item"
                          @deleteItemConfirm="deleteItemConfirm(item.id)"
                          @previewItem="(id, cat) => previewItem(id, cat)"
                        />
                      </template>
                    </vue-masonry-wall>
                  </div> -->
                </v-tab-item>
                <v-tab-item
                  value="blendedsenseCopy"
                  key="blendedsenseCopy"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-row class="ma-0 py-2">
                    <v-col cols="6" md="4" lg="3" v-for="item in copy" :key="item.id">
                      <div @click="previewItem(item.id, 'copy')" class="portfolio-item">
                        <PortfolioItem
                          :item="item"
                          :file="item.file"
                          @delete-item="deleteItemConfirm(item.id)"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </template>
      </v-tab-item>
    </v-tabs-items>
    <div class="upload-block" v-if="$route.name === 'Profile' && $vuetify.breakpoint.smAndUp">
      <Upload
        v-if="parentTab === 'my_portfolio' && myPortfolioItems.length !== 0"
        id="add-documents"
        class="upload-button text-center ml-2"
        multiple
        mode="justImage"
        @change="uploadPortfolioItems"
        :acceptedFiles="'.jpg,.jpeg,.png,.pdf,.gif,.mp4,.mov,.doc,.docx'"
      >
        <template>
          <img src="@/assets/svg/theme/file-upload.svg" width="20" height="20" />
          <span class="ml-1 upload-text h-100">Upload</span>
        </template>
      </Upload>
      <template v-else>
        <div v-if="blendedsenseItems.length !== 0" class="upload-container" @click="isModal = true">
          <div
            id="add-documents"
            class="upload-blendedsense text-center border-none"
            @click="isModal = true"
          >
            <v-row class="d-flex justify-center text-info">
              <v-icon text small color="info">mdi-plus</v-icon>
              <span class="ml-1 add-text add-button h-100">Add</span>
            </v-row>
          </div>
        </div>
      </template>
    </div>
    <v-container fluid v-if="previewModal">
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :modal="previewModal"
        content-class="main-modal preview-modal"
      >
        <PortfolioItemPreview
          :prevItemIndex="prevIndex"
          :nextItemIndex="nextIndex"
          :item="currentFile"
          @close-modal="previewModal = false"
          @onNavigate="navigate"
        />
      </Modal>
    </v-container>
    <v-container fluid v-if="showDeleteConfirm">
      <Modal persistent :modal="showDeleteConfirm" width="600">
        <v-card flat class="pa-5 confirm-popup">
          <v-row class="pa-0">
            <v-col>
              Are you sure, you want to delete the item?
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end py-0">
              <v-btn text @click="showDeleteConfirm = false" class="py-0" color="primaryGray1">
                Cancel
              </v-btn>
              <v-btn text type="submit" class="ml-2 info--text" @click="deleteItem()">
                Yes, Delete.
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </Modal>
    </v-container>
    <BlendedSense :openModal="isModal" @closeModal="setModalFalse" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
// import VueMasonryWall from 'vue-masonry-wall';
import axios from 'axios';
import Modal from '@/components/common/Modal';
import Upload from '@/components/common/Upload';
import { getOptimizedS3ImageURL } from '@/helpers/';
import PortfolioItem from './PortfolioItem';
import PortfolioItemPreview from './PortfolioItemPreview';
import BlendedSense from './blendedSense/BlendedSenseModal';
import PortfolioVideoItemThumbnail from './PortfolioVideoItemThumbnail';

const photoFormats = /jpg|jpeg|png|gif/;
const videoFormats = /mp4|mov/;
// other formats includes doc, docs, ...etc;

export default {
  components: {
    Modal,
    PortfolioItem,
    PortfolioItemPreview,
    Upload,
    // VueMasonryWall,
    BlendedSense,
    PortfolioVideoItemThumbnail,
  },
  props: {
    profileId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('profile', ['portfolioItems']),
    parentTab: {
      set(tab) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            tab,
          },
        });
      },
      get() {
        return this.$route.query.tab || 'my_portfolio';
      },
    },
    portfolioChildTab: {
      set(portfolioTab) {
        if (this.parentTab === 'my_portfolio') {
          this.$router.replace({ query: { ...this.$route.query, portfolio: portfolioTab } });
        }
      },
      get() {
        return this.$route.query.portfolio
        || (this.portfolioList.length ? _.get(this.portfolioList[0], 'value') : null);
      },
    },
    blendedsenseChildTab: {
      set(blendedsenseTab) {
        if (this.parentTab === 'blended_sense') {
          this.$router.replace({ query: { ...this.$route.query, blendedsense: blendedsenseTab } });
        }
      },
      get() {
        return this.$route.query.blendedsense
        || (this.blendedSenseList.length ? _.get(this.blendedSenseList[0], 'value') : null);
      },
    },
    tabsList() {
      const tabs = [
        {
          name: 'My Portfolio',
          value: 'my_portfolio',
        },
        {
          name: 'Blended Sense',
          value: 'blended_sense',
        },
      ];
      return tabs;
    },
    portfolioList() {
      const tabs = [];
      if (this.photos.length) {
        tabs.push({
          name: 'Photography',
          value: 'portfolioPhotography',
        });
      }
      if (this.videos.length) {
        tabs.push({
          name: 'Videography',
          value: 'portfolioVideography',
        });
      }
      if (this.copy.length) {
        tabs.push({
          name: 'Copywriting',
          value: 'portfolioCopy',
        });
      }
      return tabs;
    },
    blendedSenseList() {
      const tabs = [];
      if (this.photos.length) {
        tabs.push({
          name: 'Photography',
          value: 'blendedsensePhotography',
        });
      }
      if (this.videos.length) {
        tabs.push({
          name: 'Videography',
          value: 'blendedsenseVideography',
        });
      }
      if (this.copy.length) {
        tabs.push({
          name: 'Copywriting',
          value: 'blendedsenseCopy',
        });
      }
      return tabs;
    },
    photos() {
      if (this.parentTab === 'my_portfolio') {
        return this.myPortfolioItems.filter(
          (item) => item.fileType === '1' && photoFormats.test(item.fileFormat),
        );
      }
      return this.blendedsenseItems.filter(
        (item) => item.fileType === '1' && photoFormats.test(item.fileFormat),
      );
    },
    videos() {
      if (this.parentTab === 'my_portfolio') {
        return this.myPortfolioItems.filter(
          (item) => item.fileType === '2' && videoFormats.test(item.fileFormat),
        );
      }
      return this.blendedsenseItems.filter(
        (item) => item.fileType === '2' && videoFormats.test(item.fileFormat),
      );
    },
    copy() {
      if (this.parentTab === 'my_portfolio') {
        return _.filter(this.myPortfolioItems, ['fileType', '3']);
      }
      return _.filter(this.blendedsenseItems, ['fileType', '3']);
    },
    previewItems() {
      if (this.activeType === 'videos') {
        return _.reject(this.videos, ['fileFormat', 'video/mov']);
      }
      return this[this.activeType];
    },
    itemImage() {
      return (item) => item.smallImage || item.file;
    },
    myPortfolioItems() {
      return _.filter(this.portfolioItems, ['blendedsense', false]);
    },
    blendedsenseItems() {
      return _.map(_.filter(this.portfolioItems, ['blendedsense', true]), (item) => _.extend({}, item.blendedsenseItem, { id: item.id }));
    },
  },
  data() {
    return {
      previewModal: false,
      currentFile: {},
      prevIndex: null,
      nextIndex: null,
      activeType: 'photos',
      showDeleteConfirm: false,
      deleteItemId: null,
      isModal: false,
    };
  },
  methods: {
    ...mapActions(['setUploadingItems', 'resetFilters']),
    ...mapActions('profile', ['getPortfolioItems', 'deletePortfolioItem']),
    getOptimizedS3ImageURL,
    setModalFalse() {
      this.resetFilters();
      this.isModal = false;
    },
    uploadPortfolioItems(files) {
      if (files && files.length) {
        const { contentKitId } = this.$route.params;
        _.forEach(files, (file) => {
          const filedata = file;
          const { CancelToken } = axios;
          const source = CancelToken.source();
          filedata.cancelSource = source;
          this.setUploadingItems(
            Object.assign(
              { url: 'portfolio/upload', mutation: 'profile/PUSH_PORTFOLIO_ITEM' },
              { file: filedata },
              { contentKitId, completed: false, error: null },
            ),
          );
        });
      }
    },
    previewItem(id, type = 'photos') {
      this.activeType = type;
      this.previewModal = true;
      const itemIndex = _.findIndex(this.previewItems, ['id', id]);
      this.currentFile = this.previewItems[itemIndex];
      this.prevIndex = itemIndex - 1;
      this.nextIndex = itemIndex === this.previewItems.length - 1 ? -1 : itemIndex + 1;
    },
    navigate(index) {
      this.currentFile = Object.assign({}, this.previewItems[index]);
      this.prevIndex = index - 1;
      this.nextIndex = index === this.previewItems.length - 1 ? -1 : index + 1;
    },
    deleteItemConfirm(itemId) {
      this.deleteItemId = itemId;
      this.showDeleteConfirm = true;
    },
    deleteItem() {
      this.deletePortfolioItem(this.deleteItemId);
      this.showDeleteConfirm = false;
    },
    updateChildTab(type = 'portfolio') {
      if (this.photos.length) {
        this[`${type}ChildTab`] = 0;
      } else if (this.videos.length) {
        this[`${type}ChildTab`] = 1;
      } else if (this.copy.length) {
        this[`${type}ChildTab`] = 2;
      }
    },
  },
  async mounted() {
    await this.getPortfolioItems(this.profileId);
  },
  updated() {
    const item = this.parentTab === 'my_portfolio' ? 'portfolio' : 'blendedsense';
    switch (this[`${item}ChildTab`]) {
      case 0:
        if (!this.photos.length) this.updateChildTab(item);
        break;
      case 1:
        if (!this.videos.length) this.updateChildTab(item);
        break;
      case 2:
        if (!this.copy.length) this.updateChildTab(item);
        break;
      default:
        break;
    }
  },
};
</script>

<style scoped lang="scss">
.portfolioTabs {
  // ::v-deep .v-tabs-bar {
  //   height: auto;
  //   .v-tabs-slider-wrapper .v-tabs-slider {
  //     width: fit-content;
  //   }
  //   .tab-title {
  //     padding: 3px 14px;
  //     margin-right: 30px;
  //     height: 17px;
  //   }
  // }
  .v-card__text {
    padding-top: 30px;
    overflow-x: hidden !important;
  }
}
// .portfolio-child-tabs, .blendedSense-child-tabs {
// ::v-deep .v-tabs-bar {
//   margin-bottom: 16px;
//   .subtab {
//     margin-right: 20px !important;
//   }
//   .v-tabs-slider {
//     display: none;
//   }
// }
// }
.blendedsense {
  padding-top: 100px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $charcoalBlack;
  .blendedsense-text {
    margin: 0 0 20px;
    font-size: 24px;
  }
  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .add-text {
      font-family: $fontFamily1;
      font-size: 14px;
      letter-spacing: -0.28px;
      color: $charcoalBlack;
      display: flex;
      align-items: center;
    }
  }
}
.text-info {
  align-items: center;
  font-size: 16px;
  .add-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mdi-plus,
  .add-text {
    color: $secondary1 !important;
  }
}
.border-none {
  border: none !important;
  box-shadow: none !important;
}
::v-deep .upload-blendedsense {
  cursor: pointer;
  display: flex;
  width: 89px;
  height: 28px !important;
  border-radius: 19.5px;
  box-shadow: 0 2px 4px 0 rgba(127, 127, 127, 0.5);
  border: solid 2px $secondary2;
  label {
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-radius: 19.5px;
  }
  .mdi-plus {
    color: $charcoalBlack;
  }
}
.portfolio-container {
  position: relative;
}
// .tab-title {
// color: black !important;
// font-family: $fontFamily1;
// font-size: 14px;
// font-weight: normal;
//  border-bottom: 2px solid white;
// &.v-tab--active {
//   border-bottom: 2px solid $primary6;
//   color: $primary6 !important;
//   font-weight: bold;
//   &.subtab {
//     background: $yellowSea;
//     border-radius: 19px;
//     color: white !important;
//   }
// }
// &.subtab {
//   height: 23px;
//   border-bottom: none;
//   padding: 2px 4px;
//   &.v-tab--active {
//     padding: 3px 12px;
//   }
// }
// }
.upload-block {
  position: absolute;
  top: -3px;
  right: 0;
}
.upload-block-small {
  display: flex;
  justify-content: flex-end;
}
.upload-block, .upload-block-small {
  ::v-deep .upload-button {
    width: 89px;
    height: 28px !important;
    border-radius: 19.5px;
    border: solid 2px $secondary2;
    color: $charcoalBlack;
    label {
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-color: white;
      border-radius: 19.5px;
      font-family: $fontFamily1;
    }
  }
}
@media only screen and (max-width: 375px) {
  .blendedsense {
    .blendedsense-text {
      font-size: 20px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .blendedsense {
    padding-top: 50px;
    .blendedsense-text {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 400px) and (orientation: portrait) {
  ::v-deep .v-tab {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
  ::v-deep .upload-button {
    width: 40px !important;
    border-radius: 10px !important;
  }
  ::v-deep .border-none {
    width: 40px !important;
  }
  .text-info .mdi-plus {
    border: 1px solid;
    border-radius: 50%;
  }
}
.masonry-item {
  border-radius: 4px;
  background: #f5f5f5;
  position: relative;
  cursor: pointer;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: block;
    position: relative;
  }
  .delete-btn {
    display: none;
    position: absolute;
    top: 2px;
    right: 6px;
    .remove-item-icon {
      width: 16px;
      height: 16px;
      z-index: 2;
      background-color: #dadada;
      box-shadow: 0 1px 2px 0 rgba(125, 125, 125, 0.5);
    }
  }
  &:hover {
    .delete-btn {
      display: block;
    }
  }
}
.brand-kit-play {
  position: absolute;
  top: 0;
  .play-btn {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
  }
}
</style>
