<template>
    <v-container class="mission">
      <v-row class="flex-column description">
        <v-col
        xl="8"
        offset-xl="2"
        lg="8"
        offset-lg="2"
        md="8"
        offset-md="2"
        xs="8"
        sm="8">
          <base-text-area-field
            maxLength='140'
            outlined
            :autoGrow="true"
            placeholder=
            "What problems or needs are you solving, for who? How are you solving it, and why?"
            name="description1"
            hide-details
            v-model="business.purpose"
            :onInput="truncateText"
          >
          </base-text-area-field>
          <v-row class="justify-end ma-0">
            <span class="count" :key="business.purpose">
              {{getCount}}/140
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  name: 'Mission',
  components: {
    BaseTextAreaField,
  },
  data() {
    return {
    };
  },
  props: {
    business: {
      type: Object,
      required: false,
    },
  },
  validations: {
    business: {
      purpose: {
        maxLength: maxLength(140),
      },
    },
  },
  computed: {
    getCount() {
      const text = this.business.purpose || '';
      return text.length;
    },
  },
  methods: {
    truncateText(value) {
      this.$nextTick(() => {
        this.business.purpose = value.substr(0, 140);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mission {
  color: $neutral1 !important;
  .description {
    ::v-deep ::placeholder {
      font-family: $fontFamily1;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #AEB8BF;
    }
  }
  .count {
    font-family: $fontFamily1;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #8F8F8F;
  }
}
</style>
