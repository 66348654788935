<template>
  <div class='card'>
    <div class="card-body" :id="'card-file-' + section + item.id">
      <div class="card-file d-flex justify-center align-center"
      >
        <div
          :class="{'no-image': !validImage}"
          class="card-image d-flex justify-center align-center"
        >
          <template v-if="item.fileType === '1'">
            <img
              :src="cardImage"
              alt="no-image"
              :class="{'default-image': !validImage}"
            />
          </template>
          <template v-else-if="item.fileType === '2'">
            <div v-if="['mp4', 'mov'].includes(fileFormat)"
              class="card-image d-flex justify-center align-center"
            >
              <img
                :src="poster"
                :class="{'default-image': !item.poster}"
              />
            </div>
            <div class="card-image d-flex justify-center align-center" v-else>
              <img
              :src="cardImage"
              alt="no-image"
              :class="{'default-image': !validImage}"
            />
            </div>
          </template>
          <template v-else>
            <div
              class="long-copy d-flex justify-center align-center w-100 h-100"
            >
              <img v-if="fileFormat === 'pdf'"
                :src="require(`@/assets/svg/theme/pdf.svg`)" class="default-image"
              />
              <img v-else :src="require(`@/assets/svg/theme/doc.svg`)" class="default-image" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
    class="card-name row ma-0">
      <div class="title mb-1 notranslate"> {{item.name}} </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  /* eslint-disable global-require */
  name: 'BaseCard',
  props: {
    activeClass: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
  },
  computed: {
    fileFormat() {
      if (_.split(this.item.fileFormat, '/')[1] === 'x-subrip') {
        return 'srt';
      }
      return _.split(this.item.fileFormat, '/')[1];
    },
    image() {
      return this.item.smallImage || this.item.file;
    },
    validImage() {
      return this.image && this.isFileImage(this.item.fileFormat);
    },
    cardImage() {
      return this.validImage ? this.image : this.defaultImage;
    },
    poster() {
      if (this.item.poster) {
        return this.item.poster;
      }
      if (['mp4', 'mov'].includes(this.fileFormat)) {
        return require('@/assets/svg/theme/mp4.svg');
      }
      return require('@/assets/svg/theme/attach.svg');
    },
    defaultImage() {
      if (this.fileFormat === 'ai') {
        return require('@/assets/svg/theme/ai.svg');
      }
      if (this.fileFormat === 'psd') {
        return require('@/assets/svg/theme/psd.svg');
      }
      if (this.fileFormat === 'srt') {
        return require('@/assets/svg/theme/srt.svg');
      }
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
  },
  methods: {
    isFileImage(format) {
      return format && ['jpg', 'png', 'jpeg', 'gif'].includes(format.split('/')[1]);
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    border: none !important;
    .card-body {
      background: $neutral5;
      height: 140px;
      position: relative;
      .card-info {
        box-shadow: 0 2px 2px 0 $silver;
        width: 90%;
        overflow-y: auto;
        position: absolute;
        right: 5%;
        top: 20%;
        .v-card__title, .v-card__text {
          font-size: 12px !important;
        }
        .v-card__title {
          line-height: normal;
        }
        .name {
          font-weight: normal;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &.hover {
        opacity: 0.5;
      }
      .deliver-status {
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        right: 32px;
        top: 8px;
        z-index: 2;
      }
      .card-image {
        height: 140px;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          &.default-image {
            width: 80px !important;
            height: 80px !important;
          }
        }
        .video-player {
          margin: 0 auto;
          max-width: 100%;
        }
      }
    }
    .card-name {
      font-size: 16px;
      font-weight: bold;
      padding: 15px 8px;
      background-color: white;
      .title {
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 20px !important;
        color: #262626 !important;
        word-break: break-word;
      }
      .title {
        font-family: $fontFamily1 !important;
        font-weight: normal;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .card-info {
    overflow: hidden;
    white-space: nowrap;
    .col-6 {
      padding: 0;
    }
  }
  .v-card {
    border-radius: 0 !important;
  }

  ::v-deep .v-input {
    height: 28px;
    margin: 0 !important;
    padding: 0 !important;
    input {
      font-weight: 400;
      padding-bottom: 4px;
      padding-top: 0;
    }
  }
  ::v-deep .v-dialog {
    margin: 0 !important;
  }
</style>
