<template>
    <v-expansion-panel
        class="sub-deliverable-expansion-panel my-1"
        :class="{ 'has-error': hasError}"
        ref="expansionPanel"
    >
        <div>
            <v-expansion-panel-header
              hide-actions
              @click="toggleExpansion"
            >
            <template v-slot:default="{ open }">
              <v-row class="ma-0">
              <v-col class="d-flex pa-0">
              {{ title }}
              </v-col>
              <v-col class="d-flex justify-end pa-0">
              <v-icon
                @click="removeSweep()"
                v-if="open && state" class="caret-icon">
                mdi-close
              </v-icon>
              <v-icon v-else class="caret-icon">
                mdi-chevron-down
              </v-icon>
              </v-col>
              </v-row>
            </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <div
              v-if="state"
              class="mb-5"
              style="border-bottom: 2px solid #e4e4e4;"></div>
            <div
              class="sub-deliverable-container">
              <div v-if="state" class="sweep-container">
                <v-col
                  class="pa-0 title-col">
                  <label class="input-label">Title*</label>
                  <BaseInput
                    :class="{
                      'errorState v-input--has-state error--text': titleerror.length > 0,
                    }"
                    class="title-input"
                    solo
                    dense
                    flat
                    v-model="sweepDeliverable.title"
                    @input="checkForTitle"
                    @blur="checkForTitle"
                    placeholder
                    name="title"
                  />
                <span v-if="titleerror" class="error-message"> {{ titleerror }} </span>
                </v-col>
                <div
                  class="pt-5"
                  :class="{
                      'd-flex'
                        : $vuetify.breakpoint.smAndUp
                          && $vuetify.breakpoint.mdAndUp}">
                <v-col
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                  xxl="4"
                  class="datepicker pa-0 pr-5">
                  <label class="input-label">Date*</label>
                  <v-row>
                  <v-col class="date-icon-div">
                    <span>
                      <img src="@/assets/svg/theme/calender.svg" />
                    </span>
                  </v-col>
                  <v-col
                    class="datepicker-div">
                  <date-picker
                    solo
                    flat
                    class="date-picker"
                    content-class="date-picker"
                    no-title
                    :initialValue="sweepDeliverable.date"
                    :minValue="currentDate"
                    v-model="sweepDeliverable.date"
                    append-icon=""
                  />
                  </v-col>
                  </v-row>
                </v-col>
                <v-col
                  xs="2"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  xxl="2"
                  class="pa-0 time-col ml-5">
                  <label class="input-label">Time*</label>
                    <TimePicker
                      :timeErr="fromTimeErr"
                      parentComponent="sweepForm"
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                      class="fromTime-input type-selection"
                      :initial="sweepTimeInitial"
                      :format="timeFormat"
                      @change="fromTimeset"
                      :time="sweepDeliverable.fromTime"
                      @onTimeChange="(val) => setSweepTime(val, 'fromTime', 'fromTimeErr')"
                      @onErrorChage="(val) => onError(val)"
                      @blur="onBlur('fromTime', 'fromTimeErr')"
                    />
                </v-col>
                <v-col class="time-to">
                    <div class="time-to-text">-</div>
                  </v-col>
                <v-col
                  xs="2"
                  sm="2"
                  md="2"
                  lg="2"
                  xl="2"
                  xxl="2"
                  class="pa-0 pt-5 pl-2 time-col pb-0">
                    <TimePicker
                        :timeErr="toTimeErr"
                        v-bind:value="sweepDeliverable.toTime"
                        class="toTime-input type-selection"
                        parentComponent="sweepForm"
                        outlined
                        dense
                        :initial="sweepTimeInitial"
                        append-icon="mdi-chevron-down"
                        :format="timeFormat"
                        :time="sweepDeliverable.toTime"
                        name="toTime"
                        :optionsAfter="sweepDeliverable.fromTime"
                        @onTimeChange="(val) => setSweepTime(val, 'toTime', 'toTimeErr')"
                        @onErrorChage="(val) => onError(val)"
                        @blur="onBlur('toTime', 'toTimeErr')"
                    />
                <div
                class="mobile-error error-message pa-0 d-flex pb-1 mr-8"
                :key="timeErrors('fromTime').length > 0">
                  <span class="errorText v-messages__message error--text">
                    {{ timeErrors('fromTime').length > 0
                    || timeErrors('toTime').length > 0
                    ? timeErrors('fromTime') : null }}
                    </span
                  >
                </div>
                </v-col>
                <v-col
                  xs="1"
                  sm="1"
                  md="2"
                  lg="2"
                  xl="1"
                  xxl="1"
                  class="time-zone pa-0 pl-5 pt-1">
                  <v-select
                    :items="getTimezones"
                    v-model="sweepDeliverable.timezone"
                    item-text="timezone"
                    item-value="id"
                    name="timezone"
                    class="type-selection notranslate timezone"
                  >
                  </v-select>
                </v-col>
                </div>
                <v-row class="mt-2 ma-0"
                  v-for="(input, key) in sweepDeliverable.locations"
                  :key="input">
                  <v-col
                    lg="5"
                    xl="6"
                    class="location-input pl-0 pt-0 pb-0">
                      <v-row class="location-row ma-0"
                      >
                      <v-col class="location-div pl-0 pt-1 pb-0">
                      <v-row class="input-label ma-0">
                      <label
                    > Location #{{ key + 1 }}*</label>
                    </v-row>
                      <div
                        class="v-input__control v-input input-field
                          v-input--is-label-active v-input--is-dirty
                          theme--light v-text-field v-text-field--single-line
                          v-text-field--solo v-text-field--solo-flat v-text-field--is-booted
                          v-text-field--enclosed"
                          :class="{
                            'errorState v-input--has-state error--text': locationerror &&
                            !input.location,
                        }"
                          >
                      <div class="v-input__slot">
                        <v-col class="date-icon-div"
                        >
                        <span>
                          <img src="@/assets/svg/theme/location.svg"/>
                        </span>
                        </v-col>
                        <div class="v-text-field__slot">
                            <vue-google-autocomplete
                              :types="['establishment']"
                              :component-restrictions="null"
                              :id="input.key"
                              :key="input.key"
                              placeholder=""
                              v-model="input.location"
                              @input="checkForLocation"
                              @blur="checkForLocation"
                              item-value="location"
                              append-outer-icon="mdi-map-marker"
                              name="locations"
                              class="location-field"
                              v-on:placechanged="(data) => getAddressData(data, input.key)"
                              v-on:inputChange="(data) => setLocation(data, input.key)"
                            >
                            </vue-google-autocomplete>
                        </div>
                      </div>
                    </div>
                  <div class="v-text-field__details pt-2"
                  :class="{
                    'errorState v-input--has-state error--text': locationerror,
                  }"
                  v-if="locationerror && !input.location"
                  >
                  <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                      <div class="v-messages__message error-message">
                        Please provide valid location
                      </div>
                  </div>
              </div>
            </div>
                  </v-col>
                </v-row>
                  </v-col>
                <v-col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  xl="2"
                  xxl="2"
                  class="pt-0 pb-0 location2-input"
                >
                  <label class="input-label">Location Settings</label>
                  <v-select
                    :items="settings"
                    append-icon="mdi-chevron-down"
                    name="settings"
                    v-model="input.settings"
                    class="type-selection mb-n3 notranslate mb-0 settings pt-0"
                  ></v-select>
                </v-col>
                <v-col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  xl="3"
                  xxl="2"
                  class="pt-0 pb-0 datepicker location3-input"
                >
                  <label class="input-label">Zip</label>
                  <v-row class="zipcode-input">
                    <v-col class="date-icon-div"
                  :style="{
                    'border':
                    (locationerror && !input.zipcode)
                      || (/^\d+$/.test(input.zipcode)
                        && input.zipcode.length >= 6
                          && input.zipcode.length <= 10) ? '1px solid red' : '1px solid #E0E0E0'
                  }"
                      style="margin-top: 4px;height: 45px;"
                      >
                      <span>
                        <img src="@/assets/svg/theme/location.svg"/>
                      </span>
                      </v-col>
                    <v-col
                    :class="{
                      'errorState v-input--has-state error--text': (locationerror && !input.zipcode)
                      || (/^\d+$/.test(input.zipcode)
              && input.zipcode.length >= 6 && input.zipcode.length <= 10),
                    }"
                      class="pa-0 pb-2">
                      <BaseInput
                      placeholder
                      name="zipcode"
                      min="0"
                      @input="checkForLocation"
                      @blur="checkForLocation"
                      type="tel"
                      v-model="input.zipcode"
                      class="type-selection notranslate mb-0 settings pt-0"
                    />
                    </v-col>
                  </v-row>
                <div
                style="width: 200px;"
                      class="error-message zipcode-input"
                      v-if="(locationerror && !input.zipcode)
                        || (/^\d+$/.test(input.zipcode)
              && input.zipcode.length >= 6 && input.zipcode.length <= 10)">
                          Please provide valid zipcode
                  </div>
                </v-col>
                <div
                  cols="1"
                  class="cursor-pointer pt-9 pl-4">
                    <v-icon
                        color="darken-1"
                        class="remove-btn"
                        @click="removeLocation(key)"
                        v-show="key || ( !key && sweepDeliverable.locations.length > 1)"
                        >
                          mdi-close
                    </v-icon>
                    </div>
          <div
              class="add-sub-deliverable mt-0"
              v-show="key == sweepDeliverable.locations.length-1"
              @click="addLocation(key)">
              + Add Location
          </div>
      </v-row>
      <v-col class="pa-0 pt-5 description">
        <label class="input-label">Description*</label>
        <BaseTextAreaField
          :class="{
            'errorState v-input--has-state error--text': descriError,
          }"
          filled
          rows="3"
          class="bio-text mb-n5"
          solo
          placeholder="What kind of content do you need captured? Please be descriptive."
          @input="checkForDescription"
          @blur="checkForDescription"
          v-model="sweepDeliverable.notes"
          dense
          flat
        />
      </v-col>
      <span v-if="descriError" class="error-message">{{ descriError }}</span>
      <span v-if="affiliatedBusinesses.length > 0">
      <div class="media-type-container pt-5">
        <div class="media-type">invites & attendees</div>
        <div class="long-line"></div>
        </div>
        <v-col class="pl-0 pr-0 pb-0 affiliated-input">
          <label class="input-label">Affiliated Business</label>
          <AutoCompleteField
            placeholder="Select..."
            append-icon="mdi-chevron-down"
            :items="affiliatedBusinesses"
            multiple
            name="businessIds"
            item-text="name"
            item-value="id"
            :key="sweepDeliverable.businessIds"
            class="type-selection notranslate"
            v-model="sweepDeliverable.businessIds"
          />
        </v-col>
      </span>
      <div class="media-type-container pt-3">
        <div class="media-type">creative team</div>
        <div class="long-line"></div>
        </div>
              </div>
              <!-- :sweepIndex="sweepDeliverable.id"
                :index="index"
                :sweepDeliverable="sweepDeliverable"
                :sweep="sweepDeliverable" -->
              <OrderSubDeliverable
                v-for="(deliverable, index) in subDeliverables"
                :key="index"
                :state="state"
                :index="index"
                :subDeliverableData="deliverable"
                :removeSubDeliverable="() => removeSubDeliverable(index, deliverable)"
                :deliverableData="deliverableData"
                @updateCredits="$emit('updateCredits')"
            />
            <div class="add-sub-deliverable">
              <span @click="addSubDeliverable">{{addCTATitle}}</span>
            </div>
            </div>
            </v-expansion-panel-content>
        </div>
        <CancelOrderDeliverableModel ref="CancelOrderDeliverableModel"/>
        </v-expansion-panel>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import DatePicker from '@/components/common/DatePicker';
import TimePicker from '@/components/common/TimePicker';
import OrderSubDeliverable from './OrderSubDeliverable';
import CancelOrderDeliverableModel from './CancelOrderDeliverableModel';

export default {
  name: 'OrderDeliverable',
  components: {
    OrderSubDeliverable,
    CancelOrderDeliverableModel,
    BaseInput,
    BaseTextAreaField,
    AutoCompleteField,
    DatePicker,
    TimePicker,
    VueGoogleAutocomplete,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    deliverableData: {
      type: Object,
      required: true,
    },
    sweepIndex: {
      type: Number,
    },
    state: {
      type: String,
    },
    sweepDeliverable: {
      type: Object,
    },
  },
  data() {
    return {
      titleerror: '',
      locationerror: false,
      descriError: '',
      affiliatedBusinesses: [],
      settings: [
        { text: 'Indoor', value: '1' },
        { text: 'Outdoor', value: '2' },
        { text: 'Flex', value: '3' },
      ],
      sweepTimeInitial: true,
      timeFormat: 'HH:mm:ss',
      sweepTimeErr: false,
      fromTimeErr: '',
      toTimeErr: '',
      sweep: {},
      open: false,
    };
  },
  computed: {
    ...mapGetters('sweepBlocks', ['getTimezones']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    clients() {
      return ['agency_owner', 'member', 'agency_member', 'client'].includes(this.role);
    },
    currentDate() {
      if (this.clients) {
        return moment().add(7, 'days').format('YYYY-MM-DD');
      }
      return moment().format('YYYY-MM-DD');
    },
    timeErrors() {
      return (field) => {
        let errors = '';
        switch (field) {
          case 'fromTime':
          case 'toTime':
            if (this.sweepDeliverable.fromTime) {
              if (moment(this.sweepDeliverable.fromTime, ['HH:mm']).isAfter(moment(this.sweepDeliverable.toTime, ['HH:mm']))) {
                errors = 'Please select a time that is not in the past';
              }
              if (moment(this.sweepDeliverable.fromTime, ['HH:mm']).isSame(moment(this.sweepDeliverable.toTime, ['HH:mm']))) {
                errors = 'Start time and End time cannot be same.';
              }
            } else if (this.sweepDeliverable.toTime) {
              if (moment(this.sweepDeliverable.toTime, ['HH:mm']).isBefore(moment(this.sweepDeliverable.fromTime, ['HH:mm']))) {
                errors = 'Please select a time that is not in the past';
              }
            }
            break;
          default:
            break;
        }
        this.deliverableData.deliverables.forEach((data) => {
          if (data.sweeperrors) {
            // eslint-disable-next-line
            data.sweeperrors.timeError = errors;
          }
        });
        return errors;
      };
    },
    subDeliverables() {
      return (this.deliverableData.deliverables);
    },
    hasError() {
      const deliverablesWithErrors = this.deliverableData.deliverables.filter(
        (d) => (Object.values(d.errors).filter(Boolean).length > 0),
      );
      return deliverablesWithErrors.length > 0;
    },
    addCTATitle() {
      const creativeType = _.get(this.deliverableData, 'meta.creativeType.name', '');
      if (['Sweeps'].includes(creativeType)) {
        return '+ Add Creative';
      }
      if (['Sweep Add-ons'].includes(creativeType)) {
        return '+ Add Sweep Addon';
      }
      if (['Asset Add-ons'].includes(creativeType)) {
        return '+ Add Addon';
      }
      return '+ Add Deliverable';
    },
  },
  beforeDestroy() {
    EventBus.$off('checkSweepDetails', this.checkSweepDetails);
  },
  methods: {
    ...mapActions('sweepBlocks', ['setTimeZone', 'autoTimeZone']),
    ...mapActions('organizations', ['getAgencyBusinesses']),
    fromTimeset() {
      this.sweepDeliverable.toTime = (moment((this.sweepDeliverable.fromTime), ['HH:mm']).add(120, 'minutes').format('HH:mm'));
      return this.sweepDeliverable.toTime;
    },
    setSweepTime(val, field, fieldErr) {
      this.sweepTimeInitial = false;
      this.sweepDeliverable[field] = val;
      this.onBlur(field, fieldErr);
    },
    onError(val) {
      this.sweepTimeErr = val;
    },
    checkForTitle() {
      this.deliverableData.deliverables.forEach((data) => {
        if (!this.sweepDeliverable.title) {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.titleerror = 'Please provide title';
        } else if (this.sweepDeliverable.title.length > 160) {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.titleerror = 'Title must be less than 160 characters';
        } else {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.titleerror = '';
        }
        this.titleerror = data.sweeperrors.titleerror;
      });
    },
    checkForLocation() {
      const { locations } = this.sweepDeliverable;
      let err = false;
      this.deliverableData.deliverables.forEach((data) => {
        if (locations.length === 0) {
          err = true;
        } else {
          err = false;
          _.map(locations, async (item) => {
            if (item.zipcode) {
              // eslint-disable-next-line
              item.zipcode = item.zipcode.toUpperCase().replace(/[^0-9A-Z]/g, '');
            }
            if (item.zipcode.length === 5) {
              const result = await this.autoTimeZone({
                zipcode: item.zipcode,
              });
              if (result.success) {
                this.sweepDeliverable.timezone = result.data.id;
              }
            }
            if (!item.location || !item.zipcode
              || (/^\d+$/.test(item.zipcode)
              && item.zipcode.length >= 6 && item.zipcode.length <= 10)) {
              err = true;
            }
          });
        }
        if (err) {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.locationerror = 'Please provide valid location';
        } else {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.locationerror = '';
        }
        this.locationerror = err;
      });
    },
    checkForDescription() {
      this.deliverableData.deliverables.forEach((data) => {
        if (!this.sweepDeliverable.notes) {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.descriError = 'Please provide description';
        } else {
          // eslint-disable-next-line no-param-reassign
          data.sweeperrors.descriError = '';
        }
        this.descriError = data.sweeperrors.descriError;
      });
    },
    async checkSweepDetails() {
      this.checkForDescription();
      this.checkForLocation();
      this.checkForTitle();
    },
    async getAddressData(location, key) {
      // const placeset = new window.google.maps.places.Placeservice(document.createElement('div'));
      // console.log('afda', placeset);
      const loc = this.sweepDeliverable.locations.find((l) => l.key === key);
      if (loc && location.postal_code) {
        loc.zipcode = location.postal_code;
        // this.deliverableData.deliverables.forEach((data) => {
        //   if (loc.zipcode.length !== 5) {
        //     // eslint-disable-next-line
        //     data.sweeperrors.locationerror = 'Please provide location error';
        //   } else {
        //     // eslint-disable-next-line
        //     data.sweeperrors.locationerror = '';
        //   }
        // });
        const result = await this.autoTimeZone({
          zipcode: loc.zipcode,
        });
        if (result.success) {
          this.sweepDeliverable.timezone = result.data.id;
        }
      }
      this.sweepDeliverable.locations = [...this.sweepDeliverable.locations];
    },
    async setLocation(location, key) {
      const loc = this.sweepDeliverable.locations.find((l) => l.key === key);
      if (loc) {
        loc.location = location.newVal.trim();
      }
      this.sweepDeliverable.locations = [...this.sweepDeliverable.locations];
    },
    addLocation() {
      this.sweepDeliverable.locations.push({
        key: `location-${Date.now()}`,
        location: '',
        settings: '1',
        latitude: '',
        longitude: '',
        zipcode: '',
      });
    },
    removeLocation(index, key) {
      this.sweepDeliverable.locations.splice(index, 1);
      this.locations = this.locations.filter((loc) => loc.key !== key);
    },
    removeSweep() {
      this.deliverableData.deliverables = [];
      this.sweepTimeErr = false;
      this.fromTimeErr = '';
      this.toTimeErr = '';
      this.titleerror = '';
      this.locationerror = false;
      this.descriError = '';
      // this.$emit('removeSweep');
      this.sweepDeliverable.title = '';
      // eslint-disable-next-line
      this.sweepDeliverable.date = moment().add(7, 'days').format('YYYY-MM-DD');
      this.sweepDeliverable.fromTime = '08:00';
      this.sweepDeliverable.toTime = '10:00';
      this.sweepDeliverable.timezone = 1;
      this.sweepDeliverable.locations = [{
        key: `location-${Date.now()}`,
        settings: '1',
        location: '',
        zipcode: '',
      }];
      this.sweepDeliverable.notes = '';
      this.sweepDeliverable.businessIds = [];
      this.$emit('updateCredits');
    },
    addSubDeliverable() {
      this.$emit('addSubDeliverable');
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    async removeSubDeliverable(index, val) {
      if (val.sweepId) {
        const confirmModal = this.$refs.CancelOrderDeliverableModel;
        const { confirm } = await confirmModal.open();
        if (confirm) {
          this.deliverableData.deliverables.splice(index, 1);
          this.$forceUpdate();
          if (this.state) {
            this.$emit('updateCredits');
            this.$emit('updateSweepDetails');
          }
        }
      }
      if (!val.sweepId) {
        this.deliverableData.deliverables.splice(index, 1);
        this.$forceUpdate();
      }
    },
    toggleExpansion(e) {
      // eslint-disable-next-line
      const expansionPanel = this.$refs.expansionPanel;
      if (!expansionPanel.isActive && this.state) {
        this.deliverableData.deliverables.push({
          deliverableId: this.deliverableData.options[0].id,
          creativeType: this.deliverableData.meta.creativeType,
          category: this.deliverableData.meta.category,
          creditCost: this.deliverableData.options[0].creditCost,
          quantity: 2,
          subId: this.sweepDeliverable.subId,
          dimensions: [],
          locations: 1,
          errors: {
            deliverableType: null,
          },
          sweeperrors: {
            titleerror: '',
            locationerror: '',
            timeError: '',
            descriError: '',
          },
        });
        this.$emit('updateCredits');
      }
      if (this.subDeliverables.length > 0 && expansionPanel.isActive) {
        e.stopPropagation();
        e.preventDefault();
        expansionPanel.toggle();
      }
      if (this.state && this.subDeliverables.length > 0 && expansionPanel.isActive) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
  },
  async mounted() {
    // eslint-disable-next-line
    EventBus.$on('checkSweepDetails', this.checkSweepDetails);
    // EventBus.$on('checkForLocation', this.checkForLocation);
    // EventBus.$on('checkForTitle', this.checkForTitle);
    this.setTimeZone();
    if (this.clients) {
      this.sweepDeliverable.date = moment().add(7, 'days').format('YYYY-MM-DD');
    }
    if (this.state) {
      // this.deliverableData.deliverables.push({
      //   deliverableId: this.deliverableData.options[0].id,
      //   creativeType: this.deliverableData.meta.creativeType,
      //   category: this.deliverableData.meta.category,
      //   creditCost: this.deliverableData.options[0].creditCost,
      //   quantity: 2,
      //   subId: this.sweepDeliverable.subId,
      //   dimensions: [],
      //   locations: 1,
      //   errors: {
      //     deliverableType: null,
      //   },
      //   sweeperrors: {
      //     titleerror: '',
      //     locationerror: '',
      //     timeError: '',
      //     descriError: '',
      //   },
      // });
      // this.$emit('updateCredits');
      const { businesses } = await this.getAgencyBusinesses({
        businessId: this.$route.params.businessId
          ? this.$route.params.businessId : this.$route.query.businessId,
        status: 1,
      });
      this.affiliatedBusinesses = _.map(businesses, (business) => ({
        name: business.name,
        id: business.id,
      }));
    }
    // do stuff here
  },
};
</script>
<style lang="scss" scoped>
  .sub-deliverable-expansion-panel {
    border: 1px solid #e5e8ed;
    box-shadow: none !important;
    .error-message {
      color: red;
      font-size: 12px;
    }
    .sweep-container {
      .media-type-container {
        display: flex;
        align-items: center;
        .media-type {
          font-weight: 600;
          color: #bdbdbd;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          position: relative !important;
          width: fit-content;
          text-transform: uppercase;
        }
        .long-line {
          flex: 1;
          margin-left: 1rem;
          height: 2px;
          background: #e0e0e0;
        }
      }
      .affiliated-input {
        .type-selection {
          margin-bottom: 0px !important;
        }
        .v-text-field__details {
          display: none !important;
        }
      }
      .time-to {
        padding-top: 28px !important;
        max-width: 0px !important;
        .time-to-text {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color:#262626;
        }
      }
      .time-col {
        ::v-deep.v-text-field__details {
          display: none !important;
        }
  ::v-deep.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) >
  .v-input__control > .v-input__slot fieldset {
    color: #E0E0E0 !important;
    border: none;
  }
}
      .remove-btn {
        width: 20px;
        padding-bottom: 0px !important;
      }
      .addlocation-col {
        min-width:550px;
      }
      .time-zone {
    padding: 0px;
    max-width: 80px;
    ::v-deep.theme--light.v-select .v-select__selection--comma {
      color: black !important;
    }
    ::v-deep .type-selection {
      font-size: 16px;
      font-weight: 600;
      .v-input__icon {
        display: none;
      }
      .v-select__selections {
        border-bottom: 2px dashed #8066FF !important;
        max-width: 45px;
      }
      .v-input__slot {
        background-color: #f9f9f9;
        border: none !important;
        border-radius: 3px;
        margin-top: 6px;
      }
      .v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none !important;
      }
    }
}
      ::v-deep {
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-color:white !important;
    }
    .v-text-field > .v-input__control > .v-input__slot:before {
      border-style: none !important;
    }
    .v-text-field > .v-input__control >
    .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
      content: none !important;
    }
    .v-text-field {
      padding-top: 0px !important;
    }
    .creative-div {
      .error--text {
        .v-input__slot {
          border: 1px solid red !important;
        }
      }
    }
    .type-selection {
    .v-input__slot {
      background-color: white;
      border: solid 1px #e6e8ed;
      padding:5px;
      border-radius: 3px;
      height: 45px;
    }
  }
  .error--text {
  line-height: 12px;
  font-size: 12px !important;
  }
  // .v-text-field--outlined.v-input--dense.v-text-field--outlined
  // > .v-input__control > .v-input__slot {
  //   min-height: 44px !important;
  // }
    .v-input__slot {
      border: solid 1px #e6e8ed;
      padding:5px;
      padding-left: 0px;
      border-radius: 3px;
    }
  }
      .location-input {
        .date-icon-div {
          background-color: #F5F5F5;
          border-right: 1px solid #E0E0E0;
          max-width:40px;
          height: 42px;
          border-radius: 3px 0px 0px 3px;
        }
      }
      .location-input {
        align-items: center;
        padding-right: 0px;
        padding-left:20px;
        padding-top: 5px;
        margin-bottom: 5px;
        ::v-deep .errorState .v-input .v-input__control .v-input__slot {
          border: solid 1px transparent !important;
        }
      ::v-deep.error--text {
        .v-input__slot {
          border: 1px solid red !important;
        }
      }
  .v-input__slot {
    background-color: white;
    min-height: 44px !important;
    border: 1px solid #E0E0E0!important;
    ::v-deep.v-text-field__slot {
      padding-left: 5px;
      width: 500px !important;
    }
  }
  .v-input__control {
    max-height: 45px !important;
    max-width: 100%;
  }
  .close {
    padding-left: 5px;
    font-size: 1.4375rem;
  }
}
      @media (max-width: 600px) {
        .add-sub-deliverable {
          padding-top: 5px !important;
        }
        .location3-input {
          min-width: 250px !important;
        }
        .location2-input, .location3-input {
          padding-left: 1px !important;
        }
        .time-to {
          padding-top: 0px !important;
        }
        .time-col {
          margin-left: 0px !important;
          padding: 0px !important;
        }
        .time-zone {
          padding-left: 0px !important;
        }
        .timezone {
          width: 100px !important;
        }
      }
      .input-label {
        font-size: 14px;
        font-weight: 500;
      }
      .zipcode-input {
        // width: 250px;
        height: 50px;
        ::v-deep .errorState .v-input .v-input__control .v-input__slot {
          border: solid 1px #ff0808 !important;
          border-left: 1px solid #E0E0E0 !important;
        }
      }
      .description {
        ::placeholder {
          font-style: italic !important;
        }
      }
      .title-col, .description {
      ::v-deep .errorState .v-input .v-input__control .v-input__slot {
          border: solid 1px #ff0808 !important;
        }
      }
      .title-input {
        ::v-deep.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
          border: 1px solid #E0E0E0;
          height: 40px !important;
        }
        ::v-deep.v-text-field__details {
          display: none !important;
        }
        ::v-deep.v-text-field {
          height: 45px !important;
        }
      }
      .datepicker {
      // max-width: 220px;
      padding-right: 10px !important;
      ::v-deep.v-text-field .v-input__slot {
          border: 1px solid #E0E0E0;
          margin-left: -1px !important;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
      }
      ::v-deep.v-input__slot {
        min-height: 44px !important;
        // max-width: 170px;
      }
      .date-icon-div {
        background-color: #F5F5F5;
        border: 1px solid #E0E0E0;
        max-width:40px;
        height: 44px;
        margin-left:10px;
        border-radius: 3px 0px 0px 3px;
      }
      .datepicker-div {
        padding: 0px;
      }
      ::v-deep .v-picker--date {
        border-radius: 4px!important;
      }
    }
    }
    .v-expansion-panel-header {
      font-weight: 400;
      font-size: 16px;
      color: #383838;
    }
    &.v-expansion-panel--active{
        background: #f9f9f9;
      .border-sweep {
        border-bottom: 2px solid #e4e4e4;
        margin-right: 22px;
        margin-left: 22px;
      }
    }
    &.has-error {
      border-color: tomato;
    }
    &:last-child {
      margin-bottom: 33px !important;
    }
  }
  .add-sub-deliverable {
    margin-top: 1.5rem;
    color: $secondary1;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  .v-expansion-panel-header--active{
    .caret-icon {
      transform: rotate(180deg) !important;
    }
    .prevent-close {
      pointer-events: none;
    }
  }
  .v-expansion-panel-header:before{
    background-color: unset !important;
  }
</style>
