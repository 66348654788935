<!-- eslint-disable max-len -->
<template>
  <v-container class="pl-0 upcoming-container pt-0 pr-0">
    <v-col class="pl-0 pr-0">
    <v-row
    class="upcoming">
      <v-col v-if="!creative" class="text-center date-div">
        <p class="month">
          {{month(item.date)}}
        </p>
        <p class="date2 d-flex justify-center">
          {{date2(item.date)}}
        </p>
      </v-col>
      <v-col class="pt-0 upcoming-col">
        <v-row>
          <v-col v-if="!creative" class="d-flex align-center pa-0 pl-5">
            <div
              class="cursor-pointer font-h4 notranslate title-div pt-3 pb-2"
              @click="goToSweepDetails(item.id)">{{item.title}}</div>
          </v-col>
          <v-col v-if="creative" class="d-flex align-center pa-0 pl-5">
            <div
              @click="goToSweepDetails2(item.sweep.id)"
              class="cursor-pointer font-h4 notranslate title-div pt-3 pb-2">
              {{item.sweep.title}}</div>
          </v-col>
          <div class="mr-2 mt-2" v-if="creative">
            <v-menu offset-y left content-class="admin-menu">
              <template v-slot:activator="{ on, attrs }">
            <div
              color="info"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <span>
              <img :src="clockIconCheck" width="16" class="event-icon" />
            </span>
              <v-icon color="#09BA51" style="padding-bottom: 10px; padding-left: 2px;" size="20">
                mdi-menu-down
              </v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item
              :key="'decline'"
              class="decline"
              @click="updateInvitation('0', true)"
            >
              <v-list-item-title>Decline</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </div>
        </v-row>
        <span v-if="creative">
        <v-col class="pb-0 pt-0 d-flex no-wrap">
          <Calender2 width="18" height="22" />
          <div class="ml-4 date-span">
          {{date(item.sweep.date)}}
          </div>
        </v-col>
        <v-col class="d-flex pt-2 pb-2">
              <span class="font-body">
              <Clock width="18" height="18" />
              <span class="ml-3">
                {{time(item.sweep.fromTime)}} - {{time(item.sweep.toTime)}}
              <span class="font-label duration pl-4">
                {{ duration }}
              </span></span
              >
              </span>
        </v-col>
        <v-row v-if="creative" class="ml-3" style="flex-wrap: inherit ;"
        >
          <MapMarker width="20" height="18"/>
            <span class="pl-1 ml-2" style="word-break: break-all;width: fit-content;"
            v-for="(location) in sweepLocation"
            :key="location.id">
            <div style="width: 80%;min-width: fit-content; ">
            <a class="mb-0 a-link" style="color: black;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0 pt-0"
              v-if="item.sweep.sweepLocations.length !== 1">
              +{{item.sweep.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
        </v-row>
        <v-col class="pt-2 d-flex no-wrap" v-if="item.sweep.sweepBusiness.length > 1 && creative">
          <Business width="18" height="18" style="margin-top: 2px;"/>
          <div v-for="(business, index) in item.sweep.sweepBusiness" :key="business" class="ml-2">
              <div v-if="business.business.id !== item.sweep.business.id">
              {{
                `${business.business.name}${Object.keys(item.sweepBusiness).length - 1 !== index ? ',' : ''}`
              }}
              </div>
          </div>
        </v-col>
        </span>
        <v-col v-if="!creative" class="item d-flex no-wrap">
          <Calender width="18" height="22" />
          <div class="ml-4 date-span">
          {{date3(item.date)}}, {{time(item.fromTime)}} - {{time(item.toTime)}}
          </div>
        </v-col>
        <v-row v-if="!creative" class="item pl-4" style="flex-wrap: inherit ;"
        >
          <MapMarker width="32" height="18"/>
            <span class="pl-1 ml-1" style="word-break: break-all;width: fit-content;"
            v-for="(location) in sweepLocation2"
            :key="location.id">
            <div style="width: 80%;min-width: fit-content; ">
            <a class="mb-0 a-link" style="color: black;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0 pt-0"
              v-if="item.sweepLocations.length !== 1">
              +{{item.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
        </v-row>
        <span v-if="!creative">
        <v-col class="item d-flex no-wrap" v-if="item.sweepBusiness.length > 1">
          <Business width="18" height="18" style="margin-top: 2px;"/>
          <div v-for="(business, index) in item.sweepBusiness" :key="business" class="pl-2">
              <div v-if="business.business.id !== item.business.id">
              {{
                `${business.business.name}${Object.keys(item.sweepBusiness).length - 1 !== index ? ',' : ''}`
              }}
              </div>
          </div>
        </v-col>
        <v-col v-if="item.orderId" class="item d-flex no-wrap">
          <Group width="18" height="18" />
          <div
            v-if="!clients && !editor"
            @click="gotoOrder(item.orderId)"
            class="ml-3 order-button">Order #{{ item.orderId }}</div>
            <div
            v-else-if="clients"
            @click="gotoOrder2(item.orderId)"
            class="ml-3 order-button">Order #{{ item.orderId }}</div>
            <div
            v-else
            class="ml-3">Order #{{ item.orderId }}</div>
        </v-col>
        <v-row v-if="item.orderId" class="item ma-0">
          <Broom width="18" height="18" />
          <span class="ml-3" v-for="(order, index) in sweepDeliverables" :key="order">
            <div :class="index !== 0 ? 'ml-4' : ''">
            {{ `${index === 0 ? `${order.deliverable.category.name} :` : ''}
            ${order.deliverable.name}
             (${order.deliverable.creditCost} credits)
             ${Object.keys(sweepDeliverables).length - 1 !== index ? ',' : ''}` }}
            </div>
          </span>
        </v-row>
        </span>
      </v-col>
    </v-row>
    </v-col>
    <template v-if="showInvitationPopup" class="pa-0">
        <Modal :modal="showInvitationPopup" width="600" persistent>
        <InvitationDialog
            :invitationStatus="invitationStatus"
            :creative="item.creatives"
            @submit-invitation="submitInvitationForm"
            @close-invitation-dialog="showInvitationPopup=false"
            :askForReason="askReason"
        >
        </InvitationDialog>
      </Modal>
    </template>
    <div v-if="showOrderModal">
      <OrderForm
        :open="showOrderModal"
        @close="closeCreateOrderModal"
        :orderId="orderToEdit"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import OrderForm from '@/components/orders/OrderForm';
import Calender from '@/components/common/svg/Calender';
import MapMarker from '@/components/common/svg/MapMarker';
import Business from '@/components/common/svg/Business';
import Group from '@/components/common/svg/Group';
import Broom from '@/components/common/svg/Broom';
import Clock from '@/components/common/svg/Clock';
import Calender2 from '@/components/common/svg/Calender2';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
import Modal from '@/components/common/Modal';

export default {
  name: 'UpcomingSweeps',
  components: {
    OrderForm,
    Calender,
    MapMarker,
    Business,
    Group,
    Broom,
    Clock,
    Calender2,
    Modal,
    InvitationDialog,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    declined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOrderModal: false,
      invitationStatus: '',
      askReason: true,
      showInvitationPopup: false,
      deleteDialog: false,
      showConfirm: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    clockIconCheck() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/calendar-check.svg');
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    clients() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    editor() {
      return ['editor'].includes(this.role);
    },
    creative() {
      return this.role === 'creative';
    },
    // clients() {
    //   return ['client', 'agency_owner'].includes(this.role);
    // },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepLocation() {
      return this.item.sweep.sweepLocations.slice(0, 1);
    },
    sweepLocation2() {
      return this.item.sweepLocations.slice(0, 1);
    },
    sweepDeliverables() {
      const sweeps = this.item.orderDeliverable.filter((data) => data.deliverable.creativeType.name === 'Sweeps');
      return sweeps;
    },
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `(${rminutes} minutes)` : ''}`;
        const checkForHours = `${rhours > 0 ? `(${rhours} hours)` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `(${rhours} hours and ${rminutes} minutes)` : checkForHours;
        return text;
      };
    },
    duration() {
      const { item } = this;
      const startTime = moment(item.sweep.fromTime, 'hh:mm a');
      const endTime = moment(item.sweep.toTime, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
  },
  methods: {
    ...mapActions('sweep', ['updateInvitationById']),
    ...mapActions('orders', ['getOrderInfo']),
    async gotoOrder(val) {
      await this.getOrderInfo({
        orderId: val,
      });
      // find order from orders
      this.orderToEdit = val;
      this.showOrderModal = true;
    },
    closeCreateOrderModal() {
      this.orderToEdit = {};
      this.showOrderModal = false;
    },
    gotoOrder2(val) {
      this.$router.push(`/orders/${val}?orderId=${val}`);
    },
    goToSweepDetails(val) {
      const sweepId = val;
      const { businessId } = this.item;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          businessId,
          sweepId,
        },
      });
    },
    goToSweepDetails2(val) {
      const sweepId = val;
      return this.$router.push({
        name: 'Business Sweep Details2',
        params: {
          sweepId,
        },
      });
    },
    updateInvitation(status, needReason) {
      this.invitationStatus = status;
      this.askReason = needReason;
      this.showInvitationPopup = true;
    },
    async submitInvitationForm(reasonId) {
      this.loading = true;
      const result = await this.updateInvitationById({
        reasonId,
        status: this.invitationStatus,
        sweepId: this.item.sweep.id,
      });
      if (result) {
        this.showInvitationPopup = false;
        this.$emit('list-update');
      }
    },
    date() {
      return moment(this.item.sweep.date).format('MMMM Do, YYYY');
    },
    date3() {
      return moment(this.item.date).format('MMMM Do, YYYY');
    },
    month() {
      return moment(this.item.date).format('MMM');
    },
    date2() {
      return moment(this.item.date).format('D');
    },
  },
};
</script>

<style lang="scss">
.order-button {
  cursor: pointer;
  &:hover {
    color: #8066FF;
  }
}
.upcoming {
  margin: 0px;
  color: black;
  border:1px solid #DEDEDE;
  border-radius: 10px;
  .upcoming-col {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
  .more-location {
    font-weight: 400px;
    line-height: 24px;
    color: #929292;
  }
}
.upcoming-container {
  .no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2rem;
    color: $charcoalBlack;
    height: 74%;
    display: flex;
    align-items: center;
  }
  .duration {
    font-weight: 400;
    color: #adadad;
    text-transform: lowercase;
  }
  .title-div {
    word-break: break-all;
    width: 100% !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: 0px) and (max-width: 600px) {
    .upcoming {
      flex-wrap: nowrap;
      .title-div {
        width: 90% !important;
      }
      .item {
        width: 100% !important;
        .date-span {
          width: 200px;
          text-transform: lowercase;
          white-space: break-spaces;
        }
      }
    }
  }
}
.item {
  padding: 0px 10px 10px;
  .date-span {
    text-transform: lowercase;
    white-space: nowrap;
  }
}
.upcoming-title {
  padding-left: 0px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 15px;
}
.upcoming-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #929292;
}
.date-div {
  max-width: 50px;
  margin-top: 10px;
  padding-top: 0px;
  margin-bottom: 10px;
  border-right: 1px solid #D8D8D8;
  color: #929292;
  font-weight: 600;
  .month {
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 18px;
  }
  .date2 {
    justify-content: center;
    font-size: 20px;
    line-height: 26px;
  }
}
</style>
