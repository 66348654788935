<template>
  <div class="py-2 px-6 bulk-action-popup">
    <v-row>
      <v-col cols=12 class="d-flex justify-end pa-0 pt-2">
        <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-row class="my-0 py-0 header">
      <v-col cols=12 class="align-center py-0" :class="{release: action === 'release'}">
        <div class="main-title mb-2" v-if="action === 'assign deliverable'">
          <span class="text-capitalize"> Assign </span>
          {{count}} {{count > 1 ? 'Files to deliverable?' : 'File to deliverable?'}}
        </div>
        <div class="main-title mb-2" v-else>
          <span class="text-capitalize"> {{action}} </span>
          {{count}} {{count > 1 ? 'Files?' : 'File?'}}
        </div>
        <div class="sub-title" v-if="action === 'release'">
          Released files will be delivered to the client.
        </div>
      </v-col>
    </v-row>
    <v-row v-if="action === 'assign'">
      <v-col cols=8>
        <v-select
          :items="assignItems"
          solo
          flat
          value="Copy"
          disabled
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-else-if="action === 'move'">
        <v-col cols=8 v-if="contentKits.length >= 1">
          <v-select
            :items="contentKits"
            item-text="name"
            item-value="id"
            solo
            flat
            v-model="selectedContentKit"
          ></v-select>
        </v-col>
        <v-col v-else>
          No other content kits available
        </v-col>
    </v-row>
    <v-row v-else-if="action === 'assign deliverable'">
        <v-col cols=8 v-if="deliverables.length >= 1">
          <v-select
            label="Select Deliverable"
            :items="deliverables"
            :item-text="text"
            item-value="id"
            solo
            flat
            v-model="selectedDeliverable"
          ></v-select>
        </v-col>
        <v-col v-else>
          No deliverables available.
        </v-col>
    </v-row>
    <v-row v-else-if="action === 'bulk edit'">
      <v-col cols="12" class="bulkActions">
        <div class="d-flex justify-space-between">
          <label class="text-left input-field-label"> Creatives </label>
          <v-switch
            inset
            dense
            hide-details
            :light=true
            v-model="isCreatives"
          >
          </v-switch>
        </div>
        <v-select
          :items="creatives"
          item-value="id"
          item-text="name"
          multiple=""
          solo
          flat
          v-model="userIds"
          hide-details=""
          class="creatives-selection"
          :menu-props="{'content-class' : 'creatives-dropdown'}"
        >
          <template v-slot:selection="{ item }">
            <v-chip small color="#e5e7fa" class="creative-chip" label>
              <span>{{ item.name }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols=12>
        <div class="d-flex justify-space-between">
          <label class="text-left input-field-label"> Notes </label>
          <v-switch
            inset
            dense
            hide-details
            :light=true
            v-model="isNotes"
          >
          </v-switch>
        </div>
        <base-text-area-field
          :rows="5"
          name="Notes"
          v-model.trim="notes"
          class="notes-field"
          hide-details
          solo
          dense
          mode="edit"
        >
        </base-text-area-field>
      </v-col>
      <v-col cols=12>
        <span class="input-field-label hint">
          * Saving these changes will override existing details.
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end pt-6">
        <v-btn text
          @click="$emit('close-modal')"
          class="py-0 text-uppercase"
          color="primaryGray1"
        >
            Cancel
        </v-btn>
        <v-btn text color="info"
          type="submit"
          class="pa-0 ml-2 text-uppercase"
          @click="$emit('confirm', action === 'assign deliverable'
          ? selectedDeliverable : selectedContentKit, userIds, notes, isCreatives, isNotes)"
          :disabled="isDisabled"
        >
          {{action === 'bulk edit' ? 'Save' : action}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import linkify from 'vue-linkify';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  components: {
    BaseTextAreaField,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    contentKits: {
      type: Array,
      default: () => [],
    },
    deliverables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return ({
      isCreatives: true,
      isNotes: true,
      userIds: [],
      notes: '',
      assignItems: ['Copy'],
      selectedContentKit: _.get(this.contentKits, '[0].id', null),
      selectedDeliverable: _.get(this.deliverables, '[0].id', null),
    });
  },
  computed: {
    ...mapGetters('project', ['producersTeam', 'creativeTeam', 'executiveTeam', 'currentProject']),
    isDisabled() {
      return (this.action === 'move' && this.contentKits.length === 0) || (this.action === 'assign deliverable' && this.deliverables.length === 0) || (this.action === 'bulk edit' && this.isCreatives === false && this.isNotes === false) || (this.action === 'bulk edit' && ((this.isCreatives && this.userIds.length === 0) || (this.isNotes && this.notes.trim() === '')));
    },
    creatives() {
      return _.map(this.projectTeam, (creative) => ({ id: creative.id, name: `${creative.firstName} ${creative.lastName}` }));
    },
    projectTeam() {
      return [
        ..._.chain(this.producersTeam)
          .filter((producer) => producer.accepted === '1')
          .map((producer) => producer.user)
          .value(),
        ..._.chain(this.creativeTeam)
          .filter((creative) => creative.accepted === '1')
          .map((creative) => creative.user)
          .value(),
        ..._.chain(this.executiveTeam)
          .filter((executive) => executive.accepted === '1')
          .map((executive) => executive.user)
          .value(),
      ];
    },
  },
  methods: {
    text(item) {
      if (item.deliverable.category) {
        return `${item.deliverable.category.name} (${item.deliverable.name})`;
      }
      return item.deliverable.name;
    },
  },
};
</script>

<style lang="scss" scoped>
  .header {
    font-size: 18px;
    letter-spacing: -0.18px !important;
    .release {
      .main-title{
        font-family: $fontFamily1 !important;
        font-size: 24px !important;
        letter-spacing: -0.18px !important;
      }
      .sub-title {
        font-size: 14px;
        letter-spacing: -0.11px !important;
      }
    }
  }
  .bulk-action-popup {
    background-color: #fbfbfb;
    font-family: $fontFamily1 !important;
    .hint {
      font-style: italic;
    }
    .notes-field.input-field-label,  .bulkActions.input-field-label{
      font-weight: bold;
    }
    .notes-field {
      ::v-deep .v-input__control {
        height: auto !important;
      }
      .v-input--selection-controls__input {
        margin-right: 0px !important;
      }
    }
    .bulkActions {
      .v-input--selection-controls__input {
        margin-right: 0px !important;
      }
      .content {
        color: $neutral7;
      }
      .v-chip.v-size--small {
        font-size: 13px;
      }
      .creative-chip {
        color: $primary2;
      }
      .creatives-selection {
        ::v-deep .v-input__control {
          height: auto !important;
          min-height: 36px;
        }
      }
      ::v-deep {
        .v-select__selection--disabled {
          color: black !important;
        }
        .v-btn__content {
          font-family: $fontFamily1;
        }
        .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
          border: 1px solid #e0e0e0 !important;
        }
      }
    }
  }
</style>
