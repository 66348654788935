<template>
    <div
        class="uploaded-asset-container"
        :title="file.name"
    >
       <div class="remove-icon" v-if="canRemove">
            <v-icon
                class="close cursor-pointer"
                @click="$emit('remove', {
                    fileId: file.id,
                    orderDeliverableId: file.orderDeliverableId,
                })"
                size="16"
            >
                mdi-close
            </v-icon>
       </div>
        <div class="usc-inner" @click="$emit('showPreview')">
            <div class="usc">
                <div v-if="isPhoto" class="ucs-main">
                    <img
                        :src="getOptimizedS3ImageURL(file.file, {
                            width: 200,
                            height: 200
                        })" alt="asset"
                    >
                </div>
                <div v-if="isVideo" class="ucs-main">
                    <img
                        :src="getOptimizedS3ImageURL(poster, {
                            width: 200,
                            height: 200
                        })" alt="asset"
                    >
                    <v-icon size="24" class="play-btn">mdi-play</v-icon>
                </div>
                <div v-if="isOther" class="ucs-main">
                    <v-icon size="54" color="#f2f2f2">
                        mdi-file-outline
                    </v-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { getOptimizedS3ImageURL } from '@/helpers/';
import defaultMP4Poster from '@/assets/svg/theme/attach.svg';

export default {
  name: 'UploadedFileGridItem',
  props: {
    file: {
      type: Object,
      required: true,
    },
    canRemove: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  computed: {
    fileFormat() {
      if (_.split(this.file.fileFormat, '/')[1] === 'x-subrip') {
        return 'srt';
      }
      return _.split(this.file.fileFormat, '/')[1];
    },
    extension() {
      return _.split(this.file.file, '.').pop().toLowerCase();
    },
    isPhoto() {
      const extension = this.file.file.split('.').pop();
      return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
    },
    isVideo() {
      return ['mp4', 'mov', 'm4v'].includes(this.extension);
    },
    isOther() {
      return !this.isPhoto && !this.isVideo;
    },
    poster() {
      return this.file.poster || defaultMP4Poster;
    },
  },
};
</script>

<style lang="scss" scoped>
.uploaded-asset-container {
    margin: 0.5rem;
    cursor: pointer;
    position: relative;
}

.ucs-main {
    width: 54px;
    height: 54px;
    position: relative;
    overflow: hidden;
    border-radius: 0.3rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.remove-icon {
    background: white;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}
.uploaded-asset-container:hover > .remove-icon {
    display: flex;
}
.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: $charcoalBlack;
    cursor:pointer;
}
@media screen and (max-width: 600px) {
  .uploaded-asset-container {
    margin-left:0rem !important;
  }
}
</style>
