<!-- eslint-disable-next-line -->
<template>
    <div class="closeout-container">
      <v-row class="ma-auto model-header">
          <v-col class="d-flex align-center py-0 pl-0" cols="10">
            <span class="text modal-header-title"> Closeout Sweep </span>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
            <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div class="ml-4 mr-4 sweep-data">
            <v-col class="sweep-title">
                {{sweep.title}}
            </v-col>
            <v-col class="item d-flex no-wrap">
                <Calender2 width="22" height="18"/>
                <div class="data ml-3">
                  {{date(sweep.date)}}
                </div>
            </v-col>
            <v-col class="item d-flex no-wrap">
                <Clock width="18" height="18" />
                <div class="data ml-3">
                 {{time(sweep.fromTime)}} - {{time(sweep.toTime)}}
                </div>
                <span class="ml-3 small-data">
                {{ duration }}
                </span>
            </v-col>
            <v-col
            class="item d-flex no-wrap pb-0">
            <MapMarker width="20" height="18"
            style="min-width: 20px;margin-top: 2px;"/>
            <span class="ml-2"
            v-for="(location) in sweepLocation"
            :key="location.id">
            <div style="min-width: fit-content; ">
            <a class="mb-0 a-link" style="color: black;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0"
              v-if="sweep.sweepLocations.length !== 1">
              +{{sweep.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
            </v-col>
            <v-col class="item mt-2 d-flex no-wrap" v-if="sweepBusiness">
          <Business width="18" height="18"/>
          <span v-for="(business) in sweep.sweepBusiness" :key="business.id">
              <span style="margin-left: 10px;" v-if="business.business.id !== sweep.business.id">
              {{
                `${business.business.name}`
              }}
              </span>
          </span>
        </v-col>
        </div>
        <div class="ml-0 mt-5 pr-5 creatives-list">
          <v-form>
            <v-row class="ml-4 creatives">
                <div
                class="d-flex no-wrap"
                >
                <span class="d-flex no-wrap" v-if="sweepTeam.length">
                  <span class="creative-heading">Creatives</span>
                <p class="line mb-0 mb-3 pt-0 ml-2"></p>
                </span>
                </div>
                <span
                style="padding-right: 10px;"
                class="mt-3"
                v-for="creative in sweepTeam" :key="creative.id">
                <v-col
                xs="5" sm="5" lg="12"
                class="creatives-data mb-3 mr-2">
                    <div class="data-col d-flex no-wrap"
                    >
                      <v-col
                      lg="8"
                      class="d-flex flex-column pt-0 pl-0">
                        <span class="data d-flex no-wrap profilePic">
                            <AvatarRound
                            class="icon"
                            :size="52"
                            :avatar="creative.creatives.profilePic"
                            :name="memberInitials(creative.creatives)"
                            textStyle="font-size: 25px;"
                            />
                            <span class="d-flex flex-column ml-3">
                            <span class="data"
                            style="overflow-x: hidden;text-overflow: ellipsis;">
                              {{creative.creatives.firstName + ' ' + creative.creatives.lastName}}
                            </span>
                        <span class="data-2">{{skillset(creative.skill)}}</span>
                        </span>
                        </span>
                      </v-col>
                      <v-col class="d-flex flex-column estim-payout mr-0 pr-0 pt-0 pl-1">
                        <span class="data-3">Estimated pay</span>
                        <span class="data" v-if="creative.skill === '1'">{{creative.skill === '1' &&
                      sweep.photographerPayout !== null ? `$${sweep.photographerPayout}` : `$${0}`}}
                        </span>
                      <span class="data" v-if="creative.skill === '2'">{{creative.skill === '2' &&
                      sweep.videographerPayout !== null ? `$${sweep.videographerPayout}` : `$${0}`}}
                        </span>
                        <span class="data" v-if="creative.skill === '3'">{{creative.skill === '3' &&
                      sweep.talentPayout !== null ? `$${sweep.talentPayout}` : `$${0}`}}
                        </span>
                    <span class="data" v-if="creative.skill === '4'">{{creative.skill === '4' &&
                    sweep.fieldProducerPayout !== null ? `$${sweep.fieldProducerPayout}` : `$${0}`}}
                    </span>
                      </v-col>
                    </div>
                    <div>
                        <v-row>
                          <v-col class="pb-0" xs="8" sm="8" md="8" lg="12">
                          <label class="data">Comment</label>
                        <BaseTextAreaField
                            filled
                            rows="3"
                            class="bio-text comment-input mb-n5"
                            solo
                            dense
                            flat
                            type="text"
                            v-bind:placeholder="`How did ${creative.creatives.firstName} perform?`"
                            v-model="creative.comment"
                            />
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="pb-0 pl-3 pt-0" xs="12" sm="12" md="12" lg="5">
                                <label class="data">Actual Pay</label>
                                <div class="payout-div pa-0">
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <v-text-field
                                    :class="{
                                        'errorState v-input--has-state error--text':
                                        creativesErr && !creative.payout
                                    }"
                                    class="type-selection payout-input"
                                    outlined
                                    dense
                                    name="payout"
                                    v-model="creative.payout"
                                    min="0"
                                    oninput=
                                    "validity.valid || (value = value.slice(0,value.length-1))"
                                    type="number"
                                    step="0.01"
                                >
                        <template v-slot:prepend-inner class="pa-0 mr-5">
                            <div class="dollar text-left">
                                <Dollar color="#939393"/>
                            </div>
                        </template>
                    </v-text-field>
                                </div>
                                <span
                                    :class="{
                                    'errorState v-input--has-state error--text': creativesErr,
                                    }"
                                v-if="creativesErr && !creative.payout">Payout requried</span>
                            </v-col>
                            <v-col lg="2" class="dub"></v-col>
                        <v-col xs="8" sm="8" md="8" lg="5" class="pb-0 pt-0">
                                <label class="data">Reimbursement</label>
                                <div class="payout-div pa-0">
                                <v-text-field
                                    :class="{
                                        'errorState v-input--has-state error--text':
                                        creativesErr && !creative.reimbursement
                                    }"
                                    class="type-selection payout-input"
                                    outlined
                                    dense
                                    name="payout"
                                    v-model="creative.reimbursement"
                                    min="0"
                                    oninput=
                                    "validity.valid || (value = value.slice(0,value.length-1))"
                                    type="number"
                                    step="0.01"
                                >
                        <template v-slot:prepend-inner class="pa-0 mr-5">
                            <div class="dollar text-left">
                                <Dollar color="#939393"/>
                            </div>
                        </template>
                    </v-text-field>
                                </div>
                                <span
                                    :class="{
                                    'errorState v-input--has-state error--text': creativesErr,
                                    }"
                    v-if="creativesErr && !creative.reimbursement">Reimbursement requried</span>
                                  </v-col>
                        </v-row>
                    </div>
                </v-col>
                </span>
            </v-row>
            <v-col class="buttons pr-0 mr-0 ml-4">
                <div class="d-flex justify-end">
                <button
                class="cancel cursor-pointer mr-5"
                @click="$emit('closemodal')">Cancel</button>
                <v-btn
                class="closeout ml-2 mr-3 btn-purple"
                type="submit"
                :loading="loading"
                @click="submitForm"
                >Closeout</v-btn>
                </div>
            </v-col>
            </v-form>
        </div>
        </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import Clock from '@/components/common/svg/Clock';
import Dollar from '@/components/common/svg/Dollar';
import MapMarker from '@/components/common/svg/MapMarker';
import Business from '@/components/common/svg/Business';
import Calender2 from '@/components/common/svg/Calender2';
import AvatarRound from '@/components/common/Avatar';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  components: {
    Clock,
    Dollar,
    MapMarker,
    Business,
    Calender2,
    AvatarRound,
    BaseTextAreaField,
  },
  props: {
    sweep: {
      type: Object,
      requried: true,
    },
  },
  data() {
    return {
      creativesErr: false,
      loading: false,
    };
  },
  computed: {
    sweepTeam() {
      // eslint-disable-next-line max-len
      const data = this.sweep.sweepTeam.filter((x) => x.accepted);
      return data;
    },
    memberInitials() {
      return (member) => {
        const firstName = _.get(member || member, 'firstName', '').trim();
        const lastName = _.get(member || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepLocation() {
      return this.sweep.sweepLocations.slice(0, 1);
    },
    sweepBusiness() {
      return this.sweep.sweepBusiness.length > 1;
    },
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `(${rminutes} minutes)` : ''}`;
        const checkForHours = `${rhours > 0 ? `(${rhours} hours)` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `(${rhours} hours and ${rminutes} minutes)` : checkForHours;
        return text;
      };
    },
    duration() {
      const { sweep } = this;
      const startTime = moment(sweep.fromTime, 'hh:mm a');
      const endTime = moment(sweep.toTime, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
  },
  methods: {
    ...mapActions('sweep', ['closeOutForm']),
    skillset(val) {
      switch (val) {
        case '1':
          return 'Photographer';
        case '2':
          return 'Videographer';
        case '3':
          return 'Talent';
        default:
          return 'Field Producer';
      }
    },
    date() {
      return moment(this.sweep.date).format('MMMM Do, YYYY');
    },
    async checkForCreative() {
      let err = false;
      if (this.sweepTeam.length === 0) {
        err = true;
      } else {
        err = false;
        _.map(this.sweepTeam, (item) => {
          if (!item.payout || !item.reimbursement) {
            err = true;
          }
        });
      }
      this.creativesErr = err;
    },
    async submitForm(e) {
      this.checkForCreative();
      e.preventDefault();
      // eslint-disable-next-line
      const getCreatives = this.sweep.sweepTeam.filter((sweep) => sweep.accepted == 1);
      if (!this.creativesErr || getCreatives.length === 0) {
        this.loading = true;
        const { id } = this.sweep;
        const creatives = {
          sweepId: id,
          creatives: getCreatives.map((data) => ({
            comment: data.comment,
            payout: Number(data.payout),
            type: this.skillset(data.skill),
            userId: data.creatives.id,
            reimbursement: Number(data.reimbursement),
          })),
        };
        const result = await this.closeOutForm(creatives);
        if (result) {
          this.$emit('closemodal', true);
          this.$emit('reload-sweeps', true);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.closeout-container {
  overflow-x: hidden;
  .dub {
    display: block;
  }
  @media (min-width: 0px) and (max-width: 600px) {
    .creatives-data {
      padding: 10px !important;
    }
    .dub {
      display: none;
    }
    .estim-payout {
        margin-left: 0px !important;
    }
  }
  .buttons {
    border-top: 1px solid #E0E0E0;
  }
  // .model-header {
  //   padding-top: 5px;
  //   padding-bottom: 5px;
  //   border-bottom: 1px solid #D1D1D1;
  // }
  .model-title {
    color: #929292;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    font-family: 'Poppins';
  }
  .sweep-data {
    border-bottom: 10px solid #D9D9D9;
    .sweep-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #262626;
        .data {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .small-data {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #929292;
    }
  }
  .creatives-list {
    // .creatives {
    //   border-bottom: 1px solid #E0E0E0;
    // }
    .creative-heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #B5B5B5;
    }
    .line {
        width: 335px;
        border-bottom: 2px solid #EBEBEB;
    }
    .creatives-data {
        background: #F4F4F4;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        .icon {
            width: 50px;
        }
        // .estim-payout {
        //     margin-left: 155px;
        // }
        .data-col {
            border-bottom: 1px solid #D9D9D9;
        }
        .data {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        .data-2 {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #AAAAAA;
        }
        .data-3 {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          text-transform: uppercase;
          color: #939393;
        }
        .comment-input {
            height: 65px;
            font-weight: 400;
            font-size: 13px;
            ::v-deep.v-textarea.v-text-field--enclosed textarea {
              height: 20px !important;
            }
            ::v-deep.v-input__slot {
                border: 1px solid #d3d3d3;
            }
            ::v-deep.v-text-field__slot {
                margin-right: -10px;
            }
            ::-webkit-input-placeholder {
                width:  200px !important;
                overflow-x: hidden !important;
                overflow-y: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
            }
        }
        ::v-deep.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
            border-color:white !important;
        }
        .payout-div {
            ::v-deep {
                .v-text-field__details {
                  display: none;
                }
                .v-input__slot {
                  background-color: white;
                  padding-left: 0px !important;
                }
                .v-input__prepend-inner {
                    margin-top: 0px !important;
                }
                .v-text-field--outlined fieldset {
                    border: 1px solid #D2D2D2;
                }
            }
            .dollar-div {
                margin-top: 0px;
            }
            .dollar {
                background-color: #F5F5F5;
                border-right: 1px solid #E0E0E0;
                width: 35px;
                height: 40px;
                margin-top: 0px;
                padding: 8px;
                border-radius: 3px 0px 0px 3px;
            }
        }
    }
    .closeout {
        border-radius: 30px;
    }
  }
}
</style>
