<template>
    <v-container class="general-info">
        <v-row class="flex-column general-info-box">
            <v-list class="pt-4">
                <v-list-item
                    v-for="(item, j) in info" :key="`item${j}`"
                    class="icon-position d-flex align-middle"
                >
                    <v-row class="p-4 align-center mb-4">
                        <v-col
                        xl="3"
                        offset-xl="2"
                        lg="3"
                        offset-lg="2"
                        md="3"
                        offset-md="2"
                        xs="3"
                        sm="3"
                        class="d-flex align-center pa-0">
                            <label class="font-label">
                                {{`${item.placeholder} ${item.name === 'name' ? '*' : ''}`}}
                            </label>
                        </v-col>
                        <v-col
                        class="pa-0"
                        xl="5"
                        lg="5"
                        md="5"
                        sm="10"
                        xs="10">
                          <BaseInput
                              outlined
                              dense
                              :mode="mode.about"
                              :name="item.name"
                              v-model.trim="business[item.name]"
                              :status="errors(item.name).length ? 'error': 'normal'"
                              :error-messages="errors(item.name)"
                              @input="$v.business[item.name].$touch()"
                              @blur="$v.business[item.name].$touch()"
                              >
                          </BaseInput>
                        </v-col>
                    </v-row>
              </v-list-item>
            </v-list>
        </v-row>
    </v-container>
</template>

<script>
/* eslint-disable global-require */
import { mask } from 'vue-the-mask';
import { url, generateUrl } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';

export default {
  name: 'GeneralInfo',
  components: {
    BaseInput,
  },
  directives: {
    mask,
  },
  props: {
    business: Object,
  },
  validations: {
    business: {
      website: {
        url,
      },
      facebook: {
        url,
      },
      instagram: {
        url,
      },
      youtube: {
        url,
      },
      linkedin: {
        url,
      },
      pinterest: {
        url,
      },
    },
  },
  computed: {
    info() {
      return (
        [{
          name: 'website',
          placeholder: 'Website',
          value: this.business.instagram,
          avatar: require('@/assets/svg/instagram.svg'),
        }, {
          name: 'instagram',
          placeholder: 'Instagram',
          value: this.business.instagram,
          avatar: require('@/assets/svg/instagram.svg'),
        }, {
          name: 'facebook',
          placeholder: 'Facebook',
          value: this.business.facebook,
          avatar: require('@/assets/svg/facebook.svg'),
        }, {
          name: 'linkedin',
          placeholder: 'LinkedIn',
          value: this.business.linkedin,
          avatar: require('@/assets/svg/linkedin.svg'),
        }, {
          name: 'youtube',
          placeholder: 'YouTube',
          value: this.business.youtube,
          avatar: require('@/assets/svg/theme/youtube-outline.svg'),
        }, {
          name: 'pinterest',
          placeholder: 'Pinterest',
          value: this.business.pinterest,
          avatar: require('@/assets/svg/instagram.svg'),
        },
        ]
      );
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.business[field].$dirty) return errors;
        switch (field) {
          case 'website':
          case 'instagram':
          case 'facebook':
          case 'linkedin':
          case 'youtube':
          case 'pinterest':
            if (!this.$v.business[field].url) { errors.push('Please provide valid url'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    url(link) {
      return generateUrl(link);
    },
  },
  data() {
    return {
      mode: 'edit',
    };
  },
  mounted() {
    const pinterestURL = this.business.pinterest;
    this.business.pinterest = pinterestURL === '0' ? '' : pinterestURL;
    this.$emit('initVuelidate', this.$v);
  },
};
</script>
<style lang="scss" scoped>
  .general-info {
      color: $neutral1 !important;
      .v-label {
          padding-left: 8px;
      }
  }
</style>
