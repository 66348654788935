import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"brand-colors"},[_vm._l((_vm.brandColorsSet),function(type,i){return _c(VRow,{key:i,staticClass:"colors-card"},[_c(VCol,{staticClass:"pt-0 mb-3"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"color-card-heading"},[_vm._v(_vm._s(type.name))])]),_c('div',{staticClass:"d-flex flex-row flex-wrap",class:'color-picker-'+type.name},_vm._l(((type.colors)),function(color,index){return _c('div',{key:index,staticClass:"color-picker-wrapper",class:{'hidden-screen-only':
                (type.colors.length-1 === index && type.colors.length > 4)
                }},[_c('ColorPicker',{staticClass:"color-picker-container",attrs:{"currentPage":"brandQuiz","value":color.colorCode,"state":(_vm.activeColor.index === index && _vm.activeColor.mainIndex === i) ?
                'edit': 'normal',"isNew":!color.id,"width":_vm.$vuetify.breakpoint.smAndDown ? '200px': '300px',"alignRight":_vm.$vuetify.breakpoint.smAndDown},on:{"update-color":function (val) { return _vm.updateColor(val, index, i, color.id, type.colors.length-1); },"delete-color":function () { return _vm.showConfirmModal(color.id); },"close-color":_vm.closeColor}},[[_c('div',{staticClass:"color-block"},[(type.colors.length-1 !== index && _vm.canUpload)?_c(VBtn,{staticClass:"remove-color-icon",attrs:{"icon":"","x-small":"","color":"black"},on:{"click":function($event){return _vm.showConfirmModal(color.id)}}},[_c(VIcon,{attrs:{"size":"10"}},[_vm._v("mdi-close")])],1):_vm._e(),_c(VBtn,{staticClass:"circle-color",class:{'color-add-btn': type.colors.length-1 === index,
                            'mr-0' : ((type.colors.length-1 === index)
                            || (type.colors.length > 4 && index === 3)),
                            'mr-7' : !((type.colors.length-1 === index)
                            || (type.colors.length > 4 && index === 3))},staticStyle:{"box-shadow":"none"},attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '41px': '65px',"height":_vm.$vuetify.breakpoint.smAndDown ? '41px': '65px',"fab":"","small":"","color":color.colorCode},on:{"click":function($event){return _vm.togglePickerState(index, i, color.colorCode)}}},[(type.colors.length-1 === index)?_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]):_vm._e()],1)],1),(type.colors.length-1 !== index)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(color.colorCode),expression:"color.colorCode",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"hex-text mt-1",class:_vm.$vuetify.breakpoint.smAndDown ? 'pl-0' : 'pl-2'},[_vm._v(" "+_vm._s(color.colorCode)+" ")]):_vm._e()]],2)],1)}),0)])],1)}),_c('ConfirmModal',{attrs:{"showConfirm":_vm.showConfirm},on:{"onDelete":_vm.confirmDelete,"cancel":_vm.cancelModal}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }