<template>
    <v-container
      fluid
      v-if="isModal"
      class="confirm-popup"
    >
      <Modal
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :modal="isModal"
        width="650"
        persistent
      >
          <v-card class="pa-5" :flat="true">
            <v-row class="pa-0">
              <v-col>
                Are you sure you want to delete?
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-end py-0">
                  <v-btn text
                    @click="cancel"
                    class="py-0"
                    color="primaryGray1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-2 btn-purple"
                    @click="confirmDelete"
                  >
                    Yes
                  </v-btn>
              </v-col>
            </v-row>
          </v-card>
      </Modal>
    </v-container>
</template>

<script>
import Modal from '@/components/common/Modal';

export default {
  name: 'ConfirmModal',
  components: {
    Modal,
  },
  props: {
    showConfirm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isModal() {
      return this.showConfirm;
    },
  },
  methods: {
    confirmDelete() {
      this.$emit('onDelete');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
