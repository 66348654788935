<template>
  <div>
    <div class="long-text" >
      <span v-html="truncatedText" v-linkified> </span>
      <span
        v-if="isTextExceededLength()"
        class="more-text ml-1"
        @click="showPopup=true"
      >
        {{moreText}}
      </span>
      <v-container
        v-if="showPopup"
        class="pa-0"
      >
        <Modal
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :modal="showPopup"
          width="600"
          persistent
        >
          <v-card class="pa-1">
            <v-card-title class="d-flex">
              <v-row class="my-0">
                <v-col class="d-flex align-center py-0">
                  <v-card-title class="pa-0">{{popupTitle}} </v-card-title>
                </v-col>
                <v-col class="d-flex justify-end pa-0">
                  <v-icon color="darken-1" @click="showPopup = false">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pt-3 text--primary" v-linkified>
              {{text}}
            </v-card-text>
          </v-card>
        </Modal>
      </v-container>
    </div>
  </div>
</template>

<script>
import linkify from 'vue-linkify';
import Modal from './Modal';

export default {
  components: {
    Modal,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 100,
    },
    moreText: {
      type: String,
      default: 'View more',
    },
    popupTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      readMoreActive: false,
      showPopup: false,
    };
  },
  methods: {
    isTextExceededLength() {
      return this.text && this.text.length > this.maxLength;
    },
  },
  computed: {
    truncatedText() {
      if (this.isTextExceededLength()) {
        return this.text.slice(0, this.maxLength);
      }
      return this.text;
    },
  },
};
</script>

<style lang="scss" scoped>
  .more-text {
    color: $primary1;
    cursor: pointer;
  }
  .v-card {
    border-radius: 4px !important;
  }
</style>
