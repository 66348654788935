<!-- eslint-disable max-len -->
<template>
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3697_23620)">
      <path
        d="M12 6C11.2089 6 10.4355 6.2346 9.77772 6.67412C9.11993 7.11365 8.60723 7.73836 8.30448 8.46927C8.00173 9.20017 7.92252 10.0044 8.07686 10.7804C8.2312 11.5563 8.61216 12.269 9.17157 12.8284C9.73098 13.3878 10.4437 13.7688 11.2196 13.9231C11.9956 14.0775 12.7998 13.9983 13.5307 13.6955C14.2616 13.3928 14.8864 12.8801 15.3259 12.2223C15.7654 11.5645 16 10.7911 16 10C16 8.93913 15.5786 7.92172 14.8284 7.17157C14.0783 6.42143 13.0609 6 12 6ZM12 12C11.6044 12 11.2178 11.8827 10.8889 11.6629C10.56 11.4432 10.3036 11.1308 10.1522 10.7654C10.0009 10.3999 9.96126 9.99778 10.0384 9.60982C10.1156 9.22186 10.3061 8.86549 10.5858 8.58579C10.8655 8.30608 11.2219 8.1156 11.6098 8.03843C11.9978 7.96126 12.3999 8.00087 12.7654 8.15224C13.1308 8.30362 13.4432 8.55996 13.6629 8.88886C13.8827 9.21776 14 9.60444 14 10C14 10.5304 13.7893 11.0391 13.4142 11.4142C13.0391 11.7893 12.5304 12 12 12Z"
        :fill="color"
      />
      <path
        d="M12 24.0001C11.158 24.0044 10.3271 23.8069 9.57707 23.4241C8.82703 23.0413 8.1796 22.4844 7.68901 21.8C3.87801 16.543 1.94501 12.591 1.94501 10.053C1.94501 7.3863 3.00437 4.82877 4.89005 2.94309C6.77573 1.05741 9.33326 -0.00195312 12 -0.00195312C14.6668 -0.00195312 17.2243 1.05741 19.11 2.94309C20.9956 4.82877 22.055 7.3863 22.055 10.053C22.055 12.591 20.122 16.543 16.311 21.8C15.8204 22.4844 15.173 23.0413 14.4229 23.4241C13.6729 23.8069 12.8421 24.0044 12 24.0001ZM12 2.18105C9.91242 2.18343 7.91102 3.01377 6.43488 4.48992C4.95873 5.96606 4.12839 7.96746 4.12601 10.055C4.12601 12.065 6.01901 15.782 9.45501 20.521C9.7467 20.9228 10.1294 21.2498 10.5717 21.4753C11.0141 21.7008 11.5035 21.8183 12 21.8183C12.4965 21.8183 12.986 21.7008 13.4283 21.4753C13.8706 21.2498 14.2533 20.9228 14.545 20.521C17.981 15.782 19.874 12.065 19.874 10.055C19.8716 7.96746 19.0413 5.96606 17.5651 4.48992C16.089 3.01377 14.0876 2.18343 12 2.18105Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3697_23620">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MapMarker',
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>
