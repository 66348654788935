<template>
  <div class="orders-history pa-0">
    <v-row class="title-header ma-0">
      <v-col class="d-flex align-center py-0 pl-0" cols="10">
        <span class="text heading"> Order History </span>
      </v-col>
    </v-row>
    <v-row
    class="ma-0"
    v-if="completedOrders && completedOrders.length" :key="completedOrders.length">
      <v-col class="pa-0">
          <v-row class="ma-0 table-header">
            <v-col cols="2" class="text-center">Order#</v-col>
            <v-col cols="2" class="py-0 order-date"
            :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">Order Date</v-col>
            <v-col cols="5" class="deliverable-label"
            :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">Deliverables</v-col>
            <v-col class="text-center credits-label">Credits</v-col>
          </v-row>
          <div class="table-data scrollable">
            <v-row
            class="ma-auto cursor-pointer"
            v-for="item in completedOrders"
            :key="item.id"
            @click="getOrderInfo(item)">
              <v-col
              cols="2"
              class="data text-purple"
              :class="!$vuetify.breakpoint.xsOnly && 'text-center'"
              >{{ item.id }}</v-col>
              <v-col
              cols="2"
              class="data"
              >{{ date(item.createdAt) }}</v-col>
              <v-col cols="5" class="data"
              :class="$vuetify.breakpoint.xsOnly && 'visibility-hidden'">
                <span>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                      <div
                          style="width: auto;"
                          class="deliverables"
                          v-on="on"
                      >
                      {{ dimensions(item) }}
                      </div>
                      </template>
                      <span>
                        {{ dimensions(item) }}
                      </span>
                  </v-tooltip>
                </span>
              </v-col>
              <v-col class="data text-center">{{ item.credits }}</v-col>
              <v-col cols="9" class="data" v-if="$vuetify.breakpoint.xsOnly">
                <span>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                      <div
                          style="width: auto;"
                          class="deliverables"
                          v-on="on"
                      >
                      {{ dimensions(item) }}
                      </div>
                      </template>
                      <span>
                        {{ dimensions(item) }}
                      </span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-col>
    </v-row>
    <v-row class="ma-0 no-history" v-else>
        <v-col class="my-5 d-flex flex-column justify-center align-center">
            <v-img
              width="2rem"
              src="@/assets/svg/no_active_orders.svg"
              aspect-ratio="1"
            ></v-img>
            <div style="height: 200px;">No Order History</div>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderHistory',
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('orders', ['completedOrders']),
    customer() {
      const role = _.get(this.userDetails, 'role.name', '');
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(role);
    },
    businessId() {
      let { businessId } = this.$route.query;
      if (this.customer) {
        businessId = _.get(this.userDetails, 'currentBusinessId');
      }
      if (this.$route.name === 'Organization') {
        businessId = this.$route.params.organizationId;
      }
      return businessId;
    },
  },
  methods: {
    getOrderInfo(order) {
      const { projectId } = this.$route.params;
      if (this.customer) {
        const queryParams = { businessId: this.businessId, orderId: order.id };
        if (projectId) {
          queryParams.projectId = projectId;
        }
        this.$router.push({ name: 'Order', params: { orderId: order.id }, query: queryParams });
      } else {
        this.$emit('goToOrder', order.id);
      }
    },
    dimensions(deliverable) {
      const orderDeliverables = _.get(deliverable, 'orderDeliverables', []);
      return orderDeliverables.length ? orderDeliverables.map((x) => (`${x.deliverable.name} (${x.deliverable.creditCost})`)).join(', ') : null;
    },
    date(createdDate) {
      return moment(createdDate).format('M/DD/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.orders-history {
  min-height: 250px;
  .title-header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #929292;
    padding-bottom: 2px;
    border-bottom: 1px solid #D8D8D8;
  }
  .scrollable {
    // overflow: auto;
    // max-height: 600px;
    padding: 18px 0px 8px 0px;
  }
  .table-header {
    border-bottom: 1px solid #D8D8D8;
    padding-top: 18px;
    padding-bottom: 7px;
    div {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
      padding: 0px;
      margin-right: 5px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .table-data {
    border-bottom: 1px solid #D8D8D8;
    .data {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      padding: 0px;
      margin-right: 5px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
    .deliverables {
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
    }
  }
  .no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2rem;
    color: $charcoalBlack;
    height: 74%;
    display: flex;
    align-items: center;
  }
}
.visibility-hidden {
  visibility: hidden !important;
}
</style>
