import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewProfileModal)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"modal":_vm.viewProfileModal,"width":"300","persistent":""}},[_c('div',{staticClass:"details-block"},[_c(VCard,{staticClass:"detail-card",attrs:{"flat":""}},[_c(VImg,{staticClass:"white--text align-end profile-pic",attrs:{"src":_vm.getOptimizedS3ImageURL(_vm.profilePic, { width: 500, height: 500 } ),"aspect-ratio":1.2}}),_c(VBtn,{staticClass:"close-icon",attrs:{"icon":"","width":"30","height":"30","color":"#343838"},on:{"click":function($event){return _vm.$emit('setViewProfileModal', false)}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-close")])],1),_c(VCardText,{staticClass:"text--primary pb-2"},[_c('div',{staticClass:"name text-capitalize pb-1 notranslate font-h3"},[_vm._v(" "+_vm._s(_vm.name)+" "),_c('span',{staticClass:"pl-2 pronouns"},[_vm._v(" "+_vm._s(_vm.pronoun(_vm.selectedMember.userPronouns))+" ")])]),_c('div',{staticClass:"text skill"},[_vm._v(_vm._s(_vm.skillset))])]),_c(VCardSubtitle,{staticClass:"px-4 py-0 number"},[_vm._v(_vm._s(_vm.selectedMember.number))]),_c(VCardSubtitle,{staticClass:"px-4 py-0 pb-2 email notranslate"},[_vm._v(" "+_vm._s(_vm.selectedMember.email))]),(_vm.parent === 'creativeTeam'
      &&
      (_vm.selectedMember.role === 'creative' || _vm.selectedMember.role === 'client')
      && _vm.isCreativeDeleted)?_c('div',{staticClass:"px-4 pb-2 profilebtn"},[_c(VBtn,{staticClass:"pa-0",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('modalOpen',true)}}},[_c('span',{staticClass:"text text-purple view-profile pa-0"},[_vm._v("View Portfolio")])])],1):_vm._e(),_c(VDivider),_c(VCardText,{staticClass:"py-2 px-4"},[(_vm.selectedMember.creativeProfile)?_c('div',{staticClass:"text bio-text"},[_vm._v(" Bio: "+_vm._s(_vm.selectedMember.creativeProfile.bio))]):_c('div',{staticClass:"text bio-text"},[_vm._v("Bio: "+_vm._s(_vm.selectedMember.bio))])])],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }