<template>
<div
  class="kit">
  <v-card
    class="mx-auto kit-inner"
    flat
    :style="{
      'background-image':
      'url(' + getOptimizedS3ImageURL(imageSrc,
         { width: 200, height: 200 }) + ')',
      'background-size': validImage ? '122px 122px': '75px 75px'
     }"
    style="background-position: center;"
  >
    <div v-if="fileExtension === 'mp4'"
    class="brand-kit-play w-100 h-100 d-flex align-center justify-center">
      <div> <v-icon class="play-btn">mdi-play</v-icon> </div>
    </div>
    <div class="d-flex justify-end card-body"
    >
      <v-menu bottom left offset-y offset-x content-class="uploaded-content">
        <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              height="auto"
              width="10"
              v-on="on"
              class="menu-btn"
            >
              <v-icon color="black" dense>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn text class="w-100" @click="$emit('onDownload')">
                  <img
                    src="@/assets/svg/theme/download-small.svg"
                    class="download-icon"
                    width=24
                    height=14
                  />
                  Download
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$attrs.delete">
              <v-list-item-title>
                <v-btn text @click="$attrs.delete"
                class="button-content w-100">
                <v-icon small class="pr-2">mdi-delete</v-icon>Delete
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
    </div>
  </v-card>


    <div
      class="d-block text-center w-100 text-truncate pa-1 pl-2 notranslate"
      style=""
    >
      {{ item.filename }}
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { lookup } from 'mime-types';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'UploadedContent',
  components: {
  },
  props: {
    brandQuiz: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fileType() {
      return lookup(this.file);
    },
    fileExtension() {
      return this.item.kit.split('.').pop().toLowerCase();
    },
    validImage() {
      return this.thumbnailFormats.includes(this.fileExtension);
    },
    imageSrc() {
      if (this.validImage) {
        return this.fileExtension === 'mp4' ? this.item.poster : this.item.thumbnail || this.item.kit;
      }
      return require(`@/assets/svg/theme/${this.fileExtension}.svg`);
    },
  },
  prop: {
    onDownload: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  data() {
    return ({
      thumbnailFormats: ['jpg', 'jpeg', 'png', 'mp4'],
    });
  },
};
</script>

<style lang="scss" scoped>
  .kit {
    border: solid 1px #e6e6e6;
    border-radius: 5px !important;
    font-size: 11px;
    height: 122px;
  }
    .v-card {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        background-color: $neutral6;
    }
    .uploaded-file-type-image {
        width: 43px;
        height: 56px;
        border-radius: 0 !important;
    }
    .v-card__title {
        font-family: $fontFamily1;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.24px;
        color: $neutral1;
        padding: 10px;
        line-height: 1rem;
    }
    .uploaded-content {
      .v-list-item {
        min-height: 0 !important;
        padding: 0;
      }
    }
    .button-content{
      justify-content: left;
    }
  .v-list {
    border-radius: 0;
    padding: 0;
  }
  .menu {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 7;
  }
  .menu-btn {
    width: 10px;
    margin-right: 5px;
  }
  .v-menu__content {
    border-radius: 0;
  }
  .v-list-item {
    min-height: auto;
    padding: 0 5px;
    .v-btn {
      height: 30px;
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px;
        font-family: $fontFamily1;
        justify-content: flex-start;
        width: auto;
        .download-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding: 5px;
      }
    }
  }
  .brand-kit-play {
    position: absolute;
    .play-btn {
      background: rgba(0, 0, 0, 0.4);
      color: white;
      cursor: pointer;
    }
  }
</style>
