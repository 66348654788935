<template>
  <v-container class="add-credits pa-0">
    <v-row class="ma-auto title-header">
      <v-col class="d-flex align-center py-0 pl-0" cols="10">
        <span class="text heading"> Add / Subtract Credits </span>
      </v-col>
      <v-col class="d-flex justify-end py-0 pr-0">
        <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-row class="ma-auto">
      <v-col class="pb-5">
        <v-form @submit="submitForm">
          <div class="scollable-div ">
            <v-row class="ma-auto">
              <v-col xs="12" sm="5" md="5" lg="5" xl="5" xxl="5" class="pb-0">
                <label class="text-left font-label"> # of Credits*</label>
                <BaseInput
                  solo
                  dense
                  flat
                  v-model.trim="credits.count"
                  :status="errors('count').length ? 'error' : 'normal'"
                  :error-messages="errors('count')"
                  @input="$v.credits.count.$touch()"
                  @blur="$v.credits.count.$touch()"
                />
              </v-col>
              <v-col xs="12" sm="7" md="7" lg="7" xl="7" xxl="7" class="pb-0">
                <label class="text-left font-label"> Reason*</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="reasons"
                  item-text="reason"
                  item-value="id"
                  v-model="credits.reason"
                  @change="getReason"
                  :menu-props="{ 'content-class': 'notranslate' }"
                  :status="errors('reason').length ? 'error' : 'normal'"
                  :error-messages="errors('reason')"
                  @input="$v.credits.reason.$touch()"
                  @blur="$v.credits.reason.$touch()"
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="ma-auto">
              <v-col cols="12" class="pt-0 pb-2">
                <label class="text-left font-label" :key="notesRequired">
                  {{ notesRequired ? 'Notes*' : 'Notes' }}
                </label>
                <BaseTextAreaField
                  filled
                  rows="3"
                  class="bio-text mb-n5"
                  solo
                  dense
                  flat
                  v-model.trim="credits.notes"
                  :status="errors('notes').length ? 'error' : 'normal'"
                  :error-messages="errors('notes')"
                  @input="$v.credits.notes.$touch()"
                  @blur="$v.credits.notes.$touch()"
                />
              </v-col>
            </v-row>
          </div>
          <v-card-actions>
            <v-btn text class="py-0 cancel" color="primaryGray1" @click="$emit('close-modal')">
              Cancel
            </v-btn>
            <v-btn
              class="py-0 submit btn-purple"
              type="submit"
              :loading="loading"
              :disabled="$v.$dirty && $v.$invalid"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import {
  required, requiredIf,
} from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  name: 'UpdateCredits',
  components: {
    BaseInput,
    BaseTextAreaField,
  },
  data() {
    return {
      credits: {
        count: '',
        reason: '',
        notes: '',
      },
      loading: false,
      notesRequired: false,
    };
  },
  methods: {
    ...mapActions('business', ['updateCredits']),
    getReason(reason) {
      const reasonText = _.get(_.find(this.reasons, (item) => item.id === reason), 'reason');
      this.notesRequired = false;
      if (reasonText === 'Other') {
        this.notesRequired = true;
      }
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const { reason, notes, count } = this.credits;
        const credits = {
          businessId: this.businessId,
          reasonId: reason,
          description: notes,
          credits: count,
        };
        const result = await this.updateCredits(credits);
        if (result.success) {
          this.$emit('close-modal');
        }
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters('business', ['reasons']),
    businessId() {
      let { businessId } = this.$route.query;
      if (this.$route.name === 'Organization') {
        businessId = this.$route.params.organizationId;
      }
      return businessId;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.credits[field].$dirty) return errors;
        switch (field) {
          case 'count':
            if (!this.$v.credits.count.required) {
              errors.push('Please provide credits');
            }
            // if (!this.$v.credits.count.numeric) {
            //   errors.push('Please provide numeric value');
            // }
            // if (!this.$v.credits.count.minValue) {
            //   errors.push('Please provide valid credits');
            // }
            break;
          case 'reason':
            if (!this.$v.credits.reason.required) {
              errors.push('Please provide reason');
            }
            break;
          case 'notes':
            if (!this.$v.credits.notes.required) {
              errors.push('Please provide notes');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  validations: {
    credits: {
      count: {
        required,
        // numeric,
        // Commenting this out: we need to be able to subtract credits
        // minValue: minValue(1),
      },
      reason: {
        required,
      },
      notes: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.notesRequired;
        }),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.add-credits {
  .title-header {
    border-bottom: 1px solid #D1D1D1;
    padding: 16px 21px 13px 21px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
  }
  .scollable-div {
    .base-input {
      margin-top: 6px;
    }
  }
  .v-form {
    .v-card__actions {
      padding: 0;
      justify-content: flex-end;
      padding: 20px 20px 0px 20px;
      .v-btn {
        border-radius: 30px;
        color: $secondary3;
        box-shadow: none;
        height: 42px;
        ::v-deep .v-btn__content {
          font-weight: 400;
          line-height: 24px;
          font-size: 16px;
        }
      }
      .submit {
        ::v-deep .v-btn__content {
          font-weight: 600;
          line-height: 28px;
          font-size: 16px;
        }
        width: 124px;
        margin-left: 12px !important;
      }
      .cancel {
        width: 57px;
      }
    }
    ::v-deep .input-field {
      font-family: $fontFamily1;
      height: inherit;
    }
    ::v-deep .v-select__slot {
      font-family: $fontFamily1;
    }
    ::v-deep .normalState .v-input__slot {
      border: 1px solid #e0e0e0 !important;
    }
    ::v-deep .nullState .v-input__slot {
      border: 1px solid #e0e0e0 !important;
    }
    ::v-deep .v-input {
      .v-input__control {
        .v-select__selection,
        input {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #4f4f4f !important;
        }
      }
    }
    ::v-deep .v-input__slot {
      min-height: 40px;
    }
    ::v-deep .type-selection {
      margin-top: 6px;
      .v-input__slot {
        border: solid 1px #e6e8ed;
      }
    }
    ::v-deep .type-selection.error--text .v-input__slot {
      border: solid 1px #ff0808;
    }
  }
}
</style>
