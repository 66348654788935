import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kit"},[_c(VCard,{staticClass:"mx-auto kit-inner",staticStyle:{"background-position":"center"},style:({
      'background-image':
      'url(' + _vm.getOptimizedS3ImageURL(_vm.imageSrc,
         { width: 200, height: 200 }) + ')',
      'background-size': _vm.validImage ? '122px 122px': '75px 75px'
     }),attrs:{"flat":""}},[(_vm.fileExtension === 'mp4')?_c('div',{staticClass:"brand-kit-play w-100 h-100 d-flex align-center justify-center"},[_c('div',[_c(VIcon,{staticClass:"play-btn"},[_vm._v("mdi-play")])],1)]):_vm._e(),_c('div',{staticClass:"d-flex justify-end card-body"},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","content-class":"uploaded-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
     var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"menu-btn",attrs:{"dark":"","icon":"","height":"auto","width":"10"}},on),[_c(VIcon,{attrs:{"color":"black","dense":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('onDownload')}}},[_c('img',{staticClass:"download-icon",attrs:{"src":require("@/assets/svg/theme/download-small.svg"),"width":"24","height":"14"}}),_vm._v(" Download ")])],1)],1),(_vm.$attrs.delete)?_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"button-content w-100",attrs:{"text":""},on:{"click":_vm.$attrs.delete}},[_c(VIcon,{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v("Delete ")],1)],1)],1):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"d-block text-center w-100 text-truncate pa-1 pl-2 notranslate"},[_vm._v(" "+_vm._s(_vm.item.filename)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }