import moment from 'moment';
import _ from 'lodash';

const orderStatusMap = {
  DRAFT: {
    displayText: 'Draft',
    color: '#DEDEDE',
    backgroundColor: '#F5F5F5',
  },
  PENDING_APPROVAL: {
    displayText: 'Pending Order Approval',
    color: '#DEDEDE',
    backgroundColor: '#F5F5F5',
  },
  REQUESTED_CHANGES: {
    displayText: 'Changes requested',
    color: '#E7D33D',
    backgroundColor: '#FFFAD6',
  },
  ACTIVE: {
    displayText: 'Order In Progress',
    color: '#8066FF',
    backgroundColor: '#ECE8FF',
  },
  COMPLETED: {
    displayText: 'Completed',
    color: '#8066FF',
    backgroundColor: '#ECE8FF',
  },
};

export const getStatusDisplayText = (status) => {
  const statusObj = orderStatusMap[status];
  return statusObj ? statusObj.displayText : status;
};

export const getStatusColor = (status) => {
  const statusObj = orderStatusMap[status];
  return statusObj ? statusObj.color : '';
};

export const getStatusBackground = (status) => {
  const statusObj = orderStatusMap[status];
  return statusObj ? statusObj.backgroundColor : '';
};

function weekOfMonth(m) {
  return m.week() - moment(m).startOf('month').week() + 1;
}
export const getWeeks = (year, month) => {
  const m = moment();
  m.set({ year, month, date: 1 }); // (months are 0-based, days are 1-based)
  const decNumDays = m.daysInMonth();
  const result = [];
  // eslint-disable-next-line
  for (let date = 1; date <= decNumDays; date++) {
    m.set('date', date);
    result.push({
      dayName: m.format('dddd'),
      dayOfMonth: date,
      weekOfMonth: weekOfMonth(m),
    });
  }
  const weeks = [];
  const objectKeys = _.groupBy(result, 'weekOfMonth');
  const week = Object.values(objectKeys);
  // eslint-disable-next-line
  for(let i=0; i < week.length; i++) {
    weeks[i] = week[i].map((x) => x.dayOfMonth);
  }
  return weeks;
};
