<template>
  <v-card
    class="mx-auto"
    flat
    v-if="preview"
    @click="getPdfFullFileView(file)"
  >
    <div class="d-flex justify-end card-body">
        <v-menu bottom left offset-y offset-x content-class="uploaded-content">
            <template v-slot:activator="{ on }">
                <v-btn
                  dark
                  icon
                  height="auto"
                  width="10"
                  v-on="on"
                  class="menu-btn"
                >
                <v-icon color="black" dense>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text class="w-100" @click="$emit('onDownload')">
                    <img
                      src="@/assets/svg/theme/download-small.svg"
                      class="download-icon"
                      width=24
                      height=14
                    />
                    Download
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$attrs.delete">
                  <v-list-item-title>
                    <v-btn text @click="$attrs.delete"
                    class="button-content w-100">
                    <v-icon small class="pr-2">mdi-delete</v-icon>Delete
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
      </div>
    <v-img
        class="mx-auto uploaded-file-type-image"
        :src="getOptimizedS3ImageURL(imageSrc, { width: 200, height: 200 })"
        centered
        vertical
    >
    </v-img>
    <v-card-title
      class="d-inline-block text-center w-100 text-truncate font-body-small"
    >
      {{ fileName }}
    </v-card-title>
    <v-container
      fluid
      v-if="previewModal"
    >
      <Modal
        persistent
        :key="file.id"
        :modal="previewModal"
        content-class="main-modal preview-modal"
      >
        <div class="content-kit-file-preview px-4" style="overflow: hidden">
          <v-row class="my-0 heading">
            <v-col cols="10" sm="10" md="4" class="d-flex align-center pb-0">
              <span class="text-capitalize name-align mr-5"> {{fileName}} </span>
              <v-icon color="darken-1" class="zoom-in"
               @click='zoomIn'>
                mdi-magnify-plus-outline
              </v-icon>
              <div class="pl-4">
                <v-icon color="darken-1" class="zoom-out" :disabled='zoom === 100' @click='zoomOut'>
                  mdi-magnify-minus-outline
                </v-icon>
              </div>
            </v-col>
            <v-col class="d-flex justify-end pb-0">
              <v-icon color="darken-1" class="close-icon" @click="previewModal=false">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <div class="item-body">
            <v-row class="ma-0 d-flex justify-center align-center">
              <v-col cols=12 class="pa-0">
                <div class="pdf-loading" v-if="pdfLoading">
                  <v-progress-circular indeterminate color="grey"/>
                </div>
                <div v-if="fileType === 'application/pdf'" class="pdf-file">
                  <div class="pdf-container">
                    <pdf
                      class="pdf-page-container"
                      :class='{"zoom-active": zoom > 100 }'
                      :style="{width: zoom + '%',}"
                      :width="zoom + '%'"
                      v-for="i in numPages"
                      :key="i"
                      :src="src"
                      :page="i"
                      type="application/pdf"></pdf>
                  </div>
                </div>
                <div v-else class="image-div text-center" aspect-ratio=1>
                  <img
                  :src="getOptimizedS3ImageURL(file, { width: 200, height: 200 })"
                  alt="..." :style="imageStyles()">
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </Modal>
    </v-container>
  </v-card>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { lookup } from 'mime-types';
import pdf from 'vue-pdf';
import Modal from '@/components/common/Modal';
import { getOptimizedS3ImageURL } from '@/helpers/';


export default {
  name: 'UploadedContent',
  components: {
    Modal,
    pdf,
  },
  props: {
    fileName: {
      type: String,
      default: 'file',
    },
    file: {
      type: String,
      default: '',
    },
    preview: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fileType() {
      return lookup(this.file);
    },
    fileExtension() {
      return this.file.split('.').pop().toLowerCase();
    },
    imageSrc() {
      if (this.fileTypes.includes(this.fileExtension)) {
        return require(`@/assets/svg/theme/${this.fileExtension}.svg`);
      }
      return require('@/assets/svg/theme/jpg.svg');
    },
    isLandscape() {
      const img = new Image();
      img.src = this.file;
      return img.width > img.height;
    },
  },
  prop: {
    onDownload: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return ({
      fileTypes: ['png', 'pdf', 'svg', 'jpg', 'ttf', 'fnt', 'fon', 'mp3', 'mp4', 'zip'],
      previewModal: false,
      src: '',
      numPages: 0,
      zoom: 100,
      pdfLoading: false,
    });
  },
  methods: {
    getOptimizedS3ImageURL,
    imageStyles() {
      return this.isLandscape ? 'max-width: 700px; aspect-ratio: 1.2' : 'max-height: 550px; aspect-ratio: 1.2';
    },
    zoomIn() {
      this.zoom += 25;
    },
    zoomOut() {
      this.zoom -= 25;
    },
    getPdfFullFileView(file) {
      this.previewModal = true;
      if (this.fileType === 'application/pdf') {
        this.pdfLoading = true;
        const loadingTask = pdf.createLoadingTask(file);
        this.src = loadingTask;
        this.src.promise.then((pdfFile) => {
          this.numPages = pdfFile.numPages;
          this.pdfLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    .v-card {
        border-radius: 0 !important;
        box-shadow: 0 2px 4px 0 $silver;
        border: solid 1px #e6e6e6;
        background-color: $neutral6;
    }
    .uploaded-file-type-image {
        width: 43px;
        height: 56px;
        border-radius: 0 !important;
    }
    .v-card__title {
        font-family: $fontFamily1;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.24px;
        color: $neutral1;
        padding: 10px;
        line-height: 1rem;
    }
    .uploaded-content {
      .v-list-item {
        min-height: 0 !important;
        padding: 0;
      }
    }
    .button-content{
      justify-content: left;
    }
  .v-list {
    border-radius: 0;
    padding: 0;
  }
  .menu {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 7;
  }
  .menu-btn {
    width: 10px;
    margin-right: 5px;
  }
  .v-menu__content {
    border-radius: 0;
  }
  .v-list-item {
    min-height: auto;
    padding: 0 5px;
    .v-btn {
      height: 30px;
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px;
        font-family: $fontFamily1;
        justify-content: flex-start;
        width: auto;
        .download-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding: 5px;
      }
    }
  }
  .profile-preview-modal {
    overflow-y: hidden !important;
  }
  .pdf-file {
    embed {
      min-height: 450px;
    }
  }
  .pdf-container{
    height: 100%;
    .pdf-page-container{
      width:100%;
      padding-bottom: 10px;
    }
  }
  .image-div {
    width: 100%;
    height: 100%;
    img {
      max-width: 100%;
      max-height: 480px;
    }
  }
  .item-body {
    max-height: 550px;
    overflow: auto;
  }
  .pdf-loading {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      height: 100%;
  }
  @media screen and (max-height: 766px) {
    .item-body {
      img {
        max-height: 440px;
      }
    }
  }
  @media screen and (max-height: 700px) {
    .item-body {
      embed, img {
        max-height: 360px;
      }
    }
}
@media screen and (max-width: 700px) {
    .pdf-loading {
      width: 80%;
    }
    .name-align {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
</style>
