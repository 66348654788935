<template>
  <div class="brand-music-container">
    <music-action-item
      :coverColor="coverColor"
      :audio="audio"
      :title="title"
    />
  </div>
</template>

<script>
import MusicActionItem from '@/components/profile/client/brandQuiz/wizard/Music/MusicActionItem';

export default {
  name: 'brand-music',
  components: {
    MusicActionItem,
  },
  props: {
    audio: String,
    title: String,
    coverColor: String,
  },
};

</script>
<style lang="scss" scoped>
.brand-music-container{
  max-width: 400px;
}
</style>
