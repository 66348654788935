<template>
  <v-container pa-0 class="h-100">
    <v-row>
      <v-col class="d-flex align-center pb-0 pt-5">
        <div class="text-uppercase card-heading">{{title}}</div>
      </v-col>
    </v-row>
    <v-row class="card-content">
      <v-col cols="12" class="d-flex agreements" v-if="files.length">
          <div
            class="uploaded-document"
            v-for="(document, index) in files"
            :key="index"
          >
          <UploadedContent
            :fileName="document.filename"
            :file="document.file"
            @onDownload="downloadAgreementFiles(document.id)"
          />
        </div>
      </v-col>
      <v-col v-else>
        You don't have any agreements at this time
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import UploadedContent from '@/components/common/UploadedContent';

export default {
  name: 'Agreements',
  components: {
    UploadedContent,
  },
  props: {
    title: {
      type: String,
      default: 'HANDBOOKS & AGREEMENTS',
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions('profile', ['downloadAgreement']),

    async downloadAgreementFiles(id) {
      const result = await this.downloadAgreement(id);
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .card-heading {
    font-weight: bold;
    letter-spacing: -0.28px;
    color: $card-title;
  }
  .agreements {
    overflow-x: auto;
    .uploaded-document {
      margin-right: 16px;
      .v-card {
        width: 122px;
        height: 122px;
      }
    }
  }
</style>
