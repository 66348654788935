<template>
  <div class="placeorder-container mb-5">
    <v-col
    xs="12"
    sm="12"
    md="11"
    lg="11"
    xl="10"
    xxl="10"
    style="border: 1px solid #dedede;border-radius: 10px;background: #f8f8f8">
    <v-row class="ma-0" style="border-bottom: 1px solid #dedede;">
      <v-col class="credits-heading pa-0 pb-2">
      Quick actions
      </v-col>
    </v-row>
    <v-col
      @click="openCreateOrderModal"
      class="task-btn pl-0 mt-3 pb-2 pt-2 text-center">
      Place Order
    </v-col>
    </v-col>
    <div v-if="showOrderModal">
      <OrderForm
        :open="showOrderModal"
        @close="closeCreateOrderModal"
        :orderId="orderToEdit.id"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderForm from '@/components/orders/OrderForm';

export default {
  components: {
    OrderForm,
  },
  data() {
    return {
      showOrderModal: false,
      orderToEdit: {},
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  methods: {
    ...mapActions('orders', ['createOrder']),
    async openCreateOrderModal() {
      this.orderToEdit = await this.createOrder({ businessId: this.userDetails.currentBusinessId });
      // have to set the order id
      this.$router.push(`/order_create/${this.orderToEdit.businessId}/${this.orderToEdit.id}`);
      // this.$router.push({
      //   name: 'Order Details',
      //   params: {
      //     businessId: this.orderToEdit.businessId,
      //     orderId: this.orderToEdit.id,
      //   },
      // });
    },
    closeCreateOrderModal() {
      this.orderToEdit = {};
      this.showOrderModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.placeorder-container {
  .credits-heading {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .task-btn {
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    line-height: 28px;
    color: white;
    border-radius: 30px;
    background-color: $primary1;
        // .v-btn {
        //   width: 275px;
        //   height: 42px;
        //   border-radius: 30px;
        //   box-shadow: none !important;
        //   ::v-deep .v-btn__content {
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //   }
        // }
      }
}
</style>
