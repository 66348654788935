import { render, staticRenderFns } from "./BusinessOverview.vue?vue&type=template&id=4e85bec5&scoped=true"
import script from "./BusinessOverview.vue?vue&type=script&lang=js"
export * from "./BusinessOverview.vue?vue&type=script&lang=js"
import style0 from "./BusinessOverview.vue?vue&type=style&index=0&id=4e85bec5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e85bec5",
  null
  
)

export default component.exports