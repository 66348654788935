<template >
  <div class="file-preview px-4 pb-2">
    <v-row class="my-0 heading">
      <v-col cols="11" class="d-flex align-center pb-0">
        <div
        class="text-capitalize text-ellipsis pl-2 notranslate modal-header-title">
        {{item.filename}} </div>
      </v-col>
      <v-col cols="1" class="d-flex justify-end pb-0 pl-0 pr-5">
        <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
      </v-col>
    </v-row>
      <v-divider class="my-2 pb-5"></v-divider>
    <div class="item-body">
        <v-btn
          icon
          x-large
          class="previous navigate-btn"
          @click="$emit('onNavigate', prevItemIndex)"
          :disabled="prevItemIndex < 0"
        >
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn
        icon
        x-large
        class="next navigate-btn"
        @click="$emit('onNavigate', nextItemIndex)"
        :disabled="nextItemIndex < 0"
      >
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
      <v-row class="ma-0 d-flex justify-center align-center h-100">
        <v-col cols=12 class="pa-0 height" :key="item.id">
          <div v-if="item.fileType == '2'" class="video-div h-100 text-center">
            <!-- <template v-if="fileFormat === 'mp4'"> -->
              <video-player
                :options="videoOptions"
                class="video-player"
              />
              <!-- <video-player
                :options="videoOptions"
                class="video-player"
              /> -->
            <!-- </template> -->
            <!-- <template v-else>
              <v-img contain
                :key="item.file"
                :src="defaultImage"
              >
              </v-img>
            </template> -->
          </div>
          <div v-else-if="item.fileType == '1'"  class="image-div height text-center">
            <img
                            loading="lazy"
                            style="max-height: 350px;object-fit:scale-down;"
                            :src="getOptimizedS3ImageURL(item.file)"
                          />
              <!-- <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5">
                  </v-progress-circular>
                </v-row>
              </template> -->
          </div>
          <div
           class="audio-div height"
           v-else-if="item.fileType == '4'"
          >
           <div class="audio-wrapper">
             <audio controls :src="item.file"></audio>
           </div>
          </div>
          <div v-else class="height">
            <template v-if="fileFormat === 'pdf'">
                <div class="pdf-container">
                  <div class="pdf-loading" v-if="pdfLoading">
                    <v-progress-circular indeterminate color="grey"/>
                  </div>
                <pdf
                  class="pdf-page-container"
                  v-for="i in numPages"
                  :key="item.id + i"
                  :src="src"
                  :page="i"
                  type="application/pdf">
                </pdf>
              </div>
            </template>
            <template v-else>
                <iframe class="contentkit-pdf"
                  :src="`https://view.officeapps.live.com/op/embed.aspx?src=${item.file}`"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  role="document"
                  width="100%"
                  height="500px"
                  :key="'contentkit-iframe-'+item.file"
                  frameborder="0"
                >
                </iframe>
            </template>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import pdf from 'vue-pdf';
import VideoPlayer from '@/components/common/VideoPlayer';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  /* eslint-disable global-require */
  components: {
    pdf,
    VideoPlayer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    prevItemIndex: {
      type: Number,
      default: 0,
    },
    nextItemIndex: {
      type: Number,
      default: 0,
    },
  //   src: {
  //     type: Object,
  //     required: true,
  //   },
  //   numPages: {
  //     type: Number,
  //     default: 0,
  //   },
  //   pdfLoading: {
  //     type: Boolean,
  //     default: false,
  //   },
  },
  watch: {
    item() {
      if (this.fileFormat === 'pdf') {
        setTimeout(() => {
          this.loadPdf();
        }, 0);
      }
    },
  },
  computed: {
    fileFormat() {
      return _.split(this.item.fileFormat, '/')[1];
    },
    defaultImage() {
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.item.file,
            type: 'video/mp4',
          },
        ],
        poster: this.getOptimizedS3ImageURL(this.poster, { width: 250, height: 250 }),
      };
    },
    poster() {
      return this.item.poster;
    },
    imageStyles() {
      return this.isLandscape ? 'max-width: 100%; max-height: 600px;' : 'max-height: 600px;';
    },
    mobileImageStyles() {
      return this.isLandscape ? 'max-width: 100%;' : 'max-height: 450px;';
    },
  },
  data() {
    return {
      src: '',
      numPages: 0,
      pdfLoading: false,
    };
  },
  methods: {
    getOptimizedS3ImageURL,
    loadPdf() {
      this.pdfLoading = true;
      const loadingTask = pdf.createLoadingTask(this.item.file);
      this.src = loadingTask;
      this.src.promise.then((pdfFile) => {
        this.numPages = pdfFile.numPages;
        this.pdfLoading = false;
      });
    },
  },
  mounted() {
    if (this.fileFormat === 'pdf') {
      this.loadPdf();
    }
  },
};
</script>

<style lang="scss" scoped>

.audio-div {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.image-div {
    padding-left: 25px;
    width: 96%;
    height: 100%;
    ::v-deep .v-image {
      max-height: 600px;
    }
  }
  .file-name {
    word-break: break-all;
  }
  .video-div {
    padding: 1rem;
  }
  .video-player {
      margin: 0 auto;
      max-height: 600px;
      background: white;
      width: 100%;
  }
  .video-player::v-deep .vjs-poster{
    background-color: white !important;
  }
.file-preview{
    background: white;
    padding-bottom: 2rem !important;
    .item-body {
      position: relative;
      padding-left: 2rem;
      padding-right: 2rem;
      .navigate-btn {
        padding: 0;
        position: absolute;
        bottom: 45%;
        z-index: 9;
        &.audio-file {
          top: 0;
        }
        &.previous {
          left: 0;
        }
        &.next {
          right: 0;
        }
      }
    }
  }
  .pdf-container{
    padding-left: 25px;
    overflow-y: auto;
    width: 99%;
    .pdf-loading {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pdf-page-container{
      width:100%;
      max-height: 470px !important;
      padding-bottom: 10px;
    }
  }
  .contentkit-pdf {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media screen and (min-width: 600px) and (max-width: 1024px) {
    .video-player {
      height: 500px;
    }
  }
  @media screen and (max-height: 766px) {
    .item-body {
      ::v-deep .v-image  {
        max-height: 400px;
      }
    }
    .video-player {
      margin: 0 auto;
      height: 400px;
    }
  }
  @media screen and (max-height: 700px) {
    .item-body {
      ::v-deep .v-image  {
        max-height: 300px;
      }
    }
  }
  @media screen and (max-width:600px) {
    .image-div, .video-div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .height {
      height: 90%;
    }
    .file-preview{
      height: 100%;
      .item-body {
        padding-left: 0px !important;
        padding-right: 0px !important;
        height: 100%;
        .navigate-btn {
          padding: 0 10px;
          position: absolute;
          bottom: 50%;
          z-index: 2;
          height: 30px;
          width: 30px;
          background: $neutral5;
        }
      }
    }
    .pdf-container {
      padding: 0 10px;
      height: 100%;
      width: 100%;
      display: -webkit-box;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .video-player {
      width: 100%;
      height: 500px !important;
    }
    .contentkit-pdf {
      padding: 0px;
      width: 100%;
      height: 650px;
    }
  }
  @media screen and (max-width: 1000px) and (orientation: landscape) {
    .file-preview {
      height: 100%;
    }
    .item-body {
      ::v-deep .v-image  {
        max-height: 250px;
      }
    .video-player {
      height: 250px !important;
    }
    }
  }
  @media screen and (min-width: 601px) {
    .file-preview {
      height: 100%;
    }
    .item-body {
      height: 80%;
      display: flex;
    }
  }
</style>
