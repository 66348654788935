<!-- eslint-disable max-len -->
<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 3H13.425C13.2509 2.15356 12.7904 1.39301 12.1209 0.846539C11.4515 0.300068 10.6142 0.00109089 9.75 0L8.25 0C7.38585 0.00109089 6.54849 0.300068 5.87906 0.846539C5.20964 1.39301 4.74907 2.15356 4.575 3H3.75C2.7558 3.00119 1.80267 3.39666 1.09966 4.09966C0.396661 4.80267 0.00119089 5.7558 0 6.75L0 14.25C0.00119089 15.2442 0.396661 16.1973 1.09966 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H14.25C15.2442 17.9988 16.1973 17.6033 16.9003 16.9003C17.6033 16.1973 17.9988 15.2442 18 14.25V6.75C17.9988 5.7558 17.6033 4.80267 16.9003 4.09966C16.1973 3.39666 15.2442 3.00119 14.25 3ZM8.25 1.5H9.75C10.2137 1.50192 10.6655 1.64706 11.0435 1.91557C11.4216 2.18407 11.7074 2.56282 11.862 3H6.138C6.29256 2.56282 6.57842 2.18407 6.95648 1.91557C7.33453 1.64706 7.7863 1.50192 8.25 1.5ZM3.75 4.5H14.25C14.8467 4.5 15.419 4.73705 15.841 5.15901C16.2629 5.58097 16.5 6.15326 16.5 6.75V9H1.5V6.75C1.5 6.15326 1.73705 5.58097 2.15901 5.15901C2.58097 4.73705 3.15326 4.5 3.75 4.5ZM14.25 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V10.5H8.25V11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12C9.19891 12 9.38968 11.921 9.53033 11.7803C9.67098 11.6397 9.75 11.4489 9.75 11.25V10.5H16.5V14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5Z" :fill="color"/>
  </svg>
</template>

<script>

export default {
  name: 'Business',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>
