<template>
  <div class="bg-white h-100">
    <Loader v-if="loaded" />
    <div v-if="!loaded" class="main-container h-100">
      <div class="title text-purple d-flex align-center">
        <div @click="$router.push('/projects')">
          <span>All Businesses</span>
          <v-icon style="font-size: 16px;">mdi-chevron-right</v-icon>
        </div>
      </div>
      <v-row class="ma-0 my-4">
        <v-col class="d-flex align-center justify-start pa-0">
          <div style="width: 50px; height: 50px; margin-right: 15px;">
            <img
              :src="getOptimizedS3ImageURL(bannerImage, { width: 250, height: 250 })"
              :key="business.id"
              style="height: 100%; width: 100%; object-fit: cover;"
              :style="`border-radius: ${business.bannerImage && '50%'} `"
            />
          </div>
          <span class="title" :key="business.id">
            {{ business.name }}
          </span>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-select
          v-if="$vuetify.breakpoint.xsOnly"
          solo
          flat
          class="type-selection mt-5 notranslate tabs-dropdown"
          placeholder="Select..."
          :items="tabs"
          item-text="name"
          item-value="value"
          v-model="currentTab"
          @change="updateSelect"
          :menu-props="{ 'content-class': 'notranslate' }"
          append-icon="mdi-chevron-down"
          hide-details
        ></v-select>
        <v-tabs
        v-else
        v-model="currentTab"
        class="business-tabs">
          <v-tab v-for="(tab, index) in tabs" :key="index" @click="updateStep(0, tab.nameKey)">
            {{ tab.name }}
          </v-tab>
        </v-tabs>
        <div class="divider"></div>
        <v-tabs-items v-model="currentTab" class="business-tab-content">
          <v-tab-item
          v-for="(tab, index) in tabs"
          :key="index"
          :transition="false"
          :reverse-transition="false">
          <ProjectOverview
          v-if="tab.nameKey === 'brief'"
              @showSweeps="
                (type, sweepId) => {
                  goToSweepsTab('sweeps', type, sweepId, 'brief');
                }
              "
            />
            <AffiliatedBusinesses
            v-if="tab.nameKey === 'businesses'"
            :businessId="businessId"
            :refreshKey="refreshKey" />
            <BusinessBrandProfile
            v-if="tab.nameKey === 'brandkit'"/>
            <div
            v-if="tab.nameKey === 'orders'">
              <Orders
              v-if="currentStep === 0"
              @goToOrder="(orderId) => goToOrder(orderId)"
              @showSweeps="
                () => {
                  goToSweepsTab('sweeps', 1, '', 'orders');
                }
              "/>
              <OrderInfo
                v-if="currentStep === 1"
                :orderId="selectedOrderId"
                @backToAllOrders="backToAllOrders"
              />
            </div>
            <AssetLibrary
            v-if="tab.nameKey === 'assetLibrary'"
            :currentTab="currentTab" page="business" />
            <Sweeps
            v-if="tab.nameKey === 'sweeps'"
              alignment="justify-left"
              :selectedBusinessId="businessId"
              :sweepsType="currentStep"
              :sweepId="sweepId"
            />
            <div class="settings"
            v-if="tab.nameKey === 'settings'">
              <Settings
                :selectedBusinessId="businessId"
                :reference="business.type === '1' ? 'business' : 'organization'"
                through="business"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { getOptimizedS3ImageURL } from '@/helpers/';
import fallbackBusinessImage from '@/assets/svg/businesses.svg';
import fallbackOrganizationImage from '@/assets/svg/organisations.svg';
import ProjectOverview from '@/components/projects/ProjectOverview';
import BusinessBrandProfile from '@/views/BusinessBrandProfile';
import AssetLibrary from '@/components/AssetLibrary/AssetLibrary';
import Settings from '@/components/organizations/Settings';
import Sweeps from '@/views/SweepManagement';
import Orders from '@/views/Orders';
import OrderInfo from '@/components/orders/SingleOrderInfo';
import AffiliatedBusinesses from '@/views/AffiliatedBusinesses';
import Loader from '@/components/common/Loader';

export default {
  name: 'BusinessOverView',
  components: {
    ProjectOverview,
    BusinessBrandProfile,
    AssetLibrary,
    Settings,
    Sweeps,
    Orders,
    OrderInfo,
    AffiliatedBusinesses,
    Loader,
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('user', ['userDetails']),
    canShowBusinessTab() {
      return this.business.type === '2' && ['admin', 'executive', 'producer', 'editor'].includes(this.role);
    },
    bannerImage() {
      const businessIcon = this.business.type === '1' && fallbackBusinessImage;
      const orgIcon = this.business.type === '2' && fallbackOrganizationImage;
      const defaultImage = this.business.type === '1' ? businessIcon : orgIcon;
      return this.business.bannerImage || defaultImage;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    enableSettings() {
      return ['admin', 'producer', 'executive', 'editor'].includes(this.role);
    },
    enableSettings2() {
      return ['admin', 'producer', 'executive'].includes(this.role);
    },
    canAccess() {
      return !['creative'].includes(this.role);
    },
    tabs() {
      const data = [
        {
          name: 'Creative Brief',
          nameKey: 'brief',
        },
        {
          name: 'Brand Kit',
          nameKey: 'brandkit',
        },
        {
          name: 'Asset Library',
          nameKey: 'assetLibrary',
        },
      ];
      if (this.canShowBusinessTab) {
        data.splice(1, 0, {
          name: 'Businesses',
          nameKey: 'businesses',
        });
      }
      if (this.canAccess) {
        const index = this.canShowBusinessTab ? 3 : 2;
        data.splice(index, 0, {
          name: 'Orders',
          nameKey: 'orders',
        });
      }
      if (this.enableSettings) {
        data.push(
          {
            name: 'Sweeps',
            nameKey: 'sweeps',
          },
        );
      }
      if (this.enableSettings2) {
        data.push(
          {
            name: 'Settings',
            nameKey: 'settings',
          },
        );
      }
      const updatedData = [];
      _.map(data, (item, i) => {
        updatedData.push({ ...item, value: i });
      });
      return updatedData;
    },
  },
  methods: {
    ...mapActions('business', ['getBusiness', 'getContentPlan']),
    ...mapActions('project', ['getProject']),
    getOptimizedS3ImageURL,
    async goToSweepsTab(tabType, type, sweepId, referedFrom) {
      if (referedFrom === 'brief') {
        this.$router.replace({
          query: {
            businessId: this.$route.query.businessId,
            activeTab: 'sweeps',
          },
        });
      }
      let index = 0;
      this.tabs.map((tab, i) => {
        if (tab.nameKey === tabType) {
          index = i;
        }
        return false;
      });
      this.currentTab = index;
      this.currentStep = type;
      this.sweepId = type === 0 ? null : sweepId;
      this.$router.replace({ query: { ...this.$route.query, activeTab: tabType } });
    },
    updateSelect(data) {
      this.tabs.map((tab) => {
        if (tab.value === data) {
          this.$router.replace({ query: { ...this.$route.query, activeTab: tab.nameKey } });
        }
        return false;
      });
    },
    updateStep(step, tabType) {
      this.currentStep = step;
      this.$router.replace({ query: { ...this.$route.query, activeTab: tabType } });
    },
    goToOrder(orderId) {
      this.currentStep = 1;
      this.selectedOrderId = orderId;
    },
    backToAllOrders(orderId) {
      this.currentStep = 0;
      this.selectedOrderId = orderId;
    },
  },
  data() {
    return {
      refreshKey: Date.now().toString(),
      loaded: true,
      currentTab: 0,
      currentStep: 0,
      selectedOrderId: '',
      sweepId: '',
    };
  },
  watch: {
    currentTab(VAL) {
      this.refreshKey = Date.now().toString();
      if (VAL === 2) {
        const { businessId } = this.$route.query;
        this.getContentPlan({ businessId });
      }
    },
  },
  async mounted() {
    const { projectId } = this.$route.params;
    const { project } = await this.getProject(projectId);
    this.businessId = project.businessId;
    this.$router.replace({
      path: this.$route.fullPath,
      query: {
        businessId: this.businessId,
      },
    });
    await this.getBusiness(this.businessId);
    const { activeTab, orderId } = this.$route.query;
    if (activeTab) {
      this.tabs.map((tab, i) => {
        if (tab.nameKey === activeTab) {
          this.currentTab = i;
        }
        return false;
      });
      if (['orders'].includes(activeTab)) {
        if (orderId) {
          this.currentStep = 1;
          this.selectedOrderId = orderId;
        }
      }
    }
    this.loaded = false;
  },
};
</script>

<style lang="scss" scoped>
.text-purple {
  cursor: pointer;
  width: fit-content;
  font-weight: 400;
  font-size: 18px;
}
.business-tab-content {
  width: 100%;
}
.business-tabs {
  ::v-deep.v-tab {
    letter-spacing: 0px !important;
    font-size: 15px !important;
  }
}
</style>
