<template>
  <div class="wrapper-container">
    <div class="header">
        <div class="header-title font-label">{{this.title}}</div>
        <div class="header-actions" v-if="canEdit">
            <div class="header-action" v-if="!hideEditAction">
                <img src="@/assets/svg/theme/edit.svg" @click="$emit('edit')"/>
            </div>
        </div>
    </div>
    <div class="content">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editable-section-wrapper',
  props: {
    title: String,
    hideEditAction: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.wrapper-container{
    border: 1px solid $neutral5;
    flex: 1;
    padding: 24px 20px ;
    background: white;
    border-radius: 0.5rem;
    margin-bottom: 00.5rem;
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #EAEAEA;
    padding-bottom: 0.5rem;
    .header-title{
        margin-right: 0.5rem;
    }
    .header-action{
        cursor: pointer;
    }
}
.content{
    margin-top: 0.5rem;
}
</style>
