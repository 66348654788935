<template >
  <div :key="items">
    <div v-if="items.length">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="ma-0 content-kit-files-grid d-flex align-stretch">
        <v-col cols=6 md=4 lg=2 xl=2 class="pt-0 pb-1 mb-5 file-padding"
          v-for="(item) in items" :key="`content-kit-${item.id}`">
          <div class="content-kit-file-container" :class="{active: isSelected(item.id)}">
            <v-checkbox
              v-model="selected"
              :value="item.id"
              dense
              class='multi-check'
              off-icon="mdi-checkbox-blank-circle-outline"
              on-icon="mdi-checkbox-marked-circle"
              @change="$emit('selected', $event, fileType)"
            ></v-checkbox>
            <content-kit-file
              :item="item"
              :activeClass="{ active: isSelected(item.id) }"
              :section="section"
              class="content-kit-file"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-col
        v-if="activeFilter"
        class="pt-0 file-padding"
      >
      Sorry, no results found!
      Please try searching for something else or applying a different filter.
      </v-col>
      <v-col class="pt-0 pb-4 file-padding" v-else>
        Uh oh. You don't have any files uploaded in this section
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import ContentKitFile from './AssetFile';

export default {
  components: {
    ContentKitFile,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    section: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: 'photo',
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['contentKitFilters']),
    activeFilter() {
      return _.get(this.contentKitFilters, 'search', '').length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0;
    },
  },
  data() {
    return {
      selected: this.selectedItems,
    };
  },
  methods: {
    isSelected(itemId) {
      return _.includes(this.selected, itemId);
    },
  },
};
</script>


<style scoped lang="scss">
  .content-kit-file-container {
    background: white;
    height: 100%;
    position: relative;
    border: solid 1px #edeff2 !important;
    &.active {
      border: solid 1px $lightGreen !important;
    }
  }
  .multi-check {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
    ::v-deep {
      .v-icon{
        background-color: rgba(0, 0, 0, 0.2);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        font-size: 18px;
        &.mdi-checkbox-blank-circle-outline{
        color: #fff !important;
        }
        &.mdi-checkbox-marked-circle{
          color: $lightGreen !important;
        }
      }
      .v-input--selection-controls__ripple {
        width: 18px;
        height: 18px;
      }
    }
    &.v-input--is-label-active {
      ::v-deep {
        .v-icon{
          background-color: white;
        }
      }
    }
  }
  .v-menu__content {
    border-radius: 0;
    margin-right: 10px;
  }
  .v-list-item {
    min-height: auto;
    padding: 0;
    .v-btn {
      height: 30px;
      :hover::before {
        opacity: 0.9 !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px;
        justify-content: flex-start;
        width: auto;
        .download-icon, .archive-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding-right: 50px;
      }
    }
  }
  @media (max-width: 600px) {
    .file-padding {
      padding-left: 4px;
    }
  }

</style>
