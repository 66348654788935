<template>
  <v-container class="pa-0">
    <v-row class="credits ma-0">
      <v-col xs="12" sm="12" md="12" lg="12" class="pt-0">
        <v-row class="credts-row">
          <div class="heading1">Credits</div>
          <div class="heading2 text-purple d-flex align-center"
          v-if="authorizedToUpdateCredits"
          @click="showOptionToUpdateCredits = true">+ Add / Subtract Credits</div>
          <!-- <div class="heading2 d-flex align-center"
          v-if="owners">Purchase Credits</div> -->
        </v-row>
        <v-row class="plan">
          <v-col cols="12" class="px-0 pt-5">
            <p class="d-flex align-center mb-2">
              CREDIT BANK
              <span>
                <v-tooltip bottom content-class="affiliatedOrg-tooltip">
                    <template v-slot:activator="{ on }">
                    <div
                        v-on="on"
                        style="width: 16px; height: 16px; margin-left: 10px; cursor: pointer;"
                    >
                        <v-img :src="infoSvg" />
                    </div>
                    </template>
                    <span>
                      <div style="max-width: 200px;">Total number of credits available for
                      the purchase of digital assets or related services.</div>
                    </span>
                </v-tooltip>
              </span>
            </p>
            <div class="count" :key="business.credits">{{ business.credits }}</div>
            <div class="history text-purple" @click="showHistory = true">View History</div>
          </v-col>
          <v-col cols="12" class="px-0">
            <p class="mb-1">SUBSCRIPTION PLAN</p>
            <div class="plan-type">
              {{ business.subscriptionBlends.length > 0
              ? business.subscriptionBlends[0].name : '-' }}
              <!-- <span class="plan-desc"> Renews in 2 weeks (5/23/2022) </span> -->
            </div>
            <div
            class="billingLink"
            @click="$router.push('/billing')"
            v-if="owners">Go To Billing</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container
      fluid
      v-if="showHistory"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="showHistory"
        scrollable
      >
        <CreditHistory
        @close-modal="showHistory = false"/>
      </Modal>
    </v-container>
    <v-container
      fluid
      v-if="showOptionToUpdateCredits"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="showOptionToUpdateCredits"
        scrollable
      >
        <UpdateCredits
        @close-modal="showOptionToUpdateCredits = false"/>
      </Modal>
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import infoIcon from '@/assets/svg/info.svg';
import CreditHistory from '@/components/credits/History';
import UpdateCredits from '@/components/credits/UpdateCredits';

export default {
  name: 'CreditsManagement',
  components: {
    Modal,
    CreditHistory,
    UpdateCredits,
  },
  methods: {
    ...mapActions('business', ['getReasons', 'getCreditsHistory']),
  },
  data() {
    return {
      showHistory: false,
      showOptionToUpdateCredits: false,
    };
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('user', ['userDetails']),
    infoSvg() {
      return infoIcon;
    },
    role() {
      return _.get(this.userDetails, 'role.name', '');
    },
    isAdmin() {
      return ['admin', 'executive'].includes(this.role);
    },
    authorizedToUpdateCredits() {
      return this.isAdmin || (['producer'].includes(this.role) && this.business.assigned);
    },
    customer() {
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(this.role);
    },
    owners() {
      return ['client', 'agency_owner'].includes(this.role);
    },
    businessId() {
      let { businessId } = this.$route.query;
      if (this.customer) {
        businessId = _.get(this.userDetails, 'currentBusinessId');
      }
      if (this.$route.name === 'Organization') {
        businessId = this.$route.params.organizationId;
      }
      return businessId;
    },
  },
  async mounted() {
    await this.getReasons();
    await this.getCreditsHistory({ businessId: this.businessId });
  },
};
</script>
<style lang="scss" scoped>
.credits {
  .credts-row {
    border-bottom: 1px solid #babfc7;
    justify-content: space-between;
    .heading1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #929292;
    }
    .heading2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .plan {
    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #999999;
      margin-bottom: 0px !important;
    }
    .count {
      font-weight: 400;
      font-size: 34px;
      line-height: 51px;
      color: #262626;
    }
    .history {
      cursor: pointer;
      width: max-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    .plan-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      .plan-desc {
        padding-left: 10px;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #96A9A9
      }
    }
    .billingLink {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $secondary1;
      cursor: pointer;
    }
  }
}
</style>
