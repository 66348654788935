<template>
<div style="border: solid 1px #e6e6e6;">
  <v-hover v-slot:default="{ hover }">
    <div
    @click="$emit('click', item)"
    class="kit" :class="{ 'on-hover': hover}">
      <v-card class="mx-auto kit-inner" :class="from" flat>
        <div
          v-if="['mp4', 'mov'].includes(fileExtension)"
          class="brand-kit-play w-100 h-100 d-flex align-center justify-center"
        >
          <div><v-icon class="play-btn">mdi-play</v-icon></div>
        </div>
        <v-row flex-column class="ma-auto">
        <div class="d-flex justify-end card-body ma-auto">
          <v-menu bottom left offset-y offset-x content-class="uploaded-content">
            <template v-slot:activator="{ on }">
              <v-btn dark icon v-on="on" height="auto" width="auto" v-show="hover" class="menu-btn">
                <v-icon color="black" dense>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text class="w-100" @click="$emit('onDownload')">
                    <img
                      src="@/assets/svg/theme/download-small.svg"
                      class="download-icon"
                      width="24"
                      height="14"
                    />
                    Download
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$attrs.delete">
                <v-list-item-title>
                  <v-btn text class="w-100" @click="$attrs.delete">
                    <img
                    style="filter: brightness(0%);"
                      src="@/assets/svg/theme/trash.svg"
                      class="download-icon"
                      width="24"
                      height="14"
                    />
                    Delete
                  </v-btn>
                  <!-- <v-btn text @click="$attrs.delete" class="button-content w-100">
                    <v-icon small class="pr-2">mdi-delete</v-icon>Delete
                  </v-btn> -->
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div
            :class="{ 'no-image': !validImage }"
            class="card-image d-flex justify-center align-center ma-auto"
          >
            <div
              class="long-copy d-flex justify-center align-center w-100 h-100"
              v-if="!validImage"
            >
              <img
                :src="getOptimizedS3ImageURL(imageSrc, { width: 250, height: 250 })"
                alt="no-image"
                :class="{ 'default-image': !validImage }"
              />
            </div>
            <img
              v-else
              :src="getOptimizedS3ImageURL(imageSrc, { width: 250, height: 250 })"
              alt="no-image"
              :class="{ 'default-image': !validImage }"
            />
          </div>
        </div>
        </v-row>
      </v-card>
    </div>
  </v-hover>
  <div
    class="filename-text py-1 d-flex align-center font-body-small"
    v-if="parentComponent === 'brandFiles'">
      <!-- <img
      width="15"
      height="15"
      src="@/assets/svg/theme/project-banner-default.svg"/> -->
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
        v-bind="attrs"
        v-on="on"
        class="notranslate"> {{ fileName }} </span>
      </template>
      <span class="notranslate"> {{ fileName }} </span>
    </v-tooltip>
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { lookup } from 'mime-types';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'UploadedContent',
  components: {},
  props: {
    addHeight: {
      type: Boolean,
      default: false,
    },
    onDownload: {
      type: Function,
      default: () => {},
    },
    parentComponent: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    brandQuiz: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  computed: {
    fileType() {
      return lookup(this.file);
    },
    fileExtension() {
      const file = this.item.fileUrl || this.item.kit;
      return file
        .split('.')
        .pop()
        .toLowerCase();
    },
    fileName() {
      return this.item.filename;
    },
    validImage() {
      return this.thumbnailFormats.includes(this.fileExtension);
    },
    imageSrc() {
      const file = ['mp4', 'mov'].includes(this.fileExtension)
        ? this.item.poster
        : this.item.fileUrl || this.item.kit || this.item.thumbnail;
      if (this.validImage) {
        return file;
      }
      return require(`@/assets/svg/theme/${this.fileExtension}.svg`);
    },
  },
  data() {
    return {
      thumbnailFormats: ['jpg', 'jpeg', 'png', 'mp4', 'mov'],
    };
  },
};
</script>

<style lang="scss" scoped>
  .filename-text {
    background-color: #ffff;
    width: 100%;
    padding: 10px 8px;
    span {
      padding-left: 8px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
      height: 26px;
    }
  }
.kit-inner {
  height: 100%;
}
.card-body {
  height: 100%;
  .menu-btn {
    position: absolute;
  }
}
.brainstorm-image {
  .card-body {
    display: none !important;
  }
}
.brainstorm-image.onhover {
  .card-body {
    display: block !important;
  }
}
.profileView {
  .card-image {
    height: 122px !important;
  }
}
.kit {
  cursor: pointer;
  font-size: 11px;
  height: 122px;
  .card-image .default-image {
    height: 80px !important;
  }
  .card-image {
    height: 122px;
    width: 122px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
.v-card {
  background-color: $neutral6;
}
.uploaded-file-type-image {
  width: 43px;
  height: 56px;
  border-radius: 0 !important;
}
.v-card__title {
  font-family: $fontFamily1;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.24px;
  color: $neutral1;
  padding: 10px;
  line-height: 1rem;
}
.uploaded-content {
  .v-list-item {
    min-height: 0 !important;
    padding: 0;
  }
}
.button-content {
  justify-content: left;
}
.v-list {
  border-radius: 0;
  padding: 0;
}
.menu {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 5px;
  z-index: 7;
}
.menu-btn {
  width: 10px;
  margin-right: 5px;
}
.v-menu__content {
  border-radius: 0;
}
.v-list-item {
  min-height: auto;
  padding: 0 5px;
  .v-btn {
    height: 30px;
  }
  ::v-deep {
    .v-btn__content {
      font-size: 14px;
      font-family: $fontFamily1;
      justify-content: flex-start;
      width: auto;
      .download-icon {
        padding-right: 5px;
      }
    }
    .v-btn {
      padding: 5px;
    }
  }
}
.brand-kit-play {
  position: absolute;
  .play-btn {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
  }
}
</style>
