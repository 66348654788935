<template >
  <div>
    <v-row class="my-0">
      <v-col  cols="8" class="d-flex py-0" md=10>
        <v-card-title class="pa-0">{{title}}</v-card-title>
      </v-col>
      <v-col cols="4" class="d-flex justify-end py-0" md=2 v-if="canEdit">
        <v-btn icon small @click="toggleMode">
          <img src="@/assets/svg/theme/edit.svg" />
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text
      class="text--primary pa-0 mt-1 html-content"
      v-html="`<pre>${value ? value : ''}</pre>`"
      :style="{height: contentHeight}"
      v-linkified
    >
    </v-card-text>
    <v-container
      fluid
      v-if="mode === 'edit'"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="html-editor"
        :key="title"
        :modal="mode === 'edit'"
      >
        <v-card flat>
          <v-card-title> {{title}}</v-card-title>
          <v-divider class="my-2"></v-divider>
          <BaseHtmlEditor :content="value" class="py-2 px-5"
            @save="handleSave"
            @cancel="toggleMode"> </BaseHtmlEditor>
        </v-card>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import linkify from 'vue-linkify';
import BaseHtmlEditor from '@/components/common/BaseHtmlEditor';
import Modal from '@/components/common/Modal';

export default {
  components: {
    BaseHtmlEditor,
    Modal,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    contentHeight: {
      type: String,
      default: 'auto',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'normal',
      showModal: false,
      content: this.value,
    };
  },
  methods: {
    toggleMode() {
      if (this.mode === 'normal') {
        this.mode = 'edit';
      } else {
        this.mode = 'normal';
      }
    },
    handleSave(val) {
      this.mode = 'normal';
      this.$emit('save', val);
    },
    updateValue(val) {
      this.value = val;
    },
  },
};
</script>

<style lang="scss">
  .v-card__text {
    font-size: 16px;
    line-height: 1.56;
    overflow: auto;
  }
  .html-content {
    p {
      padding: 0;
      margin-top: 16px !important;
      margin-bottom: 16px !important;
      min-height: 1em;
    }
    pre {
      font-family: inherit;
    }
  }

  .html-editor {
    max-width: 700px;
    .v-card, .v-card__title {
      border-radius: 0px !important;
    }
    .tiptap-vuetify-editor .v-card{
      min-height: 300px;
    }
  }
</style>
