<template>
    <v-container class="blendedsense">
        <v-container
        fluid
        v-if="isModal"
        >
            <Modal
                persistent
                fullscreen
                :modal="isModal"
                content-class="main-modal preview-modal full-screen"
                width="80%"
                scrollable
            >
                <v-card class="pa-5 confirm-popup" flat>
                    <v-card-title class="d-flex titleCard">
                        <v-row class="my-0 mr-0 ml-0 title">
                            <v-col
                            class="d-flex align-center modal-header-title py-0 pl-0" cols=10>
                              Browse from Blended Sense
                            </v-col>
                            <v-col class="d-flex justify-end pa-0" cols=2>
                            <v-icon color="darken-1"
                            @click="$emit('closeModal', false)">mdi-close</v-icon>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-row>
                        <v-container fluid class="content-wrapper">
                            <ContentKitFilters
                            :showSearch="canShowSearch"
                            > </ContentKitFilters>
                            <ContentKitOverview
                            :isLoading="loading"
                            @filters-change="filtersValue"
                            @modal="setBlendedSenseModal"
                            > </ContentKitOverview>
                        </v-container>
                    </v-row>
                </v-card>
            </Modal>
        </v-container>
    </v-container>
</template>

<script>
import Modal from '@/components/common/Modal';
import ContentKitFilters from '@/components/profile/creative/blendedSense/AssetsFilters';
import ContentKitOverview from '@/components/profile/creative/blendedSense/AssetsOverview';

export default {
  name: 'Blendedsense',
  components: {
    Modal,
    ContentKitFilters,
    ContentKitOverview,
  },
  data() {
    return ({
      isModal: false,
      loading: false,
      canShowSearch: true,
    });
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    openModal(val) {
      this.isModal = val;
    },
  },
  methods: {
    filtersValue(value) {
      if (value > 0) {
        this.canShowSearch = true;
      } else {
        this.canShowSearch = false;
      }
    },
    setBlendedSenseModal(val) {
      this.$emit('closeModal', val);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  padding: 0px 20px;
  .align-center {
    word-break: break-word;
    font-family: $fontFamily1;
  }
}
@media (max-width: 600px) {
  .titleCard {
    padding: 0px !important;
    .title {
      padding: 0px;
      .align-center {
        font-size: 14px;
      }
    }
  }
}
</style>
