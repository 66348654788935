<template>
  <div class="affiliated-list h-100">
    <Loader v-if="fetching"/>
    <grid-list
      v-else
      :headers="headers"
      :items="items"
      :fetching="fetching"
      @onModalOpen="$emit('onModalOpen')"
      :search="search"
      @rowClicked="(data) => $emit('rowClicked', data)"
      :refreshKey="refreshKey"
    >
    </grid-list>
  </div>

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import moment from 'moment-shortformat';
import GridList from '@/components/organizations/GridList';
import AvatarRender from '@/components/organizations/AvatarRender';
import BtnActions from '@/components/organizations/BtnActions';
import { getOptimizedS3ImageURL } from '@/helpers/';
import businessesIcon from '@/assets/svg/businesses.svg';
import Loader from '@/components/common/Loader';

export default {
  components: {
    GridList,
    Loader,
  },
  props: {
    businesses: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    refreshKey: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['businessTypes']),
    ...mapGetters('project', ['currentProject']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    headers() {
      const $this = this;
      const items = [];
      if ($this.$vuetify.breakpoint.smAndUp) {
        items.push($this.businessColumn());
        items.push($this.businessTypeColumn());
      }
      if ($this.$vuetify.breakpoint.smAndDown) {
        items.push($this.businessColumn2());
      }
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      if (['admin', 'agency_owner', 'executive'].includes(this.role) || (['producer'].includes(this.role) && assigned)) {
        items.push($this.btnActionsColumn());
      }
      return items;
    },
    items() {
      return _.map(this.businesses, (business) => ({
        id: business.id,
        business,
        name: business.name,
        bannerImage: business.bannerImage,
        createdDate: business.date ? moment(business.date).format('M/D/YY') : null,
        ownerName: this.ownerName(business),
        // team: business.business.userBusiness,
        status: this.getStatus(business.status),
        businessType: this.businessTypeName(business),
      }));
    },
  },

  methods: {
    getOptimizedS3ImageURL,
    ownerName(business) {
      const users = _.get(business, 'business.userBusiness');
      let primaryOwner = {};
      _.map(users, (user) => {
        if (user.user.isPrimaryOwner) {
          primaryOwner = user.user;
        }
      });
      return `${primaryOwner.firstName} ${primaryOwner.lastName}`;
    },
    getStatus(status) {
      switch (status) {
        case '0':
          return 'Inactive';
        case '2':
          return 'Archived';
        default:
          return 'Active';
      }
    },
    businessTypeName(business) {
      const type = _.get(_.filter(this.businessTypes, { id: business.businessTypeId }), '[0].name');
      return type;
    },
    openModal(val, type) {
      this.$emit('onModalOpen', val, type);
    },
    businessColumn() {
      return {
        headerName: 'business',
        headerClass: 'name-column',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap justify-start w-100 pl-1',
        width: 100,
        cellRenderer: (params) => `<div class="d-flex align-center">
        <div class="d-flex">
          <div style="width: 40px; height: 40px;padding-right: 5px;">
            <img src=${getOptimizedS3ImageURL(params.data.bannerImage || businessesIcon, { width: 250, height: 250 })} style="width: 100%; height: 100%; object-fit: contain;"/>
          </div>
        </div>
        <span class="text-purple ml-6"
        style="font-weight: 400;
                      width: 100px;
                      position: relative;
                      font-size: 16px;
                      line-height: 22px;">${params.value}</span></div>
                      `,
      };
    },
    businessColumn2() {
      return {
        headerName: 'business',
        headerClass: 'name-column',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap justify-start w-100 pl-1',
        width: 100,
        cellRenderer: (params) => `<div class="d-flex align-center">
        <div class="d-flex">
          <div style="width: 40px; height: 40px;padding-right: 5px;">
            <img src=${getOptimizedS3ImageURL(params.data.bannerImage || businessesIcon, { width: 250, height: 250 })} style="width: 100%; height: 100%; object-fit: contain;"/>
          </div>
        </div>
        <div class="mt-5 ml-1"
        style="width: 250px;overflow-x: hidden !important;text-overflow: ellipsis !important;">
        <span class="text-purple"
        style="font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;">${params.value}</span>
                      <div style="width: 250px;">
                      <p
                      >${params.data.businessType}</p></div></div>
                      `,
      };
    },
    businessTypeColumn() {
      return {
        headerName: 'Business Vertical',
        field: 'businessType',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap  justify-start',
        width: 100,
      };
    },
    ownerNameColumn() {
      return {
        headerName: 'Owner',
        field: 'ownerName',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap',
        width: 80,
      };
    },
    teamColumn() {
      return {
        headerName: 'team',
        field: 'team',
        cellStyle: { textAlign: 'left' },
        width: 160,
        height: 200,
        cellClass: 'notranslate text-wrap',
        cellRendererFramework: AvatarRender,
      };
    },
    statusColumn() {
      return {
        field: 'status',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap',
        width: 60,
      };
    },
    btnActionsColumn() {
      const $this = this;
      return {
        headerName: '',
        cellStyle: { display: 'flex', justifyContent: 'flex-end', 'margin-left': '10px' },
        field: 'id',
        cellRendererFramework: BtnActions,
        cellRendererParams: {
          role: this.role,
          type: 'business',
          clicked(params, type) {
            $this.openModal(params.value, type);
            return false;
          },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.affiliated-list {
  ::v-deep.ag-body-horizontal-scroll-viewport {
    overflow-x: hidden !important;
  }
}
@media (min-width: 0px) and (max-width: 700px) {
  .affiliated-list {
  ::v-deep.ag-theme-alpine .ag-header-cell {
    width: 200px !important;
  }
  }
}
</style>
