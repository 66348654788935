<template>
  <v-container fluid class="content-wrapper project-overview-container">
    <Loader v-if="loading" style="height:60vh;" />
    <v-row class="ma-0 d-flex" v-if="!loading">
      <v-col cols=12 md=4 class="brief-padding top-padding">
        <v-card class="project-card sweep-card">
          <v-row class="ma-0">
            <v-col class="d-flex pa-0 justify-start" md="10">
            <v-card-title class="pa-0 pb-4">SWEEPS</v-card-title>
            </v-col>
            <v-col class="d-flex pa-0 justify-end" md="2"
              v-if="role === 'creative' && sweepInviteCount">
              <v-badge
                :content="sweepInviteCount"
                color="info"
                overlap
                class="badge-icon"
               >
                <v-btn
                text
                class="email-icon"
                :to="{path: '/sweeps', query: { tab: 'invitations'}}"
                >
                <v-icon color="info">mdi-email-outline</v-icon>
              </v-btn>
              </v-badge>
            </v-col>
          </v-row>
          <v-card-text class="pa-0 mt-1 mb-3" style="height: 245px">
            <template v-if="sweep">
              <div>
                <SweepCard
                  :sweep="sweep"
                  :sweepTeam="sweepTeam"
                  :showEdit="isSweepEditable(sweep)"
                  @showSweepEdit="$emit('showSweeps', 1, sweep.id)"
                >
                </SweepCard>
              </div>
            </template>
            <template v-else>
              <div class="no-sweep">
                <div class="mb-8 font-body-small"> You have no sweeps scheduled at this time. </div>
                <div class="text-center pb-4">
                  <img :src="require('@/assets/svg/theme/group.svg')" height="160px"/>
                </div>
              </div>
            </template>
          </v-card-text>
          <v-divider> </v-divider>
          <v-card-actions class="px-0 py-1">
            <v-row class="my-0">
              <v-col class="d-flex align-center py-0" md=6>
                <v-btn text
                  class="btn-purple"
                  @click="openForm = true;"
                  v-if="isEditable"
                >
                  + Add Sweep
                </v-btn>
              </v-col>
              <v-col
              class="d-flex justify-end py-0"
              md=6
              v-if="canUpdate || editor">
                <v-btn text
                  class="btn-purple d-flex justify-end"
                  @click="$emit('showSweeps', 0)"
                >
                  View all
                </v-btn>
              </v-col>
              <v-col
              class="d-flex justify-end py-0"
              md=6
              v-if="isCreative">
                <v-btn text
                  class="btn-purple d-flex justify-end"
                  @click="addPortal"
                >
                <v-icon class="right"> upload </v-icon>
                  Upload Footage
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card class="project-card team-card mt-4">
          <v-row class="my-0">
            <v-col class="d-flex align-center py-0" md=9>
              <v-card-title class="pa-0">CREATIVE TEAM</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end py-0" md=3 v-if="isEditable">
              <v-btn small icon
              :to="{ name: 'Project Team', params: { projectId: $route.params.projectId}}">
                <img src="@/assets/svg/theme/edit.svg" />
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-card-title class="pa-0">CREATIVE TEAM</v-card-title> -->
          <div style="min-height: 420px;">
            <CreativeTeam
              :creativeTeam="projectTeam"
              :wrapperMaxHeight="'410px'"
              class="pt-2"
            />
          </div>
        </v-card>
      </v-col>

      <v-col cols=12 md=8 style="height: 100%" class="brief-padding">
          <!-- <MyContentPlan class="content-height"
            :lastContentKit="lastContentKit"
            :allContentKits="allContentKits"
            :contentKitPercentages="contentKitPercentages"
            :subscription="defaultSubscription"
          /> -->
          <!-- <v-btn
            icon
            height="auto"
            width="auto"
            class="down-button mr-6"
            @click="cancelEdit"
            v-if="isEditable && currentProject.contentKits && currentProject.contentKits.length"
          >
            <v-icon dense>mdi-menu-down</v-icon>
          </v-btn> -->

          <!-- <div
          class="edit-card"
          v-if="showStageEdit">
          <div class="heading mb-4 d-flex">Edit Stage Tracker</div>
              <v-select
                  :label="getLabel()"
                  :items="options"
                  dense
                  solo
                  v-model="selectedStatus"
                  :menu-props="{'content-class' : 'select-dropdown'}"
                ></v-select>
                <div class="d-flex justify-end">
                <v-btn text height=26 class="pa-0 cancel-btn mr-0"
                  @click="cancelEdit">
                  <span class="view-text save-btn-text">CANCEL</span>
                </v-btn>

                <v-btn text height=26 class="pa-0 save-btn"
                @click="updateContentKitStatus(selectedStatus)"
                >
                  <span class="view-text save-btn-text">SAVE</span>
              </v-btn>
                </div>
          </div>
           </v-col> -->
          <!-- <v-card-text class="text--primary pa-0">
            <CustomTracker :steps="steps" :currentStep="updatedStatus || contentKitState"/>
          </v-card-text> -->
        <v-card class="project-card brand-card mt-4 card-height">
          <v-card-text class="text--primary pa-0 overflow-visible">
            <v-row class="ma-0 mt-1">
              <v-col cols="12" class="pa-0">
                    <ProjectHtmlContent
                      :title="'SWEEP KEY INFO'"
                      :value="currentProject.brandProfile"
                      @save="handleUpdate('brandProfile', $event)"
                      :contentHeight="$vuetify.breakpoint.md ? '207px' : '195px'"
                      :canEdit="isEditable"
                    >
                    </ProjectHtmlContent>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-container
          fluid
          v-if="openForm"
        >
          <Modal
            persistent
            class="sweep-container"
            content-class="new-user-form"
            :modal="openForm"
            width="726px"
            height="1148px"
          >
          <SweepForm
            id="sweepModal"
            @close-modal="openForm = false"
            @reload-sweeps="fetchSweeps"
          />
            <!-- @reload-sweeps="fetchSweeps" -->
            <!-- :sweepObj="currentSweep"
            :fallBackProjectId="currentProject.id" -->
          </Modal>
        </v-container>
  </v-container>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
// import CustomTracker from '@/components/common/CustomTracker';
import CreativeTeam from '@/components/dashboard/screens/common/CreativeTeam';
// import CalendarEvent from '@/components/common/CalendarEvent';
// import BaseTextAreaField from '@/components/common/BaseTextAreaField';
// import BrandKitList from './BrandKitList';
// import LongText from '../common/LongText';
import SweepCard from '@/components/projects/sweep/SweepCard';
// import MyContentPlan from '@/components/dashboard/screens/common/MyContentPlan';
import Loader from '@/components/common/Loader';
import EventBus from '@/helpers/event-bus';
import SweepForm from '@/components/projects/sweep/SweepForm';
import Modal from '../common/Modal';
import ProjectHtmlContent from './ProjectHtmlContent';


export default {
  components: {
    ProjectHtmlContent,
    // BaseTextAreaField,
    // BrandKitList,
    // CalendarEvent,
    // CustomTracker,
    CreativeTeam,
    // LongText,
    Modal,
    SweepForm,
    SweepCard,
    // MyContentPlan,
    Loader,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject', 'producersTeam', 'creativeTeam', 'executiveTeam', 'editorTeam']),
    ...mapGetters('sweep', ['sweeps']),
    projectTeamDetails() {
      return (users, userRole) => _.map(users, (user) => ({
        profilePic: user.profilePic,
        smallImage: user.smallImage,
        thumbnail: user.thumbnail,
        firstName: user.firstName,
        lastName: user.lastName,
        skill: userRole === 'creative' ? this.creativeSkillsets(user) : userRole,
        number: user.number,
        email: user.email,
        bio: user.bio,
        availability: user.availability,
        role: userRole,
        userPronouns: user.userPronouns,
        userId: user.id,
        deletedAt: user.deletedAt,
      }));
    },
    sweepSkill() {
      return (skill) => {
        switch (skill) {
          case '1':
            return 'Photographer';
          case '2':
            return 'Videographer';
          case '3':
            return 'Talent';
          default:
            return 'Field Producer';
        }
      };
    },
    // pendingSweeps() {
    //   // _.sortBy(this.messagesByDate[date], (o) => moment(o.dateCreated));
    //   return _.sortBy(this.sweeps.pending, (s) => moment(s.date));
    // },
    projectTeam() {
      const producers = this.projectTeamDetails(
        _.map(_.filter(this.producersTeam, ['accepted', '1']), 'user'),
        'Content Producer',
      );
      const executives = this.projectTeamDetails(
        _.map(_.filter(this.executiveTeam, ['accepted', '1']), 'user'),
        'Production Manager',
      );
      const creativeTeam = this.projectTeamDetails(
        _.map(_.filter(this.creativeTeam, ['accepted', '1']), 'user'),
        'creative',
      );
      const editors = this.projectTeamDetails(
        _.map(_.filter(this.editorTeam, ['accepted', '1']), 'user'),
        'Editor',
      );
      return _.concat(producers, executives, creativeTeam, editors);
    },
    creativeSkillsets() {
      return (member) => {
        const skillsets = _.get(member, 'creativeProfile.skillsets', []);
        return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
      };
    },
    contentKitState() {
      return Number(_.get(this.currentProject, 'contentKits[0].state', 0));
    },

    sweep() {
      return _.get(this.currentProject, 'sweep');
    },

    lastContentKit() {
      return _.get(this.currentProject, 'lastKitValues', {});
    },

    allContentKits() {
      return _.get(this.currentProject, 'allKitValues', {});
    },

    contentKitPercentages() {
      return _.get(this.currentProject, 'contentKitsPercentages', []);
    },

    defaultSubscription() {
      return _.get(this.currentProject, 'projectSnapshot', {});
    },

    deliverableList() {
      return _.get(this.currentProject, 'deliverablesList', []);
    },

    sweepTeam() {
      return _.filter(_.get(this.sweep, 'sweepTeam', []), ['accepted', true]);
    },

    limitedBrandKits() {
      return this.getBrandKits().slice(0, 6);
    },

    role() {
      return _.get(this.userDetails, 'role.name');
    },
    canUpdate() {
      return _.includes(['admin', 'producer', 'executive'], this.role);
    },
    editor() {
      return ['editor'].includes(this.role);
    },
    isCreative() {
      return _.includes(['creative'], this.role);
    },
    isEditable() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return (this.canUpdate && assigned && this.currentProject.status !== '2')
      || this.role === 'admin' || this.role === 'executive';
    },
  },
  data() {
    return {
      loading: true,
      openForm: false,
      options: [{ text: 'Sweep', value: 1 }, { text: 'Editing', value: 2 }, { text: 'Copy', value: 3 }, { text: 'Quality Check', value: 4 }, { text: 'Delivered', value: 5 }],
      selectedStatus: '',
      updatedStatus: null,
      showPopUp: false,
      showEditModal: false,
      showStageEdit: false,
      mode: {
        brandProfile: 'normal',
        deliverables: 'normal',
      },
      steps: [
        {
          id: 1, label: 'Sweep',
        }, {
          id: 2, label: 'Editing',
        }, {
          id: 3, label: 'Copy',
        }, {
          id: 4, label: 'Quality Check',
        }, {
          id: 5, label: 'Delivered!',
        },
      ],
      sweepInviteCount: 0,
    };
  },
  methods: {
    ...mapActions('project', ['getProject', 'getProjectTeam', 'updateProject']),
    ...mapActions('contentKit', ['updateContentKit', 'getUserContentKits']),
    ...mapActions('sweep', ['getSweeps', 'getSweepInvitiesCount']),
    isSweepEditable(sweep) {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return this.role === 'admin' || this.role === 'executive'
      || (this.canUpdate && assigned && this.currentProject.businessId === sweep.businessId);
    },
    addPortal() {
      EventBus.$emit('openMassivePortal');
    },
    getLabel() {
      const status = this.contentKitState ? this.contentKitState : this.selectedStatus;
      switch (status) {
        case 1:
          return 'Content Shoot';
        case 2:
          return 'Editing';
        case 3:
          return 'Copy';
        case 4:
          return 'Quality Check';
        case 5:
          return 'Delivered';
        default:
          return 'Select Stage';
      }
    },
    profilePic(member) {
      const profilePic = _.get(member, 'profilePic');
      if (profilePic) {
        return profilePic;
      }
      return require('@/assets/svg/users.svg');
    },
    getColors(index) {
      const colors = _.get(this.currentProject, 'brandColors', []);
      return _.filter(colors, (color) => color.colorType === index);
    },
    getBrandKits() {
      return _.get(this.currentProject, 'brandKits', []);
    },
    async toggleMode(type) {
      if (this.mode[type] === 'normal') {
        this.mode[type] = 'edit';
      } else {
        const response = await this.updateProject({
          id: this.currentProject.id, project: this.currentProject,
        });
        if (response.success) {
          this.mode[type] = 'normal';
        }
      }
    },
    async updateContentKitStatus(status) {
      this.showStageEdit = false;
      const activeKitId = _.get(this.currentProject, 'contentKits[0].id', 0);
      this.updateContentKit({
        id: activeKitId,
        payload: { state: status },
      }).then((res) => {
        if (res.success) {
          this.updatedStatus = status;
        }
      });
    },
    handleUpdate(key, value) {
      const project = this.currentProject;
      project[key] = value;
      this.updateProject({
        id: this.currentProject.id,
        project,
      });
    },
    cancelEdit() {
      this.showStageEdit = !this.showStageEdit;
      if (!this.showStageEdit) {
        this.selectedStatus = this.updatedStatus ? this.updatedStatus : this.contentKitState;
      }
    },
    fetchSweeps() {
      if (this.role === 'creative') {
        this.getSweeps({ projectId: this.$route.params.projectId, status: 'pending' });
      }
    },
  },
  async mounted() {
    this.loading = true;
    const project = await this.getProject(this.$route.params.projectId);
    await this.getProjectTeam(this.$route.params.projectId);
    if (!project) {
      this.$router.push({ path: '/dashboard' });
    }
    this.selectedStatus = this.contentKitState;
    if (this.role === 'creative') {
      const result = await this.getSweepInvitiesCount(this.$route.params.projectId);
      if (result.success) {
        this.sweepInviteCount = result.sweepInviteCount;
      }
    }
    this.loading = false;
  },
  watch: {
    contentKitState(val) {
      this.selectedStatus = val;
    },
  },
};
</script>

<style lang="scss" scoped>
  .project-overview-container {
    // max-height: calc(100vh - 72px);
    // overflow: scroll;
    font-family: $fontFamily1;
   ::v-deep .v-card__title {
      font-family: $fontFamily1;
      font-size: 14px !important;
    }
  }
  .project-card {
    padding: 20px 24px 20px !important;
    .v-card__text {
      border-radius: 0 !important;
      line-height: 1.56;
    }
  }
  .deliverable-card {
  > div {
        max-width: 100%;
      }
      .name-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
}
.card-heading {
  color: #565682;
  font-size: 14px;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
  letter-spacing: -0.28px;
}
  .project-card.sweep-card {
    height: 377px;
    .v-card__text {
      color: black !important;
    }
    .no-sweep{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  // .project-card.team-card {
  //   height: calc(100% - 300px)
  // }
  .team-card {
    height: 481px;
  }
  .project-card {
    &.deliverables-card, &.props-card {
      height: 292px;
      > div {
        height: 100%;
      }
    }
  }
  .project-card.assets-card {
    height: calc(100% - 200px)
  }

  .project-card.colors-card {
    height: calc(100% - 250px)
  }
  .hex-text {
    font-family: $fontFamily1;
    font-size: 11px;
  }
    .view-text {
    font-family: $fontFamily1;
    color: $secondary1;
    font-size:14px;
  }
  .kits-list{
    max-height:300px;
    overflow:auto;
  }
  .heading{
    font-size: 18px;
    letter-spacing: -0.36px;
    font-family:$fontFamily1;
  }
  .edit-card{
    height:180px;
    width:282px;
    background: white;
    padding: 20px;
    box-shadow: 0 2px 4px 0 $silver;
    position: absolute;
    top: 50px;
    right: 10px;z-index: 3
  }
  ::v-deep.v-input>.v-input__control>.v-input__slot>.v-select__slot
  >.v-select__selections>.v-select__selection{
    font-weight:normal;
  }

  ::v-deep{
  .v-select__selection--comma {
      font-size: 14px;
    }
  }
  .tracker-card{
    position: relative;
    padding: 20px 0 12px !important;
  }
  .save-btn{
    background-color:#fff;
  }
  .save-btn-text{
    font-size:16px;
  }
  .save-button {
    color: $secondary1;
  }
  .brandkit-text {
    ::v-deep .v-list-item__title {
      font-family: $fontFamily1;
      font-size: 16px;
      font-weight: normal;
    }
  }
  .email-icon{
    color: #65d6eb;
    border-radius: 50%;
    padding: 0 5px !important;
    height: 30px !important;
    min-width: 26px !important;
  }
  @media (max-width: 959px) {
    .tracker-card, .props-card, .colors-card {
      margin-right: 0px;
    }
    .calendar-card, .team-card {
      margin-right: 0px;
    }
    .team-card {
      min-height: 340px;
    }
    .deliverables-card, .assets-card {
      margin-left: 0px;
    }
    .deliver-padding {
      padding-right: 14px !important;
    }
  }
  @media (max-width: 600px) {
    .project-card {
      margin-left: 0px;
    }
    .card-height {
        height: 100% !important;
    }
    .no-sweep {
      overflow: hidden;
      white-space: nowrap;
    }
    .brief-padding {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 4px;
    }
    .top-padding {
      padding-top: 20px;
    }
    .key-props {
      padding-right: 14px;
      padding-top: 8px
    }
    .props-card, .deliverables-card {
      height: 100% !important;
    }
    .deliver-padding {
      padding-top: 15px;
    }
  }
</style>

<style scoped lang="scss">
.sweep-team {
      min-height: 90px;
      .v-image {
        border-radius: 18px;
        cursor: pointer;
        &.no-image {
          border: 2px solid $card-title;
        }
      }
    }
    .card-height {
      height: 260px;
    }
</style>
