<template>
    <v-container class="brand-colors">
      <v-row class="colors-card" v-for="(type, i) in brandColorsSet" :key="i">
        <v-col class="pt-0 mb-3">
            <div class="mb-3">
                <div class="color-card-heading">{{type.name}}</div>
            </div>
            <div class="d-flex flex-row flex-wrap" :class="'color-picker-'+type.name">
                <div
                class="color-picker-wrapper"
                :class="{'hidden-screen-only':
                    (type.colors.length-1 === index && type.colors.length > 4)
                    }"
                v-for="(color, index) in (type.colors)"
                :key="index"
                >
                <ColorPicker
                    currentPage="brandQuiz"
                    class="color-picker-container"
                    :value="color.colorCode"
                    :state="
                    (activeColor.index === index && activeColor.mainIndex === i) ?
                    'edit': 'normal'
                    "
                    :isNew="!color.id"
                    @update-color="(val) =>
                    updateColor(val, index, i, color.id, type.colors.length-1)"
                    @delete-color="() => showConfirmModal(color.id)"
                    @close-color="closeColor"
                    :width="$vuetify.breakpoint.smAndDown ? '200px': '300px'"
                    :alignRight="$vuetify.breakpoint.smAndDown"
                >
                    <template>
                    <div class="color-block">
                        <v-btn
                        v-if="type.colors.length-1 !== index && canUpload"
                        icon
                        x-small
                        color="black"
                        class="remove-color-icon"
                        @click="showConfirmModal(color.id)"
                        >
                            <v-icon size="10">mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            class="circle-color"
                            :width="$vuetify.breakpoint.smAndDown ? '41px': '65px'"
                            :height="$vuetify.breakpoint.smAndDown ? '41px': '65px'"
                            fab
                            small
                            @click="togglePickerState(index, i, color.colorCode)"
                            :color="color.colorCode"
                            :class="{'color-add-btn': type.colors.length-1 === index,
                                'mr-0' : ((type.colors.length-1 === index)
                                || (type.colors.length > 4 && index === 3)),
                                'mr-7' : !((type.colors.length-1 === index)
                                || (type.colors.length > 4 && index === 3))}"
                                style="box-shadow: none;"
                            >
                            <v-icon v-if="type.colors.length-1 === index" dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="hex-text mt-1"
                    :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : 'pl-2'"
                    v-if="type.colors.length-1 !== index"
                    v-clipboard:copy="color.colorCode"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >
                        {{color.colorCode}}
                    </div>
                    </template>
                </ColorPicker>
                </div>
              </div>
        </v-col>
      </v-row>
      <ConfirmModal
      :showConfirm="showConfirm"
      @onDelete="confirmDelete"
      @cancel="cancelModal"
      />
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmModal from '@/components/profile/client/brandQuiz/wizard/common/Modal';
import ColorPicker from '@/components/common/ColorPicker';

export default {
  name: 'BrandColors',
  components: {
    ColorPicker,
    ConfirmModal,
  },
  props: {
    brandProfileId: {
      type: Number,
      default: null,
    },
    reference: {
      type: String,
      default: 'business',
    },
    brandColors: {
      type: Array,
      default: () => [],
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeColor: { },
      showConfirm: false,
      selectedColor: '',
      addOrUpdateInprogress: false,
    };
  },
  computed: {
    brandColorsSet() {
      return ([{
        name: 'primary',
        colors: [...this.getColors('1'), { colorCode: '#FFFFFF' }],
      }, {
        name: 'secondary',
        colors: [...this.getColors('2'), { colorCode: '#FFFFFF' }],
      }]);
    },
  },
  methods: {
    ...mapActions('brandProfile', ['addBrandColor', 'updateBrandColor', 'deleteBrandColor', 'downloadLink']),
    ...mapActions(['setNotification']),
    // eslint-disable-next-line consistent-return
    async updateColor(val, index, mainIndex, id, colorsLength) {
      const availableColors = this.getColors(mainIndex === 0 ? '1' : '2');
      const colorCodes = availableColors.map((c) => c.colorCode) || [];
      if (this.addOrUpdateInprogress) return;
      if (colorCodes.includes(val)) {
        // eslint-disable-next-line consistent-return
        return this.setNotification({
          message: 'Color is already chosen before, try another color!',
          type: 'error',
        }, { root: true });
      }
      if (this.brandProfileId) {
        const color = {
          id,
          colorCode: val,
          colorType: mainIndex === 0 ? '1' : '2',
          brandProfileId: this.brandProfileId,
          reference: this.reference,
        };
        this.addOrUpdateInprogress = true;
        const response = colorsLength !== index
          ? await this.updateBrandColor(color) : await this.addBrandColor(color);
        this.addOrUpdateInprogress = false;
        if (response.success) {
          this.activeColor = {};
        }
      } else {
        this.setNotification({
          message: 'Something went wrong, please contact your administrator.',
          type: 'error',
        }, { root: true });
        this.activeColor = {};
      }
      // eslint-disable-next-line consistent-return
      return null;
    },
    showConfirmModal(colorId) {
      this.selectedColor = colorId;
      this.showConfirm = true;
    },
    cancelModal() {
      this.showConfirm = false;
    },
    confirmDelete() {
      this.showConfirm = false;
      this.deleteColor(this.selectedColor);
    },
    async deleteColor(id) {
      const payload = {
        id,
        brandProfileId: this.brandProfileId,
        reference: this.reference,
      };
      const response = await this.deleteBrandColor(payload);
      if (response.success) {
        this.activeColor = { };
      }
    },
    closeColor() {
      this.activeColor = { };
    },
    togglePickerState(index, mainIndex, colorCode) {
      // window.scrollTo(0, document.body.scrollHeight);
      if (this.canUpload) {
        this.activeColor = { index, mainIndex };
      } else {
        this.copyToClipboard(colorCode);
      }
    },
    getColors(index) {
      return this.brandColors.filter((x) => x.colorType === index);
    },
    copyToClipboard(val) {
      this.$copyText(val).then(() => {
        this.setNotification({
          message: 'Color copied to clipboard.',
          type: 'success',
        }, { root: true });
      }, (e) => {
        this.setNotification({
          message: e.message,
          type: 'error',
        }, { root: true });
      });
    },
    onCopy() {
      this.setNotification({
        message: 'Color copied to clipboard.',
        type: 'success',
      }, { root: true });
    },
    onError(e) {
      this.setNotification({
        message: e.message,
        type: 'error',
      }, { root: true });
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-btn {
  ::v-deep .v-btn__content {
    font-size: 14px;
    color: $charcoalBlack;
  }
}
.brand-colors {
  color: $neutral1 !important;
  width: auto;
}
    .card-heading {
      font-weight: bold;
      letter-spacing: -0.28px;
      color: $card-title;
    }
    .color-picker-container{
     // margin-right:34px;
     width: 65px;
    }
    .color-picker-wrapper{
      //width:100px;
      margin-left:0px !important;
      margin-right:1rem !important;
    }
    .colors-card {
      .color-block {
        position: relative;
        width:30px;
        .circle-color {
          border: 1px solid #cccccc;
          border-color: #cccccc !important;
        }
        .v-btn {
          &.color-add-btn {
            border-color: #828282 !important;
            border: 0.780488px dashed #828282;
            margin-right: 0px !important;
          }
        }
        .remove-color-icon {
          display: none;
          position: absolute;
          right: -30px !important;
          top: 0px;
          width: 18px;
          height: 18px;
          z-index: 2;
          background-color: #f4f4f4;
          box-shadow: 0 1px 2px 0 rgba(125, 125, 125, 0.5);
        }
        &:hover {
          .remove-color-icon {
            display: block;
          }
        }
      }
    }
    .color-card-heading {
      font-size: 14px;
      letter-spacing: -0.28px;
      color: $neutral1;
      text-transform: capitalize;
    }
    .hex-text {
      font-family: $fontFamily1;
      font-size: 12px;
      letter-spacing: -0.11px;
      color: $neutral1;
      cursor: pointer;
      line-height: 14px;
      color: $charcoalBlack;
      padding-top: 15px;
      padding-left: 10px;
    }
    @media (max-width: 959px) {
      .colors-card {
        padding: 0 25px;
      }
      .remove-color-icon {
        right: -30px !important;
        top: 0px !important;
      }
      .color-picker-container{
        width: 41px;
      }
    }
    @media (max-width: 600px) {
      .colors-card {
        padding-left: 140px;
      }
      .color-picker-container{
        width: 41px;
      }
    }

</style>
