<template>
    <v-container
      fluid
      v-if="isModal"
      class="container"
    >
        <Modal
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :modal="isModal"
          max-width="1300px"
          style="height:100% !important;"
          persistent
          content-class="bq-modal"
          :overlay-opacity="0.9"
          @vDialogMount="setModalHeight"
        >
          <div class="pa-1 brandQuiz-modal">
              <div class="d-flex justify-end action-section"
                v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon
                      color="darken-1"
                      class="close-icon pt-4"
                      @click="$emit('closeModal', false)"
                    >mdi-close</v-icon>
                </div>
              <div class="bq-content">
                  <div
                    class="left-menu-options"
                  >
                    <div class="pic-placeholder">
                      <v-img
                        class="profile-image"
                        :src="getImage"
                        alt="Profile"
                      />
                    </div>
                    <v-row
                     class="mx-0 justify-space-between menu-item-row"
                     v-for="(item, i) in wizardMenu"
                    :key="`item ${i}`"
                    @click="changeState(i)"
                    :class="currentPage-1 === i && 'bg-pink'">
                        <div class="text-row">
                            <img :src="getIcon(i)" />
                            <span class="text">{{item.text}}</span>
                        </div>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-row>
                  </div>
                  <div
                    class="pr-0 d-flex flex-column justify-space-between bq-modal-right-section"
                    ref="modalRightSection"
                  >
                    <div class="main-column"
                      :style="`height: ${modalHeight}px !important;`"
                      ref="mainColumn"
                    >
                        <div class="d-flex justify-end pa-0 action-section"
                          v-if="$vuetify.breakpoint.mdAndUp">
                            <v-icon color="darken-1" class="close-icon"
                            @click="$emit('closeModal', false)"
                            >mdi-close</v-icon>
                        </div>
                        <div class="modal-header" :key="currentPage">
                          <template v-if="currentPage === 1">
                            <p class="text1 font-h5">Contact & social information.</p>
                            <div class="text2 font-body">
                              We need your contact info and anyone on your team who
                              will use the app to deploy your creative digital assets.
                            </div>
                          </template>
                          <template v-if="currentPage === 2">
                            <p class="text1 font-h5">Lets start with your logo.</p>
                            <div class="text2 font-body">
                              <!-- eslint-disable-next-line max-len -->
                              For you: Your logo file will *finally* live in a central place with all your content! <br/>
                              <!-- eslint-disable-next-line max-len -->
                              For us: We’ll use this in many different assets, from videos, to photos, to audiograms and more.
                            </div>
                            <!-- eslint-disable-next-line max-len -->
                            <div class="text2 font-body pb-8">If you have a brand style guide, upload that here!</div>
                          </template>
                          <template v-if="currentPage === 3">
                            <p class="text1 font-h5">Brand colors.</p>
                            <div class="text2 font-body">
                                Access to Brand colors helps our post production
                                team align all your content with the same asthetic.
                                <!-- eslint-disable-next-line max-len -->
                                Best part is you now have copy and paste color codes to apply anywhere you need them too!
                            </div>
                          </template>
                          <template v-if="currentPage === 4">
                            <p class="text1 font-h5">Fonts to fit your brand.</p>
                            <!-- eslint-disable-next-line max-len -->
                            <div class="text2 font-body">Select from our library of fonts, or upload your own.</div>
                            <!-- eslint-disable-next-line max-len -->
                            <div class="text2 font-body">This keeps your visual message consistent across every platform.</div>
                          </template>
                          <template v-if="currentPage === 5">
                            <p class="text1 font-h5">Sync up your style.</p>
                            <div class="text2 font-body">
                              Take a look through the images below and
                              choose one that visually resonates with your brand.
                            </div>
                          </template>
                          <template v-if="currentPage === 6">
                            <p class="text1 font-h5">Select your music style.</p>
                            <div class="text2 font-body"> Music elevates story telling.
                              Take a listen to these clips, and select an audio style.</div>
                          </template>
                          <template v-if="currentPage === 7">
                            <p class="text1 font-h5">What is your business’ main purpose?</p>
                            <div class="text2 font-body">
                              <!-- eslint-disable-next-line max-len -->
                              When we understand the why behind what you do, we can bring intention to your content.
                            </div>
                          </template>
                          <template v-if="currentPage === 8">
                            <p class="text1 font-h5">Share your visual inspiration.</p>
                            <div class="text2 font-body">
                              Please share any pictures, screenshots,
                              or websites of your preferred brand style
                              for inspiration. Example visuals help best
                              describe what you’re looking for.
                            </div>
                          </template>
                        </div>
                        <div class="modal-content"
                        >
                            <GeneralInfo
                              :business="brandProfileState"
                              v-if="currentPage === 1"
                              @initVuelidate="vuelidate => vuelidateInstace = vuelidate"
                            />
                            <BrandLogoFiles
                            :brandKitLogosData="brandKits"
                            :reference="brandProfileState.reference"
                            :brandProfileId="brandProfile.id"
                            :canUpload="checkEditAccess"
                            v-if="currentPage === 2" />
                            <BrandColors
                              :reference="brandProfileState.reference"
                              :brandProfileId="brandProfile.id"
                              :brandColors="brandColors"
                              :canUpload="checkEditAccess"
                              v-if="currentPage === 3"
                            />
                            <BrandFonts
                              :reference="brandProfileState.reference"
                              :brandProfileId="brandProfile.id"
                              :fonts="this.brandSources.brandFonts"
                              :canUpload="checkEditAccess"
                              v-if="currentPage === 4"
                            />
                            <BrandStyle
                              :reference="brandProfileState.reference"
                              :brandProfileId="brandProfile.id"
                              v-if="currentPage === 5"
                              :styles="brandStyles"
                              @selectstyle="id=>brandStyle.id = id"
                            />
                            <Music
                              :reference="brandProfileState.reference"
                              :brandProfileId ="brandProfile.id"
                              v-if="currentPage === 6"
                              :genres="musicGenres"
                              @selectmusic="id => brandMusic.id = id"
                            />
                            <Mission
                            :business="brandProfileState"
                            v-if="currentPage === 7" />
                            <Brainstorm
                            :reference="brandProfileState.reference"
                            :brandProfileId="brandProfile.id"
                            :brandInspirations="brandKitInspirations"
                            :brandLinks="brandKitLinks"
                            :canUpload="checkEditAccess"
                            v-if="currentPage === 8" />
                        </div>
                        <div class="footer">
                          <div>
                              <v-btn
                                :loading="saving"
                                class="next-btn btn-purple"
                                :key="currentPage"
                                @click="changeState(currentPage, { saveTheDate: true })"
                              >
                                  {{this.currentPage === 8 ? 'Finish' : 'Next'}}
                              </v-btn>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </Modal>
    </v-container>
</template>
<script>
/* eslint-disable global-require */
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import GeneralInfo from './wizard/GenaralInfo';
import BrandLogoFiles from './wizard/BrandLogoFiles';
import BrandColors from './wizard/BrandColors';
import BrandFonts from './wizard/BrandFonts';
import BrandStyle from './wizard/BrandStyle';
import Music from './wizard/Music/Music';
import Mission from './wizard/Mission';
import Brainstorm from './wizard/Brainstorm';

export default {
  name: 'Layout',
  components: {
    Modal,
    GeneralInfo,
    BrandLogoFiles,
    BrandColors,
    BrandFonts,
    BrandStyle,
    Music,
    Mission,
    Brainstorm,
  },
  computed: {
    ...mapGetters('brandProfile', ['brandProfile', 'brandSources']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    brandKits() {
      return this.brandProfile.brandKits;
    },
    brandColors() {
      return this.brandProfile.brandColors;
    },
    brandFonts() {
      return this.brandProfile.brandFonts;
    },
    brandKitLinks() {
      return this.brandProfile.brandKitLinks;
    },
    purpose() {
      return this.brandProfile.purpose;
    },
    name() {
      return this.brandProfile.name;
    },
    brandStyleSelected() {
      return this.brandProfile.brandStyle;
    },
    brandMusicSelected() {
      return this.brandProfile.brandMusic;
    },
    brandKitInspirations() {
      return this.brandProfile.brandKitInspirations;
    },
    getImage() {
      const images = [
        require('@/assets/svg/theme/brandquiz/general.svg'),
        require('@/assets/svg/theme/brandquiz/logo_files.svg'),
        require('@/assets/svg/theme/brandquiz/brand_colors.svg'),
        require('@/assets/svg/theme/brandquiz/brand_fonts.svg'),
        require('@/assets/svg/theme/brandquiz/brand_style.svg'),
        require('@/assets/svg/theme/brandquiz/music.svg'),
        require('@/assets/svg/theme/brandquiz/brand vision.svg'),
        require('@/assets/svg/theme/brandquiz/brainstorm.svg'),
      ];
      return images[this.currentPage - 1];
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    checkEditAccess() {
      return this.canEdit;
    },
    musicGenres() {
      return this.brandSources.brandMusic;
    },
    brandStyles() {
      return this.brandSources.brandStyles
        .map(({ id, name, imageUrl: url }) => ({ id, name, url }));
    },
  },
  props: {
    brandProfileId: {
      type: Number,
      // new profiles can have this come back as undefined,
      required: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    modalInitialStep: {
      type: Object,
      default: () => ({ step: 1 }),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('brandProfile', ['updateGeneralInfo']),
    setModalHeight() {
      // eslint-disable-next-line prefer-destructuring
      const rightSection = this.$refs.modalRightSection;
      if (!rightSection) return;
      this.modalHeight = rightSection.clientHeight;
    },
    getName(index) {
      const { currentPage } = this;
      const page = index + 1;
      const {
        brandColors,
        brandKits,
        brandKitLinks,
        purpose,
        brandFonts,
        brandMusicSelected,
        brandStyleSelected,
        brandKitInspirations,
      } = this;
      const {
        website,
        facebook,
        instagram,
        linkedin,
        pinterest,
        youtube,
      } = this.brandProfile;
      const primary = _.filter(Object.keys(brandFonts.primary), (val) => val !== 'custom' && val !== 'fontId').length > 0;
      const secondary = _.filter(Object.keys(brandFonts.secondary), (val) => val !== 'custom' && val !== 'fontId').length > 0;
      const brandMusicData = Object.keys(brandMusicSelected).length > 0;
      const brandStyleData = Object.keys(brandStyleSelected).length > 0;
      if (page === 1) {
        // eslint-disable-next-line
        const hasSomeGeneralInfo = Boolean(website || facebook || instagram || linkedin || pinterest || youtube);
        if (hasSomeGeneralInfo) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 2) {
        if (brandKits.length > 0) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 3) {
        if (brandColors.length > 0) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 4) {
        if (primary || secondary) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 5) {
        if (brandStyleData) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 6) {
        if (brandMusicData) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 7) {
        if (purpose) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      if (page === 8) {
        if (brandKitInspirations.length > 0 || brandKitLinks.length > 0) {
          return require('@/assets/svg/theme/check-green.svg');
        }
        if (currentPage === page) {
          return require('@/assets/svg/theme/check-white.svg');
        }
        return require('@/assets/svg/theme/check-gray.svg');
      }
      return require('@/assets/svg/theme/check-white.svg');
    },
    getIcon(index) {
      switch (index) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          return this.getName(index);
        default:
          return require('@/assets/svg/theme/check-gray.svg');
      }
    },
    async changeState(index, options = {}) {
      // do not have to call the api when navigating through sidebar.
      // so use this flag to save the current page info
      if (options.saveTheDate) {
      // call the respective api call and next
        switch (this.currentPage - 1) {
          case 0:
            // general info
            if (this.brandProfileState.name
              && this.vuelidateInstace
              && !this.vuelidateInstace.$invalid
            ) {
              this.saving = true;
              await this.updateGeneralInfo({
                ...this.brandProfileState,
                ...this.generalInfo,
                brandProfileId: this.brandProfile.id,
                reference: this.brandProfileState.reference,
              });
              this.saving = false;
            }
            break;
          case 1:
            // brand logo
            break;
          case 2:
            // brand color
            break;
          case 3:
            // brand font
            break;
          case 4:
            // brand style
            break;
          case 5:
            // brand music
            break;
          case 6:
            // mission
            // eslint-disable-next-line no-case-declarations
            const text = this.brandProfileState.purpose || '';
            if (text.length <= 140) {
              this.saving = true;
              await this.updateGeneralInfo({
                ...this.brandProfileState,
                ...this.generalInfo,
                brandProfileId: this.brandProfileId,
                reference: this.brandProfileState.reference,
              });
              this.saving = false;
            }
            break;
          case 7:
            // brain storm
            break;
          default:
            break;
        }
      }
      if (index <= this.wizardMenu.length - 1) {
        this.currentPage = index + 1;
      } else {
        this.$emit('closeModal', false);
      }
      if (this.brandProfile && typeof this.brandProfile === 'object') {
        this.brandProfileState = { ...this.brandProfile };
      }
    },
  },
  data() {
    return {
      currentPage: 1,
      generalInfo: {},
      brandMusic: {},
      brandStyle: {},
      brandFont: {},
      saving: false,
      brandProfileState: {},
      vuelidateInstace: null,
      resizeObserver: null,
      wizardMenu: [
        {
          step: 1,
          name: 'generalInfo',
          text: 'Contact & Social Info',
          status: 'incomplete',
        },
        {
          step: 2,
          name: 'brandLogo',
          text: 'Brand Logo Files',
          status: 'incomplete',
        },
        {
          step: 3,
          name: 'brandColors',
          text: 'Brand Colors',
          status: 'incomplete',
        },
        {
          step: 4,
          name: 'brandFonts',
          text: 'Brand Fonts',
          status: 'incomplete',
        },
        {
          step: 5,
          name: 'brandStyle',
          text: 'Brand Style',
          status: 'incomplete',
        },
        {
          step: 6,
          name: 'music',
          text: 'Music',
          status: 'incomplete',
        },
        {
          step: 7,
          name: 'mission',
          text: 'Company Purpose',
          status: 'incomplete',
        },
        {
          step: 8,
          name: 'brainStorm',
          text: 'Visual Inspiration',
          status: 'incomplete',
        },
      ],
      modalHeight: 0,
    };
  },
  watch: {
    modalInitialStep({ step = 1 }) {
      this.changeState(step - 1);
    },
    brandProfile(newValue) {
      if (newValue && typeof newValue === 'object') {
        this.brandProfileState = { ...newValue };
      }
    },
  },
  mounted() {
    if (this.brandProfile && typeof this.brandProfile === 'object') {
      this.brandProfileState = { ...this.brandProfile };
    }
    this.resizeObserver = new ResizeObserver(() => {
      this.setModalHeight();
    });
    this.resizeObserver.observe(document.body);
    window.addEventListener('resize', () => {
      this.setModalHeight();
    });
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(document.body);
    }
    window.removeEventListener('resize', () => {
      // remove listender
    });
  },
};
</script>

<style lang="scss" scoped>
    .brandQuiz-modal {
        height: 100% !important;
        border-radius: unset !important;
        padding: 0px 16px 0px 0px !important;
        background: $neutral6;
        .left-menu-options {
          height: 100%;
          overflow: auto;
          border-top: 8px solid $secondary1;
          min-width: 250px;
        }
        .close-icon {
            position: fixed;
        }
        .main-column {
          flex: 1;
          padding-right: 16px;
          padding-left: 26px;
          display: flex;
          flex-direction: column;
          background: white;
          .modal-content {
            flex: 1;
            display: flex;
            padding-top: 1rem;
            overflow: auto;
          }
        }
        .pic-placeholder {
            margin-left: 16px;
            max-width: 311px;
            height: 167px;
        }
        .menu-item-row {
            padding: 10px 20px;
            align-items: center;
            cursor: pointer;
            .text-row {
                display: flex;
                align-items: center;
                .text {
                    padding-left: 10px;
                    font-family: $fontFamily1;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 25px;
                    color: $secondary1;
                }
            }
            ::v-deep .v-icon {
              color: $secondary1;
            }
        }
        .bg-pink {
            background: #FFE6C5;
            .text {
              color: $charcoalBlack !important;
              font-weight: bold !important;
            }
            ::v-deep .v-icon {
              color: $charcoalBlack !important;
            }
        }
        .v-divider {
            margin: 20px 0px;
        }
        .next-btn {
            padding: 8px 50px !important;
            font-family: $fontFamily1;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
        }
    }
    .modal-header{
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 1rem;
      margin-top: 2rem;
    }
    .text1 {
        text-align: center;
    }
    .text2 {
        text-align: center;
    }
    .bq-content{
      height: 100%;
      display: flex;
    }
    .bq-modal-right-section{
      height: 100%;
      display: flex;
      flex: 1;
    }
    .footer{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1rem;
      height: 7rem !important;
      border-top: 1px solid#D8D8D8;
    }
    .action-section{
      margin-top: 2rem !important;
    }
    @media screen and (max-width: 600px) {
      .bq-content{
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
</style>
