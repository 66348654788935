<template>
    <div class="invite-div mt-6">
        <v-col class="pb-0 mb-0 expire-time">
          {{invitationExpiryTime(item)}}
        </v-col>
        <v-row class="pl-3">
        <v-col
        @click="$router.push({ path: `/sweeps/${item.sweep.id}` })"
        class="title cursor-pointer">
          {{item.sweep.title}}
        </v-col>
        <v-col cols="3" class="payout-div mr-6 text-center">
          <span class="payout-label pr-1">PAYOUT</span>
          <img src="@/assets/svg/i-icon.svg"/>
          <v-col class="pa-0">
          <span v-if="item.skill === '1'" class="amount">
          ${{item.sweep.photographerPayout}}
          </span>
          <span v-if="item.skill === '2'" class="amount">
          ${{item.sweep.videographerPayout}}
          </span>
          <span v-if="item.skill === '3'" class="amount">
          ${{item.sweep.talentPayout}}
          </span>
          <span v-if="item.skill === '4'" class="amount">
          ${{item.sweep.fieldProducerPayout}}
          </span>
          </v-col>
        </v-col>
        </v-row>
        <v-col class="ml-1 pb-0 mt-0 pt-0">
            <Calender2 width="18" height="22" />
            <span class="ml-2">
            {{date(item.sweep.date)}}
            </span>
        </v-col>
        <v-col class="ml-1">
            <Clock width="18" height="18" />
            <span class="ml-2">
            {{time(item.sweep.fromTime)}} - {{time(item.sweep.toTime)}}
            </span>
        </v-col>
        <v-row class="pl-6 pb-0" style="flex-wrap: inherit ;">
          <MapMarker width="25" height="18"/>
            <span class="pl-1 ml-1" style="word-break: break-all;width: fit-content;"
            v-for="(location) in sweepLocation"
            :key="location.id">
            <div style="width: 95%;">
            <a class="mb-0 a-link" style="color: black;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0 pt-0"
              v-if="item.sweep.sweepLocations.length !== 1">
              +{{item.sweep.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
        </v-row>
        <v-col class="pl-4 d-flex no-wrap pb-0 pt-3"
        v-if="item.sweep.sweepBusiness.length > 1">
          <Business width="18" height="20" style="margin-top: 2px;"/>
          <div class="ml-3 d-flex no-wrap">
          <div v-for="(business) in item.sweep.sweepBusiness" :key="business">
              <span v-if="business.business.id !== item.sweep.business.id" class="mr-1">
              {{
                `${business.business.name}`
              }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col class="item pl-4 mt-3">
            <Skill width="18" height="20"/>
            <span class="ml-2 pt-0">
                {{skill(item.skill)}}
            </span>
        </v-col>
        <v-col class="ml-0 invite-buttons">
           <v-col>
            <button
            @click="updateInvitation('0', true)"
            class="button cursor-pointer mt-2 ml-3">Decline</button>
            <button
            @click=" updateInvitation('1', false)"
            class="button2 cursor-pointer mt-2 ml-5">Accept</button>
           </v-col>
        </v-col>
        <template
        v-if="showInvitationPopup"
        class="pa-0"
      >
        <Modal
          :modal="showInvitationPopup"
          width="600"
          persistent
        >
          <InvitationDialog
            :creative="item.creatives"
            :invitationStatus="invitationStatus"
            @submit-invitation="submitInvitationForm"
            @close-invitation-dialog="showInvitationPopup=false"
            :askForReason="askReason"
          >
          </InvitationDialog>
        </Modal>
      </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Calender2 from '@/components/common/svg/Calender2';
import Clock from '@/components/common/svg/Clock';
import MapMarker from '@/components/common/svg/MapMarker';
import Business from '@/components/common/svg/Business';
import Skill from '@/components/common/svg/Skill';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
// import EventBus from '@/helpers/event-bus';
import Modal from '@/components/common/Modal';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    Calender2,
    Clock,
    MapMarker,
    Business,
    Skill,
    Modal,
    InvitationDialog,
  },
  data() {
    return {
      showInvitationPopup: false,
      invitationStatus: '',
      askReason: true,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    invite() {
      return _.get(this.userDetails, 'inviteExpiry');
    },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepLocation() {
      return this.item.sweep.sweepLocations.slice(0, 1);
    },
  },
  methods: {
    ...mapActions('sweep', ['updateInvitationById']),
    skill(val) {
      switch (val) {
        case '1':
          return 'Photographer';
        case '2':
          return 'Videographer';
        case '3':
          return 'Talent';
        default:
          return 'Field Producer';
      }
    },
    updateInvitation(status, needReason) {
      this.invitationStatus = status;
      this.askReason = needReason;
      this.showInvitationPopup = true;
    },
    async submitInvitationForm(reasonId) {
      this.loading = true;
      const result = await this.updateInvitationById({
        reasonId,
        status: this.invitationStatus,
        sweepId: this.item.sweep.id,
      });
      if (result) {
        this.showInvitationPopup = false;
        this.$emit('list-update');
      }
    },
    invitationExpiryTime(invitation) {
      const expiration = moment(invitation.invitationSentAt).add(this.invite, 'hours');
      let minsAverage = expiration.diff(moment(), 'minutes');
      minsAverage = minsAverage > 0 ? minsAverage : '';
      const hours = minsAverage < 60 ? `Expires in ${parseFloat(minsAverage % 60, 0)} min` : `Expires in ${parseFloat(minsAverage / 60).toFixed(1)} hours`;
      return hours;
    },
    date() {
      return moment(this.item.sweep.date).format('MMMM Do, YYYY');
    },
  },
};
</script>

<style lang="scss">
.invite-div {
    border: 1px solid #dedede;
    border-radius: 10px;
    .expire-time {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #FF371B;
    }
    .title {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
    }
    .invite-buttons {
        background: #fafafa;
        border-top: 1px solid #d3d3d3;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .button {
        height: 35px;
        width: 45%;
        font-family: 'Poppins';
        background: #ffffff;
        border: 2px solid #41E0BA;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #262626;
    }
    .button2 {
        height: 35px;
        width: 45%;
        font-family: 'Poppins';
        background: $primary1 !important;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: white;
    }
    .payout-div {
        max-width: 20%;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        background: #F4F5FF;
        .payout-label {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        color: #9D9D9D;
  }
  .amount {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #262626;
  }
}
}
</style>
