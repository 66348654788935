import { render, staticRenderFns } from "./UploadedItemPreviewWrapper.vue?vue&type=template&id=48fec568&scoped=true"
import script from "./UploadedItemPreviewWrapper.vue?vue&type=script&lang=js"
export * from "./UploadedItemPreviewWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fec568",
  null
  
)

export default component.exports