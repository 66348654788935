<template>
  <div class='portfolio-item' :class="{'no-preview': fileFormat === 'mov'}">
    <div class="delete-btn" v-if="$route.name === 'Profile'">
      <v-btn
        icon
        x-small
        color="black"
        class="remove-item-icon"
         v-on:click.stop="$emit('delete-item')"
      >
        <v-icon size="12">mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card
      flat
    >
      <div
        :class="{'no-image': !validImage}"
        class="card-image d-flex justify-center align-center"
      >
        <template v-if="item.fileType === '1'">
          <img
            :src="getOptimizedS3ImageURL(imageSrc,
              { width: 200, height: 200 })"
            alt="no-image"
            :class="{'default-image': !validImage}"
          />
        </template>
        <template v-else-if="item.fileType === '2'">
          <div v-if="fileFormat === 'mp4'"
            class="card-image d-flex justify-center align-center"
          >
            <img
              :src="getOptimizedS3ImageURL(item.poster, { width: 200, height: 200 })"
              :class="{'default-image': !item.poster}"
            />
              <div
                class="brand-kit-play w-100 h-100 d-flex align-center justify-center"
              >
                <div> <v-icon class="play-btn">mdi-play</v-icon> </div>
              </div>
          </div>
          <div class="card-image d-flex justify-center align-center" v-else>
            <img
            :src="imageSrc"
            alt="no-image"
            :class="{'default-image': !validImage}"
          />
          </div>
        </template>
        <template v-else>
          <div
            class="long-copy d-flex justify-center align-center w-100 h-100"
          >
            <img v-if="fileFormat === 'pdf'"
              :src="require(`@/assets/svg/theme/pdf.svg`)" class="default-image"
            />
            <img v-else :src="require(`@/assets/svg/theme/doc.svg`)" class="default-image" />
            <!-- <embed
              :src="item.file"
              type="application/pdf" width="100%" height="140px"
              v-on:click="showPreview"
              id="embed-tag"
              ref="embed-tag"
            /> -->
          </div>
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { lookup } from 'mime-types';
import _ from 'lodash';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'UploadedContent',
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fileType() {
      return lookup(this.file);
    },
    fileExtension() {
      return this.item.file.split('.').pop().toLowerCase();
    },
    validImage() {
      return this.thumbnailFormats.includes(this.fileExtension);
    },
    fileFormat() {
      if (_.split(this.item.fileFormat, '/')[1] === 'x-subrip') {
        return 'srt';
      }
      return _.split(this.item.fileFormat, '/')[1];
    },
    imageSrc() {
      if (this.validImage) {
        return this.fileExtension === 'mp4' ? this.item.poster : this.item.smallImage || this.item.file;
      }
      if (this.fileFormat === 'mov') {
        return require('@/assets/svg/theme/project-banner-default.svg');
      }
      if (this.fileFormat === 'pdf') {
        return require('@/assets/svg/theme/pdf.svg');
      }
      return require('@/assets/svg/theme/doc.svg');
    },
  },
  prop: {
    onDownload: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  data() {
    return ({
      thumbnailFormats: ['jpg', 'jpeg', 'png', 'mp4', 'gif'],
    });
  },
};
</script>

<style lang="scss" scoped>
  .portfolio-item {
    border: solid 1px #e6e6e6;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 11px;
    position: relative;
    &.no-preview {
      cursor: default;
    }
    .remove-item-icon {
      display: none;
      position: absolute;
      right: 6px;
      top: 8px;
      width: 16px;
      height: 16px;
      z-index: 2;
      background-color: #dadada;
      box-shadow: 0 1px 2px 0 rgba(125, 125, 125, 0.5);
    }
    &:hover {
      .remove-item-icon {
        display: block;
      }
    }
  }
  .v-card {
      background-color: $neutral6;
  }
  .card-image {
    height: 140px;
    width: 100%;
    img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          &.default-image {
            width: 80px !important;
            height: 80px !important;
          }
    }
  }
  .default-image {
    width: 80px !important;
    height: 80px !important;
  }
  .brand-kit-play {
    position: absolute;
    .play-btn {
      background: rgba(0, 0, 0, 0.4);
      color: white;
      cursor: pointer;
    }
  }
</style>
