<template>
  <div class="orderInfo pa-0 bg-white h-100">
    <div class="main-container h-100">
        <Loader v-if="loading" />
       <div v-else>
        <v-row class="ma-auto">
        <v-col class="d-flex align-center py-0 px-0" cols="10">
          <div class="title text-purple d-flex align-center">
            <span class="cursor-pointer" @click="backToOrders()">All Orders</span>
            <span><v-icon style="font-size: 16px;">mdi-chevron-right</v-icon></span>
          </div>
        </v-col>
        </v-row>
        <v-row class="ma-auto pb-12">
          <v-col class="align-center pa-0" cols="10">
            <div class="text order-heading" :key="order.id">Order #{{ order.id }}</div>
            <div class="order-date" :key="order.id">{{ date(order.createdAt) }}</div>
          </v-col>
        </v-row>
        <!-- <v-row class="ma-auto">
          <v-col cols="12" class="pa-0">
            <div class="order-date-container">
              <div class="order-date-label">
                ORDER DATE
              </div>
              <span class="order-date" :key="order.id">{{ date(order.createdAt) }}</span>
            </div>
          </v-col>
        </v-row> -->
        <v-row v-if="sweepDeliverables.length > 0" class="sweep-heading ma-0">
            Sweeps
        </v-row>
        <v-row class="ma-auto" v-if="sweepDeliverables.length > 0">
          <v-col class="py-2 px-0">
            <v-row class="ma-auto table-header">
              <v-col cols="5" class="text-ellipse">Creatives</v-col>
              <v-col cols="3" class="pl-7 text-ellipse">
              </v-col>
              <v-col cols="2" class="text-center">Hours</v-col>
              <v-col cols="2" class="text-center">Total</v-col>
            </v-row>
            <div class="table-data">
              <v-row class="ma-auto table-data-row"
                v-for="item in sweepDeliverables" :key="item.id"
              >
                <v-col cols="5" class="data">
                  <div class="d-flex">
              <div class="pr-3 pt-1">
                <img
                  :src="require(`@/assets/orderIcons/${item.deliverable.name}.png`)">
                  </div>
                  <div>
                  <!-- <div class="categoryName text-ellipse">
                    {{ catergoryName(item.deliverable) }}</div> -->
                  <div
                  class="text-ellipse deliverableName">{{ deliverableName(item.deliverable) }}</div>
                  <div
                  class="deliverableDesc text-ellipse">
                  {{ item.description || '-' }}</div></div>
                  </div>
                </v-col>
                <v-col cols="3" class="data pl-7">
                  <!-- <div v-if="item.sweeps !== null">
                                <div class="categoryName">sweep name
                                </div>
                                    <div
                                      @click="goToSweepDetails(item.sweeps)"
                                      class="text-ellipse sweep-title">
                                        {{ item.sweeps.title }}
                                    </div>
                                <div class="categoryName">date & time</div>
                                <div v-if="item.sweeps !== null" class="deliverableName">
                                    <span>{{ sweepDate(item.sweeps.date) }}</span>
          <div>{{ time(item.sweeps.fromTime) }}-{{ time(item.sweeps.toTime) }}</div>
                                </div>
                                </div>
                                <div v-else>-</div> -->
                </v-col>
                <v-col cols="2" class="data text-center">{{ item.quantity }}</v-col>
                <v-col cols="2" class="data text-center">{{ totalCredits(item) }}</v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-auto mt-5 mb-5" v-if="sweepDeliverables.length > 0">
              <v-col cols="5" class="text-ellipse py-0"></v-col>
              <v-col cols="3" class="pl-7 py-0 text-ellipse"></v-col>
              <v-col cols="2" class="text-center py-0 deliverableName">Total</v-col>
              <v-col
              cols="2"
              class="text-center py-0 deliverableName">{{sweepDeliverablesCost}}</v-col>
          </v-row>
        <v-row v-if="nonSweepDeliverables.length > 0" class="sweep-heading ma-0">
          Digital assets
        </v-row>
        <v-row class="ma-auto" v-if="nonSweepDeliverables.length > 0">
          <v-col class="py-8 px-0 pb-0 pt-2">
            <v-row class="ma-auto table-header">
              <v-col cols="5" class="text-ellipse">Deliverable</v-col>
              <v-col cols="3" class="pl-7 text-ellipse">Dimensions</v-col>
              <v-col cols="2" class="text-center">Qty</v-col>
              <v-col cols="2" class="text-center">Total</v-col>
            </v-row>
            <div class="table-data">
              <v-row class="ma-auto table-data-row"
                v-for="item in nonSweepDeliverables" :key="item.id"
              >
                <v-col cols="5" class="data">
                  <div class="d-flex">
                  <div class="pr-3 pt-1">
                <img
                  :src="require(`@/assets/orderIcons/${item.deliverable.creativeType.name}.png`)">
                  </div>
                  <div>
                  <div class="categoryName text-ellipse">{{ catergoryName(item.deliverable) }}</div>
                  <div
                  class="text-ellipse deliverableName">{{ deliverableName(item.deliverable) }}</div>
                  <div
                  class="deliverableDesc text-ellipse">
                  {{ item.description || '-' }}</div></div>
                </div>
                  <v-row
                    v-if="(item.files || []).length"
                  >
                  <UploadedFileGridItem
                  v-for="(file, index) in item.files || []"
                  :key="file.id"
                  :file="file"
                  @showPreview="onShowPreview(index, item.files)"
                  />
                </v-row>
                </v-col>
                <v-col cols="3"
                  :class="$vuetify.breakpoint.smAndUp ? 'pl-12' : 'pl-8'"
                  class="data">
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div
                        class="dimensionsList deliverableName" v-on="on">
                          {{ dimensions(item) }}
                        </div>
                      </template>
                      <span>
                        {{ dimensions(item) }}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="2" class="data text-center">{{ item.quantity }}</v-col>
                <v-col cols="2" class="data text-center">{{ totalCredits(item) }}</v-col>
              </v-row>
            </div>
            <v-row class="ma-auto mt-5">
              <v-col cols="5" class="text-ellipse py-0"></v-col>
              <v-col cols="3" class="pl-7 py-0 text-ellipse"></v-col>
            </v-row>
          </v-col>
        </v-row>
            <v-row class="ma-auto mt-5" v-if="nonSweepDeliverables.length > 0">
              <v-col cols="5" class="text-ellipse py-0"></v-col>
              <v-col cols="3" class="pl-7 py-0 text-ellipse"></v-col>
              <v-col cols="2" class="text-center py-0 deliverableName">Total</v-col>
              <v-col
              cols="2"
              class="text-center py-0 deliverableName">{{nonSweepDeliverablesCost}}</v-col>
          </v-row>
        <v-row v-if="enableActions">
              <v-col class="pt-10">
                <v-card-actions>
                  <v-btn class="py-0 reqBtn" @click="reqModal = true">
                    Request Changes
                  </v-btn>
                  <v-btn
                    class="py-0 submit btn-purple"
                    type="submit"
                    :loading="loading"
                    @click="approveOrder()"
                  >
                    Confirm Order
                  </v-btn>
                </v-card-actions>
              </v-col>
        </v-row>
        <v-row class="ma-auto no-history" v-else-if="deliverables.length === 0">
          <v-col class="py-8">
            <div>No deliverables</div>
          </v-col>
        </v-row>
       </div>
    </div>
    <v-container
      fluid
      v-if="reqModal"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="reqModal"
        scrollable
        width="510px"
      >
      <ReqChangesModal
        :orderId="order.id"
        @close-modal="reqModal = false"
      />
      </Modal>
    </v-container>
    <UploadedItemPreviewWrapper
      @close="showPreview = false"
      :open="showPreview"
      v-if="showPreview"
      :items="previewItems"
      :previewIndex="previewIndex"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import ReqChangesModal from '@/components/orders/ReqChangesModal';
import Loader from '@/components/common/Loader';
import UploadedFileGridItem from '@/components/orders/UploadedFileGridItem';
import UploadedItemPreviewWrapper from '@/components/orders/UploadedItemPreviewWrapper';

export default {
  name: 'single-order',
  components: {
    Modal,
    ReqChangesModal,
    Loader,
    UploadedFileGridItem,
    UploadedItemPreviewWrapper,
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  watch: {
    singleOrder(order) {
      this.order = order;
      this.deliverables = _.get(order, 'orderDeliverables', []);
    },
    async orderId(id) {
      if (id) {
        this.loading = true;
        await this.getOrderInfo({ orderId: id });
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('orders', ['singleOrder']),
    sweepDate() {
      return (val) => moment((val)).format('M-DD-YYYY');
    },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    nonSweepDeliverables() {
      return this.deliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return !/^Sweeps$/i.test(category);
      });
    },
    nonSweepDeliverablesCost() {
      // eslint-disable-next-line
      const pay = this.nonSweepDeliverables.map((data) => {
        return data.quantity * data.deliverable.creditCost;
      });
      // eslint-disable-next-line
      let sum = 0;
      pay.forEach((item) => {
        sum += item;
      });
      return sum;
    },
    sweepDeliverables() {
      return this.deliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return /^Sweeps$/i.test(category);
      });
    },
    sweepDeliverablesCost() {
      // eslint-disable-next-line
      const pay = this.sweepDeliverables.map((data) => {
        return data.quantity * data.deliverable.creditCost;
      });
      // eslint-disable-next-line
      let sum = 0;
      pay.forEach((item) => {
        sum += item;
      });
      return sum;
    },
    role() {
      return _.get(this.userDetails, 'role.name', '');
    },
    customer() {
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(this.role);
    },
    enableActions() {
      return this.customer && ['PENDING_APPROVAL'].includes(this.order.status);
    },
  },
  data() {
    return {
      order: '',
      deliverables: [],
      loading: false,
      reqModal: false,
      showPreview: false,
      previewItems: [],
      previewIndex: null,
    };
  },
  methods: {
    ...mapActions('orders', ['getOrderInfo', 'confirmOrder']),
    goToSweepDetails(val) {
      const sweepId = val.id;
      const { businessId } = val;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          sweepId,
          businessId,
        },
      });
    },
    async approveOrder() {
      await this.confirmOrder(this.order.id);
    },
    backToOrders() {
      if (this.customer) {
        this.$router.push({ name: 'Orders' });
      } else {
        this.$emit('backToAllOrders', '');
      }
    },
    date(createdDate) {
      return moment(createdDate).format('M/DD/YYYY - hh:mm a');
    },
    catergoryName(deliverable) {
      return _.get(deliverable, 'category.name', '');
    },
    deliverableName(deliverable) {
      const name = _.get(deliverable, 'name', '');
      return `${name} (${deliverable.creditCost} credits)`;
    },
    totalCredits(deliverable) {
      return deliverable.quantity * deliverable.deliverable.creditCost;
    },
    dimensions(deliverable) {
      return deliverable.dimensions.length
        ? deliverable.dimensions.map((x) => x.dimension.name).join(', ')
        : null;
    },
    totalCreditsCount() {
      const countArr = this.deliverables
        .map((deliverable) => (deliverable.quantity * deliverable.deliverable.creditCost));
      return countArr.reduce((a, b) => a + b, 0);
    },
    onShowPreview(index, items) {
      this.previewItems = items;
      this.previewIndex = index;
      this.showPreview = true;
    },
  },
  async mounted() {
    let id = '';
    if (this.customer) {
      id = this.$route.params.orderId;
    } else {
      id = this.orderId;
    }
    if (id) {
      this.loading = true;
      await this.getOrderInfo({ orderId: id });
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.orderInfo {
  .sweep-heading {
      letter-spacing: 0.7px;
      text-transform: uppercase;
      padding: 10px;
      font-weight: 600;
      background-color: #F1F1F1;
    }
  .sweep-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    cursor: pointer;
    &:hover {
      color: #8066ff;
    }
  }
  .text-ellipse {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .order-date-container {
    background: #F2F2F2;
    padding: 13px 15px;
    border-radius: 4px;
    .order-date-label {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #999999;
    }
    .order-date {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
    }
  }
  .text-purple {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $secondary1;
  }
  .order-heading {
    font-family: $fontFamily2;
    font-weight: 800;
    font-size: 46px;
    line-height: 58px;
    color: $titleBlack;
    padding-top: 16px;
  }
  .scrollable {
    overflow: auto;
  }
  .table-header {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    div {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .table-data {
    .table-data-row {
      border-bottom: 1px solid #D8D8D8;
    }
    .data {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .categoryName {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #999999;
    }
    .deliverableDesc {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #999999;
    }
    .deliverableName {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
    }
    .dimensionsList {
      cursor: pointer;
      font-weight: 400;
      width: fit-content;
    }
  }
  .v-card__actions {
    padding: 0;
    justify-content: flex-end;
    border-top: 1px solid #babfc7;
    padding: 14px 27px 18px;
    .v-btn {
      border-radius: 30px;
      font-weight: 400;
      color: #262626;
      box-shadow: none;
      line-height: 24px;
      width: 170px;
      height: 42px;
      ::v-deep .v-btn__content {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }
    .submit {
      margin-left: 12px !important;
    }
    .reqBtn {
      background: #FFFFFF !important;
      border: 2px solid $secondary2;
      .v-btn__content {
        color: #262626;
      }
    }
  }
  .no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    color: $charcoalBlack;
    height: 74%;
    display: flex;
    align-items: center;
  }
}
</style>
