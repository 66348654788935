<template>
  <v-container class="pl-0 active-container pb-0 pr-0">
    <v-row class="active mb-5">
      <v-col class="pt-0 pb-0 active-col">
        <v-row>
          <v-col class="d-flex align-center pa-0 pl-5">
            <div
              v-if="!creative"
              class="cursor-pointer font-h4 notranslate title-div pt-3 pb-2"
              @click="goToSweepDetails(item.id)">{{item.title}}</div>
            <div
              v-if="creative"
              class="cursor-pointer font-h4 notranslate title-div pt-3 pb-2"
              @click="goToSweepDetails2(item.id)">{{item.title}}</div>
          </v-col>
        </v-row>
        <span v-if="creative">
        <v-col class="pb-0 pt-0 d-flex no-wrap">
          <Calender2 width="18" height="22" />
          <span class="ml-3 date-span">
          {{date(item.date)}}
          </span>
        </v-col>
        <v-col class="d-flex pt-2">
              <!-- During sweep -->
              <span class="font-body">
              <Clock width="18" height="18" />
              <span class="ml-2">
                <span class="text-live" v-if="isSweepLive && !item.cancelledAt">LIVE </span>
                {{time(item.fromTime)}} - {{time(item.toTime)}}
              <span class="font-label duration pl-4">
                {{ duration }}
              </span></span
              >
              </span>
        </v-col>
        </span>
        <v-col v-if="!creative" class="item d-flex no-wrap">
          <Calender width="18" height="22" style="margin-left: 2px;" />
          <div class="pl-3 date-span">
          {{date(item.date)}}, {{time(item.fromTime)}} - {{time(item.toTime)}}
          </div>
        </v-col>
        <v-row class="ml-3" style="flex-wrap: inherit ;">
          <MapMarker width="18" height="18"/>
            <span class="pl-1 ml-2" style="word-break: break-all;width: fit-content;"
            v-for="(location) in sweepLocation"
            :key="location.id">
            <div style="width: 80%;min-width: fit-content;">
            <a class="mb-0 a-link" style="color: black;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0 pt-0"
              v-if="item.sweepLocations.length !== 1">
              +{{item.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
        </v-row>
        <v-col class="item mt-1 d-flex no-wrap" v-if="item.sweepBusiness.length > 1">
          <Business width="18" height="20" style="margin-top: 2px;"/>
          <div class="ml-4 d-flex no-wrap">
          <div v-for="(business, index) in item.sweepBusiness" :key="business">
              <span v-if="business.business.id !== item.business.id" class="mr-1">
              {{
                `${business.business.name}
                  ${Object.keys(item.sweepBusiness).length - 1 !== index ? ',' : ''}`
              }}
              </span>
            </div>
          </div>
        </v-col>
        <span v-if="!creative">
        <v-col v-if="item.orderId" class="item d-flex no-wrap mt-2">
          <Group width="18" height="18" style="margin-left: 2px;" />
          <div
            v-if="!clients && !editor"
            @click="gotoOrder(item.orderId)"
            class="ml-3 order-button">Order #{{ item.orderId }}</div>
            <div
            v-else-if="clients"
            @click="gotoOrder2(item.orderId)"
            class="ml-3 order-button">Order #{{ item.orderId }}</div>
            <div
            v-else
            class="ml-3">Order #{{ item.orderId }}</div>
        </v-col>
        <v-row v-if="item.orderId" class="item ma-0">
          <Broom width="18" height="18" />
          <span class="ml-3" v-for="(order, index) in sweepDeliverables" :key="order">
            <div :class="index !== 0 ? 'ml-4' : ''">
            {{ `${index === 0 ? `${order.deliverable.category.name} :` : ''}
            ${order.deliverable.name}
             (${order.deliverable.creditCost} credits)
             ${Object.keys(sweepDeliverables).length - 1 !== index ? ',' : ''}` }}
            </div>
          </span>
        </v-row>
        </span>
      </v-col>
    </v-row>
  <div v-if="showOrderModal">
      <OrderForm
        :open="showOrderModal"
        @close="closeCreateOrderModal"
        :orderId="orderToEdit"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import OrderForm from '@/components/orders/OrderForm';
import Calender from '@/components/common/svg/Calender';
import Clock from '@/components/common/svg/Clock';
import Calender2 from '@/components/common/svg/Calender2';
import MapMarker from '@/components/common/svg/MapMarker';
import Business from '@/components/common/svg/Business';
import Broom from '@/components/common/svg/Broom';
import Group from '@/components/common/svg/Group';

export default {
  name: 'AciveSweeps',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrderForm,
    Calender,
    Clock,
    Calender2,
    MapMarker,
    Business,
    Broom,
    Group,
  },
  data() {
    return {
      showOrderModal: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    clients() {
      return ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role);
    },
    editor() {
      return ['editor'].includes(this.role);
    },
    creative() {
      return this.role === 'creative';
    },
    isSweepLive() {
      const { item } = this;
      const date = moment().format('YYYY-MM-DD');
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime());
      startDate.setHours(item.fromTime.split(':')[0]);
      startDate.setMinutes(item.fromTime.split(':')[1]);
      const endDate = new Date(currentDate.getTime());
      endDate.setHours(item.toTime.split(':')[0]);
      endDate.setMinutes(item.toTime.split(':')[1]);
      const valid = startDate < currentDate && endDate > currentDate;
      if (date === item.date && valid) {
        return true;
      }
      return false;
    },
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `(${rminutes} minutes)` : ''}`;
        const checkForHours = `${rhours > 0 ? `(${rhours} hours)` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `(${rhours} hours and ${rminutes} minutes)` : checkForHours;
        return text;
      };
    },
    duration() {
      const { item } = this;
      const startTime = moment(item.fromTime, 'hh:mm a');
      const endTime = moment(item.toTime, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepLocation() {
      return this.item.sweepLocations.slice(0, 1);
    },
    sweepDeliverables() {
      const sweeps = this.item.orderDeliverable.filter((data) => data.deliverable.creativeType.name === 'Sweeps');
      return sweeps;
    },
  },
  methods: {
    ...mapActions('orders', ['getOrderInfo']),
    async gotoOrder(val) {
      await this.getOrderInfo({
        orderId: val,
      });
      // find order from orders
      this.orderToEdit = val;
      this.showOrderModal = true;
    },
    closeCreateOrderModal() {
      this.orderToEdit = {};
      this.showOrderModal = false;
    },
    gotoOrder2(val) {
      this.$router.push(`/orders/${val}?orderId=${val}`);
    },
    goToSweepDetails(val) {
      const sweepId = val;
      const { businessId } = this.item;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          businessId,
          sweepId,
        },
      });
    },
    goToSweepDetails2(val) {
      const sweepId = val;
      return this.$router.push({
        name: 'Business Sweep Details2',
        params: {
          sweepId,
        },
      });
    },
    date() {
      return moment(this.item.date).format('MMMM Do, YYYY');
    },
  },
};
</script>

<style lang="scss">
.order-button {
  cursor: pointer;
  &:hover {
    color: #8066FF;
  }
}
.active-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #929292;
}
.item {
  padding: 0px 10px 10px;
  .date-span {
    text-transform: lowercase;
    white-space: nowrap;
  }
}
.active-container {
  .title-div {
    word-break: break-all;
    width: 100% !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .active {
    margin: 0px;
    color: black;
    border:1px solid #DEDEDE;
    border-radius: 10px;
    .active-col {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
  .text-live {
    color: $colorRed1;
  }
  .duration {
    font-weight: 400;
    color: #adadad;
    text-transform: lowercase;
  }
}
  .no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2rem;
    color: $charcoalBlack;
    height: 74%;
    display: flex;
    align-items: center;
  }
}
.more-location {
  font-weight: 400px;
  line-height: 24px;
  color: #929292;
}
@media (min-width: 0px) and (max-width: 600px) {
  .active-container {
    .title-div {
      width: 90% !important;
    }
    .item {
        width: 100% !important;
        .date-span {
          width: 200px;
          text-transform: lowercase;
          white-space: break-spaces;
        }
      }
  }
}
</style>
