<template>
  <v-container fluid class="">
    <div class="music-categories">
      <music-action-item
        v-for="(category,index) in musicCategories"
        :key="index"
        :coverColor="category.coverColor"
        :audio="category.fileUrl"
        :title="category.name"
        :id="category.id"
        :selected="isSelectedGenre(category.id)"
        @select="onSelectGenre"
        @play="id => activeMusicId=id"
        :activeMusicId="activeMusicId"
        ></music-action-item>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MusicActionItem from './MusicActionItem';

export default {
  name: 'Music',
  components: {
    MusicActionItem,
  },
  props: {
    genres: Array,
    brandProfileId: Number,
    reference: {
      type: String,
      default: 'business',
    },
  },
  data() {
    return ({
      selectedGenres: [],
      activeMusicId: null,
    });
  },
  computed: {
    ...mapGetters('brandProfile', [
      'brandProfile',
    ]),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    brandMusic() {
      return this.brandProfile.brandMusic;
    },
    musicCategories() {
      const colors = [
        '#CEB668',
        '#51C5DC',
        '#E08025',
        '#FF97C3',
        '#C5795C',
        '#BF2D69',
        '#8A8A8A',
        '#874CBE',
        '#1BA529',
        '#D8D167',
        '#82E899',
        '#577DC0',
      ];
      // eslint-disable-next-line arrow-body-style
      const items = this.genres.map((genre, index) => {
        return {
          ...genre,
          coverColor: colors[index] || 'black',
        };
      });
      return items;
    },
  },
  methods: {
    ...mapActions('brandProfile', ['updateBrandMusic']),
    isSelectedGenre(id) {
      return this.selectedGenres.includes(id);
    },
    async onSelectGenre(id) {
      const index = this.selectedGenres.indexOf(id);
      if (this.brandMusic.id !== id) {
        await this.updateBrandMusic({
          brandMusicId: id,
          brandProfileId: this.brandProfileId,
          reference: this.reference,
        });
      }
      if (index !== -1) {
        // disabing unselect for now
        // this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres = [];
        this.selectedGenres.push(id);
      }
      this.$emit('selectmusic', id);
    },
  },
  mounted() {
    this.onSelectGenre(this.brandMusic.id);
  },
};
</script>
<style lang="scss">
  .section-header{
    text-align: center;
  }
  .music-categories{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
  }
  @media screen and (min-width: 1340px) {
    .music-categories{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: auto;
    }
  }
</style>
