import { render, staticRenderFns } from "./ViewProfileModal.vue?vue&type=template&id=5bb970c8&scoped=true"
import script from "./ViewProfileModal.vue?vue&type=script&lang=js"
export * from "./ViewProfileModal.vue?vue&type=script&lang=js"
import style0 from "./ViewProfileModal.vue?vue&type=style&index=0&id=5bb970c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb970c8",
  null
  
)

export default component.exports