import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-history pa-0"},[_c(VRow,{staticClass:"title-header ma-0"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"text heading"},[_vm._v(" Order History ")])])],1),(_vm.completedOrders && _vm.completedOrders.length)?_c(VRow,{key:_vm.completedOrders.length,staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0"},[_c(VRow,{staticClass:"ma-0 table-header"},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v("Order#")]),_c(VCol,{staticClass:"py-0 order-date",class:_vm.$vuetify.breakpoint.xsOnly && 'visibility-hidden',attrs:{"cols":"2"}},[_vm._v("Order Date")]),_c(VCol,{staticClass:"deliverable-label",class:_vm.$vuetify.breakpoint.xsOnly && 'visibility-hidden',attrs:{"cols":"5"}},[_vm._v("Deliverables")]),_c(VCol,{staticClass:"text-center credits-label"},[_vm._v("Credits")])],1),_c('div',{staticClass:"table-data scrollable"},_vm._l((_vm.completedOrders),function(item){return _c(VRow,{key:item.id,staticClass:"ma-auto cursor-pointer",on:{"click":function($event){return _vm.getOrderInfo(item)}}},[_c(VCol,{staticClass:"data text-purple",class:!_vm.$vuetify.breakpoint.xsOnly && 'text-center',attrs:{"cols":"2"}},[_vm._v(_vm._s(item.id))]),_c(VCol,{staticClass:"data",attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.date(item.createdAt)))]),_c(VCol,{staticClass:"data",class:_vm.$vuetify.breakpoint.xsOnly && 'visibility-hidden',attrs:{"cols":"5"}},[_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"deliverables",staticStyle:{"width":"auto"}},on),[_vm._v(" "+_vm._s(_vm.dimensions(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.dimensions(item))+" ")])])],1)]),_c(VCol,{staticClass:"data text-center"},[_vm._v(_vm._s(item.credits))]),(_vm.$vuetify.breakpoint.xsOnly)?_c(VCol,{staticClass:"data",attrs:{"cols":"9"}},[_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"deliverables",staticStyle:{"width":"auto"}},on),[_vm._v(" "+_vm._s(_vm.dimensions(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.dimensions(item))+" ")])])],1)]):_vm._e()],1)}),1)],1)],1):_c(VRow,{staticClass:"ma-0 no-history"},[_c(VCol,{staticClass:"my-5 d-flex flex-column justify-center align-center"},[_c(VImg,{attrs:{"width":"2rem","src":require("@/assets/svg/no_active_orders.svg"),"aspect-ratio":"1"}}),_c('div',{staticStyle:{"height":"200px"}},[_vm._v("No Order History")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }