<template>
  <div class="brand-colors-wrapper">
      <div class="brand-colors-inner d-flex flex-wrap">
          <div class="bc-section bc-primary-container flex-grow-1 col-6">
              <div class="bc-section-title">Primary</div>
              <div class="bc-list mt-2 d-flex">
                    <div class="bc-color-wrapper cursor-pointer"
                        v-for="(color, index) in primaryColors"
                        :key="index"
                        v-clipboard:copy="color"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError">
                      <div class="bc-color" :style="`background:${color};`"></div>
                        <div
                        class="bc-color-code"
                        >{{color}}</div>
                    </div>
              </div>
          </div>
          <div class="bc-section bc-secondary-container flex-grow-1 col-6">
              <div class="bc-section-title">Secondary</div>
                <div class="bc-list mt-2 d-flex">
                    <div class="bc-color-wrapper cursor-pointer"
                        v-for="(color, index) in secondaryColors"
                        :key="index"
                        v-clipboard:copy="color"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                    >
                      <div class="bc-color" :style="`background:${color};`"></div>
                        <div
                        class="bc-color-code"
                        >{{color}}</div>
                    </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'brand-colors',
  props: {
    primaryColors: Array,
    secondaryColors: Array,
  },
  methods: {
    ...mapActions(['setNotification']),
    onCopy() {
      this.setNotification({
        message: 'Color copied to clipboard.',
        type: 'success',
      }, { root: true });
    },
    onError(e) {
      this.setNotification({
        message: e.message,
        type: 'error',
      }, { root: true });
    },
  },
};
</script>

<style scoped lang="scss">
.bc-section{
  width: auto;
  padding: 0.5rem;
}
.bc-section-title{
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid #D8D8D8;
}
.bc-list {
  flex-wrap: wrap;
}
.bc-color-wrapper{
    margin: 0.5rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bc-color{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: navy;
    border: 1px solid #cccccc;
}
.bc-color-code {
  cursor: pointer;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: $charcoalBlack;
}
</style>
