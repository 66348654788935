<!-- eslint-disable max-len -->
<template>
  <div class="order-container">
    <div class="order-main-section">
      <div class="order-header cursor-pointer" @click="openActiveOrder(order.id)">
        <v-row class="align-center order-header-top justify-space-between">
          <div class="order-number">
            <v-row>
            <span class="mt-1">Order #{{ order.id }}</span>
            <v-col
            v-if="showMenu"
            class="ml-3 order-status-container"
            :style="
              `border: 2px solid ${getStatusColor(order.status)};
                    border-left: 0.5rem solid ${getStatusColor(order.status)};
                    background: ${getStatusBackground(order.status)}`
            "
          >
            <span class="order-status">{{ getStatusDisplayText(order.status) }}</span>
            </v-col>
            </v-row>
          </div>
          <div
            v-if="!showMenu"
            class="order-status-container"
            :style="
              `border: 2px solid ${getStatusColor(order.status)};
                    border-left: 0.5rem solid ${getStatusColor(order.status)};
                    background: ${getStatusBackground(order.status)}`
            "
          >
            <span class="order-status">{{ getStatusDisplayText(order.status) }}</span>
          </div>
          <div v-if="showMenu">
              <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      size="2em"
                      v-on="on"
                      color="#262626">mdi-dots-horizontal </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="$emit('editOrder')"
                      class="order-menu-item">
                      <v-list-item-title >Edit Order</v-list-item-title>
                    </v-list-item>
                    <!-- <v-divider></v-divider>
                    <v-list-item
                      @click="$emit('deleteOrder')"
                      class="order-menu-item delete"
                    >
                      <v-list-item-title
                      style="color: #ff371a">
                        Cancel Order
                      </v-list-item-title>
                    </v-list-item> -->
                  </v-list>
              </v-menu>
          </div>
        </v-row>
        <v-row class="order-data">
          <v-col cols="5">
            <div class="order-date-container">
              <div class="order-main-label">
                ORDER DATE
              </div>
              <span class="order-text">{{ orderDate }}</span>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0">
            <!-- <div class="order-credits-container">
              <div class="order-main-label">COMPLETED CREDITS</div>
              <span class="order-text">0/{{ order.credits }}</span>
            </div> -->
          </v-col>
          <v-col cols="3" class="pr-0 pb-0">
            <div
              class="order-credits-container d-flex flex-column align-center"
            >
              <div class="order-main-label">COMPLETED CREDITS</div>
              <span class="order-text credits">0/{{ order.credits }}</span>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row style="padding: 0 1rem;">
          <div class="order-description-container">
              <div class="order-main-label">
                  ORDER DESCRIPTION
              </div>
              <div class="order-main-text">{{order.description || '-'}}</div>
          </div>
        </v-row> -->
    </div>
    <!-- :class="{showTopBorder: showDeliverables}" -->
    <div
      class="order-body"
      :style="`height: ${showDeliverables ? '100%' : '0px'}`"
      ref="orderBody"
    >
    <div v-if="sweepDeliverables.length > 0">
    <v-row class="sweep-heading">
      Sweep
        </v-row>
        <div v-for="(deliverable, index) in sweepDeliverables" :key="deliverable.id">
          <!-- {{ deliverable }} -->
          <v-col v-if="index === 0" class="pl-0">
            <div
              class="sweep-title"
              @click="goToSweepDetails(deliverable.sweeps)">
            {{ deliverable.sweeps.title }}
            </div>
            <div class="order-del-description">{{deliverable.sweeps.notes || '-'}}</div>
          </v-col>
        </div>
        <v-row class="dt-head" style="border-top: 1px solid #ccc;">
            <v-col class="dt-col-deliverable pl-0">
              Creatives
            </v-col>
            <!-- <v-col v-if="showStatus"
              class="text-center mr-2 sweep-deliverable">
                Status
            </v-col> -->
            <v-col class="text-center col-min-width dt-col-qty">
              Hours
            </v-col>
            <v-col class="text-center col-min-width dt-col-total">
              Total
            </v-col>
        </v-row>
        <div
            v-for="deliverable in sweepDeliverables"
            :key="deliverable.id"
            class="dt-row flex-column"
          >
            <v-row style="flex-wrap: nowrap !important">
              <v-col class="dt-col-deliverable pl-0">
              <div style="overflow-wrap: anywhere">
                <div class="d-flex">
                <div class="pr-4 pt-1">
                <img
                  :src="require(`@/assets/orderIcons/${deliverable.deliverable.name}.png`)">
                  </div>
                <div>
                <!-- <div class="order-del-category">{{ catergoryName(deliverable.deliverable) }}</div> -->
                <div class="order-del-name">{{ deliverableName(deliverable.deliverable) }}
                </div>
                <div class="order-del-description">{{deliverable.description || '-'}}</div>
                </div>
                </div>
              </div>
              </v-col>
               <!-- <v-col class="text-center sweep-details" v-if="showStatus(deliverable)">
               <div class="py-2">
                                    <div class="order-del-upload-btn" v-if="!deliverable.sweeps">
                                        <v-btn
                                            depressed
                                            class="del-upload-btn"
                                            @click="() => scheduleTheSweep(deliverable)"
                                        >
                                            Schedule
                                        </v-btn>
                                    </div>
                                <div class="order-del-upload-btn" v-if="deliverable.sweeps !== null">
                                <span class="order-del-category">sweep name
                                </span>
                                    <div
                                      @click="goToSweepDetails(deliverable.sweeps)"
                                      class="sweep-title">
                                        {{ deliverable.sweeps.title }}
                                    </div>
                                <span class="order-del-category">date & time</span>
                                <div v-if="deliverable.sweeps !== null">
                                    <span>{{ sweepDate(deliverable.sweeps.date) }}</span>
          <div>{{ time(deliverable.sweeps.fromTime) }}-{{ time(deliverable.sweeps.toTime) }}</div>
                                </div>
                                </div>
                                </div>
              </v-col> -->
              <v-col class="text-center col-min-width dt-col-qty">
                {{ deliverable.quantity }}
              </v-col>
              <v-col class="text-center col-min-width dt-col-total">
                {{ deliverable.quantity * deliverable.deliverable.creditCost }}
              </v-col>
            </v-row>
            <!-- <v-row
              style="width: 200px"
             class="text-center sweep-details2 ml-0 pl-0" v-if="showStatus(deliverable)">
                <div class="order-del-upload-btn" v-if="!deliverable.sweeps">
                  <v-btn
                    depressed
                    class="del-upload-btn"
                    @click="() => scheduleTheSweep(deliverable)">
                      Schedule
                  </v-btn>
                  </div>
                        <div class="order-del-upload-btn" v-if="deliverable.sweeps !== null">
                            <span class="order-del-category">sweep name
                                </span>
                                    <div
                                      @click="goToSweepDetails(deliverable.sweeps)"
                                      class="sweep-title">
                                        {{ deliverable.sweeps.title }}
                                    </div>
                                <span class="order-del-category">date & time</span>
                                <div v-if="deliverable.sweeps !== null">
                                    <span>{{ sweepDate(deliverable.sweeps.date) }}</span>
          <div>{{ time(deliverable.sweeps.fromTime) }}-{{ time(deliverable.sweeps.toTime) }}</div>
                                </div>
                                </div>
              </v-row> -->
          </div>
          <v-row v-if="sweepDeliverables.length > 0">
              <v-spacer></v-spacer>
              <v-col class="text-center dt-col-qty">
                  Total
              </v-col>
              <v-col class="text-center dt-col-total">
              {{ sweepDeliverablesCost }}</v-col>
              </v-row>
    </div>
    <v-row v-if="nonSweepDeliverables.length > 0" class="sweep-heading">
            Digital assets
        </v-row>
    <div class="deliverables-table mt-3"
      :style="{
        'border-top': nonSweepDeliverables.length > 0 ? '1px solid #ccc' : '',
      }">
        <v-row class="dt-head" v-if="nonSweepDeliverables.length > 0">
            <v-col class="dt-col-deliverable pl-0">
              Deliverable
            </v-col>
            <!-- <v-col v-if="showStatus"
              class="text-center mr-2 sweep-deliverable">
                Status
            </v-col> -->
            <v-col class="text-center dt-col-dimension">
              Dimensions
            </v-col>
            <v-col class="text-center col-min-width dt-col-qty">
              Qty
            </v-col>
            <v-col class="text-center col-min-width dt-col-total">
              Total
            </v-col>
        </v-row>
        <div class="dt-body">
          <div
            v-for="deliverable in nonSweepDeliverables"
            :key="deliverable.id"
            class="dt-row flex-column"
          >
            <v-row style="flex-wrap: nowrap !important">
              <v-col class="dt-col-deliverable pl-0">
              <div style="overflow-wrap: anywhere">
                <div class="d-flex">
                <div class="pr-4 pt-1">
                <img
                  :src="require(`@/assets/orderIcons/${deliverable.deliverable.creativeType.name}.png`)">
                  </div>
                <div>
                <div class="order-del-category">{{ catergoryName(deliverable.deliverable) }}</div>
                <div class="order-del-name">{{ deliverableName(deliverable.deliverable) }}
                </div>
                <div class="order-del-description">{{deliverable.description || '-'}}</div>
                </div>
                </div>
              </div>
              </v-col>
               <!-- <v-col class="text-center sweep-details" v-if="showStatus(deliverable)">
               <div class="py-2">
                                    <div class="order-del-upload-btn" v-if="!deliverable.sweeps">
                                        <v-btn
                                            depressed
                                            class="del-upload-btn"
                                            @click="() => scheduleTheSweep(deliverable)"
                                        >
                                            Schedule
                                        </v-btn>
                                    </div>
                                <div class="order-del-upload-btn" v-if="deliverable.sweeps !== null">
                                <span class="order-del-category">sweep name
                                </span>
                                    <div
                                      @click="goToSweepDetails(deliverable.sweeps)"
                                      class="sweep-title">
                                        {{ deliverable.sweeps.title }}
                                    </div>
                                <span class="order-del-category">date & time</span>
                                <div v-if="deliverable.sweeps !== null">
                                    <span>{{ sweepDate(deliverable.sweeps.date) }}</span>
          <div>{{ time(deliverable.sweeps.fromTime) }}-{{ time(deliverable.sweeps.toTime) }}</div>
                                </div>
                                </div>
                                </div>
              </v-col> -->
              <v-col class="text-center dt-col-dimension">
                <div class="dimensions-labels">
                <span
                class="dimensions-label"
                v-for="(dim, index) in deliverable.dimensions"
                :key="index"
                >
                {{`${dim.dimension.name}${index !== deliverable.dimensions.length - 1 ? ',' : ''}`}}
                  </span>
                </div>
              </v-col>
              <v-col class="text-center col-min-width dt-col-qty">
                {{ deliverable.quantity }}
              </v-col>
              <v-col class="text-center col-min-width dt-col-total">
                {{ deliverable.quantity * deliverable.deliverable.creditCost }}
              </v-col>
            </v-row>
            <v-row
              style="width: 200px"
             class="text-center sweep-details2 ml-0 pl-0" v-if="showStatus(deliverable)">
                <div class="order-del-upload-btn" v-if="!deliverable.sweeps">
                  <v-btn
                    depressed
                    class="del-upload-btn"
                    @click="() => scheduleTheSweep(deliverable)">
                      Schedule
                  </v-btn>
                  </div>
                      <!-- eslint-disable-next-line -->
                        <div class="order-del-upload-btn" v-if="deliverable.sweeps !== null">
                            <span class="order-del-category">sweep name
                                </span>
                                    <div
                                      @click="goToSweepDetails(deliverable.sweeps)"
                                      class="sweep-title">
                                        {{ deliverable.sweeps.title }}
                                    </div>
                                <span class="order-del-category">date & time</span>
                                <div v-if="deliverable.sweeps !== null">
                                    <span>{{ sweepDate(deliverable.sweeps.date) }}</span>
          <div>{{ time(deliverable.sweeps.fromTime) }}-{{ time(deliverable.sweeps.toTime) }}</div>
                                </div>
                                </div>
              </v-row>
            <v-row>
              <v-col class="text-center dt-col-dimension2">
                  <div class="dimensions-labels">
                      <span
                          class="dimensions-label"
                          v-for="(dim, index) in deliverable.dimensions"
                          :key="index"
                      >
                      <!-- eslint-disable-next-line -->
                      {{`${dim.dimension.name}${index !== deliverable.dimensions.length - 1 ? ',' : '' }`}}
                      </span>
                  </div>
                </v-col>
            </v-row>
            <v-row
              v-if="(deliverable.files || []).length"
            >
            <UploadedFileGridItem
              v-for="(file, index) in deliverable.files || []"
              :key="file.id"
              :file="file"
              @remove="removeFile"
              @showPreview="$emit('showPreview', index, deliverable.files)"
            />
            </v-row>
          </div>
              <v-row v-if="nonSweepDeliverables.length > 0">
              <v-spacer></v-spacer>
              <v-col class="text-center dt-col-qty">
                  Total
              </v-col>
              <v-col class="text-center dt-col-total">
              {{ nonSweepDeliverablesCost }}</v-col>
              </v-row>
      </div>
      </div>
    </div>
    </div>
    <div class="order-toggler d-flex justify-end">
            <div class="order-toggle-btn d-flex text-purple" @click="toggleDeliverables">
                <div class="order-toggle-text" :key="showDeliverables">
                    <span>{{ showDeliverables ? 'Hide' : 'Show' }} Deliverables</span>
                </div>
                <div class="order-toggle-icon">
                    <v-icon color="#8066FF">
                        {{ showDeliverables ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { getStatusDisplayText, getStatusColor, getStatusBackground } from '@/helpers/orders';
import UploadedFileGridItem from '@/components/orders/UploadedFileGridItem';

export default {
  name: 'Order',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    nonSweepDeliverables() {
      return this.order.orderDeliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return !/^Sweeps$/i.test(category);
      });
    },
    nonSweepDeliverablesCost() {
      // eslint-disable-next-line
      const pay = this.nonSweepDeliverables.map((data) => {
        return data.quantity * data.deliverable.creditCost;
      });
      // eslint-disable-next-line
      let sum = 0;
      pay.forEach((item) => {
        sum += item;
      });
      return sum;
    },
    sweepDeliverables() {
      return this.order.orderDeliverables.filter((deliverable) => {
        const category = _.get(deliverable, 'deliverable.creativeType.name', '');
        return /^Sweeps$/i.test(category);
      });
    },
    sweepDeliverablesCost() {
      // eslint-disable-next-line
      const pay = this.sweepDeliverables.map((data) => {
        return data.quantity * data.deliverable.creditCost;
      });
      // eslint-disable-next-line
      let sum = 0;
      pay.forEach((item) => {
        sum += item;
      });
      return sum;
    },
    time() {
      return (time) => moment((time), ['HH:mm']).format('h:mmA');
    },
    sweepDate() {
      return (val) => moment((val)).format('M-DD-YYYY');
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    orderDate() {
      return moment(this.order.createdAt).format('M/DD/YYYY - hh:mm A');
    },
    showMenu() {
      return ['DRAFT'].includes(this.order.status);
    },
  },
  methods: {
    getStatusDisplayText,
    getStatusColor,
    getStatusBackground,
    showStatus(deliverable) {
      const type = _.get(deliverable, 'deliverable.creativeType.name');
      return ['ACTIVE'].includes(this.order.status) && type === 'Sweeps';
    },
    goToSweepDetails(val) {
      const sweepId = val.id;
      const { businessId } = val;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          sweepId,
          businessId,
        },
      });
    },
    scheduleTheSweep(val) {
      this.$router.push({
        path: '/sweeps',
        query: {
          new: true,
          referrer: 'orders',
          orderId: this.order.id,
          deliverableId: val.id,
        },
      });
    },
    openActiveOrder(orderId) {
      const { projectId } = this.$route.params;
      const { businessId } = this.$route.query;
      this.$router.push({
        name: 'Order',
        params: { orderId },
        query: { businessId, orderId, projectId },
      });
    },
    async toggleDeliverables() {
      if (this.deliverablesLoading) return;
      const showDeliverables = !this.showDeliverables;
      if (!showDeliverables) {
        this.showDeliverables = false;
        this.order.showDeliverables = false;
        return;
      }
      this.showDeliverables = true;
      this.order.showDeliverables = true;
    },
    catergoryName(deliverable) {
      return _.get(deliverable, 'category.name', '');
    },
    deliverableName(deliverable) {
      const name = _.get(deliverable, 'name', '');
      return `${name} (${deliverable.creditCost} credits)`;
    },
  },
  data() {
    return {
      showDeliverables: false,
      bodyHeight: 0,
      timer: null,
    };
  },
  mounted() {
    this.timer = setTimeout(() => {
      // eslint-disable-next-line
      const height = this.$refs.orderBody.scrollHeight;
      this.bodyHeight = height;
    }, 100);
    this.showDeliverables = this.order.showDeliverables;
    this.timer = setTimeout(() => {
      // eslint-disable-next-line
      const height = this.$refs.orderBody.scrollHeight;
      this.bodyHeight = height;
    }, 100);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  components: {
    UploadedFileGridItem,
  },
};
</script>

<style lang="scss" scoped>
.del-upload-btn {
    background: #fff !important;
    border: 2px solid $secondary2;
    border-radius: 1.5rem;
    & > .v-btn__content {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.sweep-title {
    cursor: pointer;
    word-break: break-all;
    width: 100% !important;
    overflow-x: hidden;
    color: $primary1;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    // &:hover {
    //     color: #8066ff;
    // }
  }
.order-container {
  margin-top: 1.5rem;
  border: 1px solid #D2D2D2;
  padding: 1rem 0rem 0rem 0rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2.5rem;
  &:last-child {
    margin-bottom: 0px;
  }
}
.order-data{
  padding-bottom:1.0rem;
}
.order-main-section {
  padding: 0rem 1.5rem;
}
.order-header {
  .order-number {
    margin-right: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $secondary1;
  }
  .order-header-top {
    border-bottom: 1px solid #D8D8D8;
    padding: 0em 0em 0.8em 0em;
  }
  .order-status-container {
    border-radius: 0.5rem;
    border: 2px solid $secondary1;
    border-left: 0.5rem solid $secondary1;
    padding: 0.2rem 0.5rem;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    text-transform: uppercase;
    color: #262626;
  }
}
.row {
  margin: 0px !important;
}
.order-main-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #999999;
    .order-main-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
    }
}
.order-text.credits {
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  color: #383838;
}
.order-body {
    //transition: height 0.5s ease-in-out;
    will-change: height;
    overflow: hidden;
    &.showTopBorder{
        border-top: 1px solid #ccc;
    }
    .sweep-heading {
      letter-spacing: 0.7px;
      text-transform: uppercase;
      padding: 10px;
      font-weight: 600;
      background-color: #F1F1F1;
    }
}
.order-toggler{
    background: #f9f9f9;
    padding: 1rem;
    border-top: 1px solid #ccc;;
}
.order-toggle-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}
.dt-head {
  flex-wrap: nowrap !important;
  border-bottom: thin solid #ccc;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #262626;
}
.dt-col-deliverable{
    flex: 1 !important;
    // max-width: 330px !important;
}
.order-del-category {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #999999;
}
.order-del-name{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.order-del-description {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #999999;
}
.dt-col-qty,
.dt-col-total{
    flex: 1;
    min-width: 70px;
    flex-basis: 70px;
    max-width: 70px;
}
.dt-row .text-center {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #262626;
}
.dt-col-dimension{
    flex: 1;
    min-width: 120px;
    flex-basis: 120px;
    max-width: 120px;
}
.dimensions-labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.dimensions-label {
    margin-right: 0.3rem;
    margin-bottom: 0.1rem;
}
.dt-row:not(:last-child) {
    border-bottom: thin solid #ccc;
}
.dt-col-dimension2 .dimensions-label  {
    display: none;
}
.sweep-details2 {
  display: none;
}
@media screen and (max-width: 600px) {
    .row {
        flex-wrap:wrap !important;
    }
    .col{
        padding:0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .order-container{
        width:330px;
    }
    .dt-col-dimension{
        display: none !important;
    }
    .dt-col-dimension2{
        margin-left:0px !important;
        margin-right: 230px;
    }
    .dt-col-dimension2 .dimensions-label{
        display: block;
    }
    .dimensions-labels{
        -webkit-box-pack: start;
        display: -webkit-box !important;
        flex-wrap: nowrap;
    }
    .sweep-details2 {
      display: block;
    }
    .sweep-details {
      display: none;
    }
    .deliverable-item{
        width:200px;
    }
    // .dt-col-qty2{
    //     min-width: 80px;
    //     max-width:80px;
    // }
    // .dt-col-qty1{
    //     margin-left:30px;
    // }
    // .dt-col-total2,
    // .dt-col-total1,
    // .dt-col-total3{
    //     min-width: 50px;
    //     max-width:40px;
    // }
    .order-credits-container{
        margin-left: 10px;
    }
    .order-description-container{
        display: none;
    }
    .order-del-category{
        width:150px;
    }
    .order-del-name{
        width:200px;
    }
    .sweep-deliverable {
      display: none;
    }
    .dt-col-deliverable{
        width:50px;
    }
}
</style>
