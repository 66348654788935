<template>
    <v-dialog
        v-model="open"
        width="90%"
        max-width="1280px"
        :persistent="true"
    >
        <v-card class="modeldetails" style="border-radius: unset !important;">
          <div v-if="loading">
            <Loader style="height:100%;min-height:50vh;" />
          </div>
          <div v-else>
            <div class="px-0 py-4 titlebar">
                <v-row class="ma-auto title-header px-5">
                <v-col class="d-flex align-center py-0 pl-0" cols="10">
                    <span class="modal-header-title"> Order Details </span>
                </v-col>
                <v-col class="d-flex justify-end py-0 pr-0">
                    <v-icon
                    color="darken-1"
                    @click="$emit('close')">mdi-close</v-icon>
                </v-col>
                </v-row>
                <div class="order-details-container mx-4 py-4">
                <div class="order-details">
                    <div>Name</div>
                    <div class="order_number">
                      <div>
                        <span>{{orderNumber}}</span>
                      </div>
                    </div>
                    <div>
                    <span
                        v-if="!enableAddOrderDescription"
                        class="order-details-ad-button"
                        @click="enableAddOrderDescription = true"
                    >
                        Add Description
                    </span>
                    <div v-else>
                        <textarea
                        v-model="orderInfo.description"
                        class="order-details-description"
                        placeholder="Add Description"
                        ></textarea>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <v-card-text>
                <v-row>
                <v-col cols="9">
                <div class="card-content-body">
                    <div>
                      <div class="heading pb-4">Digital Assets</div>
                    </div>
                    <div
                    v-for="mediaType in getAssetMediaTypes(
                      rankMediaTypes(Object.keys(groupedAvailableDeliverables))
                    )"
                    :key="mediaType"
                    >
                        <div class="media-type-container">
                        <div class="media-type">{{ mediaType }}</div>
                        <div class="long-line"></div>
                        </div>
                        <v-expansion-panels
                          multiple flat
                          v-model="expansionPanels[mediaType]"
                        >
                          <OrderDeliverable
                            v-for="(subDeliverable, index)
                            in Object.keys(groupedAvailableDeliverables[mediaType])"
                            :title="subDeliverable"
                            :deliverableData="
                              groupedAvailableDeliverables[mediaType][subDeliverable]"
                            :key="mediaType+'-'+index"
                            @addSubDeliverable="
                              () => addSubDeliverable({
                                mediaType, subDeliverable,
                            })"
                            @updateCredits="updateCredits"
                          />
                        </v-expansion-panels>
                    </div>
                    <div>
                      <div class="heading pb-4">Sweeps</div>
                    </div>
                    <div
                    v-for="mediaType in getSweepMediaTypes(
                      rankMediaTypes(Object.keys(groupedAvailableDeliverables))
                    )"
                    :key="mediaType"
                    >
                        <div class="media-type-container">
                        <div class="media-type">{{ mediaType }}</div>
                        <div class="long-line"></div>
                        </div>
                        <v-expansion-panels
                          multiple flat
                          v-model="expansionPanels[mediaType]"
                        >
                          <OrderDeliverable
                            v-for="(subDeliverable, index)
                            in Object.keys(groupedAvailableDeliverables[mediaType])"
                            :title="subDeliverable"
                            :deliverableData="
                              groupedAvailableDeliverables[mediaType][subDeliverable]"
                            :key="mediaType+'-'+index"
                            @addSubDeliverable="
                              () => addSubDeliverable({
                                mediaType, subDeliverable,
                            })"
                            @updateCredits="updateCredits"
                          />
                        </v-expansion-panels>
                    </div>
                </div>
                </v-col>
                <v-col cols="3" class="credit-box">
                <div class="credit-bank-wrapper">
                    <div class="credit-bank">
                    <div class="heading">Production Credits</div>
                    <div class="cb-line-item">
                        <div class="cb-line-item-title">Credit Bank</div>
                        <div class="cb-line-item-value">{{creditsAvailable}}</div>
                    </div>
                    <div class="cb-line-item">
                        <div class="cb-line-item-title">Used</div>
                        <div
                          :key="creditsUsed"
                          class="cb-line-item-value">{{creditsUsed}}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="cb-line-item mt-2" v-if="creditsLeft>=0">
                        <div class="cb-line-item-title">Remaining</div>
                        <div
                          class="cb-line-item-value"
                          :key="creditsLeft">{{creditsLeft}}</div>
                    </div>
                    <div class="cb-line-item mt-2 some-red-text" v-else>
                        <div :key="creditsLeft" class="cb-line-item-title">Extra</div>
                        <div
                          class="cb-line-item-value"
                          :key="creditsLeft">({{Math.abs(creditsLeft)}})</div>
                    </div>
                    <div class="credits-warning mt-2 some-red-text" v-if="creditsLeft<0">
                      <div class="credits-warning-inner d-flex">
                        <div class="icon">
                          <v-icon color="#FF371B">mdi-information-outline</v-icon>
                        </div>
                        <div class="text ml-2">Customer does not have enough credits</div>
                      </div>
                    </div>
                    </div>
                    <div class="order-actions">
                    <div class="order-action-btn mt-2" v-if="canDraft">
                        <v-btn
                          class="ocb draft"
                          depressed
                          @click="() => saveOrder('DRAFT')"
                          :loading="draftingOrder"
                        >
                          Save Draft
                        </v-btn>
                    </div>
                    <div class="order-action-btn mt-2">
                        <v-btn
                          class="ocb submit btn-purple"
                          depressed
                          :disabled="creditsLeft<0"
                          @click="() => saveOrder('PENDING_APPROVAL')"
                          :loading="savingOrder"
                        >
                          {{callToActionText}}
                        </v-btn>
                    </div>
                    <v-checkbox
                      v-model="selected"
                      v-if="canDraft && !client"
                      class="mt-3 checkbox"
                    >
                    <template v-slot:label>
                    <span class="checkbox-text">Skip customer approval</span>
                    </template>
                    </v-checkbox>
                    </div>
                </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader';
import OrderDeliverable from './OrderDeliverable';

export default {
  name: 'OrderForm',
  components: {
    OrderDeliverable,
    Loader,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return ({
      groupedAvailableDeliverables: {},
      enableAddOrderDescription: false,
      orderInfo: null,
      creditsUsed: 0,
      loading: true,
      expansionPanels: {},
      savingOrder: false,
      draftingOrder: false,
      selected: false,
    });
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    client() {
      return ['client', 'agency_owner', 'agency_member', 'member'].includes(this.role);
    },
    orderNumber() {
      return `Order #${this.orderId.toString().trim()}`;
    },
    creditsLeft() {
      // if the order is already placed,
      // the creditsLeft should not include previously submitted credits
      if (this.orderInfo && ['CREATED', 'DRAFT'].includes(this.orderInfo.status)) {
        return parseInt(this.business.credits, 10) - parseInt(this.creditsUsed, 10);
      }
      // eslint-disable-next-line
      return parseInt(this.business.credits, 10) - (parseInt(this.creditsUsed, 10) - parseInt(this.orderInfo.credits, 10));
    },
    callToActionText() {
      // eslint-disable-next-line
      if (this.orderInfo && ['CREATED', 'DRAFT', 'PENDING_APPROVAL', 'REQUESTED_CHANGES'].includes(this.orderInfo.status)) {
        return 'Submit Order';
      }
      return 'Update Order';
    },
    creditsAvailable() {
      if (this.orderInfo && ['CREATED', 'DRAFT'].includes(this.orderInfo.status)) {
        return parseInt(this.business.credits, 10);
      }
      return parseInt(this.business.credits, 10) + parseInt(this.orderInfo.credits, 10);
    },
    previouslyDebitedCredits() {
      // not in created or draft means it's already placed and debited
      if (this.orderInfo && !['CREATED', 'DRAFT'].includes(this.orderInfo.status)) {
        return this.orderInfo.credits;
      }
      return 0;
    },
    canDraft() {
      return ['CREATED', 'DRAFT'].includes(this.orderInfo.status);
    },
  },
  methods: {
    ...mapActions('business', ['getBusiness', 'getDeliverables', 'getCreditsHistory']),
    ...mapActions('orders', ['getOrderInfo', 'updateOrder']),
    ...mapActions('contentKit', ['getDimensions']),
    rankMediaTypes(mediaTypes) {
      const ranks = {
        photo: 1,
        video: 2,
        audio: 3,
        'asset add-ons': 4,
        sweeps: 5,
        'sweeps add-ons': 5,
      };
      return _.sortBy(mediaTypes, (mt) => (ranks[mt.toLowerCase()] || Number.MAX_VALUE));
    },
    getAssetMediaTypes(mediaTypes) {
      return _.filter(mediaTypes, (mt) => !/(sweeps|sweep add-ons)/i.test(mt));
    },
    getSweepMediaTypes(mediaTypes) {
      return _.filter(mediaTypes, (mt) => /(sweeps|sweep add-ons)/i.test(mt));
    },
    updateCredits() {
      let creditsUsed = 0;
      const creativeTypes = Object.keys(this.groupedAvailableDeliverables);
      creativeTypes.forEach((mediaType) => {
        const subDeliverables = Object.keys(this.groupedAvailableDeliverables[mediaType]);
        subDeliverables.forEach((subDeliverable) => {
          const container = this.groupedAvailableDeliverables[mediaType][subDeliverable];
          container.deliverables.forEach((deliverable) => {
            creditsUsed += (deliverable.creditCost * deliverable.quantity);
          });
        });
      });
      this.creditsUsed = creditsUsed;
      this.validateAndGetDeliverables();
    },
    formatDeliverables(deliverables) {
      const deliverablesGroupedByCreativeType = deliverables
        .filter((deliverable) => deliverable.status)
        .reduce((result, item) => {
          if (!result[item.creativeType.name]) {
            // eslint-disable-next-line no-param-reassign
            result[item.creativeType.name] = [];
          }
          // eslint-disable-next-line no-param-reassign
          result[item.creativeType.name].push(item);
          return result;
        }, {});
      const finalFormattedObject = Object.keys(deliverablesGroupedByCreativeType)
        .reduce((result, mediaType) => {
          const options = deliverablesGroupedByCreativeType[mediaType];
          // now group the options by
          const subGroup = options.reduce((subResult, item) => {
            if (!subResult[item.category.name]) {
              // eslint-disable-next-line no-param-reassign
              subResult[item.category.name] = {
                meta: {
                  category: item.category,
                  creativeType: item.creativeType,
                },
                options: [],
                deliverables: [],
              };
            }
            subResult[item.category.name].options.push(item);
            return subResult;
          }, {});
          // eslint-disable-next-line no-param-reassign
          result[mediaType] = subGroup;
          return result;
        }, {});
      return finalFormattedObject;
    },
    addSubDeliverable(info) {
      const { mediaType, subDeliverable } = info;
      const container = this.groupedAvailableDeliverables[mediaType][subDeliverable];
      container.deliverables.push({
        deliverableId: info.mediaType === 'Sweeps' ? container.options[0].id : 0,
        creativeType: container.meta.creativeType,
        category: container.meta.category,
        creditCost: info.mediaType === 'Sweeps' ? container.options[0].creditCost : 0,
        quantity: info.mediaType === 'Sweeps' ? 2 : 1,
        dimensions: [],
        locations: 1,
        errors: {
          deliverableType: null,
        },
      });
      if (info.mediaType === 'Sweeps') {
        this.updateCredits();
      }
    },
    validateAndGetDeliverables() {
      const errors = [];
      const deliverables = [];
      const creativeTypes = Object.keys(this.groupedAvailableDeliverables);
      creativeTypes.forEach((mediaType) => {
        const subDeliverables = Object.keys(this.groupedAvailableDeliverables[mediaType]);
        subDeliverables.forEach((subDeliverable, subDeliverableIndex) => {
          const container = this.groupedAvailableDeliverables[mediaType][subDeliverable];
          container.deliverables.forEach((deliverable) => {
            deliverables.push(deliverable);
            if (!deliverable.deliverableId) {
              // eslint-disable-next-line no-param-reassign
              deliverable.errors.deliverableType = 'Deliverable type is required';
              errors.push(subDeliverableIndex);
            } else {
              // eslint-disable-next-line no-param-reassign
              deliverable.errors.deliverableType = null;
            }
          });
        });
      });
      if (errors.length) return null;
      return deliverables;
    },
    async saveOrder(status) {
      // remove deliverables in which deliverable type not selected
      Object.keys(this.groupedAvailableDeliverables).forEach((mediaType) => {
        Object.keys(this.groupedAvailableDeliverables[mediaType])
          .forEach((deliverableType) => {
            // eslint-disable-next-line
            const container = this.groupedAvailableDeliverables[mediaType][deliverableType];
            // eslint-disable-next-line
            container.deliverables = container.deliverables.filter((deliverable) => deliverable.deliverableId);
          });
      });
      const deliverables = this.validateAndGetDeliverables();
      if (!deliverables) return;
      const order = {};
      order.orderId = this.orderInfo.id;
      order.deliverables = deliverables;
      order.description = this.orderInfo.description;
      order.status = status;
      order.skipCustomerApproval = this.selected;
      if (status === 'DRAFT') {
        this.draftingOrder = true;
      } else {
        this.savingOrder = true;
      }
      const response = await this.updateOrder({ order });
      const updateHistory = this.getCreditsHistory({ businessId: this.orderInfo.businessId });
      this.savingOrder = false;
      this.draftingOrder = false;
      if (response && updateHistory) {
        this.$emit('close');
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getDimensions();
      const deliverables = await this.getDeliverables();
      const orderInfo = await this.getOrderInfo({
        orderId: this.orderId,
      });
      await this.getBusiness(orderInfo.businessId);
      this.orderInfo = orderInfo;
      this.creditsUsed = orderInfo.credits;
      this.enableAddOrderDescription = Boolean(orderInfo.description);
      this.groupedAvailableDeliverables = this.formatDeliverables(deliverables);
      // todo attach sub deliverable info from order info
      let orderDeliverables = _.get(orderInfo, 'orderDeliverables', []);
      orderDeliverables = orderDeliverables.map((orderDeliverable) => {
        const { deliverable } = orderDeliverable;
        // eslint-disable-next-line
        orderDeliverable.category = deliverable.category.name;
        // eslint-disable-next-line
        orderDeliverable.creativeType = deliverable.creativeType.name;
        // eslint-disable-next-line
        orderDeliverable.dimensions = orderDeliverable.dimensions.map((d) => d.dimensionId);
        // eslint-disable-next-line
        orderDeliverable._category = deliverable.category;
        // eslint-disable-next-line
        orderDeliverable._creativeType = deliverable.creativeType;
        // eslint-disable-next-line
        orderDeliverable.errors = {
          deliverableType: null,
        };
        // eslint-disable-next-line
        return orderDeliverable;
      });
      orderDeliverables.forEach((orderDeliverable) => {
        const { category, creativeType } = orderDeliverable;
        const creditCost = _.get(orderDeliverable, 'deliverable.creditCost', 0);
        // eslint-disable-next-line
        orderDeliverable.creditCost = creditCost;
        if (!this.groupedAvailableDeliverables[creativeType]) {
          // eslint-disable-next-line no-param-reassign
          this.groupedAvailableDeliverables[creativeType] = {};
        }
        let container = this.groupedAvailableDeliverables[creativeType][category];
        // PATCH: the deliverable might be made inactive but been selected in the order,
        //  below snippets will handle this case
        // and show the deliverable in the order even if its inactive.
        if (!container) {
          this.groupedAvailableDeliverables[creativeType][category] = {
            meta: {
              // eslint-disable-next-line
              category: orderDeliverable._category,
              // eslint-disable-next-line
              creativeType: orderDeliverable._creativeType,
            },
            options: [],
            deliverables: [],
          };
          container = this.groupedAvailableDeliverables[creativeType][category];
        }
        // push option to container if not exist
        const option = {
          ...orderDeliverable.deliverable,
          // eslint-disable-next-line
          category: orderDeliverable._category,
          // eslint-disable-next-line
          creativeType: orderDeliverable._creativeType,
        };
        if (!container.options.find((o) => o.id === option.id)) {
          container.options.push(option);
        }
        container.deliverables.push(orderDeliverable);
      });
      // set expansion panels
      Object.keys(this.groupedAvailableDeliverables).forEach((mediaType) => {
        Object.keys(this.groupedAvailableDeliverables[mediaType])
          .forEach((deliverableType, index) => {
            const container = this.groupedAvailableDeliverables[mediaType][deliverableType];
            if (container.deliverables.length) {
              this.expansionPanels[mediaType] = [
                ...new Set([...(this.expansionPanels[mediaType] || []), index]),
              ];
            }
          });
      });
      this.loading = false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
};
</script>
<style lang="scss" scoped>
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 13px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
  }
  .heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
  }
 .order-details-container {
    padding: 10px;
    border-bottom: 2px solid #e5e8ed;
    .order-details-ad-button {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $secondary1;
      cursor: pointer;
    }
    .order-details-description {
      width: 100%;
      height: 100px;
      border: 1px solid #e5e8ed;
      border-radius: 4px;
      padding: 10px;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    .order_number  {
      margin: 0rem 0rem 1rem 0rem;
      div {
        font-size: 16px;
        padding: 0.5rem 2rem;
        background: #f3f3f3;
        width: fit-content;
        color: #505050;
        border: 1px solid #babfc7;
        min-width: 200px;
        letter-spacing: none !important;
      }
    }
  }
  .media-type-container {
    display: flex;
    align-items: center;
    .media-type {
      font-weight: 400;
      color: #bdbdbd;
      font-weight: bold;
      position: relative !important;
      width: fit-content;
      text-transform: uppercase;
    }
    .long-line {
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background: #e0e0e0;
    }
  }
    .cb-line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0rem;
    color: #8C8C8C;
  }
.credit-bank {
    border: 1px solid #e0e0e0;
    border-radius: 0.3rem;
    padding: 1rem;
    .heading {
      color: #383838;
    }
  }
  .order-action-btn {
    .ocb {
      width: 100%;
      border-radius: 1.5rem;
      height: 44px;
      .v-btn__content {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .ocb.draft {
      border: 2px solid $secondary2;
      background: white !important;
      .v-btn__content {
        font-weight: 500;
        color: #262626;
      }
    }
    .ocb.submit {
      color: white;
      background: $secondary1 !important;
      .v-btn__content {
        font-weight: 600;
      }
    }
  }
  .loader{
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  img {
    width: 100px;
    height: 100px;
  }
}
.some-red-text {
  color: #FF371B;
}
.credits-warning {
  color: #FF371B;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  border: 1px solid #FF371B;
  max-width: fit-content;
}
::v-deep .v-dialog{
  overflow: auto !important;
  overflow-x:hidden !important;
  position:relative;
}
.titlebar{
  position: sticky !important;
  position:-webkit-sticky;
  z-index: 5;
  top:0;
  background:white;
}
.card-content-body{
  z-index: 1;
}
.checkbox{
  padding-left:10px !important;
}
.checkbox-text{
  font-size:14px;
  color:#262626;
}
.credit-bank-wrapper{
  width: 230px !important;
  position: fixed !important;
}
@media screen and (max-width: 1000px){
  .credit-bank-wrapper{
    position: absolute !important;
  }
}
</style>
