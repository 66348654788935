<template>
  <v-container pa-0 class="h-100">
    <v-overlay :value="isLoading" z-index=8>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pb-2">
        <v-card class="upload-card">
          <v-container pa-0 class="h-100">
            <v-row>
              <v-col class="d-flex align-center pb-0 pt-4">
                <div class="text-uppercase card-heading">BRAND FILES</div>
              </v-col>
              <v-col class="d-flex justify-end pb-0 pt-4" v-if="canUpload">
                <Upload
                  id="upload-documents"
                  class="upload-button text-center"
                  v-model="file"
                  multiple
                  mode="justImage"
                  :acceptedFiles="'.jpg,.jpeg,.png,.svg,.pdf,.ttf,.fnt,.fon,.mp3,.mp4,.zip'"
                  @change="uploadDocuments"
                >
                  <template>
                    <img src="@/assets/svg/theme/upload.svg" />
                    <span class="ml-1 add-text h-100">Add</span>
                  </template>
                </Upload>
              </v-col>
            </v-row>
            <v-row class="card-content">
              <v-col cols="12" class="text note-text">
                Upload any brand kit and logo files you have here.
              </v-col>
              <v-col cols="12" class="d-flex brand-kit">
                <div
                  class="uploaded-document"
                  v-for="(document, index) in brandKits"
                  :key="index"
                  @click="previewBrandkit(document)"
                  :class="{clickable: canPreview(document.kit)}"
                >
                  <BrandKit
                    :item="document"
                    :file="document.kit"
                    :delete="canUpload ? () => deleteDocument(document) : null"
                    @onDownload="downloadFiles(document.id)"
                    :preview="canPreview(document.kit)"

                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" class="pa-0 pb-2 pt-2">
        <v-card class="colors-card pt-2">
          <v-container pa-0 class="h-100">
            <v-row>
              <v-col class="d-flex align-center pb-0">
                <div class="text-uppercase card-heading">Brand Colors</div>
              </v-col>
            </v-row>
            <v-row class="card-content">
              <v-col cols="12" md="10" class="text note-text">
                Select up to 4 primary and 4 secondary colors for your brand.
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pr-lg-10"
                v-for="(type, i) in brandColorsSet" :key="i"
              >
                <div class="mb-4">
                  <div class="color-card-heading">{{type.name}}</div>
                  <v-divider class="mt-1"></v-divider>
                </div>
                <div class="d-flex flex-row flex-wrap">
                  <div
                    class="color-picker-wrapper"
                    :class="{'hidden-screen-only':
                      (index === 0 && type.colors.length > 4)
                      }"
                    v-for="(color, index) in (type.colors)"
                    :key="index"
                  >
                    <ColorPicker
                      :value="color.colorCode"
                      :state="
                      (activeColor.index === index && activeColor.mainIndex === i) ?
                      'edit': 'normal'
                      "
                      :isNew="!color.id"
                      @update-color="(val) => updateColor(val, index, i, color.id)"
                      @delete-color="() => deleteColor(color.id)"
                      @close-color="closeColor"
                      :width="$vuetify.breakpoint.smAndDown ? '200px': '300px'"
                      :alignRight="$vuetify.breakpoint.smAndDown"
                    >
                      <template>
                        <div class="color-block">
                          <v-btn
                            v-if="index !== 0 && canUpload"
                            icon
                            x-small
                            color="black"
                            class="remove-color-icon"
                            @click="deleteColor(color.id)"
                          >
                            <v-icon size="10">mdi-close</v-icon>
                          </v-btn>
                          <v-btn
                            class="mr-3"
                            width="41px"
                            height="41px"
                            fab
                            small
                            @click="togglePickerState(index, i, color.colorCode)"
                            :color="color.colorCode"
                            :class="{'color-add-btn': index === 0,
                              'd-none': index === 0 && !canUpload}"
                          >
                            <v-icon v-if="index === 0" dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </div>
                        <div class="hex-text mt-1"
                        v-if="index !== 0"
                        @click="copyToClipboard(color.colorCode)"
                        >{{ color.colorCode }}</div>
                      </template>
                    </ColorPicker>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" class="pa-0 pt-2" v-if="canUpload">
        <v-card class="agreements-card">
          <agreements
            :files="agreements"
            :title="'CONTENT BLEND & AGREEMENTS'"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-container
      fluid
      v-if="previewModal"
    >
      <Modal
        persistent
        :modal="previewModal"
        content-class="main-modal preview-modal"
      >
        <div class="brand-kit-file-preview px-4 pb-2">
          <v-row class="my-0 heading">
            <v-col cols="10" class="d-flex align-center pb-0">
              <div
              class="text-capitalize text-ellipsis modal-header-title">
              {{currentFile.filename}} </div>
            </v-col>
            <v-col cols="2" class="d-flex justify-end pb-0 pl-0">
              <v-icon color="darken-1" @click="previewModal=false">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <div class="item-body">
            <div>
              <v-btn
              icon
              x-large
              class="previous navigate-btn"
              :class="{'audio-file': extension(currentFile.kit) === 'mp3'}"
              @click="navigate(prevItemIndex)"
              :disabled="prevItemIndex < 0"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
              icon
              x-large
              class="next navigate-btn"
              :class="{'audio-file': extension(currentFile.kit) === 'mp3'}"
              @click="navigate(nextItemIndex)"
              :disabled="nextItemIndex < 0"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            </div>
            <v-row class="ma-0 d-flex justify-center align-center">
              <v-col cols=12 class="py-0">
                <div class="pdf-loading" v-if="pdfLoading">
                  <v-progress-circular indeterminate color="grey"/>
                </div>
                <div v-if="extension(currentFile.kit) === 'pdf'" class="pdf-file">
                  <div class="pdf-container">
                    <pdf
                      class="pdf-page-container"
                      v-for="i in numPages"
                      :key="i"
                      :src="src"
                      :page="i"
                      type="application/pdf"></pdf>
                  </div>
                </div>
                <div v-else-if="extension(currentFile.kit) === 'mp3'"
                  class="d-flex align-center justify-center mp3-file">
                  <audio controls :key="currentFile.id">
                    <source :src="currentFile.kit" type="audio/mpeg">
                    Your browser does not support the audio tag.
                  </audio>
                </div>
                <div v-else-if="extension(currentFile.kit) === 'mp4'">
                  <video-player
                    :options="videoOptions(currentFile)"
                    class="video-player"
                  />
                </div>
                <div v-else-if="extension(currentFile.kit) === 'ttf'">
                  <h3> Cannot preview the file </h3>
                </div>
                <div v-else class="image-div text-center">
                  <v-img
                    contain
                    :key="currentFile.kit"
                    :src="currentFile.kit"
                    :lazy-src="currentFile.smallImage"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5">

                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </Modal>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import pdf from 'vue-pdf';
import Upload from '@/components/common/Upload';
import BrandKit from '@/components/common/BrandKit';
import ColorPicker from '@/components/common/ColorPicker';
import Modal from '@/components/common/Modal';
import VideoPlayer from '@/components/common/VideoPlayer';
import Agreements from '../common/Agreements';

export default {
  name: 'ProfileCards',
  components: {
    Agreements,
    BrandKit,
    ColorPicker,
    Modal,
    pdf,
    Upload,
    VideoPlayer,
  },
  props: {
    businessId: {
      type: Number,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
    brandKits: {
      type: Array,
      default: () => [],
    },
    brandColors: {
      type: Array,
      default: () => [],
    },
    agreements: {
      type: Array,
      default: () => [],
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // ...mapGetters('profile', ['brandKits', 'brandColors', 'agreements']),
    brandColorsSet() {
      return ([{
        name: 'primary',
        colors: [{ colorCode: '#FFFFFF' }, ...this.getColors('1')],
      }, {
        name: 'secondary',
        colors: [{ colorCode: '#FFFFFF' }, ...this.getColors('2')],
      }]);
    },

    extension() {
      return (file) => file.split('.').pop().toLowerCase();
    },
    // isLandscape() {
    //   return this.width >= this.height;
    // },
    // imageStyles() {
    //   return this.isLandscape ? 'max-width: 100%; max-height: 600px;' : 'max-height: 600px;';
    // },
    // mobileImageStyles() {
    //   return this.isLandscape ? 'max-width: 100%;' : 'max-height: 450px;';
    // },

    videoOptions() {
      return (file) => ({
        autoplay: false,
        controls: true,
        sources: [
          {
            src: file.kit,
            type: 'video/mp4',
          },
        ],
      });
    },
    previewBrandKits() {
      return _.filter(this.brandKits, (kit) => this.canPreview(kit.kit));
    },
  },
  data() {
    return {
      file: [],
      activeColor: { },
      isLoading: false,
      previewModal: false,
      currentFile: {},
      prevItemIndex: null,
      nextItemIndex: null,
      src: '',
      numPages: 0,
      pdfLoading: false,
    };
  },
  methods: {
    ...mapActions('business', ['brandKitUpload', 'brandKitDelete', 'addBrandColor', 'updateBrandColor', 'deleteBrandColor', 'downloadLink']),
    ...mapActions(['setNotification']),
    togglePickerState(index, mainIndex, colorCode) {
      // window.scrollTo(0, document.body.scrollHeight);
      if (this.canUpload) {
        this.activeColor = { index, mainIndex };
      } else {
        this.copyToClipboard(colorCode);
      }
    },

    copyToClipboard(val) {
      this.$copyText(val).then(() => {
        this.setNotification({
          message: 'Color copied to clipboard.',
          type: 'success',
        }, { root: true });
      }, (e) => {
        this.setNotification({
          message: e.message,
          type: 'error',
        }, { root: true });
      });
    },

    canPreview(file) {
      const ext = this.extension(file);
      return ['jpg', 'png', 'jpeg', 'pdf', 'doc', 'docx', 'mp4', 'mp3'].includes(ext);
    },

    previewClick(file) {
      if (this.canPreview(file.kit)) {
        this.currentFile = file;
        this.previewModal = true;
      }
    },
    previewBrandkit(file) {
      if (this.canPreview(file.kit)) {
        this.currentFile = file;
        this.previewModal = true;
        const itemIndex = _.findIndex(this.previewBrandKits, ['id', file.id]);
        this.currentFile = this.previewBrandKits[itemIndex];
        this.prevItemIndex = itemIndex - 1;
        this.nextItemIndex = itemIndex === this.previewBrandKits.length - 1 ? -1 : itemIndex + 1;
        this.getPdfFullFileView(this.currentFile);
      }
    },
    navigate(index) {
      this.currentFile = Object.assign({}, this.previewBrandKits[index]);
      this.prevItemIndex = index - 1;
      this.nextItemIndex = index === this.previewBrandKits.length - 1 ? -1 : index + 1;
      this.getPdfFullFileView(this.currentFile);
    },
    getPdfFullFileView(currentFile) {
      const ext = this.extension(currentFile.kit);
      if (ext === 'pdf') {
        this.pdfLoading = true;
        const loadingTask = pdf.createLoadingTask(currentFile.kit);
        this.src = loadingTask;
        this.src.promise.then((pdfFile) => {
          this.numPages = pdfFile.numPages;
          this.pdfLoading = false;
        });
      }
    },
    getColors(index) {
      return this.brandColors.filter((x) => x.colorType === index);
    },
    async updateColor(val, index, mainIndex, id) {
      if (this.businessId) {
        const color = {
          id,
          colorCode: val,
          colorType: mainIndex === 0 ? '1' : '2',
          projectId: this.projectId,
          businessId: this.businessId,
        };
        const response = index
          ? await this.updateBrandColor(color) : await this.addBrandColor(color);
        if (response.success) {
          this.activeColor = {};
        }
      } else {
        this.setNotification({
          message: 'Something went wrong, please contact your administrator.',
          type: 'error',
        }, { root: true });
        this.activeColor = {};
      }
    },
    async deleteColor(id) {
      const payload = {
        id,
        projectId: this.projectId,
      };
      const response = await this.deleteBrandColor(payload);
      if (response.success) {
        this.activeColor = { };
      }
    },
    closeColor() {
      this.activeColor = { };
    },
    async uploadDocuments(files) {
      if (this.businessId) {
        if (files && files.length) {
          this.isLoading = true;
          await this.brandKitUpload(
            { files, projectId: this.projectId, businessId: this.businessId },
          );
          this.isLoading = false;
        }
      } else {
        this.setNotification({
          message: 'Something went wrong, please contact your administrator.',
          type: 'error',
        }, { root: true });
        this.activeColor = {};
      }
    },
    deleteDocument(document) {
      this.brandKitDelete(document.id);
    },
    async downloadFiles(id) {
      const result = await this.downloadLink({ id, projectId: this.projectId });
      if (result.success) {
        window.open(result.data.url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    .upload-card {
      padding: 0 25px;
    }
    .card-heading {
      font-weight: bold;
      letter-spacing: -0.28px;
      color: $card-title;
    }
    ::v-deep .upload-button {
      width: 89px;
      height: 28px !important;
      border-radius: 19.5px;
      box-shadow: 0 2px 4px 0 rgba(127, 127, 127, 0.5);
      border: solid 2px $secondary1;
      label {
        display: flex;
        justify-content: center;
        cursor: pointer;
        background-color: white;
        border-radius: 19.5px;
      }
    }
    .add-text {
        font-size: 14px;
        letter-spacing: -0.28px;
        color: $secondary1;
    }
    .note-text {
        font-family: $fontFamily1;
        font-size: 14px;
        letter-spacing: -0.12px;
        color: $neutral1;
    }
    .brand-kit {
      overflow-x: auto;
    }
    .uploaded-document {
      margin-right: 16px;
      .kit {
        width: 122px;
        height: 150px;
        ::v-deep .v-card {
          height: 122px;
        }
      }
      &.clickable {
        cursor: pointer;
      }
    }
    .colors-card {
      padding: 0 25px;
      .color-block {
        position: relative;
        .v-btn {
          &.color-add-btn {
            border: 1px solid;
            border-color: black !important;
          }
        }
        .remove-color-icon {
          display: none;
          position: absolute;
          right: 6px;
          top: -6px;
          width: 18px;
          height: 18px;
          z-index: 2;
          background-color: #f4f4f4;
          box-shadow: 0 1px 2px 0 rgba(125, 125, 125, 0.5);
        }
        &:hover {
          .remove-color-icon {
            display: block;
          }
        }
      }
    }
    .color-card-heading {
      font-size: 14px;
      letter-spacing: -0.28px;
      color: $neutral1;
      text-transform: capitalize;
    }
    .hex-text {
      font-family: $fontFamily1;
      font-size: 11px;
      letter-spacing: -0.11px;
      color: $neutral1;
      cursor: pointer;
    }
    .agreements-card {
      padding: 0 25px 10px 25px;
    }
    @media (min-width: 960px) {
      .upload-card {
        min-height: 255px;
      }
      .colors-card {
        min-height: 210px;
      }
      .agreements-card {
        min-height: 200px;
      }
    }

  .pdf-file {
    embed {
      min-height: 450px;
    }
  }
  .pdf-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
  }
  .image-div {
    width: 100%;
    height: 100%;
    ::v-deep .v-image {
      max-width: 100%;
      max-height: 480px;
    }
  }
  .file-name {
    word-break: break-all;
  }
  .video-player {
      margin: 0 auto;
      width: 700px;
      height: 600px;
    }
  audio {
    &:focus {
      outline: none;
    }
  }
  .brand-kit-file-preview{
    .item-body {
      padding-left: 40px;
      padding-right: 40px;
      position: relative;
      .mp3-file {
        // min-height: 420px;
      }
      .navigate-btn {
        padding: 0;
        position: absolute;
        top: 30%;
        &.audio-file {
          top: 0;
        }
        &.previous {
          left: 0;
        }
        &.next {
          right: 0;
        }
      }
    }
  }
  .pdf-container{
    overflow-y: auto;
    height:450px;
    .pdf-page-container{
      width:100%;
      padding-bottom: 10px;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1024px) {
    .video-player {
      width: 100%;
      height: 500px;
    }
  }
  @media screen and (max-height: 766px) {
    .item-body {
      ::v-deep .v-image  {
        max-height: 450px;
      }
    }
    .video-player {
      margin: 0 auto;
      width: 100%;
       height: 300px;
    }
  }

  @media screen and (max-height: 700px) {
    .item-body {
      ::v-deep .v-image  {
        max-height: 360px;
      }
    }
}
  @media screen and (max-width:600px) {
      .video-player {
        width: 100%;
        max-height: 250px !important;
      }
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    .item-body {
      ::v-deep .v-image  {
        max-height: 255px;
      }
    }
  }
</style>
