<template>
  <v-container fluid v-if="isModal">
    <Modal :fullscreen="$vuetify.breakpoint.xsOnly" :modal="isModal" width="650" persistent>
      <v-card :flat="true">
        <div class="px-5 py-4">
          <v-row class="ma-auto">
            <v-col class="d-flex align-center pa-0" cols="10">
              <span class="mr-1 modal-header-title"> Edit Customer </span>
            </v-col>
            <v-col class="d-flex justify-end pa-0">
              <v-icon color="darken-1" @click="closeModal">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-text class="customerForm">
          <v-form>
            <v-row v-for="(item, j) in info" :key="`item${j}`" class="ma-auto">
              <v-row class="ma-auto px-5" v-if="item.name === 'customer'">
                <div class="sub-title pt-8 d-flex align-center">
                  <span class="capital-text font-label"> ABOUT YOUR BUSINESS </span>
                  <div class="line pl-2" />
                </div>
              </v-row>
              <v-row
                class="flex-column align-baseline ma-auto px-5 pt-5 service-field"
                v-if="item.name === 'service' || item.name === 'customer'"
                id="service-field"
              >
                <label class="label-title font-h5">{{ item.placeholder }}</label>
                <v-radio-group
                  row
                  v-model="businessInfo[item.name]"
                  :status="errors(item.name).length ? 'error' : 'normal'"
                  :error-messages="errors(item.name)"
                  @input="$v.businessInfo[item.name].$touch()"
                  @blur="$v.businessInfo[item.name].$touch()"
                >
                  <div
                    v-for="(option, index) in sources[item.name]"
                    :key="index"
                    class="input-field"
                  >
                    <v-radio :label="option" :value="option" color="info"> </v-radio>
                  </div>
                </v-radio-group>
                <BaseInput
                  :class="{ 'border-red': otherServiceErr }"
                  class="contact-input other-input-field"
                  background-color="#ffff"
                  solo
                  dense
                  flat
                  v-model.trim="otherService"
                  v-if="businessInfo[item.name] === 'Other'"
                />
                <div
                  class="other-input-field-error v-text-field__details"
                  v-if="
                    otherServiceErr && businessInfo.service === 'Other' && item.name === 'service'
                  "
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        Please provide Service.
                      </div>
                    </div>
                  </div>
                </div>
              </v-row>
              <v-row
                class="px-5 flex-column align-baseline ma-auto age-field pt-5"
                v-if="item.name === 'age'"
                id="age-field"
              >
                <label class="label-title mb-1 font-h5">{{ item.placeholder }}</label>
                <span class="gray-text font-body-samll">Check all that apply</span>
                <CustomCheckboxGroup
                  :noErrorIcon="true"
                  :options="sources[item.name]"
                  name="ages"
                  item-text="name"
                  item-value="name"
                  v-model="businessInfo.age"
                  :status="errors(item.name).length ? 'error' : 'normal'"
                  :error-messages="errors(item.name)"
                  @input="$v.businessInfo[item.name].$touch()"
                  @blur="$v.businessInfo[item.name].$touch()"
                >
                </CustomCheckboxGroup>
              </v-row>
              <v-row
                class="px-5 flex-column align-baseline ma-auto pt-5 location-field"
                v-if="item.name === 'locations'"
                id="location-field"
              >
                <label class="label-title d-flex align-center">{{ item.placeholder }} </label>
                <div class="w-100">
                  <div
                    v-for="(location, index) in locations"
                    :key="location.key"
                    class="contact-input location-input
                      v-input input-field v-input--is-label-active v-input--is-dirty
                      v-input--dense theme--light v-text-field v-text-field--single-line
                      v-text-field--solo v-text-field--solo-flat v-text-field--is-booted
                      v-text-field--enclosed errorState"
                    :class="{
                      'errorState v-input--has-state error--text': locationErrors,
                    }"
                  >
                    <div class="v-input__control">
                      <div class="v-input__slot">
                        <div class="v-text-field__slot">
                          <vue-google-autocomplete
                            :ref="location.key"
                            :id="location.key"
                            placeholder="Start typing..."
                            :value="location.value"
                            v-on:blur="syncLocations"
                            v-on:inputChange="(data) => setLocation(data, location.key)"
                            v-on:placechanged="(data) => setLocation(data, location.key)"
                            :types="['establishment']"
                            :component-restrictions="null"
                          >
                          </vue-google-autocomplete>
                        </div>
                      </div>
                    </div>
                    <v-icon
                      v-if="canShow(index)"
                      class="close cursor-pointer"
                      @click="removeLocation(location.key)"
                      >mdi-close</v-icon
                    >
                  </div>
                </div>
                <div class="v-text-field__details" v-if="locationErrors">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        Please provide valid location
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addLocation" @click="addLocation">+ Add Location</div>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="card-actions px-5 py-4">
            <v-btn text class="mr-2" color="primaryGray1" @click="closeModal">Cancel</v-btn>
            <v-btn class="save-btn ml-2 btn-purple" type="submit" @click="submitForm"
            :disabled="isSavingData"
            :loading="isSavingData">
              Save
            </v-btn>
          </v-card-actions>
      </v-card>
    </Modal>
  </v-container>
</template>

<script>

import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { required } from 'vuelidate/lib/validators';
import Modal from '@/components/common/Modal';
import BaseInput from '@/components/common/BaseInput';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';

// eslint-disable-next-line prefer-destructuring
// const { deepClone } = require('lodash').deepClone;

export default {
  name: 'CustomerDetailsModal',
  components: {
    Modal,
    VueGoogleAutocomplete,
    BaseInput,
    CustomCheckboxGroup,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    businessType: {
      type: String,
      default: '',
    },
    brandProfileId: {
      type: Number,
      required: true,
    },
    reference: {
      type: String,
      default: 'business',
    },
    locationsArr: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    brandProfile() {
      this.brandProfileData();
    },
    locationsArr(arr) {
      this.locations = [...arr];
    },
    locations(newVal) {
      if (this.businessType === 'Professional Services') {
        this.businessInfo.locations = newVal.map((l) => l.value).filter((l) => l !== '');
      }
    },
  },
  data() {
    return {
      oldBusinesData: {},
      businessInfo: {},
      locations: [...this.locationsArr],
      otherService: '',
      isSavingData: false,
      locationErrors: false,
      otherServiceErr: false,
      profSources: {
        service: [
          'Legal Services',
          'Real Estate',
          'Financial',
          'Medical',
          'Home',
          'Coaching',
          'Fitness',
          'Hospitality',
          'Other',
        ],
        customer: ['Consumers', 'Businesses', 'Both', 'Not Sure'],
        age: [
          { id: 1, name: 'Gen A (ages 0-6)' },
          { id: 2, name: 'Gen Z (ages 7-24)' },
          { id: 3, name: 'Millennials (ages 25-40)' },
          { id: 4, name: 'Gen X (ages 41-56)' },
          { id: 5, name: 'Baby Boomers (ages 57-76)' },
        ],
      },
      ecommerceSources: {
        service: [
          'Toys, Kids and Baby',
          'Accessories',
          'Food & beverage',
          'Beauty',
          'Home, garden, and tools',
          'Other',
        ],
        customer: ['Consumers', 'Businesses', 'Both', 'Not Sure'],
        age: [
          { id: 1, name: 'Gen A (ages 0-6)' },
          { id: 2, name: 'Gen Z (ages 7-24)' },
          { id: 3, name: 'Millennials (ages 25-40)' },
          { id: 4, name: 'Gen X (ages 41-56)' },
          { id: 5, name: 'Baby Boomers (ages 57-76)' },
        ],
      },
      techSources: {
        service: ['Hardware', 'Software', 'Both'],
        customer: ['Consumers', 'Businesses', 'Both', 'Not Sure'],
        age: [
          { id: 1, name: 'Gen A (ages 0-6)' },
          { id: 2, name: 'Gen Z (ages 7-24)' },
          { id: 3, name: 'Millennials (ages 25-40)' },
          { id: 4, name: 'Gen X (ages 41-56)' },
          { id: 5, name: 'Baby Boomers (ages 57-76)' },
        ],
      },
    };
  },
  validations: {
    businessInfo: {
      service: {
        required,
      },
      customer: {
        required,
      },
      age: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('brandProfile', ['brandProfile']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.businessInfo[field].$dirty) return errors;
        switch (field) {
          case 'service':
            if (!this.$v.businessInfo.service.required) {
              errors.push('Please provide Service.');
            }
            break;
          case 'customer':
            if (!this.$v.businessInfo.customer.required) {
              errors.push('Please provide Customer.');
            }
            break;
          case 'age':
            if (!this.$v.businessInfo.age.required) {
              errors.push('Please choose atleast one Age.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    prof() {
      return [
        {
          name: 'name',
          placeholder: 'Name',
        },
        {
          name: 'website',
          placeholder: 'Website',
        },
        {
          name: 'service',
          placeholder: 'What type of services do you offer?',
        },
        {
          name: 'locations',
          placeholder: 'Where do you provide services?',
        },
        {
          name: 'customer',
          placeholder: 'Who is your customer?',
        },
        {
          name: 'age',
          placeholder: 'What age is your customer?',
        },
      ];
    },
    ecommerce() {
      return [
        {
          name: 'name',
          placeholder: 'Name',
        },
        {
          name: 'website',
          placeholder: 'Website',
        },
        {
          name: 'service',
          placeholder: 'What type of physical product do you sell?',
        },
        {
          name: 'customer',
          placeholder: 'Who is your customer?',
        },
        {
          name: 'age',
          placeholder: 'What age is your customer?',
        },
      ];
    },
    tech() {
      return [
        {
          name: 'name',
          placeholder: 'Name',
        },
        {
          name: 'website',
          placeholder: 'Website',
        },
        {
          name: 'service',
          placeholder: 'What type of technology do you sell?',
        },
        {
          name: 'customer',
          placeholder: 'Who does your technology help?',
        },
        {
          name: 'age',
          placeholder: 'What age is your customer?',
        },
      ];
    },
    info() {
      if (this.businessType === 'E-Commerce') {
        return this.ecommerce;
      }
      if (this.businessType === 'Professional Services') {
        return this.prof;
      }
      if (this.businessType === 'Technology') {
        return this.tech;
      }
      return [];
    },
    sources() {
      if (this.businessType === 'E-Commerce') {
        return this.ecommerceSources;
      }
      if (this.businessType === 'Professional Services') {
        return this.profSources;
      }
      if (this.businessType === 'Technology') {
        return this.techSources;
      }
      return {};
    },
  },
  methods: {
    ...mapActions('brandProfile', ['updateCustomerDetails']),
    canShow(index) {
      if (index === 0) {
        return false;
      }
      return true;
    },
    addLocation() {
      const random = Math.random().toString(36).slice(2);
      this.locations.push({ key: `location-${random}`, value: '' });
    },
    removeLocation(key) {
      this.locations = this.locations.filter((loc) => loc.key !== key);
      this.locationErr();
    },
    async setLocation(location, key) {
      const loc = this.locations.find((l) => l.key === key);
      if (loc) {
        loc.value = location.newVal && location.newVal.trim();
      }
      this.locations = [...this.locations];
      this.locationErr();
    },
    async syncLocations() {
      // sync values
      const updatedLocations = this.locations.map((l) => {
        const [component] = this.$refs[l.key];
        // eslint-disable-next-line prefer-destructuring, no-underscore-dangle
        const value = component._vnode.elm.value;
        return { key: l.key, value };
      });
      this.locations = updatedLocations;
      this.locationErr();
    },
    locationErr() {
      const locations = _.filter(this.locations, (loc) => loc.value !== '');
      this.locationErrors = locations.length === 0;
    },
    checkOtherSerice() {
      const { otherService } = this;
      if (otherService) {
        this.otherServiceErr = false;
      } else {
        this.otherServiceErr = true;
      }
    },
    closeModal() {
      this.locationErrors = '';
      this.otherServiceErr = '';
      this.otherService = '';
      this.businessInfo = { ...this.oldBusinesData };
      const val = _.get(this, 'brandProfile');
      if (this.businessType !== 'Technology' && typeof _.get(val, 'typeOfService') === 'string') {
        this.otherService = _.get(val, 'typeOfService');
      }
      this.$emit('close-modal');
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.businessType === 'Professional Services') {
        this.locationErr();
      }
      if (this.businessInfo.service === 'Other') {
        this.checkOtherSerice();
      } else {
        this.otherServiceErr = false;
      }
      if (this.locationErrors) {
        document.getElementById('location-field').scrollIntoView(false);
        document.getElementById('location-field').scrollTop = document.getElementById('location-field').offsetTop + 500;
      }
      if (this.$v.businessInfo.service.$invalid || this.otherServiceErr) {
        document.getElementById('service-field').scrollIntoView(false);
        document.getElementById('service-field').scrollTop = document.getElementById('service-field').offsetTop + 500;
      }
      if (this.$v.businessInfo.customer.$invalid) {
        document.getElementById('customer-field').scrollIntoView(false);
        document.getElementById('customer-field').scrollTop = document.getElementById('customer-field').offsetTop + 500;
      }
      if (this.$v.businessInfo.age.$invalid) {
        document.getElementById('age-field').scrollIntoView(false);
        document.getElementById('age-field').scrollTop = document.getElementById('age-field').offsetTop + 500;
      }
      if (!this.$v.$invalid && !this.locationErrors && !this.otherServiceErr) {
        const {
          service, locations, customer, age,
        } = this.businessInfo;
        const { otherService } = this;
        const preferredAges = age;
        this.isSavingData = true;
        const payload = {
          brandProfileId: this.brandProfileId,
          reference: this.reference,
          targetCustomers: customer,
          typeOfService: service === 'Other' ? otherService : service,
          targetCustomerAges: preferredAges,
        };
        if (this.businessType === 'Professional Services') {
          const customerLocations = [];
          _.map(locations, (location) => {
            customerLocations.push(location.trim());
          });
          payload.locations = customerLocations;
        }
        const response = await this.updateCustomerDetails(payload);
        this.isSavingData = false;
        if (response) {
          this.otherService = '';
          this.$emit('close-modal');
        }
      }
    },
    brandProfileData() {
      const val = _.get(this, 'brandProfile');
      const ages = [];
      let serv = '';
      let cust = '';
      if (_.get(val, 'customerAges')) {
        _.get(val, 'customerAges').map((x) => ages.push(x.name));
      }
      if (_.get(val, 'typeOfService')) {
        if (this.businessType === 'Technology') {
          if (typeof _.get(val, 'typeOfService') === 'string') {
            serv = 'Both';
          } else if (typeof _.get(val, 'typeOfService') === 'object') {
            serv = _.get(val, 'typeOfService.name');
          }
        } else if (typeof _.get(val, 'typeOfService') === 'string') {
          serv = 'Other';
          this.otherService = _.get(val, 'typeOfService');
        } else if (typeof _.get(val, 'typeOfService') === 'object') {
          serv = _.get(val, 'typeOfService.name');
        }
      }
      if (_.get(val, 'targetCustomers')) {
        if (_.get(val, 'targetCustomers').indexOf('&') >= 0) {
          cust = 'Both';
        } else {
          cust = _.get(val, 'targetCustomers');
        }
      }
      const service = serv;
      const customer = cust;
      const locations = [];
      if (_.get(val, 'locations')) {
        _.get(val, 'locations').map((x, key) => locations.push({ key: `location-${key}`, value: x.address }));
      }
      const obj = {
        service,
        locations,
        customer,
        age: ages,
      };
      this.oldBusinesData = { ...obj };
      this.businessInfo = obj;
    },
  },
  mounted() {
    this.brandProfileData();
  },
};
</script>
<style lang="scss" scoped>
.text-capitalize {
  font-family: $fontFamily1 !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 22px;
}
::v-deep .card-actions.v-card__actions {
  justify-content: flex-end !important;
}
.customerForm {
  overflow: auto;
  height: 450px;
  padding: 0px !important;
  ::v-deep .v-input input::placeholder {
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 15px !important;
    line-height: 18px;
    color: #2d2d2d;
    opacity: 0.4;
  }
  .location-input {
    display: flex;
    align-items: center;
    .v-input__slot {
      margin-bottom: 4px;
    }
    .v-input__control {
      max-width: 93%;
    }
    .close {
      padding-left: 5px;
      font-size: 1.4375rem;
    }
  }
  .location-input.error--text .v-input__slot {
    border: 1px solid #ff0808 !important;
  }
.other-input-field {
  margin-top: -15px;
  padding-left: 2.3rem;
  height: 45px !important;
  ::v-deep .input-field {
      width: 100%;
  }
}
.other-input-field.border-red ::v-deep .v-input__slot {
  border: 1px solid red !important;
}
.other-input-field-error.v-text-field__details {
  padding-left: 2.3rem;
}
  ::v-deep {
    .v-input__slot {
      margin-bottom: 0px;
    }
  }
  .contactInfo {
    .v-text-field--outlined {
      margin-bottom: 0px !important;
    }
  }
  .label-title {
    margin-bottom: 10px;
  }
  .addLocation {
    cursor: pointer;
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 15px;
    text-decoration-line: underline;
    color: $secondary1;
  }
  .contact-input {
    min-width: 250px;
    width: 100%;
    ::v-deep .v-input__slot {
      border: 1px solid $silver !important;
    }
  }
  .line:after {
    content: '';
    display: block;
    width: 336px;
    height: 1px;
    background: #d0d0d0;
  }
  .gray-text {
    color: #828282;
  }
  ::v-deep .v-input--checkbox .v-label {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: $neutral11;
    line-height: 18px;
  }
  .filter-checkbox {
    ::v-deep {
      .v-icon {
        border-radius: 4px;
        border: 2px solid #dadada !important;
        &.mdi-check {
          border: 2px solid $primary1 !important;
          font-size: 1rem;
        }
      }
    }
  }
  ::v-deep .age-field {
    .input-field {
      margin: 10px 0px;
    }
  }
  ::v-deep {
    .v-input--radio-group__input {
      flex-direction: column !important;
      .v-label {
        font-family: $fontFamily1;
        font-weight: 500;
        font-size: 15px;
        color: $neutral11;
        line-height: 18px;
      }
      .input-field {
        min-height: 1.5rem !important;
        margin: 0px;
        background: none !important;
        box-shadow: none !important;
        border: none !important;
      }
    }
  }
  .sub-title {
    font-family: $fontFamily1;
    font-size: 16px;
    text-align: center;
    color: $neutral1;
  }
}

@media (max-width: 598px) {
  .customerForm {
    .line:after {
      content: '';
      width: 140px;
    }
  }
}
</style>
